import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export default class CreateUserGroups extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'auth/user_groups_create';
      super(baseUrl, endPoint)
    }
}