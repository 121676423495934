import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Typography } from "@mui/material";
import EditorConvertToHTML from '../../components/RichText';
import Container from '../../layout/gridContainer';
import '../../scss/adminBusiness.scss'
import { useAuth } from "user-context-fran-dev";
import BusinessManages from '../../api/businessManages';
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'

const Politics = () => {
  const [terms, setTerms] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [id, setId] = useState("");
  const { logout } = useAuth();

  const [msg, sendNotification] = useNotification();

  const UpdatePolitics = async () => {

    (async () => {
      let useBusines = new BusinessManages();
      let response = await useBusines.getAllAuth(logout);
      console.log('response', response)
      setTerms(response.data.terms_conditions);
      setPrivacy(response.data.notice_privacy);
      setId(response.data.id)
    })();

  }
  const setPolitics = async (id, formData) => {

    let updateAddress = new BusinessManages();
    const response = await updateAddress.editDataAuth(id, formData, logout);

    sendNotification(UseerrorOrsuccess(response))

  }


  useEffect(() => {
    UpdatePolitics();
  }, []);

  const handleOnClickTerms = (text) => {
    setTerms(text)
    /* console.log(terms) */
  }
  const handleOnClickPrivacy = (text) => {
    setPrivacy(text)
    /* console.log(privacy) */
  }
  return (
    <div>


      {id &&
        <Container>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              className="title-card"
              sx={{ paddingBottom: '2%' }}
            >
              <strong>Políticas</strong>
            </Typography>
          </Grid>
          <Paper className="paper">
            <Typography variant="h6" gutterBottom component="div">
              <strong>Términos y condiciones</strong>
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <br />
                <br />
                <Paper sx={{ backgroundColor: '#fff' }}>
                  <EditorConvertToHTML handleClick={handleOnClickTerms} data={terms} />
                </Paper>
                <br />
                <br />
              </Grid>

              <Grid item xs={0} sm={7} md={9} lg={10} />
              <Grid item xs={12} sm={5} md={3} lg={2} >
                <Button
                  fullWidth

                  type="submit"
                  onClick={() => setPolitics(id, { terms_conditions: terms })}
                  variant="contained"
                >
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Paper className="paper">
            <Typography variant="h6" gutterBottom component="div">
              <strong>Aviso de privacidad</strong>
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <br />
                <br />
                <Paper sx={{ backgroundColor: '#fff' }}>
                  <EditorConvertToHTML handleClick={handleOnClickPrivacy} data={privacy} />
                </Paper>
                <br />
                <br />
              </Grid>
              <Grid item xs={0} sm={7} md={9} lg={10} />
              <Grid item xs={12} sm={5} md={3} lg={2} >
                <Button
                  fullWidth
                  /* sx={{padding: "0px 8px"}} */
                  type="submit"
                  onClick={() => setPolitics(id, { notice_privacy: privacy })}
                  variant="contained"

                >
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      }
    </div>
  );
}

export default Politics;