import React, {useState, useEffect} from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initPaymentConditions, valPaymentConditions } from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from '../api/businessManages';
import Link from '@mui/material/Link';
import GenericPaymentConditions from "../components/GenericPaymentConditions"
import { Suppliers } from '../api/supplierService';
import CustomerService from '../api/customerService'
import { UseerrorOrsuccess, useNotification } from '../hooks/snackBarResponse'
import CurrencyFormat from 'react-currency-format';
import { formatNumber } from "../utils/functions";

const PaymentConditions = ({data, UpdateInfo, target}) => {
  const [open, setOpen]= useState(false);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification(); 

  var updateService = null
 
  const UpdateProfiel = async (id, formData) => {
    console.log(id)
    if(target === 'supplier'){
      updateService = new Suppliers()
    } else if(target === 'client'){
      updateService = new CustomerService()
    } else if(target === 'business'){
      updateService = new BusinessManages()
    }
      const response = await updateService.editDataAuth(id, formData, logout);
      console.log("estoy aqui")
      UpdateInfo();
      if(response){
        sendNotification(UseerrorOrsuccess(response))
      }else{
        sendNotification(UseerrorOrsuccess(response))
      }
      
  }

  const formik = useFormik({
    initialValues: initPaymentConditions(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valPaymentConditions()),
    onSubmit: async (formData) => {
        console.log(formData)
       
      setLoading(true);
  
      UpdateProfiel(data.id, formData);
     
      setLoading(false);
    },
  });


    return ( 

      <Paper  className="paper">
          <Typography variant="h6" gutterBottom component="div">
            <strong>Condiciones de pago</strong>
          </Typography>
          <Link component="button" variant="subtitle2" 
          onClick={()=>{ setOpen(true) }} underline="none" className="link">
            Editar
          </Link>
          <GenericPaymentConditions data={data} open={open} setOpen={setOpen} UpdateInfo={UpdateInfo} target={target}/>
          <form   onSubmit={formik.handleSubmit} >
            <Grid container >
              <Grid item xs={12} md={5.9}>
                <TextField
                  fullWidth
                  name="credit_days"
                  label="Días de crédito"
                  margin="dense"
                  size="small"
                  type='number'
                  value={formik.values.credit_days }
                  onChange={formik.handleChange}
                  error={
                      formik.touched.credit_days && Boolean(formik.errors.credit_days)
                  }
              
                  helperText={
                      formik.touched.credit_days ? formik.errors.credit_days : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
                
                <TextField
                  fullWidth
                  name="discount"
                  label="% descuento"
                  margin="dense"
                  size="small"
                  value={formik.values.discount+'%'}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.discount && Boolean(formik.errors.discount)
                  }
                  
                  helperText={
                      formik.touched.discount ? formik.errors.discount : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid> 
              <Grid item xs={0} md={.2}/>
              <Grid item xs={12} md={5.9}>
                <TextField
                  fullWidth
                  name="credit_limit"
                  label="Limite de Crédito"
                  margin="dense"
                  size="small"
                  value={formatNumber(formik.values.credit_limit, 2) }
                  onChange={formik.handleChange}
                  error={
                      formik.touched.credit_limit && Boolean(formik.errors.credit_limit)
                  }
              
                  helperText={
                      formik.touched.credit_limit ? formik.errors.credit_limit : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
                
               
              </Grid> 
            </Grid>
          </form>
      </Paper>
     );
}
 
export default PaymentConditions;


