import React, {useState, useEffect} from "react";
import BasicModal from "../../GenericModal";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initComment, valComment } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import CommentService from "../../../api/commentService";
import { useParams } from 'react-router-dom'
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import CountWords from "../../CountWords";


const EditGenericComments = ({setOpenEdit, openEdit, UpdateInfo, target, data}) => {
    const [loading, setLoading] = useState(false);
    const { logout } = useAuth();
    const { id } = useParams()
    const [msg, sendNotification] = useNotification();
    const [commentData, setCommentData] = useState('')
    const idComment = data.id;

    console.log('id', id)

    useEffect(() => {
        getComment()
      }, []);
    
      const getComment = () => {
        (async () => {
            let getComment = new CommentService();
            const response = await getComment.getAllByIdAuth(idComment, logout);
            setCommentData(response.data);
            console.log("contact data", response.data);
          })();
      }


    const UpdateComment = async (formData) => {
      
      let updateComment = new CommentService();
      const response = await updateComment.editDataAuth(idComment, formData , logout);
      sendNotification(UseerrorOrsuccess(response))
      UpdateInfo();
    }

    const formik = useFormik({
        initialValues: initComment(commentData),
        enableReinitialize: true,
        validationSchema: Yup.object(valComment()),
        onSubmit: async (formData) => {
          if(target == 'supplier'){
            formik.values.supplier = id
          }else if(target == 'client'){
            formik.values.client = id
          }else if(target == 'purchase_order'){
            formik.values.purchase_order = id
          }
          setLoading(true);
          console.log(formData)
          UpdateComment(formData); 
          setLoading(false);
          setOpenEdit(false)
        },
      });
      useEffect(() => {
        return () => {
          formik.resetForm();
        };
      }, [openEdit]);
    return ( 
      <BasicModal open={openEdit} setOpen={setOpenEdit} title="Editar comentario">
      <form onSubmit={formik.handleSubmit} className="comment">
            <Grid container>
                <Grid item xs={12} mt={"8px"}>
                  <TextField
                   inputProps={{ maxLength: 250 }}
                    fullWidth
                    label="Comentario"
                    multiline
                    maxRows={10}
                    name="comments"
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.comments && Boolean(formik.errors.comments)
                    }
                   
                    helperText={
                        formik.touched.comments ? formik.errors.comments : ""
                    }
                    >
                    <CountWords length={formik.values.comments.length} max="250"/>
                  </TextField>
                </Grid>
                <Grid item xs={0} md={.2}/>
              </Grid>
              <Grid container justifyContent="flex-end">
                  <Grid
                  item
                  xs={12}
                  sm={7}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ marginTop: "15px" }}
                  >
                  <LoadingButton
                      loading={loading}
                      
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ color: "#fff" }}
                  >
                      Guardar 
                  </LoadingButton>
                  </Grid>
              </Grid>

        </form>


          </BasicModal>

   );
}
 
export default EditGenericComments;