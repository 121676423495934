import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {useAuth} from 'user-context-fran-dev';
import { useNavigate } from "react-router-dom"
import { BranchService } from "../../../api/pos/branchService";
import { CashRegisterApi } from "../../../api/pos/cashRegisterService";
import { useFormik } from "formik";
import {initAddCashRegister, ValAddCashRegister} from '../../../utils/formHelper'
import * as Yup from "yup";
import { LoadingButton } from '@mui/lab';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'

export default function  AddCashRegister({setReload}){
    const [loading, setLoading] = useState('')
    const [branchList, setbranchList] = useState('')
    const {auth, logout}= useAuth();
    const [branch, setBranch] =useState('')
    const navigate = useNavigate();
    const [msg, sendNotification] = useNotification();

    useEffect(()=>{
        (async () => {

            let brancheService = new BranchService()
            let irequestFilter = []
            irequestFilter.push({'key':'user', 'value': auth.user.pk})
            let response = await brancheService.filter(irequestFilter)
            console.log('response',response.data)
            setbranchList(response.data)
            console.log(response)
          })();
    }, [])

    const handleChange = (event) => {
        console.log('evento', event.target)
        setBranch(event.target.value);
        
      };

    const formik = useFormik({
        initialValues: initAddCashRegister,
        validationSchema: Yup.object(ValAddCashRegister()),
        onSubmit: async (formData) => {
            setLoading(true)
            let cashRegisterService = new CashRegisterApi()
            formik.values.branch = branch.id
            formik.values.warehouse =  branch.warehouse
            let response = await cashRegisterService.postDataAuth(
                {
                    branch:  branch.id,
                    warehouse: branch.warehouse,
                    cash_open : {
                        amount: formik.values.amount,
                        reason: 'Abrir Caja',
                        type: 1 
                    }
                    
                }
                , logout)
            console.log('creacion de caja',response)
            sendNotification(UseerrorOrsuccess(response))
            navigateToCashRegister(response.data)
            setLoading(false)
            setReload(true)
          },
      });

  const navigateToCashRegister = (response) =>{
    navigate('/pos/pointofsale/cash-register/'+response.id+'/warehouse/'+response.warehouse+'/'+response.name)
  }

    return(
        <Grid container direction="column"  justifyContent="center" alignItems="center" >
            <Box sx={{width:300}}>
            <Grid item xs justifyContent="start" sx={{mb:'16px'}}  alignItems="start">
                        <h3>Abrir caja</h3>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
                    <Stack
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    >
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sucursal"
                        value={branch}
                        onChange={handleChange}
                        error={
                            Boolean(branch==='')
                        } 
                        >
                           {branchList &&
                            branchList.map((store, index)=>(
                                <MenuItem key={index} value={store.branch}>{store.branch.name}</MenuItem>
                            ))
                            }
                        </Select>
                        <TextField
                      fullWidth
                      name="amount"
                      label="Saldo inicial"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={
                        Boolean(formik.errors.amount)
                    } 
                    />
                      <Grid sx={{textAlign: 'end'}}>
                        <LoadingButton variant="contained" className='add-register' sx={{borderRadius: '5px'}} loading={loading} type="submit">
                            Añadir caja
                        </LoadingButton>
                      </Grid>
                    </Stack>
                    </FormControl>
                </form>
            </Box>
        </Grid>
    )
}