import { BASE_PATH } from "../../utils/constants";
import { BaseService } from 'base-service-fran-dev';


export default class StoreOrdersManages extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'store/order';
    super(baseUrl, endPoint)
  }
}

export class StoreOrdersCancel extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'store/order/cancel';
    super(baseUrl, endPoint)
  }
}

export class StoreQuotation extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'store/shipping_guide/quotation';
    super(baseUrl, endPoint)
  }
}

export class StoreGuide extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'store/shipping_guide';
    super(baseUrl, endPoint)
  }
}

export class StoreClipVerify extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'store/clip_verify';
    super(baseUrl, endPoint)
  }
}
export class StoreEnvioCliclVerify extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'store/envioclick_verify';
    super(baseUrl, endPoint)
  }
}

export class StoreWhVerify extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/warehouse/verify';
    super(baseUrl, endPoint)
  }
}

