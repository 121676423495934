import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import {getAppsCars} from '../../api/data/inicioData';
import '../../scss/home.scss'
import BusinessGroups from "../../api/businessGroups";
import { useAuth } from "user-context-fran-dev";
import UserManages from "../../api/userManages";
import HorizontalNonLinearStepper from './configStepper'
import InicioDash from '../home/HomeNav'
import { Box } from '@mui/system';


const StepperWrapper = ({changeMode, mode}) =>{

    //Todo verify if bussines exist 
 
    return(
        <Box sx={{bgcolor: 'background.default'  }} className='fondo-home'  > 
            <InicioDash changeMode={changeMode} mode={mode}/>
            <div className="container-stepper">
                <Typography 
                    variant="h6" 
                    component="div" 
                    className="titles-apps">
                    <strong>Configurar mi negocio</strong> 
                </Typography>
                <Box sx={{bgcolor: 'background.default'  }} className="box-config-business">
                <HorizontalNonLinearStepper/>
              </Box>
            </div>
        </Box>
    );
}

export default StepperWrapper;