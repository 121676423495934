import React, {useEffect, useState} from 'react'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom"
import { useAuth } from "user-context-fran-dev";
import {CashRegisterApi} from '../../../api/pos/cashRegisterService';
import {motion} from "../../../../node_modules/framer-motion/dist/framer-motion"


export default function CashRegisterPos({setHelpOpen, cashReload}){

    const [open, setOpen]=useState(false)
    const navigate = useNavigate();
    const { auth, setCashRegister, logout, setReloadUser} = useAuth()
    const [cashRegisterList, setCashRegisterList] = useState('')

    const animations ={
      hover:{
        scale: 1.01,
        
        transition: {
          duration: .3,
        }
      },
     
    }
    
    
    useEffect(()=>{
        (async () => {
        let cashRegisterService = new CashRegisterApi()
        let irequestFilter = []
        irequestFilter.push({'key':'user', 'value': auth.user.pk})
        irequestFilter.push({'key':'sts', 'value': '1'}) 
        let response = await cashRegisterService.filter(irequestFilter, logout)
        console.log('cash register', response.data['0'].warehouse)
        setCashRegisterList(response.data)
        //setWarehouse(response.data['0'].warehouse)
        console.log(response)
    })();
    },[cashReload])

    const navigateToCashRegister = (json) =>{
        console.log('localstorage',json.warehouse, json)
        let data = {wh: json.warehouse, cr: json}
        setCashRegister(data)
        setReloadUser(true)
        console.log(json)
        let name = json.branch.name.split(" ").join("")
        navigate('/pos/pointofsale/cash-register/'+json.id+'/warehouse/'+json.warehouse+'/'+name)
        setHelpOpen(false)
      }

return (
    <>
   {/* <GenericSizeModal  open={open} setOpen={setOpen} widthm={'50%'}>
        <AddCashRegister></AddCashRegister>
    </GenericSizeModal> */}
    
    <Grid container direction="column" justifyContent="start"  sx={{
          px: 3,
          padding: 0,
          
        }}>
        <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center" className='full-height' >
                <Grid item container direction="column" justifyContent="center"  alignItems="stretch"  spacing={3}>
                    <Grid item xs={10} >
                    
                    <Grid container direction="column" maxHeight={'400px'} overflow={'auto'} >
                    
                    {cashRegisterList && cashRegisterList.map((value, index)=>(
                      <Grid item key={index}>
                         <motion.div 
                          variants={animations}
                          whileHover={"hover"}
                          
                          animate={"visible"}>
                            <Button key={index} variant="contained" sx={{height: '50px', minWidth: '95%', borderRadius: '20px', mb:1}} onClick={()=>{navigateToCashRegister(value)}} >
                            {value.branch.name}
                            </Button>
                          </motion.div>
                          
                      </Grid>
                      
                    ))
                    }
                    
                    
                    </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid> 
       
    </Grid>

    </>
)     
}