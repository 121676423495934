import React, { useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { Grid, Divider, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    initValRecivedTransfer,
    valSchRecivedTransfer,
} from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { TransferDeliver, TransferReceivedApi } from "../../../api/transferService";
import ImageSize from "../../../components/ImageSize";


const TransferReceived = ({
  open,
  setOpen,
  data,
  article,
  GetPurchaseDetail,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  console.log(data);

  const formik = useFormik({
    initialValues: initValRecivedTransfer(data, article.warehouse),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchRecivedTransfer(data)),
    onSubmit: async (formData) => {
      setLoading(true);
      let setOrder = ''
      formik.values.transfer_id = data.id
      if(data.pending_deliver > 0){ 
      setOrder = new TransferDeliver()
        }else{
            setOrder = new TransferReceivedApi()
        }
      await setOrder.postDataAuth(formData, logout);
      GetPurchaseDetail();
      setLoading(false);
      setOpen(false);
    },
  });

  return (
    <BasicModal open={open} setOpen={setOpen} title="Recibir artículo">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
            <ImageSize img={data.available.model.image?.url? data.available.model.image.url :""} weight={2}/>
    {/*           <img
                src={data.available.model.image?.url? data.available.model.image.url :""}
                style={{ width: "90%", height: "auto" }}
              ></img> */}
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={12} className="">
                <h3>{data.available.model.product.name}</h3>
              </Grid>
              <Grid item xs={12} className="">
                <h4>{data.available.model.product.sku}</h4>
              </Grid> 
              <Grid item xs={12} className="">
                <h4>{data.available.model.name}</h4>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
        <Divider sx={{ height: '1px', borderColor: "text.primary", backgroundColor: "text.primary", marginTop: "8px" }}></Divider>
        </Grid>
        <Grid container  mt={1} mb={1}>
          <Grid item xs className="">
            <h5>{`Transferidos: ${data.quantity}`}</h5>
          </Grid>
          <Grid item xs className="">
            <h5>{`Entregados Pendientes: ${data.pending_deliver}`}</h5>
          </Grid>
          <Grid item xs className="">
            <h5>{`Pendientes por recibir: ${data.pending_receive}`}</h5>
          </Grid>
        </Grid>
        {data.pending_receive > 0 || data.pending_deliver > 0 ? (
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1} alignItems="center" mt={2}>
      
              <Grid item xs={3}>

                <TextField
                  fullWidth
                  label="Presentación"
                  defaultValue={data.available.presentation.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused={false}
                  size="small"
                  variant="outlined" 
                />
                </Grid>

                <Grid container item xs={6} justifyContent="center" alignItems="center">
             
                  <TextField
                    sx={{width: '55%'}}
                    fullWidth
                    type="number"
                    name="quantity"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}  
                    error={
                      formik.touched.quantity &&
                      Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity
                        ? formik.errors.quantity
                        : ""
                    }
                    autoComplete ='none'
                    label="Cantidad"
                    margin="dense"
                    size="small"
                    variant="outlined" 
                  />
            
                </Grid>
        
                
          
              
              <Grid item xs={3}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="text"
                  sx={{ color: "primary" }}
                >
                  {data.pending_deliver > 0 ? 'Entregar': 'Recibir'}
                </LoadingButton>
                </Grid>

                </Grid>
              </form>
              </Grid>
              ): (

                <Grid container justifyContent="center">
            {" "}
            <Typography variant="h5">Recibido</Typography>
          </Grid>
              )
              }
      </Grid>
    </BasicModal>
  );
};

export default TransferReceived;
