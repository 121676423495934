import React, {useEffect} from "react";
import { Grid, Typography, TextField, Link  } from "@mui/material"
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import { STATUS, STATUSCOLOR } from '../../../utils/constants'
import Chip from '@mui/material/Chip';
import ArticlesListPreview from '../../../components/inventario/tables/ArticlesListPreview'

const TableInfo = (props) => {
  const { data } = props
  const { logout } = useAuth();
  const navigate = useNavigate();

  console.log('datos en table info',data)
  
    return ( 
        <Grid spacing={2} container p={1}>
          <Grid item xs={6} mb={1}>  
            <Typography variant="subtitle1" gutterBottom component="div">
            <Chip label={STATUS[data.status]} color={STATUSCOLOR[data.status]} className="chip-with" variant="outlined"/>
            </Typography>
          </Grid>
          <Grid item xs={6} mb={1}>  
            <Grid container justifyContent="flex-end">
            <Link className="edit-buton-hover"  onClick={() => navigate(`detail/${data.id}`)} underline="none">
              <Typography variant="subtitle1" gutterBottom component="div">
                  Explorar 
              </Typography>
            </Link> 
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} >  
              <TextField
                  fullWidth
                  className="space-text-field"
                  label="Limite de credito"
                  defaultValue={data.client?.credit_limit ? data.client.credit_limit : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              <TextField
                  fullWidth
                  id="outlined-read-only-input"
                  label="Dias de credito"
                  defaultValue={data.client?.credit_days ? data.client.credit_days : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              </Grid>
              <Grid item xs={12} sm={4} >  
              <TextField
                  fullWidth
                  className="space-text-field"
                  label="Método de pago"
                  defaultValue= {data.colonia ? data.colonia : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              <TextField
              fullWidth
                  id="outlined-read-only-input"
                  label="IVA"
                  defaultValue={data.client?.iva ? data.client.iva : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              </Grid>
              <Grid item xs={12} sm={4}  > 
                <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Comentarios"
                multiline
                rows={4}
                defaultValue={data.client?.comments ? data.client.comments : ""}
                InputProps={{
                    readOnly: true,
                }}
                sx={{margin: '6px'}}
                /> 
              </Grid>
              <Grid item xs={12}>
              <ArticlesListPreview data={data}></ArticlesListPreview>
            </Grid>
          </Grid>
     );
}
 
export default TableInfo;