import React, { useState } from 'react'
import { Grid, Typography} from "@mui/material";
import GenericTable from '../../../components/GenericTable'
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DropMenu from "../../../components/dropMenu"
import GenericEmptyTable from '../../GenericEmptyTable'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import CurrencyFormat from 'react-currency-format';
import { CardHeader } from '@mui/material';
import EditArticle from '../editModals/editArticle';




const ArticlesWithTotal = (props) => {

  const { data, ...extraProp } = props
  const [open, setOpen] = useState(false);
  const [articleToEdit, setIdToEdit] = useState('');
  const [openEdit, setOpenEdit] = useState(false)

  console.log('dataOrder',data)

  return (
    <>
    { openEdit && articleToEdit && 
    <EditArticle 
    openEdit={openEdit} 
    setOpenEdit={setOpenEdit} 

    dataProduct={articleToEdit}
    orderData={data}

    type={extraProp.type}
    reloadDataOforder={extraProp.updateInfo}
    >
      
    </EditArticle>
    }
    {data.articles ? 
    <div className="articles-table">
      <GenericTable titles={["Nombre / # parte ", "Modelo / Presentación", "Cantidad", data.articles[0].price ? "Precio" : "Costo" , "Subtotal", data.status == 1 ? 'Mas': '']} >
      {data.articles.map((row, index) => (
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
          hover
          onDoubleClick={() => {
              
        /*      window.localStorage.setItem("menu", 10);
              navigate("detail"); */
            }}
        >
        <TableCell align="left" sx={{maxWidth: '25vw'}}> 
        <CardHeader
          sx={{m:0, p:0}}
            title={
              <Typography  component="div" sx={{maxWidth: ' 25vw',  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.model.product.name}
            </Typography>}
            subheader={ row.model.product.sku }
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '25vw'}}> 
        <CardHeader
          sx={{m:0, p:0}}
            title={
              <Typography  component="div" sx={{ maxWidth: '25vw',  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
               {row.model.name}
            </Typography>}
            subheader={ row.presentation.name }
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '120px'}}> 
          <Typography variant="subtitle2" component="div" item sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.quantity}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '150px'}} > 
            <CurrencyFormat value={row.price ? row.price : row.cost} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '150px'}}> 
            <CurrencyFormat value={row?.sub_total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '150px'}}> 
          <Typography variant="subtitle2" component="div" item sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {data.status == 1 &&
              <DropMenu row={row} service={extraProp.service} id={row.id} update={extraProp.updateInfo} openEdit={openEdit} setOpenEdit={setOpenEdit} setIdToEdit={setIdToEdit}></DropMenu>
            }
          </Typography>
        </TableCell>
        </TableRow>
      ))}
      </GenericTable>
      <Grid container>
        <Grid item xs></Grid>
        <Grid item >
          <TableContainer >
            <Table aria-label="spanning table">
              <TableRow className='table-row'>
              <TableRow align="left">
                    <TableCell colSpan={2}><h2>Total Bruto</h2></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2" component="div" item  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <h2>
                        <CurrencyFormat value={data.sub_total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </h2>
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow align="left">
                    <TableCell colSpan={2}><h2>Descuento ({data.discount}%)</h2></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <h2>
                        <CurrencyFormat value={data.discount_amount?data.discount_amount : "0.0"} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'} />  
                      </h2>
                      </TableCell>
                  </TableRow>
                  <TableRow align="left">
                    <TableCell colSpan={2}><h2>IVA ({data.iva}%)</h2></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <h2>
                        <CurrencyFormat value={data.iva ? (/* data.total-data.sub_total */ data.iva_amount).toFixed(2) : '0.0'} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </h2>
                      </TableCell>
                  </TableRow>
            
                  <TableRow align="left">
                    <TableCell colSpan={2}><h2>Subtotal</h2></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2" component="div" item  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <h2>
                        <CurrencyFormat value={data.sub_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </h2>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow align="left">
                    <TableCell colSpan={2}><h2>Total</h2></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <h2>
                        <CurrencyFormat value={data.total} displayType={'text'} thousandSeparator={true} prefix={'$'} />  
                      </h2>
                      </TableCell>
                  </TableRow>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div> : <GenericEmptyTable msg={"No hay artículos agregados"}/>
    } 
      
    </>
  )
}



export default ArticlesWithTotal;