import React, {useState, useEffect} from "react";
import { Grid } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import {Button} from "@mui/material";
import { AdjustmentService } from "../../../../api/inventory/adjustmentService"
import AdjustmentTable from './adjustmentTable'
import AddAdjustment from "./addAdjustment"
import CircularProgress from '@mui/material/CircularProgress';
import GenericEmptyTable from "../../../../components/GenericEmptyTable";
import BasicTable from "../../../../components/GenericTable";
import { useTableFilters } from "../../../../hooks/searchParams";
import PageParam from "../../../../components/PageParam";

const  Adjustment = () => {
const [table, setTable] = useState();
const [loading, setLoading] = useState(false)
const [openModal, setOpenModal] = useState();
const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] = useTableFilters( AdjustmentService, setTable, setLoading, "", "tab" )

return (
<Container>
    <Grid item xs={12} spacing={2}>
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
             <h2>Ajustes de inventario</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container  justifyContent="flex-end">
                    <Grid item xs={12} sm={6}>
                        <Button 
                        onClick={()=> setOpenModal(true)}
                        fullWidth 
                        variant="contained"
                        >Crear</Button>
                       <AddAdjustment openModal={openModal} UpdateAdjustment={UdateTable} setOpenModal={setOpenModal}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    <Grid item xs={12} className="margin-scrap-table">
        {table ? (
          <>
          <BasicTable className="marginTable"
          titles={["Referencia", "Fecha","Almacén", "Estado", "Total de artículos"]} >
            {table.data.map((data, index) => (
              <AdjustmentTable  key={index} data={data}/>
            ))}
          </BasicTable>
           <div className='margin'>
           <PageParam  totalpages={table.pagination} params={params} handleChange={handleSearch} />
           </div>
           </>
        ) :
        (
          <div className="model-null">
          {loading && <CircularProgress />}
          {!table && !loading &&
                <GenericEmptyTable msg={"No hay datos en la tabla"}/>
          }
        </div>
        )}
       
    </Grid>
</Container>
    )
}

export default Adjustment

