import InicioDash from './HomeNav'
import InicioApps from './Apps';
import { Box } from '@mui/system';
import '../../scss/home.scss'
import Footer from '../../components/Footer';
import { useEffect } from 'react';
import BusinessSettings from '../../api/branchManages'
import {useAuth} from 'user-context-fran-dev';
import { useNavigate } from "react-router-dom";


function Inicio({ changeMode, mode, admin }) {

  const {logout, auth}= useAuth();
  const navigate = useNavigate();

  async function getCategories() {
    let bussiness_settings = new BusinessSettings()
    let response = await bussiness_settings.getAllByIdAuth("1",logout);
    if(response.status === 204){
      console.log('respuesta',response.status)
      navigate(`/business_data`)
    }
  }
  useEffect(() => {
    getCategories()
  }, [])

  return (
    <>
      <Box sx={{ bgcolor: 'background.paper', minHeight: "calc(97.3vh - 32px)" }} /* className='fondo-home'  */ >

        <InicioDash changeMode={changeMode} mode={mode} />

        <InicioApps admin={admin} />
      </Box>
      <Footer />
    </>

  );
}

export default Inicio;