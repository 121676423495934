import React, { useState, useEffect, useMemo } from "react";
import BasicModal from "../../../components/GenericModal";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import { Link } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import CircleIcon from "@mui/icons-material/Circle";
import { PaginationGeneric } from "react-generic-pagination";
import {
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CardHeader,
} from "@mui/material";
import {
  initSearchParameters,
  valSearchparameters,
} from "../../../utils/formHelper";
import { ProductService } from "../../../api/productService";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../components/GenericTable";
import {RecipeGroup} from '../../../api/recipeManages'
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import ImageSize from "../../../components/ImageSize";
import { TableModal } from "../../../components/GenericsModals";
import SearchFilters from "../../../components/inventario/searchFilters";
import GenericEmptyTable from "../../../components/GenericEmptyTable";

const AddRecipe = ({ open, setOpen, doSomething, GoToDetailRecipe }) => {
  const [loading, setLoading] = useState(false);
  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [table, setTable] = useState(null);
  const [search, setSearch]= useState("");
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [filters, setFilters] = useState("")

  useEffect(() => {
    navigate(`page=${page}`);
    if(search) {getRecipes(search);}
  
    return () => {
      formik.resetForm();
      setTable("") 
      setSearch("")
    };
  }, [page, open]);

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };


  const formik = useFormik({
    initialValues: initSearchParameters(),
    validationSchema: Yup.object(valSearchparameters()),
    onSubmit: async (formData) => {
      setLoading(true);
      setSearch(formik.values.name)
      getRecipes(formik.values.name);
      setLoading(false);
    },
  });
  const getRecipes = (name) => {
    (async () => {
      let irequestFilter = [];
      if(filters){
        for(let element in filters){
          if(filters[element]){
              irequestFilter.push({'key':`${element}`, 'value': `${filters[element]}`})
          }
        }
      } 
      irequestFilter.push({'key':'is_active', 'value': `true`}) 
      irequestFilter.push({ key: "pattern", value: `${name}` });
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getRecipe = new ProductService();
      let response = await getRecipe.filter(irequestFilter, logout); 
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      console.log(response.data.data)
      if (response.data.data) {
        //setOpenModal(false);
    } else {
      sendNotification(UseerrorOrsuccess(response))
    } 
      
    })();
  };
  const  SetRecipeGroup= async (idProduct) => {
    let formData ={
      product: idProduct,
    }
    let setRecipe = new RecipeGroup();
    let response = await setRecipe.postDataAuth(formData, logout); 
    GoToDetailRecipe(response.data.data.id);
  }
   


  return (

      <TableModal open={open} setOpen={setOpen} title="Agregar nueva receta">

      <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          alignItems="center"
          >
          

          <Grid item xs={9} sm={4} mr="16px" >
          <TextField
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={
                formik.touched.name && Boolean(formik.errors.name)
            }
            label="Buscar por nombre"
            /* margin="normal" */
            size="small"
            variant="outlined"
            /* className="space-text-field" */
            helperText={
                formik.touched.name ? formik.errors.name : ""
            }
            />
          </Grid>
          <Grid item xs={3} sm={2} >
          <LoadingButton
              loading={loading}
              fullWidth
              type="submit"
              variant="contained"
          >
              Buscar
          </LoadingButton>
          </Grid>
            
            
          <SearchFilters filters={filters} setFilters={setFilters}/>
          </Grid>
          </form>
          </Grid>

          <Grid item xs={12} mt="16px">
        {table && (
          <Grid
            container
            sx={{maxHeight: "60vh", overflowX: "auto"}}
          >
            <Grid item xs={12}> 
            <GenericTable
              titles={["Imagen", "Nombre", "Estatus", "Categoría", "Acción"]}
            >
              {table.map((row, index) => (
                <Row row={row} key={index} doSomething={doSomething} />
              ))}
            </GenericTable>
            </Grid>

            <Grid item xs={12} mt={2}>
              <PaginationGeneric
              className="paginationStyle"
              page={page}
              total={totalpages}
              pageSetter={pageSetter}
              ></PaginationGeneric> 
            </Grid>

          </Grid>

        )}
          { !table &&  <GenericEmptyTable msg={"Sin resultados"}/>

}
        </Grid>
       
      </TableModal>

  );
};

const Row = ({ row, doSomething }) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
      >
        <TableCell align="left" sx={{ maxWidth: "200px", }}>
        <ImageSize img={row?.main_model?.image? row.main_model.image.url: ""} weight={1}/>
        </TableCell>
{/*         <TableCell align="left" sx={{ maxWidth: "120px" }}>

              <Typography variant="subtitle2" component="div">
                {row.sku}
              </Typography>
            
        
        </TableCell> */}
        <TableCell align="left" sx={{ maxWidth: "150px" }}>

              <Typography variant="subtitle2" component="div" 
              sx={{display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: "2", lineClamp: "2", overflow: "hidden"}}>
                {row.name}
              </Typography>
    
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>

              <Typography variant="subtitle2" component="div">
                {row.is_active ? (
                  <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                ) : (
                  <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                )}
              </Typography>
 
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "150px" }}>

              <Typography variant="subtitle2" component="div">
                {row.category.name}
              </Typography>
    
        </TableCell>
        <TableCell>
          <Link sx={{ cursor: "pointer" }} underline="none" onClick={() => { doSomething(row)}}>
            <Typography variant="subtitle1" component="div">
              Seleccionar
            </Typography>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AddRecipe;
