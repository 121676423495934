import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import BasicTable from "../../../components/GenericTable";
import Container from "../../../layout/gridContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import WarehouseService from "../../../api/warehouseService";
import CircularProgress from "@mui/material/CircularProgress";
import GenericEmptyTable from "../../../components/GenericEmptyTable";
import { useNavigate } from "react-router-dom";
import { PaginationGeneric } from "react-generic-pagination";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import {useAuth} from 'user-context-fran-dev'; 
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import BasicModal from "../../../components/GenericModal";
import {initValAddWareHouse, valSchAddWareHouse} from '../../../utils/formHelper';
import { TextField } from "@mui/material";
import CountWords from "../../../components/CountWords";


const AdminWareHouse = () => {
  const [loading, setLoading] = useState(true);
  const totalPerPage = 10;
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState("");



  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };
  useEffect(() => {
    navigate(`page=${page}`);
    GetWarehouses();
  }, [page]);

  const GetWarehouses = () => {
    (async () => {
      let irequestFilter = [];
      /* irequestFilter.push({ key: "get_main", value: `true` }); */
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getWarehouse = new WarehouseService();
      let response = await getWarehouse.filter(irequestFilter);

      if (response) {
        setTotalPages(response.data.pagination);
        setTable(response.data.data);
        console.log(response.data);
      }

      setLoading(false);
    })();
  };
    const OpenModal = (data, edt) => {
        setData(data);
        setEdit(edt);
        setOpen(true)
    }
 

  return (
    <Container>
      <Grid container>
      <AdminModal open={open} setOpen={setOpen} update={GetWarehouses} edit={edit} data={data}/>
        <Grid item xs={12}>
          <Grid container justifyContent="end" mb="16px">
            <Grid item xs={12} sm={4} md={3}>
  {/*             <Button
                fullWidth
                onClick={() => OpenModal("", false)}
                variant="contained"
              >
                Agregar almacén
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {table && (
            <>
              {" "}
              <BasicTable titles={["Nombre", "Descripción", "Sucursal"]}>
                {table.map((row, index) => (
                  <Row key={index} row={row} opModal={OpenModal}/>
                ))}
              </BasicTable>
              <br />
              <div className="margin">
                <PaginationGeneric
                  className="paginationStyle"
                  page={page }
                  total={totalpages}
                  pageSetter={pageSetter}
                ></PaginationGeneric>
              </div>
            </>
          )}

          {loading && !table && (
            <Grid container justifyContent="center" mt={5}>
              <CircularProgress />
            </Grid>
          )}
          {!table && !loading && <GenericEmptyTable />}
        </Grid>
      </Grid>
    </Container>
  );
};

const Row = ({ row, opModal }) => {
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
        onDoubleClick={() => { opModal(row, true)}}
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.description}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.branch ? row.name :"N/A"}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};


const AdminModal = ({open, setOpen, update, edit, data }) => {

    const [loading, setLoading] = useState(false);
    const {logout}= useAuth(); 
    const [msg, sendNotification] = useNotification();
    let getWarehouse = new WarehouseService();

    if(!edit){
        data = null;
      }
      useEffect(() => {
        return () => {
          formik.resetForm();
        };
      }, [open]);
      
    const setWareHouse = async (formData) => {
        let response = await getWarehouse.postDataAuth(formData, logout);
        if (Number(response.status) < 300) {
            setOpen(false);
          } 
          sendNotification(UseerrorOrsuccess(response))
        update();
    }
    const updateWareHouse = async (formData, id) => {
        console.log(formData);
        console.log(id);
        let response = await getWarehouse.editDataAuth(id, formData, logout);
        if (Number(response.status) < 300) {
            setOpen(false);
          }
          sendNotification(UseerrorOrsuccess(response))
        update(); 
    }

    const formik = useFormik({
        initialValues: initValAddWareHouse(data),
        enableReinitialize: true,
        validationSchema: Yup.object(valSchAddWareHouse()), 
        onSubmit: async (formData) => {
          setLoading(true);
         
          if(!edit){
            setWareHouse(formData);
          }else{
            updateWareHouse(formData, data.id);
          } 
          
          

          setLoading(false);
        },
      });

     
    
    return ( 
        <div>
  
            <BasicModal open={open} setOpen={setOpen} title={edit? 'Actualizar almacén': 'Agregar almacén'}>
            <Grid item xs={12}>
                <form onSubmit={formik.handleSubmit} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange} 
                                error={formik.touched.name && Boolean(formik.errors.name)} 
                                label="Nombre "
                                margin="dense"
                                size="small"
                                variant="outlined" 
                                autoComplete ='none'
                                inputProps={{ maxLength: 50 }}
                                helperText={formik.touched.name? formik.errors.name: ""} 
                            />
                            <CountWords length={formik.values.name.length}/>
                           
                            
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange} 
                                error={formik.touched.description && Boolean(formik.errors.description)} 
                                label="Descripción"
                                margin="dense"
                                size="small"
                                variant="outlined" 
                                autoComplete ='none'
                                inputProps={{ maxLength: 50 }}
                                helperText={formik.touched.description? formik.errors.description: ""} 
                            />
                            <CountWords length={formik.values.description.length}/>
                           
                            
                        </Grid>
                        <Grid item xs={0} sm={6} md={6} lg={6}/>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <LoadingButton loading={loading} fullWidth type="submit" variant="contained" sx={{mt:2}}  >
                                {edit? 'Actualizar almacén': 'Agregar almacén'}</LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

            </BasicModal>

        </div>
     );
}
 


export default AdminWareHouse;
