import React, { useState, useEffect } from "react";
import Container from "../../../layout/gridContainer";
import { ProductionAll, ProductionOrder } from "../../../api/productionOrder";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import { Grid } from "@mui/material";
import HeaderGeneric from "../../../components/inventario/HeaderGeneric";
import { STATUSPRODUCTION } from "../../../utils/constants";
import { Paper, Typography } from "@mui/material";
import BasicTable from "../../../components/GenericTable";
import { TableCell, TableRow, Link } from "@mui/material";
import { ProductionDelivered, ProductionReceived } from "./detailProduction/modalsProduction";
import { STATUSPRODUCTIONCOLOR } from "../../../utils/constants";
import ImageSize from "../../../components/ImageSize";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import LoadingButton from "@mui/lab/LoadingButton";
import { UseerrorOrsuccess,  useNotification } from "../../../hooks/snackBarResponse";

const DetailProduction = ({setReload}) => {
    const [data, setData] = useState("");
    const [delivered, setDelivered] = useState({idProduction: "", idRecipe: "", idPresentation:""});
    const { id } = useParams();
    const { logout, auth } = useAuth();
    const product = "1";
    const [openDelivered, setOpenDelivered] = useState(false);
    const [openReceieved, setOpenReceived]= useState(false);
    const [recieved, setRecieved] = useState("")
    const [loading, setLoading] = useState(false)
    const [msg, sendNotification] = useNotification();


    const GetOrdenProduction = () => {
        (async () => {
            let getProduction = new ProductionOrder();
            let response = await getProduction.getAllByIdAuth(id, logout)
            console.log(response.data)
            setData(response.data)
            
          })();
    }

    const OpenDeliveredModal = (n, m) => {
      setOpenDelivered(true)
      setDelivered( {idProduction: n.id, idRecipe: m.id, idPresentation: n.presentation.id})
  }
  const OpenRecivedModal = (data) => {
    setOpenReceived(true)
    setRecieved(data)
    
  }
  const ProductionAllDeliver = (id) => {
    (async () => {
      setLoading(true)
      let setOrder = new ProductionAll();
      let response = await setOrder.postDataAuth({production_order: id}, logout);
        console.log(response.data)
        sendNotification(UseerrorOrsuccess(response))
        GetOrdenProduction()
        setLoading(false)
      })();
}


    useEffect(() => {
        GetOrdenProduction();
        return () => {
          setReload((prev) => !prev);
          window.localStorage.setItem("menu",5);
        };
      }, []);

    return ( 
        <>
        {data &&
        <Container>
            {openDelivered && <ProductionDelivered open={openDelivered} 
            setOpen={setOpenDelivered} data={delivered} 
            updateOrder={GetOrdenProduction} order={data}/>}
           {openReceieved && <ProductionReceived open={openReceieved} update={GetOrdenProduction} setOpen={setOpenReceived}
           data={recieved} orden={data}/>}

              <Grid item xs={12}>
                <HeaderGeneric
                   leftTitle={ {status : STATUSPRODUCTION[data.status], color : STATUSPRODUCTIONCOLOR[data.status]}}  
                   rightTitle={"Orden de producción"}  
                   name={auth.user.first_name + " "+  auth.user.last_name}
                   noOrder={data}
                   date={data.date_created} 
                   general={data.warehouse.name}
                />

              </Grid>

              <Grid item xs={12} mt={"16px"}>
              <GenericHeaderTitle title ="Recetas">
              {data?.status === "2"? <LoadingButton loading={loading} onClick={()=>{ProductionAllDeliver(data.id)}} >Entregar todo </LoadingButton>: ""}
              </GenericHeaderTitle>
      {/*           <Paper
                    sx={{ padding: "8px 16px",  backgroundColor:'background.header' }} >
                        <Typography variant="h6" component="div">
                            <strong>Recetas</strong> 
                        </Typography>
                        
                </Paper> */}
       
              </Grid>
              <Grid item xs={12} mt={"8px"}>
                {data.recipes.map((row, index)=>{
                    const noRawProduct = row.productions.find((n)=>n.type === product)
                    const rawProduct = row.productions.filter((n)=>n.type !== product, logout)
                    const quantityP = rawProduct.some((n)=>n.status ===2)
                    console.log(noRawProduct)
                    console.log(rawProduct)
                    return(
                    <Paper key={index} sx={{mt: "16px"}}>
                        <Paper sx={{ padding: "8px 16px", backgroundColor:'background.header' }}>
                            <Grid container alignItems="center">
                              <Grid item xs={1.5}>
                                <ImageSize img={noRawProduct.model.image?noRawProduct.model.image.url: ""} weight={2}/>
                   
                              </Grid>
                              <Grid item xs={5.5} sx={{m: 0, p:0}}>
                             
                                  <h4><strong>{noRawProduct.model.product.name}</strong></h4>
                                  <p>{`${noRawProduct.model.name}, ${noRawProduct.presentation.name}`}</p>
                                
                              </Grid>
                              <Grid item xs={!quantityP?3:5}>
                                <Grid container justifyContent="flex-end">
                                       <h2> {parseInt(row.quantity)}</h2>
                                </Grid>
                                
        
                              </Grid>
                              <Grid item xs={!quantityP ?2: 0}>
                                {!quantityP && noRawProduct.status !== 4 &&
                                <Grid container justifyContent="flex-end">
                                    <Link sx={{ cursor: "pointer" }} underline="none" onClick={() => {OpenRecivedModal(noRawProduct)}}>
                                      <Typography variant="subtitle1" component="div">
                                          Recibir
                                      </Typography>
                                  </Link>
                              </Grid>
                              }
                                  {noRawProduct.status === 4 &&  <Grid container justifyContent="flex-end">
                                    <Link sx={{ cursor: "pointer", color: "text.secondary" }} underline="none">
                                      <Typography variant="subtitle1" component="div">
                                          Recibido
                                      </Typography> 
                                  </Link>
                              </Grid>}
                              </Grid>
                            </Grid>
                        </Paper>
                        <Grid container mt={1} p={2} >
                      <Grid item xs={12} sx={{display:"flex", justifyContent: "space-between"}}>   
                          {/* {`Materia prima para ${parseInt(noRawProduct.quantity)} unidades`}  */}
                          {`Materia prima para (${parseInt(row.quantity_recipe)} x ${parseInt(row.quantity)}) unidades`} 
                        
                      </Grid>
             
                      <Grid item xs={12}>
                        {rawProduct && 
                        <BasicTable
                          titles={quantityP?[
                            "#",
                            "Cantidad",
                            "Nombre",
                            "Modelo",
                            "Presentación",
                            "Acción"
                          
                          ]:[
                            "#",
                            "Cantidad",
                            "Nombre",
                            "Modelo",
                            "Presentación",
                           
                          
                          ]}
                        >
                         {rawProduct.map((data, index)=>(
                                <Row key={index} row={data} index={index} setDelivered={OpenDeliveredModal} data={row} />
                          ))} 
                          
                        </BasicTable>
                        }
               
               
        
                      </Grid> 
        
                    </Grid>
                      </Paper>
                    );
                })}
              </Grid>
          

        </Container> 
          }
        </>
    );
}

const Row = ({row, index, setDelivered, data }) => {

    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
        <TableCell align="left" sx={{ maxWidth: "100px" }}>
          <Typography variant="subtitle1" component="div">
            {index+1 +'-'}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "100px" }}>
          <Typography variant="subtitle1" component="div">
            {row.quantity}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "100px" }}>
          <Typography variant="subtitle1" component="div">
            {row.model.product.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "150px" }}>
          <Typography variant="subtitle1" component="div">
            {row.model.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "100px" }}>
          <Typography variant="subtitle1" component="div">
            {row.presentation.name}
          </Typography>
        </TableCell>
        {row.status === 2 && 
        <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Link sx={{ cursor: "pointer" }} underline="none" onClick={() => {setDelivered(row, data)}}>
            <Typography variant="subtitle1" component="div">
            Entregar
            </Typography>
        </Link>
        </TableCell>
        }
                {row.status === 3 && 
        <TableCell align="left" sx={{ maxWidth: "100px" }}>
  
            <Typography variant="subtitle1" component="div">
            Entregado
            </Typography>
   
        </TableCell>
        }
   
      </TableRow>
    );
  };
 
export default DetailProduction;