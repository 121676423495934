import React from "react";
import Container from "../../layout/gridContainer";
import Articles from "./articles/articles";

const CatalogArticles = () => {


    return ( 
    <Container>
        <Articles /> 
    </Container> 
    );
}
 
export default CatalogArticles;