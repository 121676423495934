import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export  class RecipeService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/recipe';
      super(baseUrl, endPoint)
    }
  }
  

  export  class RecipeGroup extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/recipe_group';
      super(baseUrl, endPoint)
    }
  }

  export  class RawRecipe extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/raw_material';
      super(baseUrl, endPoint)
    }
  }
  