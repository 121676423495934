import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export  class SectionsCreator extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'landing/section';
      super(baseUrl, endPoint)
    }
}

export  class ImageCreator extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/image';
    super(baseUrl, endPoint)
  }
}  

export  class SubcomponentCreator extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/subcomponent';
    super(baseUrl, endPoint)
  }
}


export  class ComponentCreator extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'landing/component';
    super(baseUrl, endPoint)
  }
}