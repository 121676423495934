
import { BusinessSettings } from "../businessManages";
import { DomainList } from "../landing/domainList";
import { useAuth } from "user-context-fran-dev";

export const CreateMenu= ( logout) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    let newData = [];
    /* let Fmenu = getData(id);
    console.log(text)
    Fmenu= Fmenu.menu.find((x)=> x.text === text)
    console.log(Fmenu) */

    

    const UpdateStyles = () => {
        
    (async () => {
        let useBusines = new BusinessSettings();
        let response = await useBusines.getAllByIdAuth("1",logout);
        GetListPages(response.data.domain)
    })();
    };
    const GetListPages =async (domain) => {
 
        let irequestFilter = [];
        irequestFilter.push({ key: "domain", value: `${domain}`}); // TODO:  test.74-208-117-143.plesk.page ${domain}
        let getArticle = new DomainList();
        let response = await getArticle.filter(irequestFilter, logout);
     

        for (const key in response.data) {
            if(response.data[key].is_active){
            newData.push({text: response.data[key].name, url: response.data[key].link.replace("/", "")}); //{text: element.name, url: element.replace("/", "")}
            }
        }

        
    
     }
       
        UpdateStyles();

    

      return newData;
        

}
 
