import * as React from 'react';
import Box from '@mui/material/Box';
import '../scss/modal.css'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Grid, Typography } from "@mui/material"
import Grow from '@mui/material/Grow';
import Backdrop from '@mui/material/Backdrop';


export default function GenericSizeModal(props) {
  const {open, setOpen, children} = props

  return (
    

      <Modal
        open={open || false}
        onClose={()=> setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Grid container justifyContent="center"
              alignItems="center"  className='modal-height' > 
          <Grid
            className='box'
            item xs={5} sm={9} md={8} lg={7} xl={6}
            sx={{bgcolor:'background.paper'}} >
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6" gutterBottom component="div">
                    {props.title}
                </Typography>
              </Grid>
              <Grid item xs={1}   > 
                <Grid container justifyContent="flex-end">
                  <IconButton 
                
                    className='close-icon'
                    onClick={()=> setOpen(false)}
                    aria-label="delete">
                    <CloseIcon className='icon' sx={{color: 'text.primary'}} />
                  </IconButton>
                </Grid>
                
              </Grid>
              <Grid item xs >
              {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Modal>
    
  );
}