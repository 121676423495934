import React, { useEffect, useState } from "react";
import HeaderGeneric from "../../../components/inventario/HeaderGeneric";
import Container from "../../../layout/gridContainer";
import { useParams } from 'react-router-dom'
import { useAuth } from "user-context-fran-dev";
import { Transfers } from "../../../api/transferService";
import { Paper, Grid, Box } from "@mui/material";
import TransferReceived from "./TransferReceived";
import { STATUSTRANSFER, STATUSTRANSFERCOLOR } from "../../../utils/constants";
import GenericEmptyTable from "../../../components/GenericEmptyTable"
import TransferReceiveList from "../../../components/inventario/tables/ArticlesTransferList";
import { Typography } from "@mui/material";

const InventoryWHPurchaseDetail = ({ setReload }) => {

  const { id } = useParams()
  const { logout, auth } = useAuth()
  const [data, setData] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [modalInfo, setModalInfo] = useState("");



  const GetPurchaseDetail = () => {
    (async () => {
      let getTransfer = new Transfers();
      let response = await getTransfer.getAllByIdAuth(id, logout)
      console.log(response.data)
      setData(response.data)
    })();
  }
  useEffect(() => {
    GetPurchaseDetail()
    window.localStorage.setItem("menu", 24);
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 5);
    };
  }, []);

  return (
    <>
      {data &&
        <Container>
          <Box sx={{ p: '20px', mb: '15px' }}>
            <Grid container>
              <Grid item xs={12} mb={3}>
                <HeaderGeneric
                  leftTitle={{ status: STATUSTRANSFER[data.status], color: STATUSTRANSFERCOLOR[data.status] }}
                  rightTitle={"Orden de transferencias"}
                  name={`${auth.user.first_name} ${auth.user.last_name}`}
                  noOrder={data}
                  date={data.date_created}
                  transfer={data}
                />

              </Grid>
              <Grid item xs={12} mt={"16px"}>
                <Paper
                  sx={{ padding: "8px 16px", backgroundColor: 'background.header' }} >
                  <Typography variant="h6" component="div">
                    <strong>Artículos</strong>
                  </Typography>
                </Paper>

              </Grid>
              <Grid item xs={12}>
                {modalInfo && <TransferReceived open={openModal} setOpen={setOpenModal} data={modalInfo}
                  GetPurchaseDetail={GetPurchaseDetail}
                  article={data} />}
                {data && <TransferReceiveList data={data} setModalInfo={setModalInfo} setOpenModal={setOpenModal}></TransferReceiveList>}
                {!data &&

                  <GenericEmptyTable msg={"No hay datos en la tabla"} />
                }
                {/* <InventoryGenericDetailsView name="Proveedor" pay="Condiciones de Pago" data={data}  type={"supplier"}>
            {data &&<ArticlesWithoutTotal data={data} setModalInfo={setModalInfo} setOpenModal={setOpenModal}></ArticlesWithoutTotal>}
              {!data &&
              
              <GenericEmptyTable msg={"No hay datos en la tabla"}/>
            }
            </InventoryGenericDetailsView> */}

              </Grid>


            </Grid>
          </Box>
        </Container>
      }
    </>


  );
}

export default InventoryWHPurchaseDetail;