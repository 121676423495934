import React from "react";
import { Grid, Typography, TextField, Link  } from "@mui/material"
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";

const TableInfo = (props) => {
  const { data } = props
  const navigate = useNavigate();
  
    return ( 
        <Grid spacing={2} container p={1}>
          <Grid item xs={6} mb={1}>  
            <Typography variant="h6" gutterBottom component="div">
                {data.business_name}
            </Typography>
          </Grid>
          <Grid item xs={6} mb={1}>  
            <Grid container justifyContent="flex-end">
            <Link className="edit-buton-hover"  onClick={() => navigate(`detail/${data.id}`)} underline="none">
              <Typography variant="h6" gutterBottom component="div">
                  Editar 
              </Typography>
            </Link> 
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} >  
              <TextField
                  fullWidth
                  className="space-text-field"
                  label="Calle y numero"
                  defaultValue={data.street ? data.street : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              <TextField
              fullWidth
                  id="outlined-read-only-input"
                  label="Teléfono"
                  defaultValue={data.phone ? data.phone : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              <TextField
              fullWidth
                  id="outlined-read-only-input"
                  label="% descuento"
                  defaultValue={data.discount ? data.discount : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              </Grid>
              <Grid item xs={12} sm={4} >  
          
              <TextField
                  fullWidth
                  className="space-text-field"
                  label="Colonia y C.P"
                  defaultValue= {data.colonia ? data.colonia : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              <TextField
              fullWidth
                  id="outlined-read-only-input"
                  label="Correo electrónico"
                  defaultValue={data.email ? data.email : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              <TextField
              fullWidth
                  id="outlined-read-only-input"
                  label="Días de crédito"
                  defaultValue= {data.credit_days ? data.credit_days : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
              </Grid>
              <Grid item xs={12} sm={4}  >  
          
              <TextField
                  fullWidth
                  className="space-text-field"
                  label="Estado y cuidad"
                  defaultValue={data.state ? data.state : ""}
                  InputProps={{
                      readOnly: true,
                  }}
                  sx={{margin: '6px'}}
                  />
                    <TextField
                      fullWidth
                      id="filled-multiline-flexible"
                      label="Comentarios"
                      multiline
                      maxRows={4}
                      defaultValue={data.comments ? data.comments : ""}
                      InputProps={{
                          readOnly: true,
                      }}
                      sx={{margin: '6px'}}
                      
                      />
              
              </Grid>
              <Grid item xs={12} sm={12}>
                  <Typography variant="h6" gutterBottom component="div">
                      Contacto 
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextField
                      fullWidth
                      className="space-text-field"
                      label="Nombre"
                      defaultValue={data.business_name ? data.business_name : ""}
                      InputProps={{
                          readOnly: true,
                      }}
                      sx={{margin: '6px'}}
                      />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextField
                      fullWidth
                      className="space-text-field"
                      label="Teléfono"
                      defaultValue={data.phone ? data.phone : ""}
                      InputProps={{
                          readOnly: true,
                      }}
                      sx={{margin: '6px'}}
                      />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextField
                      fullWidth
                      className="space-text-field"
                      label="Correo electrónico"
                      defaultValue={data.email ? data.email : ""}
                      InputProps={{
                          readOnly: true,
                      }}
                      sx={{margin: '6px'}}
                      />
              </Grid>
          </Grid>
     );
}
 
export default TableInfo;