import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export default class posAdminSettings extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'pos/settings';
      super(baseUrl, endPoint)
    }
}