import  React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Grid} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box  } from '@mui/material';
import { Link } from "react-router-dom";
import '../../scss/home.scss'

import {makeStyles} from '@mui/styles';
import {motion} from "../../../node_modules/framer-motion/dist/framer-motion"
import { useSnackbar } from 'notistack';


const useStyles = makeStyles(() => ({
  actionArea: {
    borderRadius: "32px",
    transition: ' all 0.2s',
    
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));




 function  InicioCard ({data, buy, access, sizes, TypeModal}) {
 
  console.log(data.name)
  console.log(access)
  const [style, setStyle]= useState("")
  const { enqueueSnackbar } = useSnackbar();
  

  useEffect(()=>{
    if(!buy){
      setStyle("to-buy")
    }else if(!access){
      setStyle("need-access")
    }
    
  },[]);

  const animations ={
    hidden: {
      scale: 0
    },
    visible: ( {blok})=>({
      scale: 1,
      filter: blok,
      transition: {

        duration: 0.3
      }
    }) ,
    hover:{
      scale: 1.1,
      transition: {
        duration: .3,
      }
    },
    blok:{
      //x: [0, -20,20,-20,20,0],
      /* transform: ["translate(0)", "translate(.6em)","translate(-.6em)","translate(0)"], */
      transition: {
        duration: .9,
      }
    },
/*   cus: ({delay, blok})=>({

  }) */
  }

  const goTo = (data)=>{
    if(!access || !buy){
      return "#"
    }
    //console.log("no loader", data)
    if(data.notFinish){
      console.log("no loader")
      return "#"
    }
    if(data.name === "cashRegister"){
      return `/home?tab=2`
    }
    if(data.notHome){
      if(data.name === "inventory"){
        return `/${data.name}/${data.menu[3].nestedMenu[1].url}`
      }
      return `/${data.name}/${data.menu[2].url}`
    }


    return  `/${data.name}/home`
  }


  const goClick = (data,e)=>{
    if(!buy){
      enqueueSnackbar("No las has comprado", {
        variant: "warning",
        autoHideDuration: 1000,
        //action
    });
      return;
    }
    if(!access){
      enqueueSnackbar("No tienes acceso", {
        variant: "warning",
        autoHideDuration: 1000,
        //action
    });
      return;
    }
    if(data?.notFinish){
      /* return `/pos/login/`; */
      console.log('nombre del dato',data.name)
      if(data.name == "pos"){
        TypeModal("pos")
      }
      else{
        TypeModal("build")
      }
      
      return;
    }
    if(data.name == "cashRegister"){
      TypeModal("cashRegister")
    }
    window.localStorage.setItem('menu',data.id)
  }



  return ( 
<motion.div 
    custom={{ blok: (!access || !buy? "": "")}}
    variants={animations}
    whileHover={(!access || !buy)? "blok": "hover"}
    initial={"hidden"}
    animate={"visible"}
    >
    <Card sx={{ width: 150, boxShadow: 'none', }}>
    
    
     
    
    <Box textAlign='-webkit-center'> 
    <Link 
      className='link-custom'
      underline="none"
      onClick={(e)=>{goClick(data,e)}} 
      to={goTo(data)}  
      
      /* target={  data.name === 'pos'  ? "_blank": '' } */>
      <CardActionArea >
        <CardMedia
          component="img"
          sx={{borderRadius: "10px", width:{md:"150px", xs:"100px"}}} //cover fill contain
          height="auto"
          image= {data.images}
          alt={data.title}
        />
   {/*        <Typography variant="h5" sx={{position: 'absolute', color: 'black', top: '40%', left:'55%', transform: 'translate(-50%, -50%)'}}>
        {!buy && "Compara la app"}
        {!access && 'No tienes permisos'}
      </Typography> */}
        <CardContent sx={{padding: '8px'}}>
         
        </CardContent>
      </CardActionArea>
      </Link>
      <Grid>
  <Typography gutterBottom variant="h6" component="div" sx={{fontSize:{xs:'1.1em', md:'1em', xl:'1em'}}} >
            {data.title}
          </Typography>
  </Grid>
      </Box>
   
    
  
  
  </Card>
  </motion.div>  

   );
}

export default InicioCard;


export const AppD = ({data, buy, access, sizes}) => {

  const [style, setStyle]= useState("")
  const styles = useStyles();
  useEffect(()=>{
    if(!buy){
      setStyle("to-buy")
    }else if(!access){
      setStyle("need-access")
    }
  },[]);

  return ( 
  <div>
        <Link 
      onClick={()=>{access && buy? window.localStorage.setItem('menu',data?.id): console.log("No access")}} 
      to={`/${data?.name}/home`}  
      style={{textDecoration : 'none'}} >
    <Card sx={{ width: 150, boxShadow: 'none', borderRadius: "32px" }} className={` ${style?style: styles.actionArea}   scale-in-center` } >
      <CardActionArea>
        <CardMedia
          component="img"
          sx={{borderRadius: "32px", height:{xs: "10px", md:"180"}}}
          image={data?.images}
          alt={data?.title}
        />
        <Box sx={{display: "flex", justifyContent: "center", padding: 0}}>
        <CardContent sx={{padding: "8px !important"}}>
          <Typography  variant="h6"  sx={{textAlign: "center", padding: 0}}>
             {data?.title}
          </Typography>
        </CardContent>
        </Box>
      </CardActionArea>
    </Card>
    </Link>

  </div> );
}
 
 
