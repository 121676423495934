import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import BasicModal from '../../../../components/GenericModal'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import { valSchAddRecipe, initAddRecipe } from "../../../../utils/formHelper";
import { RecipeService } from "../../../../api/recipeManages";

const RecipeModal = ({open, setOpen, isEdit, form, update}) => {
    const [loading, setLoading] = useState(false);
    const { auth, logout } = useAuth();
    /* console.log(form) */

    useEffect(()=>{

      return()=> {
        formik.resetForm();
      }
    },[open])

    const formik = useFormik({
        initialValues: initAddRecipe(form, isEdit),
        enableReinitialize: true,
        validationSchema: Yup.object(valSchAddRecipe()),
        onSubmit: async (formData) => {
          setLoading(true);
          console.log(formData)
          if(!isEdit){
          SetRecipe(formData)
        }else{
          PutRecipe(formData, isEdit.id)


        }
          
          setLoading(false);
          setOpen(false)
        },
      });

      const  SetRecipe= async (formData) => {

        /* console.log(formData) */
        let setRecipe = new RecipeService();
        let response = await setRecipe.postDataAuth(formData, logout); 

        update()
      }
      const  PutRecipe= async (formData, id) => {

        /* console.log(formData) */
        let setRecipe = new RecipeService();
        let response = await setRecipe.editDataAuth(id, formData, logout); 

        update()
      }

    return ( 
        
            <BasicModal open={open} setOpen={setOpen} title={ isEdit?"Editar Receta" : "Agregar receta"}>
                <Grid item xs={12} >
                  <form onSubmit={formik.handleSubmit}> 
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="quantity"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                        label="Cantidad"
                        margin="normal"
                        size="small"
                        variant="outlined" 
                        autoComplete ='none'
                        className="space-text-field"
                        helperText={formik.touched.quantity ? formik.errors.quantity : ""}
                        />
                    </Grid>
                    <Grid container justifyContent='flex-end' mt={'16px'}>
                        <Grid item xs={12} sm={6} md={4} lg={3} >
                        <LoadingButton
                            loading={loading}
                            fullWidth
                            type="submit"
                            variant="contained"
                            >
                                {isEdit? "Editar": "Crear"}
                            </LoadingButton>
                        </Grid>

                    </Grid>
                    </form> 
                    </Grid>

            </BasicModal>
        
     );
}
 
export default RecipeModal;