import React, { useEffect, useState } from "react";
import { Paper, Stack, Typography, IconButton, Select, MenuItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Grid, Link, Button } from "@mui/material";
import Container from "../../layout/gridContainer";
import "../../scss/adminBusiness.scss";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { adminSettings } from "../../api/settings";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from "../../api/businessManages";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValSettingContact, valSettingContact, initValSettingDomain, valSettingDomain } from "../../utils/formHelper";
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomizedDialogs from "../../components/GenericsModals";

const Settings = () => {
  const { logout } = useAuth();
  const [codes, setCodes] = useState()
  const [business, setBusiness] = useState()
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    updateCodes();
    getBusiness();
  }, [])

  const updateCodes = () => {
    (async () => {
      let settings = new adminSettings();
      let response = await settings.getAllAuth(logout);
      console.log(response.data)
      setCodes(response.data)
    })();
  };
  const getBusiness = () => {
    (async () => {
      let useBusines = new BusinessManages();
      let response = await useBusines.getAllAuth(logout);
      console.log('response', response)
      setBusiness(response.data)
    })();
  };

  const formik = useFormik({
    initialValues: initValSettingContact(business),
    enableReinitialize: true,
    validationSchema: Yup.object(valSettingContact()),
    onSubmit: async (formData) => {
      setLoading(true);
      console.log(formData)
      UpdateSocialMedia(business.id, formData)
      setLoading(false);

    },
  });

  const UpdateSocialMedia = async (id, formData) => {
    let updateSocialMedia = new BusinessManages();
    const response = await updateSocialMedia.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    getBusiness();
  }


  return (
    <div>
      <Container>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"
            sx={{ paddingBottom: '2%' }}
          >
            <strong>Configuración</strong>
          </Typography>
        </Grid>
        <ValidarEmail open={open} setOpen={setOpen} />
        <Grid item xs={12}>
          <Paper className="paper">
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h6" gutterBottom component="div">
                  <strong>Código de verificación para aplicación móvil </strong>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Link
                  component="button"
                  variant="subtitle1"
                  onClick={() => { }}
                  underline="none"
                  className="link"
                >
                  Mostrar
                </Link>
              </Grid>
            </Grid>
            <Typography variant="subtitle2" gutterBottom>
              Códigos vigentes (Expiran en 10 minutos)
            </Typography>
            <Stack spacing={"8px"} mt={"8px"}>
              {codes && codes.map((row, index) => (
                <CodeShow key={index} row={row} />
              ))}
              {/*    <CodeShow/>
            <CodeShow/> */}
            </Stack>

          </Paper>
        </Grid>
        {/* <Paper className="paper">
          <Typography variant="h6"  component="div">
            <strong>Correo de salida (SMTP)</strong>
          </Typography>
          <Typography variant="body2" gutterBottom component="div" sx={{color: "text.secondary"}}>
          Si no cuentas con un correo de salida, puedes dejar en blanco esta sección. Los correos saldrán de contacto@keskinube.com

          </Typography>
          <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={"8px"}>
              <Grid item xs={6}>
                <TextField
                  
                  fullWidth
                  name="email_notify"
                  value={formik.values.email_notify}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email_notify && Boolean(formik.errors.email_notify)
                  }
                  label="Correo electrónico"
              
                  
                  size="small"
                  variant="outlined"
                  autoComplete ='none'
             
                  helperText={
                    formik.touched.email_notify ? formik.errors.email_notify : ""
                  }
    
                />
                <TextField
                  fullWidth
                  label="Host"
                  id="email_host"
                  name="email_host"
                  placeholder="mail.midominio.com"
                  value={formik.values.email_host}
                  onChange={formik.handleChange} 
                  sx={{marginTop: "8px"}}
                  size="small"
                  autoComplete ='none'
                  helperText={formik.errors.email_host} 
                  error={
                    formik.touched.email_host && Boolean(formik.errors.email_host)
                }
                />
              </Grid>
              <Grid item xs={6} >
              <FormControl fullWidth  variant="outlined" >
              <InputLabel size="small"  htmlFor="outlined-adornment-password" 
                error={
                  formik.touched.email_password && Boolean(formik.errors.email_password)
                }>Contraseña</InputLabel>
                <OutlinedInput
                  fullWidth
                  label="Contraseña"
                  id="email_password"
                  name="email_password"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.email_password}
                  onChange={formik.handleChange} 
                  
                  size="small"
                  autoComplete ='none'
                  helperText={formik.errors.email_password} 
                  error={
                    formik.touched.email_password && Boolean(formik.errors.email_password)
                }
                
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff sx={{color: 'text.secondary'}}/> : <Visibility sx={{color: 'text.secondary'}}/>}
                    </IconButton>
                  </InputAdornment>
                }
                />
                <FormHelperText id="outlined-weight-helper-text" sx={{color: '#d7281c'}}>
                     {formik.touched.email_password ? formik.errors.email_password : ""}
                     </FormHelperText>
                </FormControl>



{/* 
                <TextField
                  fullWidth
                  label="Port"
                  id="email_port"
                  name="email_port"
                  value={formik.values.email_port}
                  onChange={formik.handleChange} 
                  sx={{marginTop: "5px"}}
                  autoComplete ='none'
                  size="small"
                  helperText={formik.errors.email_port} 
                  error={
                    formik.touched.email_port && Boolean(formik.errors.email_port)
                }
                
                /> //

          <FormControl  sx={{marginTop: "5px"}} fullWidth variant="outlined" size="small" >
                      <InputLabel id="demo-simple-select-filled-label">
                           Port
                      </InputLabel>
                      <Select
                          name="email_port"
                          labelId="demo-simple-select-standard-label"
                          label={"Port"}
                          value={formik.values.email_port}
                          onChange={formik.handleChange} >
                       {/*    <MenuItem value={""}>
                              ninguno
                          </MenuItem> 
                          {[25,465,587,2525].map((data, index) => (
                              <MenuItem key={index} value={data}>
                                 {data}
                              </MenuItem>
                          ))}
                      </Select>

                  </FormControl>
              </Grid>

              <Grid item xs={0} sm={8} md={8} lg={9} />
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <br />
                <LoadingButton
                  fullWidth
                  className="button"
                  type="submit"
                  loading={loading}
                  variant="contained"
                >
                  Verificar y actualizar
                </LoadingButton>
{/*                 <LoadingButton
                  fullWidth
                  className="button"
                  onClick={()=> setOpen(true)}          
                  variant="contained"
                >
                  Verificar y actualizar
                </LoadingButton> //
              </Grid>
            </Grid>
          </form>
          </Grid>
        </Paper> */}
        <Domain data={business} getBusiness={getBusiness} />
      </Container>
    </div>
  );
};


const Domain = ({ data, getBusiness }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification(); /* https://charming-yonath.74-208-117-143.plesk.page */

  const formik = useFormik({
    initialValues: initValSettingDomain(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSettingDomain()),
    onSubmit: async (formData) => {
      setLoading(true);
      console.log(formData)
      UpdateSocialMedia(data.id, formData)


    },
  });



  const UpdateSocialMedia = async (id, formData) => {
    let updateSocialMedia = new BusinessManages();
    const response = await updateSocialMedia.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    getBusiness();
    setLoading(false);
  }


  return (
    <Paper className="paper">
      <Typography variant="h6" gutterBottom component="div">
        <strong>Dominio</strong>
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Dominio"
              id="domain"
              name="domain"
              value={formik.values.domain}
              onChange={formik.handleChange}
              margin="dense"
              size="small"
              autoComplete='none'
              helperText={formik.errors.domain}
              error={
                formik.touched.domain && Boolean(formik.errors.domain)
              }
            />
          </Grid>

          <Grid item xs={0} sm={8} md={9} lg={10} />
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <br />
            <LoadingButton
              fullWidth
              className="button"
              type="submit"
              loading={loading}
              variant="contained"
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}


const CodeShow = ({ row }) => {
  const [copy, setCopy] = useState("Copiar")

  const handleCopy = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(row.code)
    setCopy("Copiado")
  }
  return (
    <Grid item xs={12} sm={10} >
      <Grid container>
        <Grid item xs={4} sm={3}>
          <Typography variant="body1" gutterBottom>
            {row.code}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={5}>
          <Typography variant="body1" gutterBottom className="left-text">
            <Dia text={row.date_created} />
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>

          <Tooltip title={copy}>

            <ContentCopyIcon
              onClick={(e) => handleCopy(e)}
              className="left-text"
              fontSize="small"
              sx={{ cursor: "pointer" }}
            />

          </Tooltip>


        </Grid>
      </Grid>

    </Grid>
  );
}
const Dia = ({ text }) => {
  console.log('fecha en warehouse', text)
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  return (
    <Typography variant="h7" component="div">
      {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
    </Typography>
  );
};

const ValidarEmail = ({ open, setOpen }) => {
  return (
    <CustomizedDialogs open={open} setOpen={setOpen} title="Validar cuenta de correo de salida" size={"xs"} actions={
      <>
        <Button>Cancelar</Button>
        <Button variant="contained">Actualizar ahora</Button>
      </>
    }>
      <Typography>
        Hemos enviado un correo electrónico a  business.email del correo business.email_notify si lo recibiste, presiona actualizar ahora,
        si no, vuelve a la configuración e ingresa los parámetros correctos.  Si el correo de salida no es configurado correcta mente, no podrás agregar usuarios ni clientes.

      </Typography>

    </CustomizedDialogs>
  );
}



export default Settings;
