import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { Box } from "@mui/material";
import TableInfo from './tableInfo';
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CurrencyFormat from 'react-currency-format';


const SuppliersTable = (props) => {
  const { data } = props
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
        onDoubleClick={() => {
          console.log(data)
          window.localStorage.setItem("menu", 18);
          navigate(`detail/${data.id}`);
        }}
      >
        <TableCell sx={{ minWidth: "20px", maxWidth: "15vw" }} align="left" >
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.business_name}
              </Typography>}
            subheader={data.rfc}
          />
        </TableCell>
        <TableCell sx={{ minWidth: "20px", maxWidth: "15vw" }} align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CurrencyFormat value={data.phone} displayType={'text'} format="(##) #### ####" />
              </Typography>}
            subheader={data.email}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => { setOpen(!open) }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableInfo data={data} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SuppliersTable;