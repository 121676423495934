import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export const TabStyles = ({ backgroundContent, contentContent, children, activeTab, handleTabChange }) => {
    return (
        <Box>
            <Tabs
                value={activeTab}
                onChange={(event, newValue) => handleTabChange(newValue)} //Permite manejar el cambio de pestaña. 
                indicatorColor="primary"
                textColor="primary"
                centered
                variant='fullWidth'
            >
                <Tab
                    label={
                        <span style={{ position: 'relative', textTransform: 'none', fontSize: '1.2em' }}>
                            <DesignServicesIcon style={{ position: 'absolute', left: '-32px', top: '50%', transform: 'translateY(-50%)' }} />
                            {backgroundContent}
                        </span>
                    }
                />
                <Tab
                    label={
                        <span style={{ position: 'relative', textTransform: 'none', fontSize: '1.2em' }}>
                            <ContentPasteIcon style={{ position: 'absolute', left: '-32px', top: '50%', transform: 'translateY(-50%)' }} />
                            {contentContent}
                        </span>
                    }
                />
            </Tabs>
            <Typography>
                {children}
            </Typography>
        </Box>
    );
};

