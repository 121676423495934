/*Aqui configuramos la seleccion de categoria de links*/
import React, { useEffect, useState } from "react";
import {
    Grid,
    Divider,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import CustomizedDialogs, { BottonModalDiag } from "../GenericsModals";
import { useAuth } from "user-context-fran-dev";
import GenericEmptyTable from "../GenericEmptyTable";
import { CircularProgress } from "@mui/material";
import CategoryManages from "../../api/categoryManages";
import Link from '@mui/material/Link';

const CategoryLink = ({ open, setOpen, formiko }) => {
    const [data, setData] = useState("");
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState(null);

    const UdateTable = () => {
        (async () => {
            setLoading(true)
            let getCategorys = new CategoryManages();
            let response = await getCategorys.getAllAuth(logout);
            setTable(response.data)
            setLoading(false)
        })();
    }

    useEffect(() => {
        UdateTable();
    }, [open])



    return (
        <CustomizedDialogs open={open} setOpen={setOpen} title={"Seleccionar categoría"} size={"md"}>
            <Grid container>

                <Grid item xs={12}>
                    {table &&
                        <List className="list">
                            {table.map((data, index) => (
                                <div key={index}>
                                    <ListItem >

                                        <ListItemText primary={`${index + 1}. ${data.name}`} />

                                        <Link
                                            edge="end"
                                            component="button"
                                            variant="body2"
                                            underline="none"
                                            onClick={() => {
                                                formiko.values.button_action = "/store?page=1&category=" + data.id;
                                                setOpen(false)
                                            }}>
                                            Seleccionar
                                        </Link>

                                    </ListItem>
                                    <Divider />
                                </div>
                            ))}
                        </List>
                    }

                    {!table && loading && (
                        <Grid container justifyContent="center" p={"24px"}>
                            <CircularProgress />
                        </Grid>
                    )}
                    {!table && !loading && <GenericEmptyTable />}
                    {/* {!data && !loading  && <GenericEmptyTable msg={`No se encontró resultado para "${params.get("pattern")}"`} />} */}
                </Grid>
            </Grid>

        </CustomizedDialogs>
    );
};

export default CategoryLink;