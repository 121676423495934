import BasicTable from "../../../components/GenericTable";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import GenericEmptyTable from "../../../components/GenericEmptyTable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ImageSize from "../../../components/ImageSize";
import React, {useEffect} from "react";
import { ModalRight } from "../../../components/GenericsModals";
import { InventoryServicePresentations } from "../../../api/inventoryService";
import { useAuth } from "user-context-fran-dev";


const InventoryTable = ({ table, params }) => {
  return (
    <BasicTable titles={["Nombre ", "Modelo", "Ord.", "Dis.", "Apart."/* , "Ver" */]}>
      {table &&
        table.map((data, index) => <ProductsRow data={data} key={index} params={params} />)}
    </BasicTable>
  );
};

const ProductsRow = ({ data, params }) => {
  return (
    <>
      {data.product_model &&
        data.product_model.map((model, index) => (
          <Row data={data} model={model} key={index}  params={params}/>
        ))}
    </>
  );
};

const Row = ({ data, model, params }) => {
  const [open, setOpen] = useState(false);


  const ToOpen = () => {
    setOpen(!open)
  }
   

  return (
    <>
   {open && <ModalEdit setOpen={setOpen} open={open} data={data} model={model} params={params}></ModalEdit>}
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          m: "0",
          p: "0",
        }}
        onClick={()=> { ToOpen()}}
        hover
      >
        <TableCell align="left" 
        sx={{ width: {xs:"160px", sm:"160px", md: "260px",}, maxWidth:{xs:"160px", sm:"160px", md: "260px"} }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.name}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ width: {xs:"100px", sm:"100px", md: "260px",}, maxWidth:{xs:"100px", sm:"100px", md: "260px",} }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.name}
          </Typography>
        </TableCell>

        <TableCell align="left"  sx={{ maxWidth: "170px", minWidth:"80px" }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.ordered}
          </Typography>
        </TableCell>
        <TableCell align="left"  sx={{ maxWidth: "170px", minWidth:"80px" }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.available}
          </Typography>
        </TableCell>
        <TableCell align="left"   sx={{ maxWidth: "170px", minWidth:"80px" }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.reserved}
          </Typography>
        </TableCell>
      </TableRow>

    </>
  );
};

const TablePresentation2 = ({ model, data }) => {

  const titles=["Presentación ", "Ord.", "Disp.", "Apart."]
  return (
    <>
    
      <div  style={{display: "flex", width: "100%", flexDirection: "row"}}>
      <TableContainer   /* component={Paper} */>
        <Table
        
          /* sx={{ minWidth: 250 }} aria-label="simple table" size="small" */ size="small"
        >
          <TableHead  >
          <TableRow >
            {titles.map((title)=>(
              <TableCell key={title}  align="left"><strong>{title}</strong></TableCell>
            ))}
            </TableRow>
        </TableHead>
          <TableBody >
          {model && model.map((pres, index)=>(
                  <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    m: "0",
                    p: "0",
                  }}
                  hover
                >

    
                  <TableCell key={'Presentacion'} align="left" sx={{ width: {xs:"100px" }, maxWidth: {xs:"100px"} }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                     {pres.name} 
                    </Typography>
                  </TableCell>
    
                  <TableCell align="left"  sx={{ maxWidth: "100px", minWidth:"80px" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {pres.ordered}
                    </Typography>
                  </TableCell>
                  <TableCell align="left"   sx={{ maxWidth: "100px", minWidth:"80px" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {pres.available}
                    </Typography>
                  </TableCell>
                  <TableCell align="left"   sx={{ maxWidth: "100px", minWidth:"80px" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {pres.reserved}
                    </Typography>
                  </TableCell>
{/*                   <TableCell sx={{ maxWidth: "170px", minWidth:"80px" }}>
                      <IconButton
                        
                        size="small"
                        sx={{display: "none"}}
              
                      >
                        <KeyboardArrowUpIcon /> 
                      </IconButton>
        </TableCell> */}
                </TableRow>
          ))}
      

          </TableBody>
        </Table>
      </TableContainer>
      <br/>
      </div>
    </>
  );
};

const ModalEdit = ({setOpen, open, data , model, params}) => { 
  const [table, setTable] = useState("")
  const { logout } = useAuth();
  
  useEffect(()=>{
    
    getModelPresentations()
  },[open])

  const getModelPresentations = async () => {

        
        let irequestFilter = []
        irequestFilter.push({'key':'mdl', 'value': `${model.id}`})
        irequestFilter.push({'key':'product', 'value': `${model.product}`})
        if(params.get("wh")){
        irequestFilter.push({'key':'wh', 'value': `${params.get("wh")}`})
      }
        let serviceInstance = new InventoryServicePresentations()
        let response = await serviceInstance.filter(irequestFilter, logout);
        setTable(response.data)
       
    
};


  return ( 
    <ModalRight setOpen={setOpen} open={open} title="Inventario por presentación" size="xs">
      <Box sx={{display: "flex"}}>
        <ImageSize img={data?.main_model?.image?.url? data?.main_model?.image.url: ""} weight={2}/>
      <Typography variant="h6" sx={{margin: "8px 16px"}}>
        {data.name}
        <Typography variant="body1" >
        {data.sku}
      </Typography>
      </Typography>
   
      </Box>
      <Grid container mt="32px" /* sx={{height: "100%", alignContent: "center"}} */ >
      {table &&<TablePresentation2 model={table} data={data} />}
      {!table && (
      <GenericEmptyTable msg={"No hay presentaciones en la tabla"} />)}
      </Grid>
    </ModalRight>
   );
}

export default InventoryTable;
