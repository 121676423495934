import React, { useState, useEffect } from "react";
import Container from "../../layout/gridContainer";
import { Grid, Typography, Button, Paper, Tooltip, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomTable } from "../../components/GenericTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useAuth } from "user-context-fran-dev";
import { StoreSettings } from "../../api/store/storeSettings";
import CustomizedDialogs from "../../components/GenericsModals";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValClip, valSchClip, initSecetKey, valSecetKey } from "../../utils/formHelper";
import { LoadingButton } from "@mui/lab";
import { StoreClipVerify } from "../../api/store/storeOrders"
import VerifiedIcon from '@mui/icons-material/Verified';
import {UseerrorOrsuccess,  useNotification } from "../../hooks/snackBarResponse";
import { useSnackbar } from 'notistack';

const ID_DEFAULT = 1;

export const StorePayments = () => {
  const [copy, setCopy] = useState("Copiar");
  const { logout, auth } = useAuth();
  const [data, setData] = useState()
  const [open, setOpen]= useState(false)
  const [OpenSecretKey, setOpenSecretKey]= useState(false)
  const [verify, setVerify] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAria = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ClipVerify = (secret_key) =>{
    (async () => {
      const verify = new StoreClipVerify()
      const response = await verify.postDataAuth(secret_key, logout)
      console.log('response', response)
      
      if (Number(response.status) < 300){
        console.log('resp', response)
          setVerify(response.data.clip_key_verified)
          enqueueSnackbar( "Se a validado correctamente", {
            variant: "success",
          });
          setOpenSecretKey(false)
      }else{
        console.log('response', response)
        setOpenSecretKey(false)
        setVerify(false)
        sendNotification(UseerrorOrsuccess(response))
      }
      update();

    })();
  }


  const sentSettings = (setLoading, FormData) => {
    (async () => {
  
      const settings = new StoreSettings();
      const response = await settings.editDataAuth(ID_DEFAULT, FormData, logout);
      sendNotification(UseerrorOrsuccess(response));
      update()
      setLoading(false)
    })();
  };

  const update = () => {
    (async () => {
      
      const settings = new StoreSettings();
      const response = await settings.getAllByIdAuth(ID_DEFAULT, logout);
      setData(response.data)
    })();
  };
  useEffect(()=>{
    update()
  },[])


  const handleCopy = (e, text) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    setCopy("Copiado");
  };
  return (
    <Container>
          {data && <UpdateKey open={open} setOpen={setOpen} data={data} sentSettings={sentSettings}/>}
          {data && <VerifyApiKey openSecretKey={OpenSecretKey} setOpenSecretKey={setOpenSecretKey} clipVerify={ClipVerify}/>}

      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="div">
          <strong>Clip</strong>
        </Typography>
        <a href="https://www.clip.mx/" target="_blank" style={{textDecoration: "none"}}>
        <Button >Visitar sitio</Button>
        </a>
      </Grid>
      <Grid item xs={12}mt="32px">
        
        <Typography variant="h6"><strong>Pasos para configurar tu cuenta</strong></Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Regístrate en www.clip.mx, confirma tu correo electrónico e inicia sesión.
        </Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Ingresa en la sección “Portal de desarrolladores" 
        ubicada en la parte inferior izquierda del menú, se abrirá una nueva venta. 
        </Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Dirígete a la nueva ventada y en la sección aplicaciones encontraras un botón “Crear aplicación”.
        </Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Ingresa el nombre de la aplicación, 
        en este caso puedes usar el nombre de tu negocio, presionamos el botón de “guardar”.
        </Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Una vez generada la aplicación, vamos al botón “Ver mas”, 
        en la cual podrás obtener tu API Key que tiene un formato como este:  54ac905b-d0e9-4147-8285-e907af6fc30c
        </Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Agregar tu “API Key” en la opción de “Llaves de acceso” que se muestra a continuación.
        </Typography>
        <Typography variant="body1" mt="8px">
        <span>&#8226;</span> Podemos cerrar el panel de desarrolladoras.
        </Typography>
      </Grid>
      <Paper
        sx={{
          padding: "8px 16px",
          backgroundColor: "primary.main",
          marginTop: "32px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <KeyIcon fontSize="medium" sx={{ marginRight: "8px" }} />{" "}
          <strong>Llaves de acceso</strong>
        </Typography>

        <IconButton

            aria-controls={openAria ? 'long-menu' : undefined}
            aria-expanded={openAria ? 'true' : undefined}
            onClick={handleClick}

            >
            <MoreVertIcon fontSize="medium" sx={{ marginRight: "8px", color: "#fff" }} />
            </IconButton>
            <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={openAria}
            onClose={handleClose}

            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}


            >
              <MenuItem onClick={() => {setOpen(true); handleClose();}}>
                Editar Api key
              </MenuItem>
            </Menu>
      </Paper>
      <Grid item xs={12}>
        <CustomTable
          titles={[
            { align: "start", title: "Tipo" },
            { align: "start", title: "Valor" },
          ]}
        >
          <TableRow
            key={1}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            hover
          >
            <TableCell align="left">
              <Typography variant="body1">Api Key</Typography>
            </TableCell>
            <TableCell align="left">
              {data && <Typography variant="body1">{data.clip_key}</Typography>}
            </TableCell>
          </TableRow>
        </CustomTable>
      </Grid>

   {/*    <Grid item xs={12} mt="32px">
        <Typography variant="h6"><strong>Webhooks</strong></Typography>
        <Typography variant="body1" mt="8px">
        Agrega los siguientes webhooks a tu cuenta de Openpay y verificalos,  copia el webhook, después ingresa a tu cuenta de Openpay en Opciones -{">"} Webhooks -{">"} + agregar, pega el webhook y habilita todos los eventos.
Una vez guardado, regresa aquí para copiar el código de verificación y agregarlo en OpenPay.

        </Typography>
      </Grid>
      <Grid item xs={12}mt="32px">
        <Typography variant="body1">
        https://x6pp4f337k.execute-api.us-east-2.amazonaws.com/prod/api/v1/openpay
        </Typography>
        <Tooltip title={copy}>
          <ContentCopyIcon
            onClick={(e) => handleCopy(e,  "https://x6pp4f337k.execute-api.us-east-2.amazonaws.com/prod/api/v1/openpay")}
            className="left-text"
            fontSize="small"
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      </Grid> */}

      <Grid item xs={12}mt="32px" >
        <Typography variant="h6"><strong>Verificación</strong>
        {data && <VerifiedIcon sx={{ color:(data.clip_key_verified ? 'green' : 'gray'), marginLeft:'16px'}}/>}
        </Typography>
        <Typography variant="body1" mt="8px">
        Para verificar que tu cuenta de envioclick este funcionando correctamente, presiona el siguiente botón, si vez una lista cotizaciones de guías, tu configuración se ha completado.
        </Typography>
      </Grid>
      <Grid item xs={12} mt="32px" textAlign={'right'} sx={{marginBottom: "32px"}}>
      {data && <Button disabled={!data.clip_key || data.clip_key_verified } variant="contained"  onClick={()=>{setOpenSecretKey(true)}}>Validar</Button>}
      </Grid>
    </Container>
  );
};


const UpdateKey = ({open, setOpen, data, sentSettings}) => {
  const [loading, setLoading]= useState(false)  



  const formik = useFormik({   
    initialValues: initValClip(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchClip()),  
    onSubmit: async (formData, {resetForm}) => {
      setLoading(true);
      sentSettings(setLoading, formData)
      setLoading(false); 
      setOpen(false);

    },
  });
  return ( 
    <CustomizedDialogs open={open} setOpen={setOpen}  title={"Actualizar Api key"} size={"xs"}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container >
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="clip_key"
                value={formik.values.clip_key}
                onChange={formik.handleChange}
                error={
                  formik.touched.clip_key && Boolean(formik.errors.clip_key)
                }
                label="Api Key"
            
                margin="dense"
                size="small"
                variant="outlined"
                autoComplete ='none'
           
                helperText={
                  formik.touched.clip_key ? formik.errors.clip_key : ""
                }
              />
              </Grid>
              <Grid item xs={12} sx={{display: "flex", justifyContent: "end", marginTop: "8px"}}>
              <LoadingButton
                loading={loading}
                
                type="submit"
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Actualizar
              </LoadingButton>
              </Grid>
           </Grid>

</form>

    </CustomizedDialogs>
   );
}

const VerifyApiKey = ({openSecretKey, setOpenSecretKey, clipVerify}) => {
  const [loading, setLoading]= useState(false)  


  const formik = useFormik({   
    initialValues: initSecetKey(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSecetKey()),  
    onSubmit: async (formData, {resetForm}) => {
      setLoading(true);
      clipVerify(formData)
      setLoading(false); 
      setOpenSecretKey(false);
      resetForm.apply()
    },
  });
  return ( 
    <CustomizedDialogs open={openSecretKey} setOpen={setOpenSecretKey}  title={"Verificar"} size={"xs"}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container >
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="secret_key"
                value={formik.values.secret_key}
                onChange={formik.handleChange}
                error={
                  formik.touched.secret_key && Boolean(formik.errors.secret_key)
                }
                label="Secret key"
            
                margin="dense"
                size="small"
                variant="outlined"
                autoComplete ='none'
           
                helperText={
                  formik.touched.secret_key ? formik.errors.secret_key : ""
                }
              />
              </Grid>
              <Grid item xs={12} sx={{display: "flex", justifyContent: "end", marginTop: "8px"}}>
              <LoadingButton
                loading={loading}
                
                type="submit"
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Validar
              </LoadingButton>
              </Grid>
           </Grid>

</form>

    </CustomizedDialogs>
   );
}
 