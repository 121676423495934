import React, {useEffect, useState} from "react";
import BasicModal from "../../../../components/GenericModal";
import { Grid, Link, Paper, Typography, TextField } from "@mui/material";
import { RecipeGroup } from "../../../../api/recipeManages";
import { useAuth } from "user-context-fran-dev";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProductionOrderRecipe } from "../../../../api/productionOrder";
import { initValProductionOrderRecipe, valSchProductionOrderRecipe } from "../../../../utils/formHelper";
import LoadingButton from "@mui/lab/LoadingButton";
import ImageSize from "../../../../components/ImageSize";
import { UseerrorOrsuccess, useNotification } from '../../../../hooks/snackBarResponse'

const SelectRecipe = ({open, setOpen, data, order, updata}) => {
    const { logout, auth } = useAuth();
    const [table, setTable] = useState(); 
    const [msg, sendNotification] = useNotification();
/*     console.log("data",data)
    console.log("order",order) */
    
    
    useEffect(() => {
        getRecipe_group(data.id)
        /* return () */
      }, [data]);

    
    const getRecipe_group = (id) => {
    (async () => {
        let getRecipe = new RecipeGroup();
        let response = await getRecipe.getAllByIdAuth(id, logout);
        console.log("table", response.data)
        setTable(response.data);
        })();
    }

    const postProductionOrder = (formData) => {
        (async () => {
            console.log(data)
            let addProduction = new ProductionOrderRecipe();
            let response = await addProduction.postDataAuth(formData, logout); 
            sendNotification(UseerrorOrsuccess(response));
            updata()
            })();
        }


    return ( 
       
        <BasicModal open={open} setOpen={setOpen} title={"Seleccionar receta"}>
            <Grid item xs={9}>
                <Grid container>
                    
                <ImageSize img={data.product.main_model?.image ? data.product.main_model.image.url : ""} weight={2}/>
                <Grid item ml={"32px"}>
                    <h3>{data.product.name}</h3>
                    <h3>{data.product.main_model?.sku? data.product.main_model.sku: ""}</h3>
                </Grid>

                </Grid>
                {/* 
                <Grid item xs={3} >
              <Grid container justifyContent={"start"} alignItems={"end"} flexDirection="column" sx={{height: "64px"}}>
                  <h4> {data.product_model["0"].name} </h4>
                  <h4>       {(dataProduct["0"].is_active)?<Typography  sx={{color: 'green', fontSize: 15}}>Activo</Typography>:
                  <Typography  sx={{color: 'red', fontSize: 15}}>Desactivado</Typography>}  </h4>
              </Grid> 

            </Grid>*/}

            </Grid>
            <Grid item xs={12} mt={"16px"} sx={{maxHeight: "60vh", overflowX: "auto"}}>
                
                {table?.recipe_product && table.recipe_product.map((row, index)=>(
                    <RowPaper key={index} row={row} order={order} doSomething={postProductionOrder}/>
                    
                ))}
              
            </Grid>
        </BasicModal>
     );
}

const RowPaper = ({row, order, doSomething}) => {
    const [loading, setLoading] = useState(false);
    /* console.log(order)*/
    console.log(row) 
    const formik = useFormik({
        initialValues: initValProductionOrderRecipe(order, row), 
        enableReinitialize: true,
        validationSchema: Yup.object(valSchProductionOrderRecipe()),
        onSubmit: async (formData, {resetForm}) => {
          
          console.log(formData) 
          setLoading(true);
          doSomething(formData)
          resetForm()
          setLoading(false);
        },
      });
    return ( 
        <Paper sx={{bgcolor: "background.default", padding: "2px 16px", marginTop: "8px"}}   >
            <form onSubmit={formik.handleSubmit}>
                <Grid container p={"5px"} columnSpacing="16px">
                    <Grid item xs={6}>
                        <Typography variant="body1" component="div">
                        {`${row.model.name}, ${row.presentation.name}`} 
                        <Typography variant="caption" display="inline" >{` (${row.quantity})`}</Typography> 
                            
                        </Typography>
                        
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete ='none'
                        size="small"
                        id="quantity"
                        label="Cantidad"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.quantity && Boolean(formik.errors.quantity)
                        }
                        helperText={
                            formik.touched.quantity ? formik.errors.quantity : ""
                        }
        
                        /> 
                        
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="flex-end" >
                                <LoadingButton
                                loading={loading}
                                fullWidth
                                type="submit"
                                variant="outline"
                                sx={{ color: "#288edb" }}
                            >
                                Seleccionar
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
     );
}
 


export default SelectRecipe;