import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export default class ScrapService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/scrap_order';
      super(baseUrl, endPoint)
    }
}

export class ScrapServiceItems extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/scrap';
    super(baseUrl, endPoint)
  }
}


