import React, { useState } from "react";
import { Grid, Typography, Button, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from '@mui/icons-material/Circle';
import AddPresentation from "./articleAddPresentation";
import GenericEmptyTable from "../../../components/GenericEmptyTable";
import { GenericHeaderTitle } from "../../../components/GenericHeader";



const PricePresentation = ({ data, getArticleInfo }) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState("");
  return (
    <>
      <AddPresentation data={data} edit={edit}
        getArticleInfo={getArticleInfo}
        open={open} setOpen={setOpen} />
      <Grid container mb={"32px"}>
        <Grid item xs={12}>
          <GenericHeaderTitle title="Precios y presentaciones">
            <Button
              variant="text"
              onClick={() => { setOpen(true); setEdit("") }}
              startIcon={<AddIcon fontSize="small" />}
            >
              Agregar
            </Button>

          </GenericHeaderTitle>

        </Grid>
        {data.presentation &&
          <Grid item xs={12} mt={2} >

            {data.presentation.map((data, index) => (
              <div key={index}>
                <Paper
                  onClick={() => { setEdit(data); setOpen(true); }}
                  elevation={2} sx={{ padding: '10px', mb: 1, cursor: 'pointer' }}>
                  <Grid container alignItems='center' sx={{ height: "100%" }}>
                    <Grid item xs={6} sx={{ height: "100%", display: "flex", alignItems: "center" }}>

                      {data.is_active ? <CircleIcon sx={{ color: 'green', fontSize: 15, }} /> :
                        <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}

                      <Typography variant="body2" component="div" display="inline" ml="8px">
                        {data.barcode ? `${data.name} - ${data.barcode}` : data.name}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container justifyContent='flex-end'>
                        <Typography variant="body2" >
                          {`$ ${data.price}`}
                        </Typography>
                      </Grid>
                    </Grid>

                  </Grid>
                </Paper>

              </div>




            ))}



          </Grid>
        }

        {!data.presentation &&
          <GenericEmptyTable />
        }

      </Grid>


    </>
  );
}



export default PricePresentation;