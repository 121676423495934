import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export class ProductModel extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'catalog/product_model';
      super(baseUrl, endPoint)
    }
}

export class ProductModelImage extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'catalog/product_model/image';
    super(baseUrl, endPoint)
  }
}

export class ProductModelImageDelete extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'catalog/image';
    super(baseUrl, endPoint)
  }
}