import React, {useEffect, useState} from "react";
import Container from "../../layout/gridContainer";
import ArticleData from "./articleDetail/articleData";
import ArticleModels from "./articleDetail/articleModels";
import Products from "../../api/productsManages";
import { useParams } from 'react-router-dom'
import { useAuth } from "user-context-fran-dev";

 

const CatalogArticleDetail = ({setReload}) => {
    const [articleInfo, setArticleInfo] = useState(""); 
    const { id } = useParams()
    const { logout } = useAuth();
    const [loading, setLoading]= useState(true)

    const getArticleInfo = () => {
        (async () => {
        setLoading(true)
          let getArticle = new Products();
          let response = await getArticle.getAllByIdAuth(id, logout);
          /* console.log(response.data) */
          setArticleInfo(response.data);
          setLoading(false)
        })();
      };
    

    useEffect (() => {
       
        getArticleInfo()
        window.localStorage.setItem("menu", 10);

        return()=>{ setReload(prev => !prev); 
        window.localStorage.setItem("menu", 3)};
            
    },[]);
/*     if (!article) {
        return <Navigate to={"/catalog/articles"} />;
        } */
        
    return ( 
        <Container>

            <ArticleData article={articleInfo} getArticleInfo={getArticleInfo} load={loading}/>
            <ArticleModels article={articleInfo} getArticleInfo={getArticleInfo} load={loading}/>
      
 
        </Container>
    );
}
 
export default CatalogArticleDetail;