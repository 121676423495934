import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class BranchService extends BaseService{
    constructor(){
        const baseUrl = BASE_PATH;
        const endPoint = 'site/user_branch';
        super(baseUrl, endPoint)
      }
}
