import React, { useState, useEffect } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid, FormControl, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValAddSale, valSchAddSale } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import FormControlLabel from "@mui/material/FormControlLabel";
import SalesService from "../../../api/salesService";
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WarehouseService from "../../../api/warehouseService";
import { formatISO } from "date-fns";
import { ConfirmAction } from "../../../components/GenericsModals";
import Button from '@mui/material/Button'; // Importa Button desde @mui/material

const AddSale = (props) => {
  const { openModal, setOpenModal, UpdateSalesList, idOrder } = props;
  const [loading, setLoading] = useState(false);
  const [warehouseList, setWarehouseList] = useState("");
  const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();
  /* const [noOrder, setNoOrder] = useState(""); */


  //This state is to open confirmDialog
  const [openDialog, setOpenDialog] = useState(false);
  const warehouseService = new WarehouseService();


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  useEffect(() => {
    warehouseListRequest();

  }, []);

  const warehouseListRequest = () => {
    (async () => {
      let irequestFilter = []
      irequestFilter.push({ 'key': 'get_main', 'value': `true` })
      const response = await warehouseService.filter(irequestFilter, logout);
      setWarehouseList(response.data);
      console.log("warehouse", response.data);
    })();
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: initValAddSale(),
    validationSchema: Yup.object(valSchAddSale()),
    onSubmit: async (formData) => {
      console.log(formData);
      setLoading(true);
      formData.no_order = formData.no_order + idOrder.toString();
      formData.date_created = formatISO(formData.date_created);


      let salesSer = new SalesService();
      let response = await salesSer.postDataAuth(formData, logout);
      formik.resetForm();
      if (Number(response.status) < 300) {
        setOpenModal(false);
        window.localStorage.setItem("menu", 19);
        sendNotification(UseerrorOrsuccess(response));
        navigate(`detail/${response.data.id}`);
      } else {
        sendNotification(UseerrorOrsuccess(response));
        setOpenModal(false);
      }
      UpdateSalesList();
      setLoading(false);
    },
  });
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [openModal]);




  return (
    <>
      <BasicModal open={openModal} setOpen={setOpenModal} title="Agregar venta">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container mt={"16px"} rowSpacing={"8px"} columnSpacing={"8px"}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    name="no_order"
                    value={formik.values.no_order}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.no_order && Boolean(formik.errors.no_order)
                    }
                    label="Numero de orden"
                    /*  margin="normal" */
                    size="small"
                    variant="outlined"
                    autoComplete="none"
                    helperText={
                      formik.touched.no_order ? formik.errors.no_order : ""
                    }
                    inputProps={{ maxLength: 20 - idOrder.toString().length }}
                  />
                  {idOrder && <CountWords
                    length={formik.values.no_order.length}
                    max={20 - idOrder.toString().length}
                  />}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="none"
                    size="small"
                    id="no_order"
                    label="Número Consecutivo"
                    value={idOrder}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => (
                      <TextField fullWidth size="small" {...props} />
                    )}
                    label="Fecha"
                    value={formik.values.date_created}
                    onChange={(newValue) => {
                      formik.setFieldValue("date_created", newValue);
                    }}
                    helpertext={formik.errors.date_created}
                    format="dd/MM/yyyy"
                  />

                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" size="small" >
                    <InputLabel id="demo-simple-select-filled-label">
                      Almacén
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      name="warehouse"
                      label="Almacén"
                      value={formik.values.warehouse}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.warehouse)
                      } >
                      {warehouseList && warehouseList.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.warehouse ? formik.errors.warehouse : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  Los artículos agregados a la orden se irán descontando del
                  inventario y cambiaran al estado de ordenados.
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ marginTop: "15px" }}
                  >
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ color: "#fff" }}
                    >
                      Agregar
                    </LoadingButton>
                    <>
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </LocalizationProvider>
      </BasicModal>
    </>
  );
};

export default AddSale;
