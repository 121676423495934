import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initAddBusiness, ValAddBusiness } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { Suppliers } from "../../../api/supplierService";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";


const Step1 = ({formik}) => {
    const [loading, setLoading] = useState(false);
    const { logout, auth } = useAuth();
    /* console.log(auth)
 */
/*     const formik = useFormik({
        initialValues: initAddBusiness(auth.user.pk),
        validationSchema: Yup.object(ValAddBusiness()),
        onSubmit: async (formData) => {
          setLoading(true);
        },
      }); */
      useEffect(()=>{
        console.log(formik.values)
        },[])

 
    return ( 
            <Grid item xs={12} sm={6} >
                {formik.values &&
                <form  onSubmit={formik.handleSubmit} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                        label="Nombre de la empresa"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        autoComplete ='none'
                        helperText={
                            formik.touched.name ? formik.errors.name : ""
                        }
                        />
                        <CountWords length={formik.values.name.length} max="100"/>
                        <TextField
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        name="turn_business"
                        value={formik.values.turn_business}
                        onChange={formik.handleChange}

                        error={
                            formik.touched.turn_business && Boolean(formik.errors.turn_business)
                        }
                        label="Giro de la empresa"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        autoComplete ='none'
                        helperText={
                            formik.touched.turn_business ? formik.errors.turn_business : ""
                        }
                        />
                        <div className="space-input"></div>
                        
                        <TextField
                        inputProps={{ maxLength: 10 }}
                        fullWidth
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        label="Teléfono"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        autoComplete ='none'
                        type="number"
                        helperText={formik.touched.phone ? formik.errors.phone : ""}
                        />
                        {/* <CountWords length={formik.values.phone.length} max="10"/> */}
                        <div className="space-input"></div>
                        <TextField
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        label="Correo electrónico"
                        margin="dense"
                        variant="outlined"
                        autoComplete ='none'
                        size="small"
                        helperText={
                            formik.touched.email ? formik.errors.email : ""
                        }
                        />
                        <CountWords length={formik.values.email.length} />
                  
                        
                    </Grid>
                </Grid>
                </form>
}
            </Grid>
     );
}
 
export default Step1;