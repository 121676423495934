

export function Iniciales(nombre, apellido){
    return  nombre[0].toUpperCase() + apellido[0].toUpperCase()
}

export function formatNumber(number, digits){
    if(number !== "") {
        return new Intl.NumberFormat("en",
        {maximumFractionDigits: digits, minimumFractionDigits: digits})
        .format(number);
}else{
    return ""
}
}

export function formatMoney(number, digits){
    if(number !== null || number !== "") {
        return new Intl.NumberFormat("en",
        {maximumFractionDigits: digits, minimumFractionDigits: digits,  style: 'currency', currency: 'USD' })
        .format(number);
}else{
    return "$0.00"
}
}