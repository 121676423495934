import React, {useState, useEffect} from 'react'
import { Grid, Typography, TextField } from "@mui/material";
import GenericTable  from '../../GenericTable'
import TableCell from "@mui/material/TableCell";
import { CardHeader, TableRow } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from 'react-router-dom'
import { useAuth } from "user-context-fran-dev";
import { InventoryService, InventoryServiceSalesOrder } from '../../../api/inventoryService'
import { ProductModelAvailableService } from "../../../api/productService"
import { AvailablePresentation } from '../../../api/availablePresentation'
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import { initAddArticleToPurchaseOrder, initValTransferOrder, valSchAddToPurchaseOrder, valSchTransferOrder } from "../../../utils/formHelper";
import GenericEmptyTable from '../../GenericEmptyTable'
import { TableModal } from '../../GenericsModals';
import { height } from '@mui/system'; 
import ImageSize from '../../ImageSize';
import { TransferItemsService } from '../../../api/inventory/transfersService';


export default function EditTransferArticle(props){

  const {openEdit, setOpenEdit, title, dataProduct, type, reloadDataOforder, orderData,  articleData} = props
  const [dataProductAva, setDataProduct] = useState('')
  const [localDataProduct, setLocalDataProduct] = useState('')
  const [loading, setLoading] = useState(false)
  const { logout } = useAuth();
  console.log(articleData)

  const findArticle = () => {
    for (var article in orderData.articles){
        console.log(orderData.articles[article])
        if(orderData.articles[article].id === dataProduct){
            return orderData.articles[article]
        }
        }
    }

  useEffect(() => {
  /*   setLoading(true)
    setLocalDataProduct(findArticle())
    if(localDataProduct){
        getAvailableDataModal()
    } */
    getAvailableDataModal()
    
  }, [localDataProduct]);

  async function getAvailableDataModal(){ 
    console.log('local data',localDataProduct)
    let irequestFilter = []
    irequestFilter.push({'key':'wh', 'value': `${articleData.warehouse}`})
    irequestFilter.push({'key':'mdl', 'value': `${articleData.available.model.id}`})
    let productInventory = new AvailablePresentation();
    let response = await productInventory.filter(irequestFilter, logout);
    console.log('available product', response)
    setDataProduct(response.data)
  }
 

  return ( 
    <>
    {articleData &&
    <TableModal open={openEdit} setOpen={setOpenEdit} title={'Editar'} className="modal-articles">
      <Grid
        container >

<Grid item xs={9}>
                <Grid container>
                <ImageSize img={articleData.available.model?.image?.url? articleData.available.model?.image?.url :""} weight={2}/>
                <Grid item ml={{xs: "8px", md: "32px"}}>
                    <h3>{articleData.available.model.product.name}</h3>
                    <h4>{articleData.available.model.name}  </h4> 
                </Grid>

                </Grid>
            </Grid>
            <Grid item xs={3} >
              <Grid container justifyContent={"start"} alignItems={"end"} flexDirection="column" sx={{height: "64px"}}>
                  <h4> {articleData.available.model.product.sku} </h4>
                 {/*  <h4>       {(modelToFind.is_active)?<Typography  sx={{color: 'green', fontSize: 15}}>Activo</Typography>:
                  <Typography  sx={{color: 'red', fontSize: 15}}>Desactivado</Typography>}  </h4> */}
              </Grid>

            </Grid>

        <div className="presentation-select">
        </div>
        <Grid  container  >
   {/*        <Grid xs={12} className="">
            {/* {dataPresentation.data?.main_model.image.url &&
            <img  src={dataPresentation.data?.main_model.image.url}
                style={{width: '20%', height: 'auto'}}
            ></img> 
          
          </Grid>
          <Grid xs className="">
            <Grid xs className="">
              <h1>{articleData.available.model.product.name}</h1>
            </Grid>
            <Grid xs className="">
              <h3>{articleData.available.model.product.sku}</h3>
            </Grid>
          </Grid> */}
          <Grid xs={12} >
          {articleData  && !loading  &&(
            <GenericTable titles={["Presentación", "Barcode.", "Disponible",  "Cantidad", "Acción"]} >

                {/* <Row article={articleData} setOpen={setOpenEdit} row={localDataProduct} type={type} dataProduct={dataProductAva['0']} reloadDataOforder={reloadDataOforder} >
                </Row> */}
                <Row2 row={articleData} orderData={orderData} setOpen={setOpenEdit} reloadDataOforder={reloadDataOforder}/>
            
          </GenericTable>
          )}
          {/* {!dataProductAva && openEdit && !loading &&(
            <GenericEmptyTable msg={"No hay datos en la tabla"}/>
          )}
        {loading &&(
                    <Grid itemxs={12} sx={{height: 100, width: 100}}>
                    <CircularProgress />
                    </Grid>
                    
            )} */}
          
          </Grid>
        </Grid>
 
      </Grid>
    </TableModal>
    }
    </>
  )        
}

function Row(props){

  const { row, type, reloadDataOforder, dataProduct, setOpen, article} = props

  console.log('row',row)
  console.log('data product', dataProduct)

  /* const [open, setOpen] = useState(false); */
  const [loading, setLoading] = useState(false);
  const { id } = useParams()
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const formik = useFormik({
    initialValues: initAddArticleToPurchaseOrder(row), 
    validationSchema: Yup.object(valSchAddToPurchaseOrder()),
    onSubmit: async (formData, { resetForm }) => {
      formik.values.presentation = row.presentation.id
      formik.values.model = dataProduct.product_model.id
      //resetForm();
      setLoading(true);
      if(type=='purchase'){
        formik.values.cost = row.presentation.price
      formik.values.purchase = Number(id)
      let addToPurchases = new InventoryService();
      let response = await addToPurchases.editDataAuth(row.id, formData, logout)
      if(Number(response.status) < 300){
        sendNotification(UseerrorOrsuccess(response))
      }else{
        sendNotification(UseerrorOrsuccess(response))
      }
      reloadDataOforder()
      } else {
        formik.values.sale = Number(id)
        formik.values.price = row.presentation.price
        let addToPurchases = new InventoryServiceSalesOrder();
        let response = await addToPurchases.editDataAuth(row.id, formData, logout)
        reloadDataOforder()
      }
      //udateProducts();
      setOpen(false);
      setLoading(false);
    },
  });

  async function getinfo(){
    
  }

  return(
    
        <TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
          hover
          
        >
         
        <TableCell align="left" sx={{maxWidth: '250px'}}> 
        <CardHeader
          sx={{m:0, p:0}}
            title={
              <Typography  component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {article.available.model.product.name}
            </Typography>}
            subheader={ article.available.model.name +' '+ article.available.presentation.name }
          />
        </TableCell>
        {/* <TableCell align="left" sx={{maxWidth: '120px'}}> 
          <CardHeader
            title={
              <Typography variant="subtitle2" component="div">
              {row.presentation.price ? row.presentation.price : ''}
              </Typography>}
            
          />
        </TableCell> */}
        
{/*         <TableCell align="left" sx={{maxWidth: '50px'}}> 
          <CardHeader
            title={
            <Typography variant="subtitle2" component="div">
             <a>{dataProduct.product_model['0'].ordered}</a>
            </Typography>}
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '50px'}}> 
          <CardHeader
            title={
            <Typography variant="subtitle2" component="div">
            <a>{dataProduct.product_model['0'].available}</a>
            </Typography>}
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '50px'}}> 
          <CardHeader
            title={
            <Typography variant="subtitle2" component="div">
             <a>{dataProduct.product_model['0'].reserved}</a>
            </Typography>}
          />
        </TableCell>
        
        <TableCell align="left" sx={{maxWidth: '150px'}}> 
        <form onSubmit={formik.handleSubmit}>
          <TextField
              fullWidth
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              //error={formik.touched.quantity}
              label="Cantidad"
            
              type='number'
              size="small"
              variant="outlined"
              //disabled={formik.values.quantity > row.available}
              className="space-text-field"
          />
       </form>
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '150px'}}> 
        <form onSubmit={formik.handleSubmit}>
        { type === 'sales' && <LoadingButton
              loading={false}
              fullWidth
              type="submit"
              variant="contained"
              disabled={formik.values.quantity > row.available ||  row.available === 0}
            >
              Guardar
          </LoadingButton>
            }
            { type === 'purchase' && <LoadingButton
              loading={false}
              fullWidth
              type="submit"
              variant="contained"
          >
              Guardar
          </LoadingButton>
            }
            </form>
        </TableCell> */}
      </TableRow>
    
  )
}


const Row2 = ({row, setOpen,orderData, reloadDataOforder}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams()
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const formik = useFormik({
    initialValues: initValTransferOrder(orderData, row),
    validationSchema: Yup.object(valSchTransferOrder()),
    onSubmit: async (formData, { resetForm }) => {
      
      let products = new TransferItemsService();
      let dataRes = await products.editDataAuth(row.id, formData, logout);
      sendNotification(UseerrorOrsuccess(dataRes))
      reloadDataOforder()
      resetForm()
      setOpen(false);
      setLoading(false);
    },
  });
  return ( 
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      
    <TableCell align="left" sx={{ maxWidth: "200px" }}>
      <Typography variant="subtitle2" component="div">
        {row.available.presentation.name}
      </Typography>
    </TableCell>

    <TableCell align="left" sx={{ maxWidth: "200px" }}>
      <Typography variant="subtitle2" component="div">
        {row.available?.barcode}
      </Typography>
    </TableCell>

    <TableCell align="left" sx={{ maxWidth: "200px" }}>
      <Typography variant="subtitle2" component="div">
        <a className='lineUp'>{row.available.available}</a>
      </Typography>
    </TableCell>

    <TableCell align="left" sx={{ maxWidth: "200px" }}>
    
      <Typography variant="subtitle2" component="div">
      <form onSubmit={formik.handleSubmit}>
          <TextField
                fullWidth
                name="quantity"
                type='number'
                value={formik.values.quantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.quantity && Boolean(formik.errors.quantity)
                }
                label="Cantidad"
                size="small"
                variant="outlined"
                autoComplete ='none'
                helperText={
                  formik.touched.quantity ? formik.errors.quantity : ""
                }
              />
        </form>
      </Typography>

    </TableCell>

    <TableCell align="left" sx={{ maxWidth: "200px" }}>
      <form onSubmit={formik.handleSubmit}>
        <LoadingButton
          loading={loading}
          fullWidth
          variant="text"
          /* disabled={!quantity} */
          type="submit"
          sx={{ color: "primary" }}
        >
          Transferir
        </LoadingButton>
        </form>
    </TableCell>

  </TableRow>
   );
}
 
