import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {  TableRow } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle"; 
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import {
  initAddScrapArticle,
  valSchAddScrapArticle,
} from "../../utils/formHelper";
import { ScrapServiceItems } from "../../api/inventory/scrapService";
import GenericEmptyTable from "../GenericEmptyTable";
import ImageSize from "../ImageSize";
import CustomizedDialogs from "../GenericsModals";
import { CustomTable } from "../GenericTable";
import {  AvailablePresentationInventory } from "../../api/availablePresentation";


export default function ScrapModal(props) {
  const {
    open,
    setOpen,
    title,
    dataProduct,
    type,
    reloadDataOforder,
    article,
  } = props;
  const [productdata,setProductData] = useState('')
  const { logout } = useAuth();
  const [error , setError] = useState(false)
  console.log("dataProduct", dataProduct);
  console.log("article", article);

  useEffect(() => {
    getAvailableDataModal()
  }, [open]); 

  async function getAvailableDataModal(model){
    let irequestFilter = []
    irequestFilter.push({'key':'wh', 'value': `${dataProduct.warehouse}`})
    irequestFilter.push({'key':'mdl', 'value': `${dataProduct.model}`})
    let productInventory = new AvailablePresentationInventory();
    let response = await productInventory.filter(irequestFilter, logout);
    console.log('model',response.data)
    setProductData(response.data)
  }
  


  return (
    <CustomizedDialogs open={open} setOpen={setOpen} title={title} size="md">
      <Grid container>
        
        <Grid item xs={12} mb="16px">
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <ImageSize
                  img={
                    article?.main_model?.image?.url
                      ? article.main_model.image.url
                      : ""
                  }
                  weight={2}
                />
                <Box ml="16px">
                  <h3>{article.name}</h3>
                  <h4>{article.sku}</h4>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="end">
                {/* <h4>{edit? article.category.name : article.category.name}</h4> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {productdata && (
            <CustomTable
              titles={[
                { title: "Presentación", align: "left"},
                { title: "Barcode", align: "left"},
                { title: "Estatus", align: "center"},
                { title: "Disponible", align: "left"},
               
                { title: "Cantidad", align: "left"},
                { title: "Acción", align: "center"},
              ]}
            >
              {productdata.map((rowList, indexList) => (
                rowList.items.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  type={type}
                  reloadDataOforder={reloadDataOforder}
                  setError={setError}
                  getAvailableDataModal={getAvailableDataModal}
                />
                ))
                ))}
            </CustomTable>
          )}
          {!dataProduct && <GenericEmptyTable />}
        </Grid>
        { error &&
        <Grid item xs sx={{marginTop: 2, textAlign: 'center', color: 'red'}}>
          <h4>{error}</h4>
        </Grid>
        }
      </Grid>
    </CustomizedDialogs>
  );
}

function Row(props) {
  const { row, type, reloadDataOforder, setError, getAvailableDataModal} = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();



  const formik = useFormik({
    initialValues: initAddScrapArticle(),
    validationSchema: Yup.object(valSchAddScrapArticle()),
    onSubmit: async (formData, { resetForm }) => {
      if(formik.values.quantity > row.quantity){
        setError("La cantidad a transferir no puede ser mayor al Disponible");
      }else{
        setLoading(true);
        setError(false)
        formik.values.available = row.id;
        formik.values.scrap_order = id;
        console.log(formData);
        let addToScrap = new ScrapServiceItems();
        let response = await addToScrap.postDataAuth(formData, logout)
        if(Number(response.status) < 300){
          sendNotification(UseerrorOrsuccess(response))
          getAvailableDataModal()
        }else{
          sendNotification(UseerrorOrsuccess(response))
        }  
        resetForm();
        reloadDataOforder();
        setOpen(false);
        setLoading(false);
        }
    },
  });

  /* async function getinfo() {}
 */
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle2" component="div">
          <a>{row.barcode}</a>
        </Typography>
      </TableCell>
      <TableCell align="center" sx={{ maxWidth: "50px" }}>
        <Typography variant="subtitle2" component="div">
          {row.presentation.is_active ? (
            <CircleIcon sx={{ color: "green", fontSize: 15 }} />
          ) : (
            <CircleIcon sx={{ color: "red", fontSize: 15 }} />
          )}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "30px" }}>
        <Typography variant="subtitle2" component="div">
          <a>{row.available}</a>
        </Typography>
      </TableCell>
{/*       <TableCell align="left" sx={{ minWidth: {sm:"0", md:"200px"} }}>
        <Typography variant="subtitle2" component="div">
        </Typography>
      </TableCell>      */} 

      <TableCell align="left" sx={{ width: "150px" }}>
        <TextField
          fullWidth
          name="quantity"
          label="cantidad"
          value={formik.values.quantity}
          onChange={formik.handleChange}
          helperText={
            formik.touched.quantity ? formik.errors.quantity : ""
        }
        error={
          formik.touched.quantity && Boolean(formik.errors.quantity)
      }
          type="number"
          size="small"
          variant="outlined"
          //disabled={formik.values.quantity > row.available}
          className="space-text-field"
        />
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "150px" }}>
        <form onSubmit={formik.handleSubmit}>
          <LoadingButton
            loading={loading}
            fullWidth
            disabled={!formik.values.quantity}
            type="submit"
            variant="text"
            /* disabled={row.quantity } */
          >
            Aplicar
          </LoadingButton>
        </form>
      </TableCell>
    </TableRow>
  );
}
