import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class OrderedReceived extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/ordered/received';
      super(baseUrl, endPoint)
    }
  }
  
  export class OrderedDelivered extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/reserved/delivered';
      super(baseUrl, endPoint)
    }
  }