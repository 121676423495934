import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { initValAddPresentation, valSchAddPresentation } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import Presentation from "../../../api/catalogPresentation";
import CountWords from "../../../components/CountWords";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from 'notistack';

const AddPresentation = ({ data, edit, getArticleInfo, open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [ sendNotification] = useNotification();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [open]);



  const formik = useFormik({
    initialValues: initValAddPresentation(edit, data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddPresentation()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData)
      if (edit) {
        UpdatePresentation(formData);
      } else {
        SetPresentation(formData)
      }

      getArticleInfo();

      setOpen(false);
      setLoading(false);
    },
  });

  const SetPresentation = async (formData) => {
    let setPresentation = new Presentation();
    let response = await setPresentation.postDataAuth(formData, logout);
    if (response.data.data) {
      setOpen(false);
    }
    sendNotification(UseerrorOrsuccess(response))
    getArticleInfo();

  }

  const UpdatePresentation = async (formData) => {
    let setPresentation = new Presentation();
    let response = await setPresentation.editDataAuth(edit.id, formData, logout);
    if (Number(response.status) < 300) {
      if (response.data?.message) {
        enqueueSnackbar(response.data?.message, { variant: "warning" })
      }
    }
    sendNotification(UseerrorOrsuccess(response))
    getArticleInfo();

  }



  return (
    <BasicModal open={open} setOpen={setOpen} title={edit ? "Editar precio y presentación" : "Agregar precio y presentación "}>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label="Nombre de la presentación "
                margin="dense"
                size="small"
                variant="outlined"
                helperText={formik.touched.name ? formik.errors.name : ""}
              />
              <CountWords length={formik.values.name.length} />
              <TextField
                fullWidth
                name="barcode"
                value={formik.values.barcode}
                onChange={formik.handleChange}
                error={formik.touched.barcode && Boolean(formik.errors.barcode)}
                label="Codigo de barras"
                margin="dense"
                size="small"
                variant="outlined"
                helperText={formik.touched.barcode ? formik.errors.barcode : ""}
              />
              <div className="space-input"></div>

              <TextField
                fullWidth
                name="cost"
                value={formik.values.cost}
                onChange={formik.handleChange}
                error={formik.touched.cost && Boolean(formik.errors.cost)}
                label="Costo de la presentación"
                margin="dense"
                size="small"
                variant="outlined"
                type="number"
                helperText={formik.touched.cost ? formik.errors.cost : ""}
              />
              <div className="space-input"></div>
              <TextField
                fullWidth
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                label="Precio de la presentación"
                margin="dense"
                size="small"
                variant="outlined"
                type="number"
                helperText={formik.touched.price ? formik.errors.price : ""}
              />
              <div className="space-input"></div>
              <FormControlLabel
                sx={{ marginLeft: "0px" }}
                control={
                  <Switch

                    name="is_active"
                    checked={formik.values.is_active}
                    onChange={formik.handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={formik.values.is_active ? "Dejar de mostrar" : "Mostrar"}

              />


            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ marginTop: "15px" }}
                >
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {edit ? "Editar" : "Agregar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BasicModal>
  );
};

export default AddPresentation;
