import React, {useEffect, useState} from "react";
import HeaderGeneric from "../../../components/inventario/HeaderGeneric";
import Container from "../../../layout/gridContainer";
import { useParams } from 'react-router-dom'
import { useAuth } from "user-context-fran-dev";
import SalesOrder from "../../../api/salesOrder";
import { Grid, Box } from "@mui/material";
import InventoryGenericDetailsView from "../../../components/inventario/InventoryGenericDetailsView";
import ArticlesWithoutTotal from "../../../components/inventario/tables/ArticlesWithoutTotal";
import SalesDelivered from "./salesDelivered";
import { STATUS, STATUSCOLOR } from "../../../utils/constants";

const InventoryWHSaleDetail = ({setReload}) => {

    const { id } = useParams()
    const { logout } = useAuth()
    const [ data , setData ] = useState()
    const [ openModal , setOpenModal ] = useState(false)
    const [modalInfo, setModalInfo] = useState("");
  
 
    const GetSalesDetail = () => {
        (async () => {
            let getSales = new SalesOrder();
            let response = await getSales.getAllByIdAuth(id, logout)
            setData(response.data)
          })();
    }


    useEffect(() => {
        GetSalesDetail();
        return () => {
          setReload((prev) => !prev);
          window.localStorage.setItem("menu",5);
        };
      }, []);


    return ( 
        <>
        {data &&
            <Container>
             <Box sx={{p: '20px', mb: '15px'}}>
               <Grid container> 
                <Grid item xs={12} mb={3}>
                    <HeaderGeneric 
                    leftTitle={{status:STATUS[data.status], color: STATUSCOLOR[data.status]}} 
                    rightTitle={"Orden de Venta"} 
                    name={data.user.first_name + " "+  data.user.last_name}
                    noOrder={data.no_order}
                    date={data.date_created}
                    general={data.warehouse.name}/>
                </Grid>
                <Grid item xs={12}>
                {modalInfo && data && <SalesDelivered open={openModal} setOpen={setOpenModal} 
                article={modalInfo} sales={data}
                GetSalesDetail={GetSalesDetail}/>}
                <InventoryGenericDetailsView name="Cliente" pay="Condiciones de Cobro" data={data} type={"sales"}>
                  <ArticlesWithoutTotal data={data} setModalInfo={setModalInfo} setOpenModal={setOpenModal}></ArticlesWithoutTotal> {/* falta la info de arriba */}
                </InventoryGenericDetailsView>

                </Grid>
              </Grid>
            </Box>
          </Container>  
          }
          </>
        
     );
}
 
export default InventoryWHSaleDetail;