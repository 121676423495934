import { Grid } from '@mui/material';
import React from 'react'
import Container from '../../layout/gridContainer';

const GeneralSettings = () => {
    return ( 
        <Container>
            <Grid>
                
            </Grid>
        </Container>
     );
}
 
export default GeneralSettings;