import React, { useState, useEffect } from "react";
import Container from "../../layout/gridContainer";
import { Grid, Typography, Button, Paper, Tooltip, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomTable } from "../../components/GenericTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAuth } from "user-context-fran-dev";
import { StoreSettings } from "../../api/store/storeSettings";
import CustomizedDialogs from "../../components/GenericsModals";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValEnvioclicKey, valSchEnvioclicKey } from "../../utils/formHelper";
import { LoadingButton } from "@mui/lab";
import VerifiedIcon from '@mui/icons-material/Verified';
import { StoreEnvioCliclVerify } from "../../api/store/storeOrders"
import { useNotification } from "../../hooks/snackBarResponse";
import { useSnackbar } from 'notistack';
import {
  UseerrorOrsuccess,
} from "../../hooks/snackBarResponse.js";


const ID_DEFAULT = 1;


const StoreShipments = () => {
  const [copy, setCopy] = useState("Copiar");
  const { logout, auth } = useAuth();
  const [data, setData] = useState()
  const [open, setOpen] = useState(false)
  const [verify, setVerify] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAria = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EnvioClickVerify = () => {
    (async () => {
      const verify = new StoreEnvioCliclVerify()
      const response = await verify.postData(data.envioclick_key)
      console.log('response', response)
      if (response.data.clip_key_verified) {
        setVerify(response.data.clip_key_verified)
        enqueueSnackbar("Se a validado correctamente", {
          variant: "success",
        });
      } else {
        setVerify(response.data.clip_key_verified)
        enqueueSnackbar("Algo salio mal, no se verifico", {
          variant: "error",
        });
      }
      update()

    })();
  }

  const sentSettings = (setLoading, FormData) => {
    (async () => {

      const settings = new StoreSettings();
      const response = await settings.editDataAuth(ID_DEFAULT, FormData, logout);
      sendNotification(UseerrorOrsuccess(response));
      update()
      setLoading(false)
    })();
  };

  const update = () => {
    (async () => {

      const settings = new StoreSettings();
      const response = await settings.getAllByIdAuth(ID_DEFAULT, logout);
      setData(response.data)
    })();
  };
  useEffect(() => {
    update()
  }, [])



  const handleCopy = (e, text) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    setCopy("Copiado");
  };
  return (
    <Container>
      {data && <UpdateKey open={open} setOpen={setOpen} data={data} sentSettings={sentSettings} />}

      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="div" sx={{ paddingBottom: '2%' }}>
          <strong>Envioclick</strong>
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="h6"><strong>Pasos para configurar tu cuenta</strong></Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Regístrate en www.envioclick.com, confirma tu correo electrónico e inicia sesión.
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Ingresa en la sección "Mi cuenta" ubicada en la parte superior derecha del portal.
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Encontraras una pestana que se llama “API Key / Desarrolladoras” en la cual podrás obtener tu
          API Key que tiene un formato como este:  54ac905b-d0e9-4147-8285-e907af6fc30c
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Agregar tu “API Key” en la opción de “Llaves de acceso” que se muestra a continuación.
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Verifica que el “API Key este registrada correctamente.
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Copia el webhook que aparece a continuación en tu portal de Envioclick.
        </Typography>
      </Grid>
      <Paper
        sx={{
          padding: "8px 16px",
          backgroundColor: "primary.main",
          marginTop: "32px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <KeyIcon fontSize="medium" sx={{ marginRight: "8px" }} />{" "}
          <strong>Llaves de acceso</strong>
        </Typography>


        <IconButton

          aria-controls={openAria ? 'long-menu' : undefined}
          aria-expanded={openAria ? 'true' : undefined}
          onClick={handleClick}

        >
          <MoreVertIcon fontSize="medium" sx={{ marginRight: "8px", color: "#fff" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={openAria}
          onClose={handleClose}

          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}


        >
          <MenuItem onClick={() => { setOpen(true); handleClose(); }}>
            Editar Api key
          </MenuItem>
        </Menu>


      </Paper>
      <Grid item xs={12}>
        <CustomTable
          titles={[
            { align: "left", title: "Tipo" },
            { align: "left", title: "Valor" },
          ]}
        >
          <TableRow
            key={1}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            hover
          >
            <TableCell align="left">
              <Typography variant="body1">Api Key</Typography>
            </TableCell>
            <TableCell align="left">
              {data && <Typography variant="body1">{data.envioclick_key}</Typography>}
            </TableCell>
          </TableRow>
        </CustomTable>
      </Grid>

      <Grid item xs={12} mt="32px">
        <Typography variant="h6"><strong>Verificación</strong>
          {data && <VerifiedIcon sx={{ color: (data.envioclick_key_verified ? 'green' : 'gray'), marginLeft: '16px' }} />}
        </Typography>
        <Typography variant="body1" mt="8px">
          Para verificar que tu cuenta de envioclick este funcionando correctamente con tu Api Key,
          presiona el siguiente botón, si todo esta correcto, veras un icono en color verde.
        </Typography>
      </Grid>
      <Grid item xs={12} mt="32px" textAlign={'right'}>
        {data && <Button disabled={!data.envioclick_key || data.envioclick_key_verified} variant="contained" onClick={() => { EnvioClickVerify() }}>Validar</Button>}
      </Grid>

      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="div">
          <strong>Webhooks</strong>
          {data && <VerifiedIcon sx={{ color: (data.envioclick_webhook_verified ? 'green' : 'gray'), marginLeft: '16px' }} />}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Inicia sesión en www.envioclick.com
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> En el menú de la izquierda, en la opción de Configuración, ingresa a Mis Webhooks.
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Copia el enlace que aparece a continuación y agrégalo en el campo que dice “Url”
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Presionamos el botón de “Probar” regresamos a esta vista y actualizamos el estado.
        </Typography>
        <Typography variant="body1" mt="8px">
          <span>&#8226;</span> Si aparece verificado, entonces puedes regresar a envioclick y darle “Guardar”.
        </Typography>
      </Grid>
      {data && <Grid item xs={12} mt="32px" mb="16px">
        <Typography variant="" sx={{ display: "inline-block" }}>
          {data?.envioclick_webhook ? data?.envioclick_webhook : ""}
          {/*  https://x6pp4f337k.execute-api.us-east-2.amazonaws.com/prod/api/v1/envioclick */}
        </Typography>
        <Tooltip title={copy}>
          <ContentCopyIcon
            onClick={(e) => handleCopy(e, data?.envioclick_webhook ? data?.envioclick_webhook : "")}
            className="left-text"
            fontSize="small"
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      </Grid>}

      <Grid item xs={12} textAlign={"center"} sx={{ margin: "32px 0px" }}>
        {/*  <Button>Probar EnvioClick</Button> */}
      </Grid>

    </Container>
  );
};

const UpdateKey = ({ open, setOpen, data, sentSettings }) => {
  const [loading, setLoading] = useState(false)


  const formik = useFormik({
    initialValues: initValEnvioclicKey(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchEnvioclicKey()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      sentSettings(setLoading, formData);
      setLoading(false);
      setOpen(false);

    },
  });
  return (
    <CustomizedDialogs open={open} setOpen={setOpen} title={"Actualizar Api key"} size={"xs"}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container >
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="envioclick_key"
              value={formik.values.envioclick_key}
              onChange={formik.handleChange}
              error={
                formik.touched.envioclick_key && Boolean(formik.errors.envioclick_key)
              }
              label="Api Key"

              margin="dense"
              size="small"
              variant="outlined"
              autoComplete='none'

              helperText={
                formik.touched.envioclick_key ? formik.errors.envioclick_key : ""
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", marginTop: "8px" }}>
            <LoadingButton
              loading={loading}

              type="submit"
              variant="contained"
              sx={{ color: "#fff" }}
            >
              Actualizar
            </LoadingButton>
          </Grid>
        </Grid>


      </form>

    </CustomizedDialogs>
  );
}



export default StoreShipments;
