import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardHeader,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const SyncInventory = () => {
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Paper sx={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h6" gutterBottom component="div">
          <strong>Sincronizar inventario </strong>
        </Typography>
        <FormGroup>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <CardHeader
              sx={{ padding: "0", margin: "0" }}
              title={
                <Typography variant="body1" component="div">
                  Descontar artículos disponibles de las sucursales activadas
                  para vender en tienda en línea.
                </Typography>
              }
              subheader={
                "Al prender esta opción, tu tienda sincroniza el inventario con todo lo que encuentre disponible en los almacenes seleccionados para venta en línea."
              }
            />
          </Box>
        </FormGroup>
        <Grid
          container
          justifyContent="start"
          textAlign="start"
          sx={{ alignItems: "center", gap: "8px" }}
          marginTop="30px"
        >
          <Typography variant="body1" component="div">
            Cantidad máxima para vender por artículo:
          </Typography>
          <TextField
            name="origin_address"
            value={""}
            label="Ticket total"
            size="small"
            variant="outlined"
            autoComplete="none"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Paper>
    </>
  );
};
