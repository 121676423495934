import React, { useState } from 'react'
import { Grid } from "@mui/material";
import GenericSizeModal from '../../components/GenericSizeModal';
import EditIcon from '@mui/icons-material/Edit';
import { Suppliers } from "../../api/supplierService";
import { useAuth } from "user-context-fran-dev";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import PurchaseService from '../../api/purchaseService';
import SalesService from '../../api/salesService';
import CustomerService from '../../api/customerService'
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import GenericPaymentConditions from '../GenericPaymentConditions'
import CurrencyFormat from 'react-currency-format';
import GenericEmptyTable from '../../components/GenericEmptyTable'
import ArticleExpandableView from './ArticleExpandableView';
import { GenericHeaderTitle } from '../GenericHeader';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const InventoryGenericDetailsView = ({ name, pay, data, children, type, reloadDataOforder, status }) => {

  const [open, setOpen] = useState(false)
  const [openCredit, setOpenCredit] = useState(false)
  const { logout } = useAuth();
  const [dataList, setData] = useState('')
  const [supplier, setSuplierid] = useState('Proveedor')
  const [client, setClient] = useState('')
  const [loading, setLoading] = useState(false)
  const [msg, sendNotification] = useNotification();
  /* const [dataSupplier, setDataSuppllier] = useState() */



  const handleChange = (event) => {
    console.log('evento', event)
    setSuplierid(event.target.value);
    setClient(event.target.value)
  };

  const openModalSuppliers = () => {
    supplierList();
  }

  const openModalClients = () => {
    clientList();

  }



  const supplierList = () => {
    (async () => {
      let getUsers = new Suppliers();
      let response = await getUsers.getAllAuth(logout)
      console.log('suppliers', response)
      setData(response.data);
      setOpen(true)
    })();
  };

  const clientList = () => {
    (async () => {
      let getClients = new CustomerService();
      let response = await getClients.getAllAuth(logout)
      console.log('clients', response)
      setData(response.data);
      setOpen(true)
    })();
  };


  const editSupplier = () => {
    (async () => {
      //setLoading(true)
      let getPurchases = new PurchaseService();
      let response = await getPurchases.editDataAuth(data.id, { 'supplier': supplier }, logout)
      console.log(response)
      sendNotification(UseerrorOrsuccess(response))
      reloadDataOforder()
      setOpen(false)
      setLoading(false)
    })();
  }

  const editClient = () => {
    (async () => {
      //setLoading(true)
      let getPurchases = new SalesService();
      let response = await getPurchases.editDataAuth(data.id, { 'client': client }, logout)
      console.log(response)
      sendNotification(UseerrorOrsuccess(response))
      reloadDataOforder()
      setOpen(false)
      setLoading(false)
    })();
  }

  /*   const GetInfoSupplier = () => {
      (async () => {
        let getSupplier = new Suppliers();
        let response = await getSupplier.getAllByIdAuth(data.supplier.id, logout)
        console.log(response.data.data)
        setDataSuppllier(response.data)
      })();
    } */


  return (
    <>
      {data &&

        <>
          <GenericPaymentConditions data={data} open={openCredit} setOpen={setOpenCredit} UpdateInfo={reloadDataOforder} target={type} />
          {type === 'sales' ? (
            <>
              <GenericSizeModal open={open} setOpen={setOpen} title={"Seleccionar Cliente"}>
                <Grid item xs>
                  <Grid item xs={12} >
                    {dataList ?
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Categorias"
                        fullWidth
                        name="client"
                        value={client}
                        onChange={handleChange}


                      >
                        <MenuItem value="">
                          <em>Clientes</em>
                        </MenuItem>
                        {dataList ? dataList.map((data, key) => (
                          <MenuItem key={key} value={data.id}><a>{data.business_name}</a></MenuItem>
                        )) : ''}
                      </Select> :
                      <GenericEmptyTable msg={"No hay clientes en el registro"} />
                    }
                  </Grid>
                  <Grid item xs={12} >
                    <Grid item xs ></Grid>
                    <Grid container justifyContent="flex-end">
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          onClick={editClient}
                          type="submit"
                          variant="contained"
                          disabled={!dataList}
                          sx={{ color: "#fff" }}
                        >
                          Agregar
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </GenericSizeModal>
              <Grid container spacing={3} >
                <Grid item xs>
                  <GenericHeaderTitle title={name}>
                    {data.status === 1 && <EditIcon disabled={true} sx={{ cursor: "pointer" }} onClick={() => openModalClients()} />}
                  </GenericHeaderTitle>

                  {/*  <Paper className="generic-table" sx={{ backgroundColor: 'background.header' }} elevation={3}>
            <Grid item container
                direction="row"
                justifyContent="center"
                alignItems="center">
              <Grid item xs>
                <div>
                  <h2>{name}</h2>
                </div>
              </Grid>
              <Grid item xs className="edit-button">
                {data.status === 1 && <EditIcon disabled={true}  onClick={()=> openModalClients()}/>}
              </Grid>
            </Grid>
            </Paper> */}
                  <Grid item xs={12}>
                    <div className="bussines-name">
                      <h2>{data.client?.business_name ? data.client.business_name : 'Nombre'}</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="phone">
                      <h3>{data.client?.rfc ? data.client.rfc : 'RFC'}</h3>
                    </div>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item className="phone" pr="8px">
                      <LocalPhoneIcon />
                    </Grid>
                    <Grid item xs>
                      {data.client ? <CurrencyFormat value={data.client.phone} displayType={'text'} format="(##) #### ####" /> : <a>Telefono</a>}
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item className="phone" pr="8px">
                      <EmailIcon />
                    </Grid>
                    <Grid item xs>
                      <a>{data.client?.email ? data.client.email : 'Email'}</a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <GenericHeaderTitle title={pay}>
                    {data.status === 1 && <EditIcon disabled={true} sx={{ cursor: "pointer" }} onClick={() => setOpenCredit(true)} />}
                  </GenericHeaderTitle>
                  {/*        <Paper className="generic-table" sx={{ backgroundColor: 'background.header' }} elevation={3}>
            <Grid item container
                direction="row"
                justifyContent="center"
                alignItems="center">

              <Grid item xs={9}>
                <div>
                  <h2>{pay}</h2>
                </div>
              </Grid>
              <Grid item xs className="edit-button">
               {data.status === 1 && <EditIcon disabled={true}  onClick={()=> setOpenCredit(true)}/>}
              </Grid>
            </Grid>
            </Paper> */}
                  <Grid item xs={12}>
                    <div className="bussines-name">
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={5} className="phone">
                        <a>Días de crédito:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          {data?.credit_days ? data.credit_days : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {type !== "sales" &&
                    <Grid item xs={12}>
                      <Grid item container direction="row">
                        <Grid item xs={5} className="phone">
                          <a>Límite de crédito:</a>
                        </Grid>
                        <Grid item xs >
                          <Grid container justifyContent="flex-start">
                            <CurrencyFormat value={data.client?.credit_limit ? data.client.credit_limit : ''} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>}
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={5} className="phone">
                        <a>Descuento:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          {data?.discount ? data.discount + '%' : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>IVA:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          {data.iva ? data.iva + '%' : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <GenericSizeModal open={open} setOpen={setOpen} title={"Seleccionar Provedor"}>
                <Grid item xs>
                  <Grid item xs={12} >
                    <Select
                      fullWidth
                      name="warehouse"
                      id="warehouse"
                      value={supplier}
                      onChange={handleChange}
                      label="Age"

                    >
                      <MenuItem value="">
                        <em>Proveedor</em>
                      </MenuItem>
                      {dataList ? dataList.map((data, key) => (
                        <MenuItem key={key} value={data.id}><a>{data.business_name}</a></MenuItem>
                      )) : ''}
                    </Select>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid item xs ></Grid>
                    <Grid container justifyContent="flex-end">
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          onClick={editSupplier}
                          type="submit"
                          variant="contained"
                          sx={{ color: "#fff" }}
                        >
                          Agregar
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </GenericSizeModal>
              <Grid container spacing={3} >
                <Grid item xs>
                  <GenericHeaderTitle title={name}>
                    {status === 1 && <EditIcon disabled={true} sx={{ cursor: "pointer" }} onClick={() => openModalSuppliers()} />}
                  </GenericHeaderTitle>
                  {/* <Paper className="generic-table" sx={{ backgroundColor: 'background.header' }} elevation={3}>
            <Grid item container
                direction="row"
                justifyContent="center"
                alignItems="center">
              <Grid item xs>
                <div>
                  <h2>{name}</h2>
                </div>
              </Grid>
              <Grid item xs className="edit-button">
                  {status == 1 && <EditIcon disabled={true}  onClick={()=> openModalSuppliers()}/>}
              </Grid>
            </Grid>
          </Paper> */}
                  <Grid item xs={12}>
                    <div className="bussines-name">
                      <h2>{data.supplier ? data.supplier.business_name : 'Nombre Empresa'}</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="phone">
                      <h3>{data.supplier?.rfc ? data.supplier.rfc : 'RFC'}</h3>
                    </div>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item className="phone" pr="8px">
                      <LocalPhoneIcon />
                    </Grid>
                    <Grid item xs>
                      {data.supplier ? <CurrencyFormat value={data.supplier.phone} displayType={'text'} format="(##) #### ####" /> : <a>Telefono</a>}
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item className="phone" pr="8px">
                      <EmailIcon />
                    </Grid>
                    <Grid item xs>
                      <a>{data.supplier ? data.supplier.email : 'Email Empresa'}</a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <GenericHeaderTitle title={pay}>
                    {status === 1 &&
                      <EditIcon disabled={true} sx={{ cursor: "pointer" }} onClick={() => setOpenCredit(true)} />
                    }
                  </GenericHeaderTitle>
                  {/*     <Paper className="generic-table" sx={{ backgroundColor: 'background.header' }} elevation={3}>
          <Grid item container
                direction="row"
                justifyContent="center"
                alignItems="center">
              <Grid item xs={10}>
                <div>
                  <h2>{pay}</h2>
                </div>
              </Grid>
              <Grid item xs className="edit-button">
                 {status == 1 && <EditIcon disabled={true}  onClick={()=> setOpenCredit(true)}/>}
              </Grid>
            </Grid>
          </Paper> */}
                  <Grid item xs={12}>
                    <div className="bussines-name">

                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>Días de crédito:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          {data.credit_days ? data.credit_days : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {type !== "purchase" && <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>Límite de crédito:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          <CurrencyFormat value={data.supplier?.credit_limit ? data.supplier.credit_limit : ''} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>}
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>Descuento:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          {data?.discount ? data.discount + '%' : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>IVA:</a>
                      </Grid>
                      <Grid item xs >
                        <Grid container justifyContent="flex-start">
                          {data.iva ? data.iva + '%' : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={3}>
            <Grid item xs className="table">
              {data.status === 1 &&
                <Grid item xs={12} mb={"16px"}>
                  <ArticleExpandableView type={type} reloadDataOforder={reloadDataOforder} wh={data}></ArticleExpandableView>
                </Grid>
              }
              <GenericHeaderTitle title="Artículos" />
              {/*        <Paper className="generic-table"  sx={{ backgroundColor: 'background.header' }}elevation={3}>
            <h2>Artículos</h2>
          </Paper> */}

              <Grid item xs >
                {children}
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </>
  )
}
export default InventoryGenericDetailsView