import React, { useState } from "react";
import Container from "../../../layout/gridContainer";
import { useEffect } from "react";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
  Paper,
  TextField,
  Divider,
} from "@mui/material";
import { FilterLabel, Filters } from "../../../api/catalog/filterManage";
import { useAuth } from "user-context-fran-dev";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomizedDialogs, {
  ModalRight,
} from "../../../components/GenericsModals";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValAddFilter,
  initValAddLabel,
  valSchAddFilter,
  valSchAddLabel,
} from "../../../utils/formHelper";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";

const CatalogFilter = ({ setReload }) => {
  const [filters, setFilters] = useState("");
  const [open, setOpen] = useState(false);

  const { logout } = useAuth();

  const getFilter = () => {
    (async () => {
      let getCategory = new Filters(); /* Filters product_filter */
      let response = await getCategory.getAllAuth(logout);
      setFilters(response.data);
    })();
  };

  useEffect(() => {
    getFilter();
    window.localStorage.setItem("menu", 10);

    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 3);
    };
  }, []);
  return (
    <Container>
      {open && (
        <ModalAddFilter
          setOpen={setOpen}
          open={open}
          edit={false}
          update={getFilter}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <GenericHeaderTitle title={"Filtros"}>
            <Button onClick={() => setOpen(true)}>Agregar</Button>
          </GenericHeaderTitle>
        </Grid>
        <Grid item xs={12} mt={"24px"}>
          {filters &&
            filters.map((row, index) => (
              <LabelList row={row} key={index} update={getFilter} />
            ))}
        </Grid>
      </Grid>
    </Container>
  );
};

const LabelList = ({ row, update }) => {
  const [arrow, setArrow] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {}, []);

  return (
    <Paper sx={{ marginTop: "8px" }}>
      {openEdit && (
        <ModalFilter
          open={openEdit}
          setOpen={setOpenEdit}
          data={row}
          update={update}
        />
      )}
      <Paper
        sx={{ padding: "8px 16px" /* backgroundColor: "background.header" */ }}
      >
        <Grid container>
          <Grid item xs={9} sm={6}>
            <Grid container justifyContent="start" alignItems="center">
              <Typography>
                <strong>{row.name}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={6}>
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <IconButton
                sx={{ margin: 0, padding: 0 }}
                onClick={() => {
                  setArrow(!arrow);
                }}
              >
                {arrow ? (
                  <KeyboardArrowUpIcon
                    fontSize="small"
                    sx={{ color: "text.primary", margin: 0, padding: 0 }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{ color: "text.primary", margin: 0, padding: 0 }}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Collapse in={arrow} timeout={1000} unmountOnExit>
        <Grid item xs={12} sx={{ padding: "8px 16px 16px 16px" }}>
          <Stack direction="column">
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              {" "}
              <Button onClick={() => setOpenEdit(true)}>Editar</Button>{" "}
            </Box>
            {row.labels &&
              row.labels.map((label, index) => (
                <LabelCheck key={index} label={label} index={index} />
              ))}
          </Stack>
        </Grid>
      </Collapse>
    </Paper>
  );
};

const LabelCheck = ({ label, index }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "4px 8px ",
        margin: "0px 0px",
      }}
      key={index}
    >
      <div>{`${index + 1}- ${label.name} `}</div>
    </Box>
  );
};

const ModalAddFilter = ({ open, setOpen, edit, update }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const postFilter = async (formData) => {
    setLoading(true);
    let setFilter = new Filters();
    const response = await setFilter.postDataAuth(formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
    setOpen(false);
  };

  const putFilter = async (formData) => {
    setLoading(true);
    let setFilter = new Filters();
    const response = await setFilter.editDataAuth(edit.id, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: initValAddFilter(edit),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddFilter()),
    onSubmit: async (formData, { resetForm }) => {
      if (edit) {
        putFilter(formData);
      } else {
        postFilter(formData);
      }
      /*  post to catalog/filter      put &&  /:id
      {
        name: "",
      } */
    },
  });
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);
  return (
    <CustomizedDialogs
      title={edit ? "Actualizar filtro" : "Agregar nuevo filtro"}
      setOpen={setOpen}
      open={open}
    >
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container mt={"8px"}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label="Nombre del filtro"
                margin="dense"
                size="small"
                variant="outlined"
                autoComplete="none"
                inputProps={{ maxLength: 50 }}
                helperText={formik.touched.name ? formik.errors.name : ""}
              />
              {/*   <CountWords length={formik.values.name.length} /> */}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "8px",
              }}
            >
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
              >
                {edit ? "Actualizar" : "Agregar"}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </CustomizedDialogs>
  );
};

const ModalFilter = ({ open, setOpen, data, update }) => {
  const { logout } = useAuth();
  const [openF, setOpenF] = useState(false);
  const [openL, setOpenL] = useState(false);
  const [isEditL, setIsEditL] = useState("");
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);

  const deleteFilter = async () => {
    setLoading(true);
    let setFilter = new Filters();
    const response = await setFilter.deleteAuth(data.id, logout);
    sendNotification(UseerrorOrsuccess(response));
    setLoading(false);
    update();
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <ModalRight setOpen={setOpen} open={open} title="Editar filtros" size="xs">
      {openL && (
        <ModalAddLabel
          open={openL}
          setOpen={setOpenL}
          edit={isEditL}
          setIsEditL={setIsEditL}
          filterId={data.id}
          update={update}
        />
      )}
      {openF && (
        <ModalAddFilter
          open={openF}
          setOpen={setOpenF}
          edit={data}
          update={update}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{data.name}</Typography>
            <Box>
              <IconButton
                sx={{
                
                  padding: 0,
                  color: "inherit",
                  margin: "0 12px 0 32px",
                }}
                onClick={() => {
                  setOpenF(true);
                }}
              >
                <EditIcon sx={{ cursor: "pointer" }} />
              </IconButton>

              <IconButton
                sx={{ margin: 0, padding: 0, color: "inherit" }}
                disabled={loading}
                onClick={() => {
                  deleteFilter();
                }}
              >
                <CloseIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Box>
          </Box>
          <Divider
            sx={{
              height: "1px",
              borderColor: "text.primary",
              backgroundColor: "text.primary",
              marginTop: "8px"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              margin: "24px 0px 8px 0px",
            }}
          >
            <Button onClick={() => setOpenL(true)}>Agregar Etiqueta</Button>
            {/*       put post  catalog/label  /:id 
                {
                  name: ""
                }
                add modal for add and edit  */}
          </Box>
          {data.labels &&
            data.labels.map((label, index) => (
              <Label
                key={index}
                label={label}
                index={index}
                setIsEditL={setIsEditL}
                setOpenL={setOpenL}
                update={update}
              />
            ))}
        </Grid>
      </Grid>
    </ModalRight>
  );
};

const Label = ({ label, index, setIsEditL, setOpenL, update }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const deleteLabel = async () => {
    setLoading(true);
    let setLabel = new FilterLabel();
    const response = await setLabel.deleteAuth(label.id, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "8px 8px ",
          margin: "0px 0px",
        }}
        key={index}
      >
        <div>{`${index + 1}- ${label.name} `}</div>
        <Box>
          <IconButton
            sx={{
              margin: 0,
              padding: 0,
              color: "inherit",
              margin: "0 12px 0 32px",
            }}
            onClick={() => {
              setIsEditL(label);
              setOpenL(true);
              /* 
                    put post  catalog/label  /:id 
                    {
                      name: ""
                    }
                    add modal for add and edit
                  */
            }}
          >
            <EditIcon sx={{ cursor: "pointer" }} />
          </IconButton>
          <IconButton
            sx={{ margin: 0, padding: 0, color: "inherit" }}
            disabled={loading}
            onClick={() => {
              deleteLabel(); /* delete catalog/label/id */
            }}
          >
            <CloseIcon sx={{ cursor: "pointer", color: "inherit" }} />
          </IconButton>
        </Box>
      </Box>
      <Divider
        sx={{
          height: "1px",
          borderColor: "text.primary",
          backgroundColor: "text.primary",
        }}
      />{" "}
    </>
  );
};

const ModalAddLabel = ({ open, setOpen, filterId, edit, update, setIsEditL }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const postFilter = async (formData) => {
    setLoading(true);
    let setFilter = new FilterLabel();
    const response = await setFilter.postDataAuth(formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
    setOpen(false);
  };

  const putFilter = async (formData) => {
    setLoading(true);
    let setFilter = new FilterLabel();
    const response = await setFilter.editDataAuth(edit.id, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: initValAddLabel(edit, filterId),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddLabel()),
    onSubmit: async (formData, { resetForm }) => {
      if (edit) {
        putFilter(formData);
      } else {
        postFilter(formData);
      }
    },
  });

  useEffect(() => {

    return () => {
      
      setIsEditL("")
      formik.resetForm();
    };
  }, [open]);

  return (
    <CustomizedDialogs
      title={edit ? "Actualizar etiqueta" : "Agregar nueva etiqueta"}
      setOpen={setOpen}
      open={open}
    >
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container mt={"8px"}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label="Nombre del filtro"
                margin="dense"
                size="small"
                variant="outlined"
                autoComplete="none"
                inputProps={{ maxLength: 50 }}
                helperText={formik.touched.name ? formik.errors.name : ""}
              />
              {/*   <CountWords length={formik.values.name.length} /> */}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "8px",
              }}
            >
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
              >
                {edit ? "Actualizar" : "Agregar"}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </CustomizedDialogs>
  );
};

export default CatalogFilter;
