import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import TableCell from "@mui/material/TableCell";
import Collapse from '@mui/material/Collapse';
import { Box } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import CircleIcon from '@mui/icons-material/Circle';
import {  IconButton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, TextField, Button} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { initSearchParameters, valSearchparameters} from '../../../../../utils/formHelper'
import { useAuth } from "user-context-fran-dev";
import { ProductService } from "../../../../../api/productService"
import CategoryManages from '../../../../../api/categoryManages'
import GenericTable from "../../../../../components/GenericTable"
import CircularProgress from '@mui/material/CircularProgress';
import AdjustmentModal from '../../../../../components/operations/AdjustmentsModal'
import GenericEmptyTable from '../../../../../components/GenericEmptyTable'
import { GenericHeaderExpandable } from "../../../../../components/GenericHeader";
import SearchFilters from "../../../../../components/inventario/searchFilters";
import { ProductModelService } from "../../../../../api/productService";
import ImageSize from "../../../../../components/ImageSize";

export default function SearchAddArticle(props) {
  
  const { type, reloadDataOforder, dataAdjustment} = props
  const [expanded, setExpanded] = React.useState(false); 
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [dataTable, setData] = useState(false);
  const [categories, setCategories] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [dataProduct, setDataProduct] = useState("")
  const [model, setModel]= useState("");
  const [filters, setFilters] = useState("")

  useEffect(() => {
    getCategories();
  },[]);

  const getCategories = () => {
    (async () => {
      let categories = new CategoryManages();
      let response = await categories.getAllAuth(logout);
      setCategories(response.data)
      console.log('categories',response.data)
    })();
  };


  const formik = useFormik({
    initialValues: initSearchParameters(),
    validationSchema: Yup.object(valSearchparameters()),
    onSubmit: async (formData) => {
      setLoading(true);
      let irequestFilter = []
      if(filters){
        for(let element in filters){
          if(filters[element]){
              irequestFilter.push({'key':`${element}`, 'value': `${filters[element]}`})
          }
        }
      } 
      irequestFilter.push({'key':'is_active', 'value': `true`}) 
      irequestFilter.push({'key':'pattern', 'value': `${formik.values.name}`})
      irequestFilter.push({'key':'page', 'value': `1`})
      irequestFilter.push({'key':'items', 'value': `10`})
      let products = new ProductService();
      let dataRes = await products.filter(irequestFilter, logout);
      console.log(dataRes)
      setData(dataRes.data.data)
    if (dataRes) {
        //setOpenModal(false);
    } else {
        alert("Error al buscar producto");
    } 
      //UpdatePurchaseList();
      setLoading(false);
    },
  });

  return (
    <div>
      {openModal && <AdjustmentModal open={openModal}
       setOpen={setOpenModal}
       Product={dataProduct}
       reloadDataOforder={reloadDataOforder} 
       model={model}
       dataAdjustment={dataAdjustment}
       title="Seleccionar Presentación" 

       />}
        <GenericHeaderExpandable title="Buscar artículos" arrow={expanded} setArrow={setExpanded}>

        <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          alignItems="center"
          >
          

          <Grid item xs={9} sm={4} mr="16px" >
          <TextField
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={
                formik.touched.name && Boolean(formik.errors.name)
            }
            label="Buscar por nombre"
            /* margin="normal" */
            size="small"
            variant="outlined"
            /* className="space-text-field" */
            helperText={
                formik.touched.name ? formik.errors.name : ""
            }
            />
          </Grid>
          <Grid item xs={3} sm={2} >
          <LoadingButton
              loading={loading}
              fullWidth
              type="submit"
              variant="contained"
          >
              Buscar
          </LoadingButton>
          </Grid>
          <SearchFilters filters={filters} setFilters={setFilters}/>
        </Grid>
        </form>
        { dataTable && 
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          >
          <GenericTable titles={["Imagen", "SKU", "Nombre", "Estatus", "Categoria", "Más"]} >
            {dataTable.map((row, index) => (
                <Row key={index} row={row}
                 setOpenModal={setOpenModal}
                 setDataProduct={setDataProduct}
                 dataAdjustment={dataAdjustment}
                 setModel={setModel}
                 type={type}
                 >
                </Row>
            ))}
          </GenericTable>
          </Grid>
           }

      </GenericHeaderExpandable>
     
    </div>
  
  );
}

function Row(props){

  const { row, setOpenModal, setDataProduct, setModel} = props
  const [open, setOpen] = useState(false);
  const [dataPresentation, setDataResponse] = useState(false)
  const [loading, setLoading] = useState(false)
  const { logout } = useAuth();


  console.log('data', dataPresentation)

 
  async function getinfo(id){
    setLoading(true)


    let irequestFilter = []
    irequestFilter.push({'key':'product', 'value': `${id}`}) //Modifique el endpoint que se utiliza 
    irequestFilter.push({'key':'is_active', 'value': `true`})

    let products = new ProductModelService();
    let dataRes = await products.filter(irequestFilter, logout); 
    setDataResponse(dataRes.data)
    setDataProduct(dataRes.data)
    setLoading(false)
  }


  async function getAvailableDataModal(model){
 

    setModel(model)
    setOpenModal(true)
  }

  return(
  <>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                
              >
            <TableCell align="left"  sx={{minWidth: "20px", maxWidth: "15vw" }} > 
            <ImageSize img= {row?.main_model?.image?.url? row.main_model?.image?.url:  ""} weight={1}/>
       {/*        <Typography variant="subtitle2" component="div">
              {row.id}
              </Typography> */}
            </TableCell>
            <TableCell align="left"  sx={{minWidth: "20px", maxWidth: "15vw" }} > 
              <Typography variant="subtitle2" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {row.sku}
              </Typography>
            </TableCell>
            <TableCell align="left"  sx={{minWidth: "20px", maxWidth: "15vw" }} > 
                <Typography variant="subtitle2" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {row.name}
                </Typography>
            </TableCell>
            <TableCell align="left"  sx={{minWidth: "20px", maxWidth: "15vw" }} > 
                <Typography variant="subtitle2" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {(row.is_active)?<CircleIcon  sx={{color: 'green', fontSize: 15}}/>:
                     <CircleIcon  sx={{color: 'red', fontSize: 15}}/>} 
                </Typography>
            </TableCell>
            <TableCell align="left"  sx={{minWidth: "20px", maxWidth: "15vw" }} > 
                <Typography variant="subtitle2" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
               {row.category.name}
                </Typography>
            </TableCell>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(!open)
                  if(!open){
                  getinfo(row.id)
                  }
                }
              }
              >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>     
            </TableRow>
            <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {dataPresentation  ?
                <Box sx={{ margin: 1 }}>
                    <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    >
                 <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {dataPresentation.map((model, index) => (
                          <Grid item xs={2} sm={4} md={4} key={index}>
                            <Button size="large" onClick={() => {
                              console.log(row)
                              getAvailableDataModal(model)
                              
                              }}fullWidth color="primary" variant="outlined"> {model.name}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                  </Grid>
                </Box> :
                <div className="model-null">
                  {loading && <CircularProgress />}
                  {!dataPresentation && !loading && 
                  <GenericEmptyTable msg={"No hay modelos de este producto"}/>
                  }
                </div>
                }
              </Collapse>
            </TableCell>              
          </TableRow>
           </>
  )
}