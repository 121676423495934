import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';


const LoadPage = () => {

    return ( 
        <Box sx={{
            bgcolor: "background.default", 
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"}}>
               <CircularProgress />               
        </Box>
     );
}
 
export default LoadPage;