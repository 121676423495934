import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';


const ErorPages = () => {
    const Navigate = useNavigate();
    const goHome = ()=>{
        Navigate("/home")
    }
    return ( 
        <Box sx={{
            bgcolor: "background.default", 
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"}}>
            <Grid item xs={12} md={5} >
                <Typography variant="h3" gutterBottom sx={{color: "text.primary"}}> 
                    Acceso denegado 
                </Typography>
                <Typography variant="subtitle2" gutterBottom  sx={{color: "text.secondary"}}>
                    No tienes permisos para acceder a este sito 
                </Typography>
                <Box  sx={{textAlign: "end"}}>
                <Button onClick={()=> goHome()} variant="contained">
                    Volver al Inicio
                </Button>
                </Box>
            </Grid>                 
        </Box>
     ); 
}
 
export default ErorPages;