import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import InicioCard from './CarImg';
import { getAppsCars, getAppsPos } from '../../api/data/inicioData';
import '../../scss/home.scss'
import { BusinessGroups } from "../../api/businessGroups";
import { useAuth } from "user-context-fran-dev";
import UserManages from "../../api/userManages";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Homenotifications from './homeNotifications';
import { Notifications } from "../../api/common/notification";
import { BottonModalDiag } from "../../components/GenericsModals";
import { Grid, Divider } from '@mui/material'
import appStore from "../../assets/apple-icon.svg"
import playStore from "../../assets/google-play-short.png"
import { motion } from "../../../node_modules/framer-motion/dist/framer-motion"
import Settings from "../../assets/settings.png";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useSearchParams } from "react-router-dom";
import CashRegisterPos from "../pos/cashRegister/cashRegister";
import AddCashRegister from "../pos/cashRegister/addCashRegister";


const InicioApps = ({ admin }) => {
  const [appsGroup, setAppsGroup] = useState("");
  const { logout, auth } = useAuth();
  const [user, setUser] = useState("");
  const [noty, setNoty] = useState("")
  const [open, setOpen] = useState(false)
  const [openCashReg, setOpenCashReg] = useState(false)
  const [type, setType] = useState()
  const [view, setView] = React.useState(1);
  const [params, setParams] = useSearchParams('');

  const data = getAppsCars();

  {/* POSIntegration This section has been added to have the pos integrated in the admin project */ }
  const posData = getAppsPos();
  

  const size = {
    maxWidth: 295,
    minWidth: 295,
    maxHeight: 300,
    margin: '10px',
  };

  useEffect(() => {

    console.log('posdata', posData)

    if (!params.has("tab")) {
      setView(1);
      params.set("tab", '1');
    } else if (params.has("tab")) {
      console.log('tab', params.get("tab"))
      let param = params.get("tab")
      if (param.lastIndexOf('2/') >= 0) {
        setView(2);
        params.set("tab", '2');
      } else {
        setView(1);
        params.set("tab", '1');

      }
    }
    setParams(params)
    UpdateAppAcces();
    GetCurrentUser();
    GetNotifications();

  }, []);


  const GetNotifications = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ 'key': 'is_active', 'value': "true" })
      const getNoti = new Notifications();
      const response = await getNoti.filter(irequestFilter, logout);
      console.log(response.data)
      setNoty(response.data)

    })();
  };

  const GetCurrentUser = () => {
    (async () => {
      const getUser = new UserManages();
      const response = await getUser.getAllByIdAuth(auth.user.pk, logout);
      console.log(response.data)
      setUser(response.data)
    })();
  };

  const UpdateAppAcces = () => {
    (async () => {
      let getBranchs = new BusinessGroups();
      let response = await getBranchs.getAllAuth(logout);
      setAppsGroup(response.data);

    })();
  };
  const TypeModal = (type) => {
    setType(type)
    if (type === 'POS')
      setOpen(true)
    else if (type === 'cashRegister')
      setOpenCashReg(true)
  };


  const handleChange = (event) => {
    params.set("tab", `${event.target.value}`);
    setView(event.target.value)
    setParams(params);
  };

  return (

    <div className="contenedor-apps">
      <HelperModal open={open} setOpen={setOpen} type={type} />
      <HelperModalCash openCashReg={openCashReg} setOpenCashReg={setOpenCashReg}></HelperModalCash>
      <Grid container xs direction={{xs:"column", md:"row"}} justifyContent={{ md: "start" }} alignItems="center" sx={{ mb: '16px' }} >
        <Grid md={4} sx={{ marginBottom: '16px', mr: '16px' }} >
          <Typography
            variant="h6"
            component="div"
            sx={{ color: "text.primary" }}
          >
            <strong>Tus aplicaciones</strong>
          </Typography>

        </Grid>
        <Grid xs={6} sx={{ marginBottom: '16px' }}>

          <Box sx={{ minWidth: 120 }}>
            <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Vista</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={view}
                label="Vista"
                onChange={handleChange}
                sx={{ width: '200px' }}
              >
                <MenuItem value={1}>Admin</MenuItem>
                <MenuItem value={2}>Punto de venta</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {view === 1 &&
        <Grid container direction="row" justifyContent="center" alignItems="start" m={3}>
          <Grid container justifyContent="flex-start" spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 4, md: 12 }} >
          {appsGroup && user ?
            data.map((apps, index) => {
              return (
                <Grid sx padding={{ xs: '10px', md: '16px' }}>
                  <InicioCard
                    data={apps}
                    key={index}
                    TypeModal={TypeModal}
                    buy={appsGroup.find((data) => apps.id === data.id) !== undefined}
                    access={user.groups ? user.groups.find((data) => apps.id === data.id) : false}
                  />
                </Grid>
                )
            })
            
            : (<Box sx={{
              position: 'absolute', top: '40%',
              left: '50% ', with: '100vw', mt: '16px'
            }}>
              <CircularProgress />
            </Box>)}
            </Grid>
        </Grid>
      }
      {/* POSIntegration This section has been added to have the pos integrated in the admin project */}
      {view === 2 &&
        <Grid container className="caja-apps">
          {appsGroup && user ?

            posData.map((apps, index) => {
              return <InicioCard
                data={apps}
                key={index}
                TypeModal={TypeModal}
                buy={appsGroup.find((data) => 7 === data.id) !== undefined}
                access={user.groups ? user.groups.find((data) => 7 === data.id) : false}
              />
            })

            : (<Box sx={{
              position: 'absolute', top: '40%',
              left: '50% ', with: '100vw'
            }}>
              <CircularProgress />
            </Box>)}
        </Grid>
      }
      {/* POSIntegration This section has been added to have the pos integrated in the admin project */}
      <Homenotifications noty={noty} />




    </div>
  );
}

const HelperModal = ({ open, setOpen, type }) => {
  console.log('width', window.innerWidth)
  console.log('width', window.innerHeight)
  let title = ""
  if (type === "pos") {
    title = "Punto de venta"
  } else {
    title = "Próximamente"
  }
  if (window.innerWidth <= 768) { //tablet width
    return (
      <BottonModalDiag open={open} setOpen={setOpen} title={{ "text": title, "align": "center" }}>
        {type === "pos" ?
          <Grid container>
            <Grid item xs={7}>
              {/* <Link to={"/pos/login/"}    target={"_blank"}   style={{ textDecoration: "none" }}>
             <Button startIcon={<OpenInNewIcon />} >Abrir en el navegador</Button></Link> */}
            </Grid>
            <Grid item xs={12} mt="16px">
              <Typography>Descargar en:</Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                m: 'auto',
                alignItems: "center",
                justifyContent: "start",
                width: '100%',
              }}>

            
                <a href="https://apps.apple.com/us/app/keski-pos/id1661375259" target="_blank">
                  <img src={appStore} style={{ width: "150px", height: "45px", cursor: "pointer" }}></img>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.codenationstudio.app.keski_pos" target="_blank">
                  <img src={playStore} style={{ width: "150px", height: "60px", cursor: "pointer" }}></img>
                </a>
              </Box>
            </Grid>
          </Grid> :
          <>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              alignItems: "center",
              justifyContent: "center",
              width: 'auto',
              height: "auto"
            }}>

              <motion.div

                animate={{

                  rotate: [0, 360],

                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "linear",
                  repeatDelay: 0

                }}
              >   <img src={Settings} style={{ width: "50px", height: "50px" }}></img> </motion.div>
              <motion.div
                style={{}}
                initial={{ x: 20, y: -9, rotate: 25 }}
                animate={{

                  rotate: [360, 0],

                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "linear",
                  repeatDelay: 0
                }}
              >
                <img src={Settings} style={{ width: "50px", height: "50px" }}></img>
              </motion.div>

            </Box>
          </>}
      </BottonModalDiag>
    )
  }
}

const HelperModalCash = ({ openCashReg, setOpenCashReg }) => {
  let title = "Cajas abiertas"
  const [cashReload, setReload] = useState(false)
  return (
    <BottonModalDiag open={openCashReg} setOpen={setOpenCashReg} title={{ "text": title, "align": "start" }}>
      <Grid container direction="row" justifyContent="start">
        <Grid xs>

          <CashRegisterPos setReload={setReload} cashReload={cashReload} setHelpOpen={setOpenCashReg}></CashRegisterPos>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid xs>
          <AddCashRegister setReload={setReload} cashReload={cashReload}></AddCashRegister>
        </Grid>
      </Grid>
    </BottonModalDiag>
  )
}


export default InicioApps;