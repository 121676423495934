//https://api.test.74-208-117-143.plesk.page/api/v1/inventory/available/presentation?barcode=JP1

import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class BarCodeService extends BaseService{
    constructor(){
        const baseUrl = BASE_PATH;
        const endPoint = 'inventory/available/presentation';
        super(baseUrl, endPoint)
      }
}
