import React, { useState } from "react";
import { IconButton } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CircleIcon from '@mui/icons-material/Circle';
import { Box } from "@mui/material";
import TableInfo from './tableInfo';
import { useNavigate } from "react-router-dom";
import Products from "../../../api/productsManages";
import { useAuth } from "user-context-fran-dev";
import { CardHeader, Typography } from '@mui/material';

const ArticleTable = ({ data, setArticle, id }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [product, setProduct] = useState("");
    const { logout } = useAuth();

    const GetProduct = () => {
        (async () => {
            let getArticle = new Products();
            let response = await getArticle.getAllByIdAuth(id, logout);
            setProduct(response.data);
        })();
    }



    return (

        <>
            <TableRow

                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onDoubleClick={() => {
                    window.localStorage.setItem("menu", 10);
                    navigate(`detail/${id}`);
                }}>

                <TableCell component="th" scope="row" align="left" sx={{ maxWidth: '150px' }}>
                    <img src={data.main_model?.image ? data.main_model.image.url : ""}
                        style={{ width: '30px', height: 'auto' }}
                    ></img> </TableCell>
                <TableCell component="th" scope="row" sx={{ maxWidth: '250px' }} align="left">
                    <CardHeader
                        sx={{ m: 0, p: 0 }}
                        title={
                            <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {data.name}
                            </Typography>}

                    />
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    {(data.is_active) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
                        <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    <CardHeader
                        sx={{ m: 0, p: 0 }}
                        title={
                            <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {data.category.name}
                            </Typography>}

                    />
                </TableCell>
                {/* <TableCell align="left"> $17,000.00 </TableCell> */}
                <TableCell component="th" scope="row" >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => { if (!open) GetProduct(); setOpen(!open); }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell component="th" scope="row" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {/*          <Typography variant="h6" gutterBottom component="div">
                        Articulo Info 
                    </Typography> */}
                            <br />
                            <TableInfo data={product} />
                            <br />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>


    );
}


export default ArticleTable;