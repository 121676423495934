import { useSnackbar } from 'notistack';

import { useEffect, useState } from "react";


export const useNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, /* closeSnackbar */ } = useSnackbar();

    useEffect(() => {
        

        if (conf.msg?.status == 500) {
            enqueueSnackbar('ocurrio un error interno en el servidor', {
                variant: 'error',
            })
        }else { 
        if (conf.msg && conf.variant) {
            console.log('response', conf);
            if (conf.variant == 'success') {
                enqueueSnackbar(conf.msg, {
                    variant: 'success',
                    //autoHideDuration: 1000,
                    //action
                });
            } else if (conf.msg.data?.error) {
                enqueueSnackbar(conf.msg.data.error, {
                    variant: 'error',
                    //autoHideDuration: 1000,
                    //action
                });
                if (conf.msg.data?.error_code) {
                    for (var key in conf.msg.data.error_code) {
                        conf.msg.data.error_code[key].forEach(function (elemento) {
                            console.log(elemento);
                            enqueueSnackbar(elemento, {
                                variant: 'error',
                                //autoHideDuration: 1000,
                                //action
                            });
                        }
                        )
                    }
                }
            }else{
                for (var key in conf.msg?.data) {
                    conf.msg.data[key].forEach(function(elemento) {
                        console.log(elemento);
                        enqueueSnackbar(elemento, {
                            variant: 'error',
                            //autoHideDuration: 1000,
                            //action
                            });
                       }
                    )}
                }
        };
    }
    }, [conf]);
    return [conf, setConf];
};

export const UseerrorOrsuccess = (response) => {


    if (response.status >= 400) {
        console.log('data', response);
        return { msg: response, variant: 'error' }
    } else if (response.status >= 300 && response.status < 400) {
        console.log('data', response);
        return { msg: response, variant: 'warning' }
    } else {
        return { msg: 'Operacion exitosa', variant: 'success' }
    }

}

