import React, { useEffect, useState } from "react";
import {
    Paper,
    Grid,
    Typography,
    TableRow,
    TableCell,
    Link,
    Stack,
    Switch,
    TextField
  } from "@mui/material";
  import BasicTable from "../../../components/GenericTable";
  import { PaginationGeneric } from "react-generic-pagination";
  import CircularProgress from '@mui/material/CircularProgress';
import BasicModal from "../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { initValDiscount, valSchDiscount } from "../../../utils/formHelper";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAuth } from "user-context-fran-dev";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import { useNavigate } from "react-router-dom";
import { PosDiscount } from "../../../api/posAdmin/discountsManages";
import GenericEmptyTable from "../../../components/GenericEmptyTable";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PercentIcon from '@mui/icons-material/Percent';
import { Box, display } from "@mui/system";


const Discounts = () => {
  const [open, setOpen]= useState(false)
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [table, setTable] = useState(null);
  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading]= useState(true);
  const [msg, sendNotification] = useNotification();

  const DeleteDiscout = async (id) => {
    let setDiscount = new PosDiscount();
    let response = await setDiscount.editDataAuth(id, {is_deleted: true}, logout); 
    UpdateDiscountList()
    sendNotification(UseerrorOrsuccess(response))
  }
   



  useEffect(() => {
    navigate(`page=${page}`);
    UpdateDiscountList();
  }, [page]);

  const UpdateDiscountList = () => {
    (async () => {
      setLoading(true)
      let irequestFilter = [];
      irequestFilter.push({ key: "is_deleted", value: `${false}` });
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getDiscounts = new PosDiscount();
      let response = await getDiscounts.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      console.log(response.data)
      setLoading(false)
  })();
  };
  const pageSetter = (n) => {
    setPage(n);
  };
 

  return (
    <>
    <AddDiscount open={open} setOpen={setOpen} UpdateDiscountList={UpdateDiscountList}/>
      <Grid item xs={12}>
      <GenericHeaderTitle title="Descuentos">
      <Link
                component="button"
                variant="subtitle1"
                onClick={() => {setOpen(true)}}
                underline="none"
                className="link"
                p="0px"
              >
                Crear
              </Link>
      </GenericHeaderTitle>

      </Grid>
      {table && (
      <Grid item xs={12} mt={"16px"}>
        <BasicTable
          className="marginTable"
          titles={["#", "Tipo", "Descuento", "Acción"]} > 
          {table.map((row, index)=>(
            <Row row={row} key={index} index={index} DeleteDiscout={DeleteDiscout} />
          ))}
          
        </BasicTable>
        <Grid item xs={12} mt={"16px"}>
                <PaginationGeneric
                  className="paginationStyle"
                  page={page}
                  total={totalpages}
                  pageSetter={pageSetter}
                ></PaginationGeneric>
              </Grid>

      </Grid>     
      )}
      <Grid item xs={12}>
      {!table && loading && (
            <Grid container justifyContent="center" p={"24px"}>
              <CircularProgress />
            </Grid>
          )}
          {!table && !loading && <GenericEmptyTable />} 
      </Grid>
    </>
  );
};

const Row = ({row, index, DeleteDiscout }) => {
    return (
      <>
        <TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          hover
          onDoubleClick={() => {}}
        >
          <TableCell align="left" sx={{ minWidth: "20px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {index +1}
            </Typography>
          </TableCell>

          <TableCell align="left" sx={{ minWidth: "20px", }}>
            <Box sx={{display: "flex"}}>
             {row.type == 2?  <PercentIcon />:  <MonetizationOnIcon/>}
             </Box>
          </TableCell>
          <TableCell align="left" sx={{ minWidth: "20px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`${row.amount} ${row.type == 2? "%": "pesos"}`}
            </Typography>
          </TableCell>
          <TableCell align="left" sx={{ minWidth: "20px" }}>
            <Link
              component="button"
              variant="subtitle1"
              onClick={() => {DeleteDiscout(row.id)}}
              underline="none"
            >
              Eliminar
            </Link>
          </TableCell>
        </TableRow>
      </>
    );
  };

const AddDiscount = ({open, setOpen, UpdateDiscountList}) => {
  const [loading, setLoading]= useState(false);
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();

  useEffect(()=>{
    return()=>{
      formik.resetForm();
    }
  },[])

  const formik = useFormik({
    initialValues: initValDiscount(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDiscount()),
    onSubmit: async (formData, {resetForm}) => {
      setLoading(true);
      console.log(formData)

      SetRecipeGroup(formData)

      resetForm()
      setLoading(false); 
      setOpen(false);

    },
  });

  const  SetRecipeGroup= async (formData) => {
    let setDiscount = new PosDiscount();
    let response = await setDiscount.postDataAuth(formData, logout); 
    UpdateDiscountList()
    sendNotification(UseerrorOrsuccess(response))
  }

  return ( 
    <BasicModal  open={open}
    setOpen={setOpen}
    title="Crear descuento">
      <Grid item xs={12} >
        <form onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={"16px"}>
          <Grid item xs={6} >
            <FormControl fullWidth  size="small">
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                name="type"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.type}
                label="Tipo"
                onChange={formik.handleChange}
              >
                <MenuItem value={"1"}>Cantidad en pesos</MenuItem>
                <MenuItem value={"2"}>porcentaje sobre el total</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="amount"
                values={formik.values.amount}
                onChange={formik.handleChange}
                error={
                  formik.touched.amount && Boolean(formik.errors.amount)
                }
                label="Cantidad"
                size="small"
                variant="outlined"
                autoComplete ='none'
           
                helperText={
                  formik.touched.amount ? formik.errors.amount : ""
                }
              />
            
  
            </Grid>
   
          

          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={12}
              sm={7}
              md={5}
              lg={5}
              xl={5}
              sx={{ marginTop: "16px" }}
            >
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Guardar
              </LoadingButton>
            </Grid>
          </Grid>
          </Grid>
        </form>
        </Grid>

    </BasicModal>
   );
}
  



export default Discounts;
