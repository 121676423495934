import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export class Orders  extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'common/order';
      super(baseUrl, endPoint)
    }
  }
  
  export async  function GetAllWhtParams(endPoint, param){
    let url = `${BASE_PATH}/${endPoint}`;
    url = url + "?"
    param.forEach((element, index) => {
      url += element.key +"="+element.value 
      if(index +1 < param.length){
        url += "&"
      }
    });
    console.log(url)
    try {
      const params = {
        method: "GET",
        headers: {
          "Content-Type":"application/json",
        },
      }
      const response =  await  fetch(url, params);
      const jsonData = await response.json();
       /* console.log(jsonData)  */
     
      return jsonData;
    } catch (error) {
      console.log(error)
      return null;
    }
  } 