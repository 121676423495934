import React, { useEffect, useState } from "react";
import { Grid, Divider, TextField } from "@mui/material";
import BasicTable from "../../../components/GenericTable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { AvailablePresentation } from "../../../api/availablePresentation";
import { useAuth } from "user-context-fran-dev";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValSalesDelivered,
  valSchSalesDelivered, 
} from "../../../utils/formHelper";
import { OrderedDelivered } from "../../../api/orderedReceived";
import SalesOrder from "../../../api/salesOrder";
import GenericEmptyTable from "../../../components/GenericEmptyTable"
import ImageSize from "../../../components/ImageSize"
import { TableModal } from "../../../components/GenericsModals";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'



const SalesDelivered = ({ article, open, setOpen, sales, GetSalesDetail }) => {
  const [table, setTable] = useState("");
  const idArticle = article.id;
  const idPresentation = article.presentation.id;
  const warehouse = sales.warehouse.id;

  const { logout } = useAuth();

  const [dataArticles, setDataArticles] = useState();
  const [update, setUpdate] = useState(false); 

  console.log(article);

  useEffect(() => { 
    if(open){
      UpdateModalInfo();
      getPresentation();
    }
  }, [update, article, open]);

  const getPresentation = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "wh",
        value: `${warehouse}`,
      }); /* sales.warehouse */
      irequestFilter.push({ key: "pres", value: `${idPresentation}` });
      let getPresentation = new AvailablePresentation();
      let response = await getPresentation.filter(irequestFilter, logout);
      console.log(response.data);
      setTable(response.data);
    })();
  };

  const UpdateModalInfo = () => {
    (async () => {
      let getSales = new SalesOrder();
      let response = await getSales.getAllByIdAuth(sales.id, logout);
      console.log(response.data.data);
      console.log(response.data.articles.find((data) => data.id === idArticle));
      setDataArticles(response.data.articles.find((data) => data.id === idArticle));
    })();
  };

  return (
    <TableModal open={open} setOpen={setOpen} title="Entregar artículo">
      {dataArticles && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={2} >
              <ImageSize img={dataArticles.model.image ? dataArticles.model.image.url : ""} weight={2} />
              </Grid>
              <Grid item xs={10} >
                <Grid item xs={12} >
                  <h3>{dataArticles.model.product.name}</h3>
                </Grid>
                <Grid item xs={12} >
                  <h4>{dataArticles.model.product.sku}</h4>
                </Grid>
                <Grid item xs={12} >
                  <h4>{dataArticles.model.name}</h4>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Divider sx={{ height: '1px', borderColor: "text.primary", backgroundColor: "text.primary", marginTop: "8px" }}></Divider>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} >
              <h4>{`Reservados: ${dataArticles.quantity}`}</h4>
            </Grid>
            <Grid item xs={5} className="">
              <h4>{`Pendientes: ${dataArticles.quantity_pending}`}</h4>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <br/>
              {table && (
                <BasicTable
                  className="marginTable"
                  titles={["Presentación","Barcode", "Disponible", "Cantidad", "Acción"]}
                >
                  {table.map((row, index) => (
                    <RowInfo
                      row={row}
                      key={index}
                      setOpen={setOpen}
                      data={dataArticles}
                      idArticle={article.id}
                      GetSalesDetail={GetSalesDetail}
                      setUpdate={setUpdate}
                    />
                  ))}
                </BasicTable>
              )}
              {!table && (
                <GenericEmptyTable/>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </TableModal>
  );
};

const RowInfo = ({
  row,
  setOpen,
  data,
  idArticle,
  GetSalesDetail,
  setUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [ msg, sendNotification ] = useNotification();

  const formik = useFormik({
    initialValues: initValSalesDelivered(idArticle, row),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchSalesDelivered(row.available, data.quantity_pending)),
    onSubmit: async (formData) => {
      setSalesDelivered(formData);
    },
  });

  const setSalesDelivered = async (formData) => {
    setLoading(true);
    let setOrder = new OrderedDelivered();
    let response = await setOrder.postDataAuth(formData, logout);
    /* console.log(response.data.data); */
    setUpdate((prev) => !prev);
    GetSalesDetail();
    sendNotification(UseerrorOrsuccess(response))
    setLoading(false);
    /*     setOpen(false) */
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.barcode}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.available}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
{/*           <TextField
            fullWidth
            name="first_name"
            values={cantidad}
            onChange={handleCantidad}
            error={error ? true : false}
            helperText={error ? error : ""}
            label="Cantidad"
            margin="dense"
            size="small"
            variant="outlined" 
          /> */}
             <form onSubmit={formik.handleSubmit} >
          <TextField
      /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
                   type="number"
    
                    fullWidth
                    label="Cantidad"
                    size="small"  
                    name="quantity"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.quantity && Boolean(formik.errors.quantity)
                    }
                   
                    helperText={
                        formik.touched.quantity ? formik.errors.quantity : ""
                    }
                    />
                  </form>
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
      <form onSubmit={formik.handleSubmit} >
        <LoadingButton
          loading={loading}
          fullWidth
          type="submit"
          variant="text"
          /* onClick={() => SentDelivered()} */
          sx={{ color: "primary" }}
        >
          Entregar
        </LoadingButton>
        </form>
      </TableCell>
    </TableRow>
  );
};

export default SalesDelivered;
