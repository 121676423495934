import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class CashRegisterApi extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/cash_register';
    super(baseUrl, endPoint)
  }
}

export class CashRegisterPreviewApi extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/cash_register/preview';
    super(baseUrl, endPoint)
  }
}

export class AddCashRegisterApi extends BaseService{
    constructor(){
        const baseUrl = BASE_PATH;
        const endPoint = '/mysite/cash_register';
        super(baseUrl, endPoint)
      }
}
