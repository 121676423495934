import React, {useState} from "react";
import {  IconButton, CardHeader } from "@mui/material";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TableInfo from './tableInfo';
import CurrencyFormat from 'react-currency-format';
import { DateFormat } from '../../../components/DateFormat'
import { STATUSSALES, STATUSSALESCOLOR } from '../../../utils/constants'
import Chip from '@mui/material/Chip';

const SalesTable = (props) => {
    const { data } = props
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const beginDate = data.date_created

    return ( 
        <>
          <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              hover
              onDoubleClick={() => {
                      window.localStorage.setItem("menu", 19);
                      navigate(`detail/${data.id}`);
              }}
          >
            <TableCell align="left" sx={{minWidth: "20px", maxWidth: "15vw" }}> 
            <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle2" component="div"  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                 {data.no_order}
                </Typography>}
                subheader= {DateFormat({beginDate})}
            />
            </TableCell>
            <TableCell  sx={{minWidth: "20px", maxWidth: "15vw" }} align="left">
            <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle1" component="div"  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data.client?.business_name}
                </Typography> }
                subheader={ data.warehouse.name }
            />
            </TableCell>
            <TableCell align="left" sx={{minWidth: "20px", maxWidth: "15vw" }}>
              <Typography variant="subtitle2" component="div"  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Chip label={STATUSSALES[data.status]} color={STATUSSALESCOLOR[data.status]} className="chip-with" variant="outlined"/>
              </Typography> 
            </TableCell>
            <TableCell align="left" sx={{minWidth: "20px", maxWidth: "15vw" }}>
            <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle1" component="div"  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <CurrencyFormat value={data.total} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                </Typography>  }
            />
               
            </TableCell>
            <TableCell align="left" sx={{minWidth: "20px", maxWidth: "15vw" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {setOpen(!open)}}
              >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            
          </TableRow>
          <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <TableInfo data={data}/>
              </Box>
            </Collapse>
          </TableCell>              
          </TableRow>
      </>   
     );
}
 
export default SalesTable;