import React, { useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValAddSupplier, valSchAddSupplier } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { Suppliers } from "../../../api/supplierService";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";


const AddSuppliers = (props) => {
    const {openModal, setOpenModal, UpdateSupplierList} = props;
    const [loading, setLoading] = useState(false);
    const { logout, auth } = useAuth();
    const [msg, sendNotification] = useNotification();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: initValAddSupplier(auth.user.pk),
        validationSchema: Yup.object(valSchAddSupplier()),
        onSubmit: async (formData) => {
          setLoading(true);
          let supplierService = new Suppliers();
          let response = await supplierService.postDataAuth(formData, logout); 
          setLoading(false);
        if (Number(response.status) < 300) {
            console.log('response', response)
            setOpenModal(false);
            sendNotification(UseerrorOrsuccess(response))
            window.localStorage.setItem("menu", 18);
            navigate(`detail/${response.data.id}`);
        } else {
            sendNotification(UseerrorOrsuccess(response))
        } 
          UpdateSupplierList(); 
          setLoading(false);
        },
      });
 

    return ( 
        <>
            <BasicModal open={openModal} setOpen={setOpenModal} title="Agregar proveedor">
            <Grid item xs={12}>
                <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        name="business_name"
                        values={formik.values.business_name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.business_name && Boolean(formik.errors.business_name)
                        }
                        label="Nombre de la empresa"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        autoComplete ='none'
                        helperText={
                            formik.touched.business_name ? formik.errors.business_name : ""
                        }
                        />
                        <CountWords length={formik.values.business_name.length} max="100"/>
                        <TextField
                        inputProps={{ maxLength: 13 }}
                        fullWidth
                        name="rfc"
                        values={formik.values.rfc}
                        onChange={formik.handleChange}

                        error={
                            formik.touched.rfc && Boolean(formik.errors.rfc)
                        }
                        label="RFC"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        autoComplete ='none'
                        helperText={
                            formik.touched.rfc ? formik.errors.rfc : ""
                        }
                        />
                        <CountWords length={formik.values.rfc.length} max="13"/>
                        <TextField
                        inputProps={{ maxLength: 10 }}
                        fullWidth
                        name="phone"
                        values={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        label="Teléfono"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        autoComplete ='none'
                        /* type="number" */
                        helperText={formik.touched.phone ? formik.errors.phone : ""}
                        />
                        <CountWords length={String(formik.values.phone).length} max="10"/>
                        <TextField
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        name="email"
                        values={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        label="Correo Electrónico"
                        margin="dense"
                        variant="outlined"
                        autoComplete ='none'
                        size="small"
                        helperText={
                            formik.touched.email ? formik.errors.email : ""
                        }
                        />
                        <CountWords length={formik.values.email.length} />
                  
                        
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Grid
                        item
                        xs={12}
                        sm={7}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ marginTop: "15px" }}
                        >
                        <LoadingButton
                            loading={loading}
                            
                            fullWidth
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                        >
                            Agregar 
                        </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
                </form>
            </Grid>
            </BasicModal>
        </>
     );
}
 
export default AddSuppliers;