import React, { useEffect, useState } from 'react'
import { Suppliers, SuppliersProduct } from '../../../../api/supplierService';
import HeaderGeneric from '../../../../components/inventario/HeaderGeneric';
import Container from '../../../../layout/gridContainer';
import { useAuth } from "user-context-fran-dev";
import { useParams } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  UseerrorOrsuccess,
  useNotification
} from "../../../../hooks/snackBarResponse"
import { CardHeader, Grid, IconButton, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ProductService } from '../../../../api/productService';
import SearchFilters from '../../../../components/inventario/searchFilters';
import GenericEmptyTable from '../../../../components/GenericEmptyTable';
import GenericTable from "../../../../components/GenericTable"
import ImageSize from '../../../../components/ImageSize';
import { TableCell, TableRow, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircleIcon from "@mui/icons-material/Circle";
import { GenericHeaderTitle, GenericHeaderExpandable } from '../../../../components/GenericHeader';
import BasicTable from '../../../../components/GenericTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const SuppliersArticles = () => {
  const { logout } = useAuth()
  const { id } = useParams()
  const [dataSupplier, setDataSuppllier] = useState()
  const [table, setTable] = useState()
  const [msg, sendNotification] = useNotification();
  const [arrow, setArrow] = useState(false)

  useEffect(() => {
    window.localStorage.setItem("menu", 18);
    GetInfoSupplier()
    GetSupplieraArticles()
    return () => {
      window.localStorage.setItem("menu", 5);
    };
  }, []);

  const GetInfoSupplier = () => {
    (async () => {
      let getSupplier = new Suppliers();
      let response = await getSupplier.getAllByIdAuth(id, logout)
      console.log(response.data.data)
      setDataSuppllier(response.data)
    })();
  }

  const GetSupplieraArticles = () => {
    (async () => {

      let irequestFilter = [];
      irequestFilter.push({ 'key': 'supplier', 'value': id })
      let getSupplier = new SuppliersProduct();
      let response = await getSupplier.filter(irequestFilter, logout); /* getAllByIdAuth(id, logout) */
      console.log(response.data)

      setTable(response.data)
    })();
  }

  const AddProductToSupplier = (product) => {
    (async () => {
      const formData = {
        supplier: id,
        product: product
      }
      let getCategory = new SuppliersProduct();
      let response = await getCategory.postDataAuth(formData, logout);
      if (response) {
        sendNotification(UseerrorOrsuccess(response))
        GetSupplieraArticles()
      }
    })();
  };

  const DeleteArticle = (id) => {
    (async () => {

      let getCategory = new SuppliersProduct();
      let response = await getCategory.deleteAuth(id, logout);
      if (response) {
        sendNotification(UseerrorOrsuccess(response))
        GetSupplieraArticles()
      }
    })();
  };
  return (
    <Container>
      <Grid container>
        {dataSupplier &&
          <Grid item xs={12}>
            <HeaderGeneric
              rightTitle={dataSupplier.business_name}
              name={`${dataSupplier.user.first_name} ${dataSupplier.user.last_name}`}
              supplier={dataSupplier}
            />


          </Grid>}
        <Grid item xs={12} mt={"32px"}>
          <GenericHeaderExpandable title={"Buscar artículos"} arrow={arrow} setArrow={setArrow} >
            {/* <GenericHeaderTitle title={"Buscar artículos"}   /> */}
            <Search AddProductToSupplier={AddProductToSupplier} />
          </GenericHeaderExpandable>
        </Grid>

        <Grid item xs={12} mt={"32px"}>
          <GenericHeaderTitle title={"Artículos"} />
          <ArticleSupplier table={table} deleteArticle={DeleteArticle} />

        </Grid>
      </Grid>
    </Container>
  );
}

const Search = ({ AddProductToSupplier }) => {
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(null);
  const [msg, sendNotification] = useNotification()
  const [filters, setFilters] = useState("")
  const [noFind, setFind] = useState(null);
  const { id } = useParams()
  const { logout } = useAuth()

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object({
      name: Yup.string().required("El campo es requerido"),
    }),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);

      GetArticles(formik.values.name);
      resetForm();

    },
  });
  /*  const AddProductToSupplier = (product) => {
     (async () => {
       const formData ={
         supplier: id,
         product: product
       }
       let getCategory = new SuppliersProduct();
       let response = await getCategory.postDataAuth(formData, logout);
       if(response){
         sendNotification(UseerrorOrsuccess(response))
       }
     })();
   }; */


  const GetArticles = (name) => {
    (async () => {
      let irequestFilter = [];
      if (filters) {
        for (let element in filters) {
          if (filters[element]) {
            irequestFilter.push({ 'key': `${element}`, 'value': `${filters[element]}` })
          }
        }
      }
      irequestFilter.push({ key: "pattern", value: `${name}` });
      irequestFilter.push({ 'key': 'is_active', 'value': `true` })
      let getRecipe = new ProductService();
      let response = await getRecipe.filter(irequestFilter, logout);
      setTable(response.data);
      console.log(response);
      if (response) {
        //setOpenModal(false);
      } else {
        sendNotification(UseerrorOrsuccess(response));
      }
      setFind(name)
      setLoading(false);
    })();
  };
  return (
    <Grid item xs={12} mt={"16px"}>
      {/*         {article && (
          <ModalPresentation
            open={open}
            setOpen={setOpen}
            article={article}
            edit={false}
            GetRecipeGroup={GetRecipeGroup}
            data={data}
          />
        )} */}

      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            alignItems="center"
          >


            <Grid item xs={9} sm={4} mr="16px" >
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  formik.touched.name && Boolean(formik.errors.name)
                }
                label="Buscar por nombre"
                /* margin="normal" */
                size="small"
                variant="outlined"
                /* className="space-text-field" */
                helperText={
                  formik.touched.name ? formik.errors.name : ""
                }
              />
            </Grid>
            <Grid item xs={3} sm={2} >
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
              >
                Buscar
              </LoadingButton>
            </Grid>
            <SearchFilters filters={filters} setFilters={setFilters} />

          </Grid>
        </form>
      </Grid>

      {table && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          mt={"16px"}
        >
          <Grid item xs={12}>
            <GenericTable
              titles={[
                "Imagen",
                "SKU",
                "Nombre",
                "Estatus",
                "Categoría",
                "Acción",
              ]}
            >
              {table.map((row, index) => (
                <RowRaw
                  row={row}
                  key={index}
                  doSomething={AddProductToSupplier}
                />
              ))}
            </GenericTable>
          </Grid>
        </Grid>
      )}
      {table != null && !table &&
        <Grid item sx={12}>
          <GenericEmptyTable msg={`No se encontraron resultados para  "${noFind}"`} />
        </Grid>

      }
    </Grid>
  );
}

const RowRaw = ({ row, doSomething }) => {
  const navigate = useNavigate();
  /* console.log(row) */

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
      >
        <TableCell
          align="left"
          sx={{ maxWidth: "90px", minWidth: "90px", maxHeight: "90px" }}
        >
          <ImageSize
            img={row.main_model?.image ? row.main_model.image.url : ""}
            weight={1}
          />
          {/*               <img
                  src={row.main_model?.image? row.main_model.image.url: ""}
                  style={{ width: "80%", height: "auto" }}
                ></img> */}
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography variant="subtitle2" component="div">
            {row.sku}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "150px" }}>
          <Typography variant="subtitle2" component="div">
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography variant="subtitle2" component="div">
            {row.is_active ? (
              <CircleIcon sx={{ color: "green", fontSize: 15 }} />
            ) : (
              <CircleIcon sx={{ color: "red", fontSize: 15 }} />
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "150px" }}>
          <Typography variant="subtitle2" component="div">
            {row.category.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            sx={{ cursor: "pointer" }}
            underline="none"
            onClick={() => {

              doSomething(row.id);
            }}
          >
            <Typography variant="subtitle1" component="div">
              Seleccionar
            </Typography>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

const ArticleSupplier = ({ table, deleteArticle }) => {
  const { logout } = useAuth()

  /*   const [table, seTable]= useState() */

  /*   useEffect(()=>{
      GetInfoSupplier()
    },[]) */





  return (
    <>
      <Grid item xs={12} mt={"16px"}>
        {table && (
          <>
            <BasicTable
              titles={["Imagen", "Nombre / SKU", "Estatus", "Categoría", "Acción"]}
            >
              {table.map((data, index) => (
                <Row
                  key={index}
                  data={data}
                  delet={deleteArticle}
                /* img={data.img} */
                />
              ))}
            </BasicTable>

          </>

        )}
        {!table &&

          <GenericEmptyTable />
        }
      </Grid>

    </>
  );
}

const Row = ({ data, delet }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

    <>
      <TableRow

        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
        onDoubleClick={() => {

        }}>

        <TableCell align="left" sx={{ maxWidth: '150px' }}>
          <ImageSize img={data.product?.main_model?.image ? data.product.main_model.image.url : ""} weight={1} />
        </TableCell>
        <TableCell sx={{ maxWidth: '250px' }} align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.product.name}
              </Typography>}
            subheader={
              data?.product.sku
            }

          />
        </TableCell>
        <TableCell align="left">
          {(data?.product?.is_active) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
            <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data?.product.category.name}
              </Typography>}

          />
        </TableCell>
        {/* <TableCell align="left"> $17,000.00 </TableCell> */}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => { handleClick(e) }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => { delet(data.id); handleClose(); }}>Eliminar</MenuItem>

          </Menu>

        </TableCell>

      </TableRow>

    </>


  );
}




export default SuppliersArticles;