import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { InventoryServiceInventory } from "../../../api/inventoryService";
import InventoryTable from "./inventoryTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericEmptyTable from "../../../components/GenericEmptyTable";
import { GenericSearchNew } from "../../../components/GenericSearch/GenericSearch";
import { GenericSelectTableNew } from "../../../components/GenericSelectTable";
import WarehouseService from "../../../api/warehouseService";
import { useTableFilters } from "../../../hooks/searchParams";
import PageParam from "../../../components/PageParam";


const InventoryTab = () => {

  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);

  const [paramssss, setParams, handleSearch, handleSearchEmpty, UdateTable] = useTableFilters(InventoryServiceInventory, setTable, setLoading, null, "tab", 500)

  return (
    <Grid container>

      <Grid item xs={12} mb={"16px"}>
        <Grid container /* spacing={2} */ columnSpacing="16px" alignItems="end">
          <Grid item xs={12} sm={6} sx={{ height: "100%" }}>

            <GenericSearchNew
              params={paramssss}
              handleChanges={handleSearchEmpty}
              name="pattern"
              label={"Buscar por nombre"}

            ></GenericSearchNew>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container justifyContent="flex-end"  >
              <GenericSelectTableNew
                text="Seleccionar almacén"
                listService={WarehouseService}
                params={paramssss} setChangesList={handleSearchEmpty} name="wh"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {table && !loading && (
        <>
          <Grid item xs={12} mt={2}>
            <InventoryTable table={table.data} params={paramssss} />
          </Grid>
          <Grid item xs={12} mt={2}>
            <PageParam totalpages={table.pagination} params={paramssss} handleChange={handleSearch} />
          </Grid>
        </>
      )}
      {loading && (
        <Grid container justifyContent="center" mt={5}>
          <CircularProgress />
        </Grid>
      )}

      {!table && !loading && <GenericEmptyTable />}
    </Grid>
  );
};



export default InventoryTab;
