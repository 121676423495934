/* Modal que se abre al dar click en el boton de "Vista previa" y enseña como se ve el componente creado actualmente*/
import React, { useEffect, useState } from "react";
import {
    Grid,
    ThemeProvider
} from "@mui/material";
import { BottonModalDiag } from "../GenericsModals";
import theme from "./themeCreator";


const PreviewModal = ({ open, setOpen, SECA, data }) => {

    return (
        <BottonModalDiag setOpen={setOpen} open={open} title={{ text: "Vista previa" }} size="90%">
            {data && <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                    {SECA.sections[data.type].component(data)}
                </ThemeProvider>
            </Grid>}
        </BottonModalDiag>
    );
};

export default PreviewModal;