import React  from "react";
import Container from "../../layout/gridContainer";
import {
  Grid,
} from "@mui/material";
import Discounts from "./discounts/discounts";
import DiscountsUsers from "./discounts/discountsUsers";

const psAdminDiscounts = () => {



  return (
    <Container>
      <Grid container>
        <Discounts/>
        <DiscountsUsers/>
      </Grid>
    </Container>
  );
};



export default psAdminDiscounts;
