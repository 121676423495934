import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import GenericTable from "../../components/GenericTable";
import TableCell from "@mui/material/TableCell";
import { Avatar, Grid } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import CardHeader from "@mui/material/CardHeader";
import { Iniciales } from "../../utils/functions";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import Container from "../../layout/gridContainer";
import { useAuth } from "user-context-fran-dev";
import GetUserList from "../../api/userList";
import AddUsers from "./users/addUsers";
import UserRemove from "../../api/userRemove";
import { PaginationGeneric } from "react-generic-pagination";
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import GenericEmptyTable from "../../components/GenericEmptyTable";
import { ConfirmAction } from "../../components/ConfirmAction/ConfirmAction";
import { DateFormat } from "../../components/DateFormat";



export default function AdminUsers() {


  const navigate = useNavigate();
  const { logout } = useAuth();

  const [table, setTable] = useState(null);
  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();

  const deleteUser = new UserRemove();


  //--------------------------------------
  const [open, setOpen] = useState();
  const [confirmationData, setConfirmationData] = useState(null);

  const handleOpen = (data) => {
    setConfirmationData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //---------------------------------------


  useEffect(() => {
    navigate(`page=${page}`);
    UpdateUserList();
  }, [page]);

  const UpdateUserList = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getUsers = new GetUserList();
      let response = await getUsers.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data);
      /* console.log(response) */
    })();
  };

  /*   const UpdateUserList = () => {
      (async () => {
        let getUsers = new GetUserList();
        let response = await getUsers.getAllAuth(logout);
        setTable(response);
      })();
    }; */

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };

  const handleDeleteUser = async (id, formData) => {
    let response = await deleteUser.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    UpdateUserList();
  }

  /*   const handleChangePermits = (event) => {
      setPermits(event.target.value);
    }; */

  return (
    <Container>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        className="title-card"
      >
        <strong>Usuarios</strong>
      </Typography>

      <AddUsers UpdateUserList={UpdateUserList} />

      {table && (
        <>
          <GenericTable titles={["Fecha de registro", "Nombre", "Correo electrónico", "Verificado"]}>
            {table.data.map((data) => (
              <TableRow
                key={data.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onDoubleClick={() => {

                  window.localStorage.setItem("menu", 8);
                  navigate(`detail/${data.id}`);
                }}
              >
                <TableCell align="left" >
                  <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <DateFormat beginDate={data?.date_joined} />
                  </Typography>
                </TableCell>

                <TableCell align="left" >
                  <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', fontWeight: 'bold', textOverflow: 'ellipsis' }}>
                    {data?.first_name} {data?.last_name}                  </Typography>
                </TableCell>

                <TableCell sx={{ maxWidth: '350px' }} align="left">
                  <Typography variant="subtitle2" component="div" sx={{ minWidth: "50px", maxWidth: "50vw", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data?.email}
                  </Typography>
                </TableCell>

                <TableCell sx={{ maxWidth: '350px' }} align="left">
                  <Typography variant="subtitle2" component="div" sx={{ minWidth: "50px", maxWidth: "50vw", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data?.email_confirmation === 0 ? 'No' : 'Si'}
                  </Typography>
                </TableCell>



                {/*<TableCell align="left" >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      handleOpen(data);
                    }}
                  >
                    Remover
                  </Link>
                  </TableCell>*/}
              </TableRow>
            ))}
          </GenericTable>
          <Grid item xs={12} mt={2} mb={2}>
            <PaginationGeneric
              className="paginationStyle"
              page={page}
              total={totalpages}
              pageSetter={pageSetter}
            ></PaginationGeneric>
          </Grid>

        </>
      )}
      {!table &&
        <GenericEmptyTable />
      }

      {confirmationData && (
        <ConfirmAction
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          title={`Remover accesos a usuario`}
          content={`¿Estás seguro que deseas remover accesos al usuario ${confirmationData.first_name} ${confirmationData.last_name}?`}
          disagreeText="Cancelar"
          agreeText="Remover"
          service={deleteUser}
          id={confirmationData.id}
          getCurrent={UpdateUserList}
          action={'edit'}
        />
      )}


      {console.log("confirmationData:", confirmationData)}
    </Container>
  );
}
