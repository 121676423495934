import React, { useEffect, useState } from "react";
import Container from "../../../../layout/gridContainer";
import { ProductionOrder } from "../../../../api/productionOrder";
import { useAuth } from "user-context-fran-dev";
import { useParams } from "react-router-dom";
import HeaderGeneric from "../../../../components/inventario/HeaderGeneric";
import { Grid, Paper, Typography, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { SearchRecipe } from "./searchRecipe";
import {Collapse, MenuItem,} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SelectRecipe from "./selectRecipe";
import BasicTable from "../../../../components/GenericTable";
import { UseerrorOrsuccess, useNotification } from '../../../../hooks/snackBarResponse'
import { STATUSPRODUCTION, STATUSPRODUCTIONCOLOR } from  "../../../../utils/constants";
import ImageSize from "../../../../components/ImageSize";
import Alert from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BasicModal from "../../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValProductionOrderEdit, valSchProductionOrderEdit } from "../../../../utils/formHelper";
import LoadingButton from "@mui/lab/LoadingButton";
import { ProductionOrderRecipe } from "../../../../api/productionOrder";
import { GenericHeaderExpandable } from "../../../../components/GenericHeader";

const TransformationDetails = ({setReload}) => {
  const { logout, auth } = useAuth();
  const { id } = useParams();
  const [data, setData] = useState();
  const [open, setOpen] = useState();
  const [openModal, setOpenModal]= useState(false);
  const [recipe, setRecipe] = useState();
  const [msg, sendNotification] = useNotification();
  const [check, setCheck]= useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit]= useState(false);
  const [editData, setEditData]= useState("");
  const openM = Boolean(anchorEl);
  const [loading, setLoading]= useState(false)


  const handleClick = (event) => { 
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    GetOrder();
    return()=>{ setReload(prev => !prev); 
      window.localStorage.setItem("menu", 5)};
       
  }, []);
  const GetOrder = () => {
    (async () => {
      let getOrder = new ProductionOrder();
      let response = await getOrder.getAllByIdAuth(id, logout);
      setData(response);
      setLoading(false)
    })();
  };
  const Cancel = () => {
    if(data.data.status !=5){
    (async () => {
      setLoading(true)
      let setCancel = new ProductionOrder();
      let response = await setCancel.editDataAuth(id, {'status': '5'}, logout);
      GetOrder()
      sendNotification(UseerrorOrsuccess(response))
      
    })();
  }
  };
  const End = () => {
    (async () => {
      setLoading(true)
      let setEnd = new ProductionOrder();
      let response = await setEnd.editDataAuth(id, {'status': '2'}, logout);
      /* console.log(response.response.data.error) */
      GetOrder()
      if(response.response?.data.error){
        console.log(response.response.data.error)
        setCheck(response.response.data.error[0]);
        /* console.log("soy un error") */
      }else{
        setCheck("")
      }

      
      
    })();
  };
  const Edit = (row) => {
    console.log(row)
    setEditData(row); 
    setOpenEdit(true); 
  }
  const Delete = (id) => {
    (async () => {
      console.log("dfj")
      let addProduction = new ProductionOrderRecipe();
      let response = await addProduction.deleteAuth(id, logout); 
      GetOrder()
      sendNotification(UseerrorOrsuccess(response))
      })();
  }

   
  return (
    <Container>
      {data && (
        <Grid container> 
        {editData && <EditRecipe data={editData} open={openEdit} setOpen={setOpenEdit} update={GetOrder}/>}
          {recipe && <SelectRecipe updata={GetOrder} open={openModal} setOpen={setOpenModal} data={recipe} order={data}/>}
          <Grid item xs={12}> 
            <HeaderGeneric
              leftTitle={{status : STATUSPRODUCTION[data.data.status], color : STATUSPRODUCTIONCOLOR[data.data.status], with: "none"}} //titulo del header izquierdo
              cancelButton={{handleClick: Cancel, loading: loading, enable: (data.data.status == 2? true : false) }} //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
              titleButton={{tittle : 'Finalizar', enable : (data.data.recipes != null && data.data.recipes != "" ) && data.data.status == 1 ? true : false }} // el boton a lado derecho de cancelar este solo es para el titulo
              handleButton={{handleClick: End, loading: loading,  }} //la funcion del boton
              rightTitle="Orden de producción"
              name={`${data.data.user.first_name} ${data.data.user.last_name}`}
              noOrder={data.data}
              date={data.data.date_created}
              general={data.data.warehouse.name}
            />
          </Grid> 
          <Grid item xs={12} mt={"32px"}>
          {check &&
            <Alert sx={{bgcolor: "error.alert", color: "text.primary"}}   severity="error">{`No se cuenta con el inventario disponible en el ${data.data.warehouse.name} – revisa la materia prima faltante por receta!`}</Alert>
          }
          </Grid> 

          <Grid item xs={12} mt={"16px"} mb="16px">
            {data.data.status == 1  && <GenericHeaderExpandable title={"Buscar receta"} arrow={open} setArrow={setOpen} >
              <SearchRecipe setOpen={setOpenModal} setRecipe={setRecipe} />
            </GenericHeaderExpandable>}
          
          </Grid>

          <Grid item xs={12} >
            {data.data.recipes && data.data.recipes.map((row, index)=>(
              <Paper key={index} sx={{marginBottom: "16px"}}>
                <Paper sx={{ padding: "8px 16px",  backgroundColor:'background.header' }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8} sx={{m: 0, p:0}}>
                        <Grid container alignItems={"center"}>
                          <ImageSize img={row.recipe.model.image?row.recipe.model.image.url: ""} weight={2}/>            
                            <Grid item ml={"16px"}>
                              <h4><strong>{row.recipe.model.product.name}</strong></h4>
                              <p>{`${row.recipe.model.name} ${row.recipe.presentation.name}`}</p>
                            </Grid>
                          </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container justifyContent="flex-end" alignItems="center">
                               <h2> {parseInt(row.quantity)}</h2>
                        
                       {data.data.status == 1 &&
                        <Typography variant="subtitle1" component="div" pl={"16px"}>
                          <IconButton aria-label="expand row" size="small" onClick={handleClick}>
                            <MoreVertIcon sx={{ color: "text.secondary" }} />
                          </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openM}
                                onClose={handleClose}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button', 
                                }}
                              >
                            <MenuItem onClick={()=>{ handleClose(); Edit(row); }}>Editar</MenuItem>
                            <MenuItem onClick={()=> {handleClose();  Delete(row.id); }}>Eliminar</MenuItem>

                          </Menu>
                        </Typography>}
                        </Grid>
                        

                      </Grid>
                    </Grid>
                </Paper>
                <Grid container mt={1} p={2} >
              <Grid item xs={6}>   
                  {`Materia prima para (${parseInt(row.recipe.quantity)} x ${parseInt(row.quantity)}) unidades`} 
              </Grid>
     
              <Grid item xs={12}>
                { (row.recipe.raw_materials || row.productions.raw_material) && 
                <BasicTable
                  titles={[
                    "#",
                    "Cantidad",
                    "Nombre",
                    "Modelo",
                    "Presentación",
                  
                  ]}
                >
                  {
                  data.data.status == 1 || data.data.status == 5? (

                   
                 row.recipe.raw_materials.map((data, index)=>(
                        <Row key={index} row={data} index={index} check={check} orderRecipe={row} /> ))
                  )
                  :

                  row?.productions && row?.productions.map((data, index)=>(
                   <>{ index != 0 && <Row key={index} row={data} index={index-1} check={check} orderRecipe={row} />} </> 
                  ))
   
                  }
                  
                </BasicTable>
                }
       
       

              </Grid>

            </Grid>
              </Paper>
            ))}
     

          </Grid>

        </Grid>
      )}
    </Container>
  );
};



const Row = ({row, index, check, orderRecipe}) => {
  const [missing, setMissing] = useState("")
  const [checkColor, setCheckColor] = useState("none")
 

  useEffect(() => {
    
    if(check){
      
      
      const order =check.filter((x)=> x.prod_order_recipe == orderRecipe.id);
      const IsMissing = order.filter((x)=> x.raw_material == row.id)
      setMissing(IsMissing);
     
      if(IsMissing == ""){
        setCheckColor( "#29c116");
      }else{
        setCheckColor(  "#F70000");
    
      }
    }

  }, [check]);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {index+1 +'-'}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div" sx={{color: checkColor}}>
          {row.quantity } {missing!=""?  `(${missing[0].missing})`: "" }
         {/* {row.quantity}{  orderRecipe.quantity}  */}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {row.model.product.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "150px" }}>
        <Typography variant="subtitle1" component="div"> 
          {row.model.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {row.presentation.name}
        </Typography>
      </TableCell>

 
    </TableRow>
  );
};


const EditRecipe = ({data, open, setOpen, update}) => {
  const [loading, setLoading] = useState(false);
  const { logout, auth } = useAuth();
  
  useEffect(()=>{
    formik.resetForm();
  },[open])

  const  setRecipe= (formData) => {
    (async () => {
      console.log(data)
      let addProduction = new ProductionOrderRecipe();
      let response = await addProduction.editDataAuth(data.id, formData, logout); 
      update()
      })();
  }
   


  const formik = useFormik({
      initialValues: initValProductionOrderEdit(data.quantity), 
      enableReinitialize: true,
      validationSchema: Yup.object(valSchProductionOrderEdit()),
      onSubmit: async (formData, {resetForm}) => {
        
        /* console.log(formData) */
        setLoading(true);
        setRecipe(formData)
        setOpen(false)
        resetForm()

        setLoading(false); 
      },
    });

  return ( 
    <>
              <BasicModal open={open} setOpen={setOpen} title={"Editar receta"}>
            <Grid item xs={12}>
                <Grid container>
                  <ImageSize img={data.recipe.model?.image ? data.recipe.model.image.url : ""} weight={2}/>
                  <Grid item ml={"16px"}>
                  <Typography variant="h6" >
                    {data.recipe.model.product.name}
                  </Typography>
                  <Typography variant="h6" >
                    {`${data.recipe.model.name}, ${data.recipe.presentation.name}`}
                  </Typography>
{/*                    <h3>{data.recipe.model.product.name}</h3>
                      <h3>{`${data.recipe.model.name}, ${data.recipe.presentation.name}`}</h3> */}
                  </Grid>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Grid item xs={12} mt={"32px"} >
                <TextField
                      fullWidth
                      variant="outlined"
                      autoComplete ='none'
                      size="small"
                      id="quantity"
                      label="Cantidad"
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      error={
                          formik.touched.quantity && Boolean(formik.errors.quantity)
                      }
                      helperText={
                          formik.touched.quantity ? formik.errors.quantity : ""
                      } /> 
              </Grid>
              <Grid item xs={12} mt="16px">
                  <Grid container justifyContent="flex-end" >
                  <Grid item xs={12} sm={6} md={3}>
                          <LoadingButton
                          loading={loading}
                          fullWidth
                          type="submit"
                          variant="contained"
                          
                      >
                          Editar
                      </LoadingButton>
                  </Grid>
              </Grid>
              </Grid>
            </form>
        </BasicModal>
  
    </>
   );
}
 

export default TransformationDetails;
