import React, { useState, useEffect } from "react";
import Container from "../../../layout/gridContainer";
import { ShippingCost } from "./shippingCost";
import { SyncInventory } from "./syncInventory";
import { Rules } from "./rules";
import { StoreSettings } from "../../../api/store/storeSettings";
import { useAuth } from "user-context-fran-dev";

const ID_DEFAULT = 1;

const OperationRules = () => {
  const { logout, auth } = useAuth();
  const [data, setData] = useState()

  const update = () => {
    (async () => {
      
      const settings = new StoreSettings();
      const response = await settings.getAllByIdAuth(ID_DEFAULT, logout);
      setData(response.data)
    })();
  };
  const sentSettings = (setLoading, FormData) => {
    (async () => {
  
      const settings = new StoreSettings();
      const response = await settings.editDataAuth(ID_DEFAULT, FormData, logout);
      update()
      setLoading(false)
    })();
  };
  

  useEffect(()=>{
    update()
  },[])

  return (
    <Container>
      {data && 
      <>
        <Rules data={data}  sentSettings={sentSettings}/>
        <ShippingCost data={data}  sentSettings={sentSettings}/>
        <SyncInventory />
      </>
    }
    </Container>
  );
};

export default OperationRules;
