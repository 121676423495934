import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export default class Presentation extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'catalog/presentation';
      super(baseUrl, endPoint)
    }
  }
  