import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';



export default function BasicTable(props) {
  const { titles, children } = props;
  const mobileS = useMediaQuery('(max-width:600px)');
  const mobileL = useMediaQuery('(max-width:600px)');

  return (
    <TableContainer component={Paper} sx={{
      maxHeight: 650,
      maxWidth: mobileL ? 390 : (mobileS ? 342 : '100%'), // Si mobileL es verdadero, maxWidth es 390; si no, si mobileS es verdadero, maxWidth es 342; de lo contrario, maxWidth es '100%'.
      overflowY: 'scroll'
    }}>
      <Table size="small" sx={{ minWidth: 650 }} stickyHeader aria-label="simple table" >
        <TableHead>
          <TableRow>
            {titles.map((title) => (
              <TableCell key={title} align="left"><strong>{title}</strong></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>

  );
}


export function CustomTable(props) {
  const { titles, children } = props;

  return (
    <TableContainer component={Paper} >
      <Table /* sx={{ minWidth: 250 }} aria-label="simple table" size="small" */ size="small">
        <TableHead  >
          <TableRow >
            {titles.map((data, index) => (
              <TableCell key={index} align={data?.align ? data.align : "left"}><strong>{data.title}</strong></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const TableWhite = styled(Table)(({ theme }) => ({
  '& .MuiTableHead-root': {
    backgroundColor: "#fff",
    color: "#000",
    '& .MuiTableCell-root': {
      color: "#000",
    },



  },
  '& .MuiTableBody-root': {
    backgroundColor: "#fff",
    color: "#000",
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        backgroundColor: "#fff",
        color: "#000",
      },
    },

  },

}));


export function WhiteTable(props) {
  const { titles, children } = props;

  return (
    <TableContainer component={Paper} >
      <TableWhite  /* sx={{ minWidth: 250 }} aria-label="simple table" size="small" */ size="small" >
        <TableHead  >
          <TableRow >
            {titles.map((title) => (
              <TableCell key={title} align="left"><strong>{title}</strong></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody /*  sx={{background: "#fff", color: "#000"}} */>
          {children}
        </TableBody>
      </TableWhite>
    </TableContainer>
  );
}