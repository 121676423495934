import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export default class BranchManages extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'site/branch';
      super(baseUrl, endPoint)
    }
}
