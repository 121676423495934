import React, { useEffect, useState } from 'react'
import { Grid  } from "@mui/material";
import InventoryGenericDetailsView from '../../../../components/inventario/InventoryGenericDetailsView'
import { useParams } from 'react-router-dom'
import SalesService  from '../../../../api/salesService'
import { useAuth } from "user-context-fran-dev";
import ArticlesWithTotal from '../../../../components/inventario/tables/ArticlesWithTotal'
import HeaderGeneric from '../../../../components/inventario/HeaderGeneric'
import { STATUSSALES, STATUSSALESCOLOR } from '../../../../utils/constants'
import { UseerrorOrsuccess, useNotification } from '../../../../hooks/snackBarResponse'

import { InventoryServiceSalesOrder } from '../../../../api/inventoryService';

 
const SalesDetails = ({ setReload }) => {
  
  const { id } = useParams()
  const { logout } = useAuth()
  const [ data , setData ] = useState()
  const [ reload, setReloadPage ] = useState(false)
  const [ msg, sendNotification] = useNotification();
  const getSale = new SalesService();
  const [loading, setLoading]= useState(false)

  const bottomRef = React.useRef();

/*   const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
 
  const scrollBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }
 */


  const reloadDataOforder = () => {
    (async () => {
      let response = await getSale.getAllByIdAuth(id, logout)
      setData(response.data)
      setReloadPage(true)
      setLoading(false)
    })();
  }

  useEffect(() => {
    (async () => {
      let response = await getSale.getAllByIdAuth(id, logout)
      //console.log(response.data)
      setData(response.data)
      
    })();
    return () => {
      setReload((prev) => !prev);
    };
  }, [reload]);

  const cancel = () => {
    (async () => {
      setLoading(true)
      let response = await getSale.editDataAuth(id, {'status': '4'}, logout) 
      sendNotification(UseerrorOrsuccess(response))
      reloadDataOforder()
    })();
  };

  const finishOrder = () => {
    (async () => {
      setLoading(true)
      let response = await getSale.editDataAuth(id, {'status': '2'},logout)
      sendNotification(UseerrorOrsuccess(response))
      reloadDataOforder()
    })();
  };

 


  return (
  <>
  { data &&
 
    <Grid
    container
    direction="row"
    justifyContent="space-around"
    alignItems="center"
    >
        <Grid item xs={11} className="details-view-header">
         <HeaderGeneric
          leftTitle={{status:STATUSSALES[data.status], color: STATUSSALESCOLOR[data.status]}}  //titulo del header izquierdo
          cancelButton={  {handleClick: cancel, loading: loading,  } } //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
          titleButton={{tittle : 'Continuar', enable : data.articles ? true : false }} // el boton a lado derecho de cancelar este solo es para el titulo 
          handleButton={{handleClick: finishOrder, loading: loading}} //la funcion del boton 
          print={{to:`/order?id=${data.id}&order_no=${data.no_order}&type=sales`, disable: (data.status == "1"? "none": "")}}
          rightTitle='Orden de Venta'
          name={`${data.user.first_name} ${data.user.last_name}`}
          noOrder={data}
          date={data.date_created}
          general={data.warehouse.name}
         ></HeaderGeneric>

      {/*       <Grid item xs={12}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/order?id=${data.id}&order_no=${data.no_order}&type=sales`}
                target={"_blank"}
              >
                <Button variant="outlined" size="small" disableElevation>
                  <PictureAsPdfIcon sx={{ mr: "10px" }} />
                  ver PDF
                </Button>
              </Link>
            </Grid> */}
        
        </Grid>
        <Grid item xs={11} className="details-view">
          <InventoryGenericDetailsView name="Cliente" pay="Condiciones de pago" reloadDataOforder={reloadDataOforder} data={data} type="sales" >
            <ArticlesWithTotal data={data} service={InventoryServiceSalesOrder} updateInfo={reloadDataOforder} type="sales"></ArticlesWithTotal>
          </InventoryGenericDetailsView>
        </Grid>
      
    </Grid>

  }
  </>
  )

}


export default SalesDetails