import React, { useState } from 'react'
import { Typography } from "@mui/material";
import GenericTable from '../../GenericTable'
import { CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import GenericEmptyTable from '../../GenericEmptyTable'
import DropMenu from "../../../components/dropMenu"
import EditTransferArticle from '../editModals/editTransferArticle';
const ArticlesListTrasnfer = (props) => {

  const { data, setOpenModal, setModalInfo, ...extraProp } = props

  /* const [open, setOpen] = useState(false); */
  const [articleToEdit, /* setIdToEdit */] = useState('');
  const [openEdit, setOpenEdit] = useState(false)
  const [articleEdit, setArticleEdit] = useState("")

  console.log('transfer', data.articles)
  const handleEdit = (row) => {
    setArticleEdit(row)
    setOpenEdit(true)
  }

  return (
    <>
      {openEdit && articleEdit &&
        <EditTransferArticle
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          dataProduct={articleToEdit}
          orderData={data}
          articleData={articleEdit}
          type={extraProp.type}
          reloadDataOforder={extraProp.updateInfo}
        >
        </EditTransferArticle>
      }
      {data.articles && data.articles ?
        <div className="articles-table">
          <GenericTable titles={["Nombre / #parte ", "Modelo / Presentación", "Barcode", "Cantidad", data.status == 1 ? 'Mas' : '']} >
            {data.articles.map((row, index) => (

              /* row.quantity_pending != 0 && */

              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover

              >
                <TableCell align="left" sx={{ maxWidth: '2000px' }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row?.available.model.product.name}
                      </Typography>}
                    subheader={row?.available.model.product.sku}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: '2000px' }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.available?.model.name}
                      </Typography>}
                    subheader={row.available?.presentation.name}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: '2000px' }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.available?.barcode}
                      </Typography>}
                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: '2000px' }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row?.quantity}
                      </Typography>}

                  />
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: '2000px' }}>
                  <Typography variant="subtitle2" component="div" item sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data.status == 1 &&
                      <DropMenu row={row} service={extraProp.service} id={row.id} update={extraProp.updateInfo} setOpenEdit={setOpenEdit} handleEdit={handleEdit} setIdToEdit={setArticleEdit}></DropMenu>
                    }
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </GenericTable>

        </div> : <GenericEmptyTable msg={"No hay artículos agregados"} />}
    </>
  )
}

export default ArticlesListTrasnfer;