import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class Suppliers extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/supplier'; 
      super(baseUrl, endPoint)
    }
  }

export class SuppliersProduct extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/supplier_product';
    super(baseUrl, endPoint)
  }
}
  