import React, {useState} from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddContact from './addContact'
import DropMenu from '../../components/dropMenu'
import CurrencyFormat from 'react-currency-format';
import ContactService from "../../api/contactService";
import EditContact from "./editModals/editContact";

const Phonebook = ({data, UpdateInfo, target}) => {
  const [open, setOpen]= useState(false);
  const [openEdit, setOpenEdit] = useState(false)
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false); 
  const [idToEdit, setIdToEdit] = useState('');


    return ( 

      <Paper className="paper">
        {openEdit &&
          <EditContact data={idToEdit} openEdit={openEdit} setOpenEdit={setOpenEdit} UpdateInfo={UpdateInfo} business={true} target={target}/>
        }
          <Typography variant="h6" gutterBottom component="div" className="title-card">
                <strong>Contactos</strong>
          </Typography>
              
          <Link component="button" variant="subtitle2" 
          onClick={()=>{ setOpen(true) }} underline="none" className="link">
            Agregar
          </Link>
          <AddContact data={data} open={open} setOpen={setOpen} UpdateInfo={UpdateInfo} business={true} target={target}/>
          <Grid item xs>
            <List dense={dense}>
            {data.contacts && data.contacts.map((contact, index)=>(

              <ListItem
                  key={index}
                  secondaryAction={
                    <DropMenu service={ContactService} row={contact} id={contact.id} update={UpdateInfo} openEdit={openEdit} setOpenEdit={setOpenEdit} setIdToEdit={setIdToEdit}></DropMenu>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ContactsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText sx={{width: 70, marginRight: 3}}
                  primary={contact.name}
                  />
                  <ListItemText sx={{width: 150 }}

                    primary={
                      <Typography
                          sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'horizontal',
                      }}
                      >
                        {contact.email}
                      </Typography>}
                  />
                  <ListItemText sx={{width: 10, marginLeft: 3}}
                    primary={<CurrencyFormat value={contact.phone} displayType={'text'} format="(##) #### ####" />}
                  />
                </ListItem>

              ))}
              
            </List>
        </Grid>
      </Paper>

     );
}
 
export default Phonebook;