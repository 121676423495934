import React, { useState, useEffect } from "react";
import {
  Box,
  CardHeader,
  RadioGroup,
  Grid,
  Radio,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValStoreRulesDelivery,
  valSchStoreRulesDelivery,
} from "../../../utils/formHelper";

export const ShippingCost = ({ data, sentSettings }) => {
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: initValStoreRulesDelivery(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchStoreRulesDelivery()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);
      sentSettings(setLoading, formData)

    },
  });


  return (
    <Paper sx={{ padding: "16px", marginBottom: "16px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" gutterBottom component="div">
          <strong>Costo de envió</strong>
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <CardHeader
              sx={{ padding: "0", margin: "0" }}
              title={
                <Typography variant="body1" component="div">
                  ¿Quién paga el costo de envío?
                </Typography>
              }
              subheader={"Ofrece envíos gratis para incrementar ventas."}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
            <RadioGroup
              sx={{ flexDirection: "row" }}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="is_shipping_paid_by_client"
              value={formik.values.is_shipping_paid_by_client}
            onChange={formik.handleChange}
            >
              <FormControlLabel
                control={<Radio value={true} />}
                label="Cliente"
              />
              <FormControlLabel
                control={<Radio value={false} />}
                label="Tienda"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <FormGroup>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
            name="is_free_shipping_activated"
              checked={formik.values.is_free_shipping_activated}
              onChange={formik.handleChange}
              
              inputProps={{ "aria-label": "controlled" }} 
            />
            <CardHeader
              sx={{ padding: "0", margin: "0" }}
              title={
                <Typography variant="body1" component="div">
                  Si el ticket es mayor a la cantidad definida a continuación el
                  envió es gratis para el cliente.
                </Typography>
              }
              subheader={
                "Esta opción ayuda a incrementar el valor del ticket promedio."
              }
            />
          </Box>
        </FormGroup>
        <Grid
          container
          justifyContent="start"
          textAlign="start"
          sx={{ alignItems: "center", gap: "8px" }}
          marginTop="30px"
        >
          <Typography variant="body1" component="div">
            Envíos gratis cuando sea mayor o igual a:
          </Typography>
          <TextField
            name="free_shipping_threshold"
            value={formik.values.free_shipping_threshold}
            onChange={formik.handleChange}
            error={
              formik.touched.free_shipping_threshold && Boolean(formik.errors.free_shipping_threshold)
            }
            label="Ticket total"
            size="small"
            type="number"
            variant="outlined"
            autoComplete="none"
            helperText={
              formik.touched.free_shipping_threshold ? formik.errors.free_shipping_threshold : ""
            }
          />
        </Grid>
        <Grid
          container
          justifyContent="start"
          textAlign="start"
          sx={{ alignItems: "center", gap: "8px" }}
          marginTop="30px"
        >
          <Typography variant="body1" component="div">
            Costo de envío cuando lo paga el cliente:
          </Typography>
          <TextField
            name="fixed_shipping_cost"
            value={formik.values.fixed_shipping_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.fixed_shipping_cost && Boolean(formik.errors.fixed_shipping_cost)
            }
            label="Costo de envió"
            size="small"
            variant="outlined"
            autoComplete="none"
            type="number"
            helperText={
              formik.touched.fixed_shipping_cost ? formik.errors.fixed_shipping_cost : ""
            }
          />
        </Grid>

        <Grid container justifyContent="end" mt="16px">
          <LoadingButton
            loading={loading}
            /* fullWidth */
            type="submit"
            variant="contained"
          >
            Actualizar
          </LoadingButton>
        </Grid>
      </form>
    </Paper>
  );
};
