import React, { useState, useEffect, useMemo } from 'react'
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import { useAuth } from "user-context-fran-dev";

//Nota: en el request para obtener la tabla tienes que agregar una condición ejemplo
/*   
    ****Tu componente donde quieres utilizar el selector *********
    const [whId, setWhId]= useState("")
    Funcion filter
    if(whId){
        irequestFilter.push({ key: "wh", value: `${whId}` }); 
      }
    y agregar la dependecia en el useEffect Ejemplo:
     useEffect(() => {
        getTable();
  }, [whId]);
} */
export const GenericSelectTable = ({text, listService, idlist}) => { 
    const [list, setList]= useState("");
    const [value, setValue]= useState("");
    const { logout } = useAuth(); 

    useEffect(() => { 
        listRequest();
    }, []);
    

    const setChangesList = (id)=>{
        idlist(id)
        setValue(id);
    }

    const listRequest = () => {
        (async () => {
          const getList = new listService();
          const response = await getList.getAllAuth(logout);
          setList(response.data)
          console.log('setList', response.data)
        })();
      };

    return (<> 
    {list &&
        <FormControl fullWidth variant="outlined" size="small" >
            <InputLabel id="demo-simple-select-filled-label">
                {text}
            </InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                label={text}
                value={value}
                onChange={(e) => setChangesList(e.target.value)} >
                <MenuItem value={""}>
                    Todo
                </MenuItem>
                {list.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                        {data.name}
                    </MenuItem>
                ))}
            </Select>

        </FormControl>
    }

    </>
    );
}


export const GenericSelectTableNew = ({text, listService, params, setChangesList, name}) => { 
    const [list, setList]= useState("");
    const [value, setValue]= useState("");
    const { logout } = useAuth(); 

    useEffect(() => { 
        listRequest();
    }, []);
    

/*     const setChangesList =  (id)=>{
        idlist(id)
        setValue(id);
    } */

    const listRequest = () => {
        (async () => {
          const getList = new listService();
          const response = await getList.getAllAuth(logout);
          setList(response.data)
          console.log('setList', response.data)
        })();
      };

    return (<> 
    {list &&
        <FormControl fullWidth variant="outlined" size="small" >
            <InputLabel id="demo-simple-select-filled-label">
                {text}
            </InputLabel>
            <Select
                name={name}
                labelId="demo-simple-select-standard-label"
                label={text}
                value={params.get(name) || ""}
                onChange={(e) => setChangesList(e)} >
                <MenuItem value={""}>
                    Todo
                </MenuItem>
                {list.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                        {data.name}
                    </MenuItem>
                ))}
            </Select>

        </FormControl>
    }

    </>
    );
}