import React, {useEffect, useState} from "react";
import { Grid, Typography, Paper, Box, Link } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom'
import { ProductModelService } from "../../../../api/productService";
import { useAuth } from "user-context-fran-dev";
import CircleIcon from '@mui/icons-material/Circle';
import { RecipeGroup } from "../../../../api/recipeManages";
import RecipeTable from "./recipeTable";
import RecipeModal from "./addRecipeModal";
import ImageSize from "../../../../components/ImageSize";
import { GenericHeaderTitle } from "../../../../components/GenericHeader";

 

const InventoryRecipesDetail = () => {
  const { id } = useParams()
  const { logout } = useAuth();
  const [table, setTable] = useState();
  const [productGroup, setProductGroup] = useState();


  useEffect(() => {
    GetRecipeGroup();
    

}, []);

const GetRecipeGroup = () => {
  (async () => {
    let getGroup = new RecipeGroup();
    let response = await getGroup.getAllByIdAuth(id, logout)
    console.log(response.data)
    setProductGroup(response.data)
    GetModels(response.data.product.id)
  })();
}
const GetModels = (id) => {

  (async () => {
    let irequestFilter = [];
    irequestFilter.push({ key: "product", value: `${id}` });
    let getModels = new ProductModelService();
    let response = await getModels.filter(irequestFilter, logout)
    console.log(response.data)
    setTable(response.data)
  })();
}
  return (
    <>
      <Container>
      {table && productGroup &&
      <>
        <Header data={productGroup}/>
        <ModelsPresentation table={table} productGroup={productGroup} GetRecipeGroup={GetRecipeGroup}/>
        <RecipeTable recipeGroup={productGroup} GetRecipeGroup={GetRecipeGroup}/>
      </>
      }

      </Container>
    </>
  );
};

const ModelsPresentation = ({table, productGroup, GetRecipeGroup}) => {

    /* const [open, setOpen] = useState(false); */
    const [value, setValue] = useState(0);
    const theme = useTheme(); 
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeIndex = (index) => {
      setValue(index); 
    };

    return ( 
    <>
    <Grid container >
        <Grid item xs={12}>
          <GenericHeaderTitle title={"Seleccionar presentación"} />
        </Grid>
        <Grid item xs={12} mt={"12px"}> 
         {table && ( 
          <Grid item xs={12}>
            <Box sx={{ width: "100%", m: "auto" }}>
              <Box sx={{maxWidth: { xs: 390, sm: 480, md: "100vw" }, m: "auto", borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {table.map((data, index) => (
                    <Tab key={index} iconPosition="end" 
                     label={ <IsActive data={data} /> /* data.name */} {...a11yProps(index)} />
                  ))}
                {/*     <Tab label="Item One" {...a11yProps(0)} />
                <Tab label="Item Two" {...a11yProps(1)} /> */}
                </Tabs>
              </Box>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {table.map((data, index) => (
                  <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    dir={theme.direction}
                  >
                    <PricePresentations data={data} productGroup={productGroup} GetRecipeGroup={GetRecipeGroup}/> 
                  </TabPanel>
                ))}
                {/*     <TabPanel value={value} index={0} dir={theme.direction}>
                    lkjlkjfsklfjasdlñ


                    </TabPanel>

                    <TabPanel value={value} index={1} dir={theme.direction}>
                    <PricePresentations/>
                    </TabPanel> */}
              </SwipeableViews>
            </Box>

            <br />
            <br />
          </Grid>
        )}

        </Grid>
     



    </Grid>
    
    
    </> 
    );
}


const  IsActive= ({data}) => {
  return ( 
  <>
      {data.is_active? <CircleIcon   sx={{color: 'green',  fontSize: 15, marginRight: "8px"}}/>:
      <CircleIcon   sx={{color: 'red', fontSize: 15, marginRight: "8px", }}/>} {` ${data.name}`}
    
    </>
   );
}
 
 
const PricePresentations = ({data, productGroup, GetRecipeGroup}) => {
  const { auth, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [recipe, setRecipe] = useState({
    user: "",
    presentation: "",
    model: "",
    recipe_group: "",
    quantity: ""
  });
  console.log(data)
  const  SetRecipe= async (pres) => {
    console.log(pres)
    let formData = {
      user: auth.user.pk,
      presentation: pres.id,
      model: data.id,
      recipe_group: productGroup.id, 
      quantity: ""
    }
    setRecipe(formData)
    /* console.log(form) */
    setOpen(true)
     /* console.log(formData) */

    /* let setRecipe = new RecipeService();
    let response = await setRecipe.postDataAuth(formData, logout);
    GetRecipeGroup() */
    
  }

    return ( 
      <>
      <RecipeModal open={open} setOpen={setOpen} form={recipe} update={GetRecipeGroup}/>
      {data.presentation && data.presentation.map((pres, index) => (
        
        <Paper  
        key={index}
        
    
              onClick={()=>{ /* setEdit(data); setOpen(true); */}}
              elevation={2} sx={{ padding: '8px 16px',    mb: 1 }}>
                  <Grid container sx={{height: "100%", display: "flex", alignItems: "center"}} >
                    <Grid item xs={1} sm={.5} sx={{height: "100%", display: "flex", alignItems: "center"}}>
                   {pres.is_active? <CircleIcon  sx={{color: 'green',  fontSize: 15}}/>:
                      <CircleIcon   sx={{color: 'red', fontSize: 15}}/>} 
    
                    </Grid>
                    <Grid item xs={5} sm={5.5} >
                      <Typography variant="body2" >
                        {pres.name}
                      </Typography>
                      
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justifyContent='flex-end' >
                      <Link sx={{ cursor: "pointer" }} underline="none" onClick={() => {SetRecipe(pres)}}>
                            <Typography variant="subtitle1" component="div">
                              Elegir
                            </Typography>
                        </Link>
                      </Grid>
                      
                    </Grid>

                  </Grid>
              </Paper>
        ))}
        </>
     );

}
 
const Header = ({data}) => {
  console.log("header",data)
    return (          
    <Grid container  sx={{paddingBottom: "16px"}}>
        <Grid item xs={6} md={3} >
        <ImageSize img={data.product.main_model?.image? data.product.main_model.image.url: ""} weight={3}/>

        </Grid>
        <Grid item xs= {6} md={9} >
          <Grid container justifyContent="flex-end">
            <Typography variant="h7" sx={{  }} component="div">
              <strong>Crear receta</strong> 
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Typography variant="h5" sx={{ color: 'primary.main' }} component="div">
                <strong>{data.product.name}</strong> 
                </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Typography variant="h7" sx={{ color: 'text.secondary' }} component="div">
                {data.product.sku}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Typography variant="h7" sx={{ color: 'text.secondary' }} component="div">
                
            </Typography>
          </Grid>

        </Grid>
      </Grid> );
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, pb: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
 


export default InventoryRecipesDetail;
