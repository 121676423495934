import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';


const GenericEmptyTable = (props) => {
    const {msg, justify} = props;
    
    return ( 
        
        <p style={{fontStyle:"", padding: "48px 0px", textAlign: "center"}}>{msg}</p>
   
     );
}
 
export default GenericEmptyTable;

GenericEmptyTable.propTypes ={
    msg: PropTypes.string, 
    justify: PropTypes.string
}

GenericEmptyTable.defaultProps = {
    msg: "No hay datos para mostrar en esta tabla",
    justify: "center"
}
