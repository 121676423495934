import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import { initValAddBranch, valSchAddBranch } from "../../../utils/formHelper";
import BranchManages from "../../../api/branchManages";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import CountWords from "../../../components/CountWords";



const AddBranch = ({ UpdateBranchList, edit, openModal, setOpenModal, data }) => {

  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();


  if (!edit) {
    data = {
      id: "",
      name: "",
      phone: ""
    };
  }




  const setBranch = async (formData) => {
    let setBranch = new BranchManages();
    let response = await setBranch.postDataAuth(formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    if (Number(response.status) < 300) {
      window.localStorage.setItem("menu", 9);
      navigate(`detail/${response.data.id}`);
      setOpenModal(false);
    } else {

    }
    UpdateBranchList();
  }
  const updateBranch = async (formData) => {
    let setBranch = new BranchManages();
    let response = await setBranch.postDataAuth(formData, logout);
    if (Number(response.status) < 300) {

      setOpenModal(false);
    }
    UpdateBranchList();
  }
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);




  const formik = useFormik({
    initialValues: initValAddBranch(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddBranch()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formik.values);
      if (!edit) {
        setBranch(formData);

      } else {
        updateBranch(formData);
      }
      resetForm()

      setLoading(false);
    },
  });

  /*      useEffect(() => {
         console.log(formik.values)
       }, []);  
  */
  /* console.log(formik.values) */


  return (

    <>


      <BasicModal open={openModal} setOpen={setOpenModal} title={!edit ? "Agregar nueva sucursal" : "Actualizar sucursal"}>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>

            <TextField
              fullWidth
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                formik.touched.name && Boolean(formik.errors.name)
              }
              label="Nombre"
              margin="dense"
              size="small"
              variant="outlined"
              inputProps={{ maxLength: 50 }} // Limit characters to 50
              helperText={
                formik.touched.first_name ? formik.errors.first_name : ""
              }
            />
            <CountWords length={formik.values.name.length} />

            {/*  <TextField
              fullWidth
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={
                formik.touched.phone && Boolean(formik.errors.phone)
              }
              label="Teléfono"
              margin="dense"
              size="small"
              type="number"
              variant="outlined" 
              helperText={
                formik.touched.phone ? formik.errors.phone : ""
              }

            />
            <CountWords length={formik.values.phone.toString().length}/> */}




            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={12}
                sm={7}
                md={6}
                lg={4}
                xl={4}
                sx={{ marginTop: "15px" }}
              >
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  {!edit ? 'Agregar' : 'Modificar'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </BasicModal>
    </>
  );
}

export default AddBranch;

