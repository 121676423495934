import React, { useState } from "react";
import { Grid, Typography, Button, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import "../../../scss/catalog.scss";
import PricePresentation from "./articlePricePresentation";
import AddModel from "./articleAddModel";
import { styled } from "@mui/material/styles";
import { ProductModelImageDelete } from "../../../api/productModel";
import { useAuth } from "user-context-fran-dev";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import CircularProgress from '@mui/material/CircularProgress';
import StarIcon from '@mui/icons-material/Star';
import { useParams } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import Products from "../../../api/productsManages";
import { useSnackbar } from 'notistack';
import CircleIcon from '@mui/icons-material/Circle';
import AddImage2 from "./addImage2";


const ArticleModels = ({ article, getArticleInfo, load }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  /* const classes = useStyles(); */
  const [model, setModel] = useState("")

  const handleAddModel = (data, edit) => {
    setModel({ ...data, isEdit: edit })
    setOpen(true);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (

    <Grid container >
      <Grid item xs={12}>
        <GenericHeaderTitle title="Modelos" >
          <Button
            onClick={() => {

              handleAddModel(article, false);
            }}
            variant="text"
            startIcon={<AddIcon fontSize="small" />}
          >
            Agregar
          </Button>
          {model && <AddModel
            mainModel={article}
            article={model}
            getArticleInfo={getArticleInfo}
            open={open}
            setOpen={setOpen}
          />}
        </GenericHeaderTitle>
      </Grid>
      {article &&
        <Grid item xs={12}>
          {article.model && (

            <>
              <Box sx={{ maxWidth: "90vw", margin: "auto" }}>
                <Tabs
                  value={value}

                  onChange={handleChange}
                  variant="standard"
                  scrollButtons
                  aria-label="scrollable auto tabs example"
                  allowScrollButtonsMobile


                >
                  {article.model.map((data, index) => {
                    let main = article.main_model.id;

                    return (
                      <Tab key={index} iconPosition="end"
                        
                        label={<IsActive data={data} main={main} />} {...a11yProps(index)} />
                    );

                  })}

                </Tabs>

              </Box>


              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
                sx={{ width: "10vw" }}

              >
                {article?.model && article.model.map((data, index) => (
                  <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    dir={theme.direction}


                  >
                    <InfoModelo data={data} getArticleInfo={getArticleInfo} handleAddModel={handleAddModel} />
                  </TabPanel>
                ))}

              </SwipeableViews>
            </>


          )}
        </Grid>}
      {!article && load && (
        <Grid container justifyContent="center" p={"32px"}>

          <CircularProgress />


        </Grid>
      )}

    </Grid>

  );
};
const Input = styled("input")({
  display: "none",
});

const InfoModelo = ({ data, getArticleInfo, handleAddModel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const { id } = useParams()


  const handleCloseFile = () => {
    setOpen(false);
  };

  const changeInput = (e) => {
    console.log(e.currentTarget.files)
    console.log(URL.createObjectURL(e.currentTarget.files[0]))
    if (e.currentTarget.files.length <= 1) {

      setFile(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]))
      setOpen(true);
      console.log("open")

    } else {

      enqueueSnackbar("Maximo 1 imagen ", { autoHideDuration: 1000, variant: 'warning' })

    }

  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const DeleteImgs = (id) => {
    (async () => {
      let images = new ProductModelImageDelete();
      await images.deleteAuth(id, logout);
      getArticleInfo()
    })();
  }
  const changesMainModel = async (data) => {
    let setPresentation = new Products();
    let response = await setPresentation.editDataAuth(id, { main_model: data.id }, logout);
    sendNotification(UseerrorOrsuccess(response))
    getArticleInfo()
  }


  return (

    <Grid container  >
      <Grid item xs={11}>
        <Typography variant="subtitle2" gutterBottom component="div">
          Puedes designar cualquier modelo como el principal para que sea el que se muestre en la vista previa del artículo, también puedes editarlo o desactivarlo.
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Grid container justifyContent={"flex-end"}>
          <IconButton onClick={handleClick} size="large">
            <MoreVertIcon
              fontSize="inherit"
              sx={{ color: "text.secondary" }}
            />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleAddModel(data, true);
                handleClose();
              }}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={() => {
                changesMainModel(data);
                handleClose();

              }}
            >
              Asignar como principal
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={9}>
        <Typography variant="subtitle2" gutterBottom component="div">
          <strong>Recursos gráficos</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <label >

          <Input
            hidden type="file" multiple onChange={changeInput}
          ></Input>
          <Button
            startIcon={<UploadOutlinedIcon />}
            variant="contained"
            fullWidth
            component="span"
          >
            Subir imagen
          </Button>
        </label>
        {file && open && <AddImage2 open={open} handleClose={handleCloseFile} file={file} idModel={data.id} update={getArticleInfo} />}

      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" gutterBottom component="div">
          Cuadrado, Paisaje, Retrato. (512x512 recomendado) Archivo PNG, JPEG
          o JPG.
        </Typography>
      </Grid>
      {data.images && <Grid item xs={12} sx={{ border: "1px dashed gray", width: "10vw" }}>
        {/* <div className="container-img"> */}
        <Stack className="container-img" sx={{
          flexWrap: 'no-wrap',
          overflowX: 'auto', width: "100%"
        }} direction="row">

          {data.images && data.images.map((data, index) => (
            <ImgContainer key={index} data={data} deleteImg={DeleteImgs} />
          ))}


        </Stack>


      </Grid>}

      <Grid item xs={12} mt={3}>
        <PricePresentation data={data} getArticleInfo={getArticleInfo} />
      </Grid>

    </Grid>

  );
};

const ImgContainer = ({ data, deleteImg }) => {

  return (
    <div className="car-img">
      <button className="close-button" onClick={() => deleteImg(data.id)} > x </button>
      <img
        alt="lost"
        src={data.url}
        className="img-size"
      ></img>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "16px 32px 0px 32px" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const IsActive = ({ data, main }) => {
  return (
    <>
      {data.is_active ? <CircleIcon sx={{ color: 'green', fontSize: 15, marginRight: "8px" }} /> :
        <CircleIcon sx={{ color: 'red', fontSize: 15, marginRight: "8px", }} />} {` ${data.name}`}
      {main === data.id ? <StarIcon sx={{ color: '#FFD700', marginLeft: "8px" }} /> : ""}
    </>
  );
}



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ArticleModels;
