import React, { useState, useEffect } from "react";
import { Box, Button, FormGroup, Paper, Switch, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "../../scss/adminBusiness.scss";
import Link from '@mui/material/Link';
import Container from "../../layout/gridContainer";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import BasicModal from "../../components/GenericModal";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { formatISO9075 } from 'date-fns'
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBranchName, valSchBranchName } from "../../utils/formHelper";
import BranchManages from "../../api/branchManages";
import { useAuth } from "user-context-fran-dev";
/* import { initValBranchContact, valSchBranchContact } from "../../utils/formHelper"; */
import { initValSchedule, valSchSchedule } from "../../utils/formHelper";
import ScheduleManages from '../../api/scheduleManages';
import { useParams } from 'react-router-dom'
import { UseerrorOrsuccess, useNotification } from "../../hooks/snackBarResponse";
import GenericAddress from "../../components/GenericAddress";
import WarehouseService from "../../api/warehouseService";
import { initValBranchWarehouse, valSchBranchWarehouse } from "../../utils/formHelper";

const BranchDetails = ({ setReload }) => {

  const [dataBranch, setDataBranch] = useState("");
  const { logout } = useAuth();
  const { id } = useParams()


  const UpdateBranchInfo = () => {
    (async () => {
      let getBranch = new BranchManages();
      let response = await getBranch.getAllById(id, logout);
      console.log('Respuest de admin branch', response)
      setDataBranch(response.data);
    })();
  };



  useEffect(() => {

    UpdateBranchInfo();

    return () => {
      setReload(prev => !prev);
      window.localStorage.setItem("menu", 1)
    };

  }, []);


  return (<div>
    <Container>
      {dataBranch &&
        <>
          <BranchName data={dataBranch} UpdateInfo={UpdateBranchInfo} />
          {/* <Address data={dataBranch} UpdateInfo={UpdateBranchInfo} target={'branch'}/> */}
          <GenericAddress data={dataBranch} UpdateInfo={UpdateBranchInfo} target={'branch'} />
          {/*  <Contact data={dataBranch} UpdateInfo={UpdateBranchInfo}/> */}
          <Schedule data={dataBranch} UpdateInfo={UpdateBranchInfo} />
          <WareHouse data={dataBranch} UpdateInfo={UpdateBranchInfo} />
        </>
      }


    </Container>
  </div>);
}



const Schedule = ({ data, UpdateInfo }) => {
  const weekDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [dayInfo, setDayInfo] = useState("");
  const [titleDay, setTitleDay] = useState("");


  const AddTime = (index) => {
    setDayInfo(data?.schedule ? data.schedule.find((schedule) => index === schedule.day) : "");
    setTitleDay(index)
    setOpenModal(true)
  };
  const DeleteDay = (id) => {
    (async () => {
      let setSchedule = new ScheduleManages();
      let response = await setSchedule.deleteAuth(id, logout);
      UpdateInfo()
    })();

  };


  return (

    <Paper className="paper">
      <Typography variant="h6" gutterBottom component="div">
        <strong>Horarios</strong>
      </Typography>
      <TimeModal
        openTime={openModal}
        setOpenTime={setOpenModal}
        dayInfo={dayInfo}
        data={data}
        titleDay={titleDay}
        UpdateInfo={UpdateInfo} />

      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={{ xs: 2, md: 3 }}  >

        {data && weekDays.map((day, index) => (

          <ButtonSchedule key={index} day={day} index={index} data={data} AddTime={AddTime} />

        ))}

      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} mt={4}>
          <List>
            {data.schedule && data.schedule.map((data, index) => (
              <div key={index} >
                <ListItem>
                  <ListItemText primary={weekDays[data.day]} />
                  <ListItemText primary={`${data.open_time} - ${data.close_time}`} />
                  <Link
                    edge="end"
                    component="button"
                    variant="body2"
                    underline="none"
                    onClick={() => {
                      DeleteDay(data.id)
                    }}>
                    Eliminar
                  </Link>
                </ListItem>
                <Divider />
              </div>
            ))}



          </List>
        </Grid>
      </Grid>
    </Paper>

  );
}

const ButtonSchedule = ({ day, data, index, AddTime }) => {
  const [state, setState] = useState(false);
  console.log("botton")


  useEffect(() => {
    console.log("useEffect")
    if (data?.schedule) {

      /* console.log(user) */

      let aux = (data.schedule.find((schedule) => index === schedule.day)) ? true : false;

      console.log(aux)
      setState(aux)
    } else {
      setState(false)
    }
  }, [data]);

  return (
    <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={data}>
      <Button
        fullWidth
        key={index + 1}
        name={day}
        /* hidden={day[data]} */
        variant="contained"
        className={state ? 'button-enable' : 'button-disable'}
        onClick={() => AddTime(index)}>
        {day}
      </Button>
    </Grid>
  );
}


const TimeModal = ({ openTime, setOpenTime, dayInfo, titleDay, data, UpdateInfo }) => {
  /*   const [open, setOpen] = useState("");
    const [close, setClose] = useState(""); */
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const weekDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];


  /*   const  UpdataDay= () => {
      const today = new Date();
      if(dayInfo){
        setOpen(`${today.getFullYear()} ${dayInfo.open_time}`);
        setClose(`${today.getFullYear()} ${dayInfo.close_time}`)
      }else{
        setOpen(null);
        setClose(null);
      }
    } */
  const setSchedule = (formData) => {
    setLoading(true)
    const data = {
      day: titleDay,
      open_time: formatISO9075(formData.open_time, { representation: 'time' }),
      close_time: formatISO9075(formData.close_time, { representation: 'time' }),
      branch: formData.branch
    }
    console.log(data)
    if (dayInfo) {
      (async () => {
        let setSchedule = new ScheduleManages();
        let response = await setSchedule.editDataAuth(dayInfo.id, data, logout);
        UpdateInfo()
      })();
    } else {
      (async () => {
        let setSchedule = new ScheduleManages();
        let response = await setSchedule.postDataAuth(data, logout);
        UpdateInfo()
      })();
    }

    setLoading(false)
    setOpenTime(false)
  }
  let formik = useFormik({
    initialValues: initValSchedule(dayInfo, data.id),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchSchedule()),
    onSubmit: async (formData) => {
      setSchedule(formData)

    },
  });


  return (
    <>
      <BasicModal open={openTime} setOpen={setOpenTime} title={!dayInfo ? "Agregar Horario" : "Editar Horario"}>
        <form onSubmit={formik.handleSubmit} >
          <Grid container mt={1} spacing={3} >

            <Grid item xs={4}>
              {weekDays[titleDay]}
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

              <Grid item xs={4}>
                <TimePicker
                  label="Abre a las"
                  id="open_time"
                  name="open_time"
                  value={formik.values.open_time}
                  onChange={(newValue) => {
                    formik.setFieldValue('open_time', Date.parse(newValue));
                  }}

                  renderInput={(params) => <TextField

                    helperText={
                      formik.touched.open_time ? formik.errors.open_time : ""}
                    {...params} />}
                />
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  label="Cierra a las"
                  id="close_time"
                  name="close_time"
                  value={formik.values.close_time}
                  onChange={(newValue) => {
                    formik.setFieldValue('close_time', Date.parse(newValue));
                  }}

                  renderInput={(params) => <TextField
                    helperText={
                      formik.touched.close_time ? formik.errors.close_time : ""}
                    {...params} />}
                />

              </Grid>
            </LocalizationProvider>
            <Grid container justifyContent="flex-end" mt={2}>
              <Grid item xs={6}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  /*   onClick={()=> {
                    console.log(formatISO9075(open, { representation: 'time' }));
                    console.log(open) 
                    const day = data?.schedule? data.schedule.find((schedule)=> dayInfo === schedule.day) : ""
                    const trest =  day? `${today.getFullYear()} ${day.open_time}`:"";
                    console.log(trest)
    
                  }} */
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  {!dayInfo ? "Agregar" : "Editar"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>

      </BasicModal>
    </>
  );
}


const BranchName = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false)
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();

  const formik = useFormik({
    initialValues: initValBranchName(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBranchName()),
    onSubmit: async (formData) => {

      setLoading(true)
      console.log(formData)
      let getBranch = new BranchManages();
      let response = await getBranch.editDataAuth(data.id, formData, logout);

      sendNotification(UseerrorOrsuccess(response))
      UpdateInfo();

      setLoading(false);

    },
  });


  return (
    <Paper className="paper">
      <Typography variant="h6" gutterBottom component="div" mb={"16px"}>
        <strong>Información</strong>
      </Typography>

      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit} >
          <Grid container spacing={"8px"}>
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                name="name"
                label="Nombre"

                size="small"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  formik.touched.name && Boolean(formik.errors.name)
                }
                variant="outlined"
                helperText={
                  formik.touched.name ? formik.errors.name : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <LoadingButton
                fullWidth
                className='button'
                type="submit"
                loading={loading}
                variant="contained"

              >
                Actualizar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>

    </Paper>
  );
}


const WareHouse = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();


  const formik = useFormik({
    initialValues: initValBranchWarehouse(data.warehouse),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBranchWarehouse()),
    onSubmit: async (formData) => {
      setLoading(true)
      console.log(formData)
      let setWareHouse = new WarehouseService();
      let response = await setWareHouse.editDataAuth(data.warehouse.id, formData, logout);
      UpdateInfo();
      sendNotification(UseerrorOrsuccess(response))
      setLoading(false)
    },
  });

  return (

    <Paper className="paper">
      <Typography variant="h6" gutterBottom component="div">
        <strong>Almacén</strong>
      </Typography>

      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit} >
          <Grid item md={12}>
            <TextField
              fullWidth
              name="name"
              label="Almacén"
              margin="dense"
              size="small"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                formik.touched.name && Boolean(formik.errors.name)
              }
              variant="outlined"
              helperText={
                formik.touched.name ? formik.errors.name : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Switch
                  name="sell_products_online"
                  checked={formik.values.sell_products_online}
                  onChange={formik.handleChange}

                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" component="div">
                  Disponible para venta en línea.

                </Typography>
              </Box>
            </FormGroup>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} sm={4} md={3} lg={2} >
              <br />
              <LoadingButton
                fullWidth
                className='button'
                type="submit"
                loading={loading}
                variant="contained"

              >
                Actualizar
              </LoadingButton>

              <br />

            </Grid>
          </Grid>
        </form>
      </Grid>

    </Paper>

  );
}



export default BranchDetails;