import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../../../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBusinessProfile, valSchBusinessProfile } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from '../../../api/businessManages';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'

const Profiel = ({ data, UpdateInfo }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const UpdateProfiel = async (id, formData) => {
    console.log(id)
    let updateProfiel = new BusinessManages();
    const response = await updateProfiel.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    UpdateInfo();
  }

  const formik = useFormik({
    initialValues: initValBusinessProfile(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessProfile()),
    onSubmit: async (formData) => {
      console.log(formData);

      if (formik.isValid) { // Solo si el formulario es válido se ejecuta la actualización
        setLoading(true);
        try {
          await UpdateProfiel(data.id, formData);
          setLoading(false);
        } catch (error) { // Maneja el error "correo invalido"
          setLoading(false);
        }
      }
    },
  });

  //Function like event to manage the event onKeyPress and permise only letters and spaces in blanck
  const handleNumbersOnly = (event) => {
    const regex = /^\d+$/; // Expresión regular to only permisse numbers
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevents the entry of characters other than letters
    }
  };

  return (

    <>
      <Grid container sx={{ paddingBottom: '2%' }}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"

          >
            <strong>Mi negocio</strong>
          </Typography>
        </Grid>
      </Grid>

      <Paper className="paper">
        <Typography variant="h6" gutterBottom component="div">
          <strong>Perfil</strong>
        </Typography>
        <br />
        <form onSubmit={formik.handleSubmit} >
          <Grid container >
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Nombre de la empresa"
                margin="dense"
                size="small"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  formik.touched.name && Boolean(formik.errors.name)
                }

                helperText={
                  formik.touched.name ? formik.errors.name : ""
                }
              />
              <TextField
                fullWidth
                name="turn_business"
                label="Giro de la empresa"
                margin="dense"
                size="small"
                value={formik.values.turn_business}
                onChange={formik.handleChange}
                error={
                  formik.touched.turn_business && Boolean(formik.errors.turn_business)
                }

                helperText={
                  formik.touched.turn_business ? formik.errors.turn_business : ""
                }

              />
              <TextField
                fullWidth
                name="phone"
                label="Teléfono"
                margin="dense"
                size="small"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onKeyPress={handleNumbersOnly}
                error={
                  formik.touched.phone && Boolean(formik.errors.phone)
                }

                helperText={
                  formik.touched.phone ? formik.errors.phone : ""
                }
              />
              <TextField
                fullWidth
                name="email"
                label="Correo electrónico"
                margin="dense"
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  formik.touched.email && Boolean(formik.errors.email)
                }

                helperText={
                  formik.touched.email ? formik.errors.email : ""
                }
              />
            </Grid>
            <Grid item xs={0} sm={8} md={9} lg={10} />
            <Grid item xs={12} sm={4} md={3} lg={2} >
              <br />
              <LoadingButton
                fullWidth
                disabled={loading} // El botón se deshabilita solo si está en estado de carga
                className='button'
                type="submit"
                loading={loading}
                variant="contained"
              >
                Actualizar
              </LoadingButton>
            </Grid>

          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default Profiel;