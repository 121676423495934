import React, { useEffect, useState } from 'react'
import GenericSettings from '../../components/GenericSettings';
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse';
import { useAuth } from "user-context-fran-dev";
import inventorySettings from '../../api/inventory/settings';
import { Grid, Typography } from '@mui/material';

const InventorySettings = () => {
    const [data, setData] = useState();
    const { logout } = useAuth();
    const [msg, sendNotification] = useNotification();

    const updateSettings = async (id, formData) => {
        let settings = new inventorySettings();
        let response = await settings.editDataAuth(id, formData, logout);
        sendNotification(UseerrorOrsuccess(response))
        getSettings()
    }
    const getSettings = () => {
        (async () => {
            let getSetting = new inventorySettings();
            let response = await getSetting.getAllAuth(logout);
            setData(response.data);
        })();
    };
    useEffect(() => {
        getSettings()
    }, [])

    return (
        <>
            {data && <GenericSettings data={data} title={"Órdenes de compra y ventas"} update={updateSettings} />}
        </>
    );
}

export default InventorySettings;