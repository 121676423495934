import React, { useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { Grid, Divider, TextField, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValRecivedPurchase,
  valSchRecivedPurchase,
} from "../../../utils/formHelper";
import { OrderedReceived } from "../../../api/orderedReceived";
import { useAuth } from "user-context-fran-dev";
import ImageSize from "../../../components/ImageSize"
import { formatNumber } from "../../../utils/functions";
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'

const PurchaseRecieved = ({
  open,
  setOpen,
  data,
  article,
  GetPurchaseDetail,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  /* console.log(data); */ 
  const [msg, sendNotification ] = useNotification();

  const formik = useFormik({
    initialValues: initValRecivedPurchase(data, article.warehouse),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchRecivedPurchase(data)),
    onSubmit: async (formData) => {
      setLoading(true);
      if(formik.values.barcode === ""){
        formik.values.barcode = null;
      }
      let setOrder = new OrderedReceived();
      let response = await setOrder.postDataAuth(formData, logout);
      GetPurchaseDetail();
      sendNotification(UseerrorOrsuccess(response))
      setLoading(false);
      setOpen(false);
    },
  });


  return (
    <BasicModal open={open} setOpen={setOpen} title="Recibir artículo">
      
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <ImageSize img={data.model.image?.url? data.model.image.url :""} weight={2}/>
{/*               <img
                src={data.model.image?.url? data.model.image.url :""}
                style={{ width: "90%", height: "auto" }}
              ></img> */}
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={12} className="">
                <h3>{data.model.product.name}</h3>
              </Grid>
              <Grid item xs={12} className="">
                <h4>{data.model.product.sku}</h4>
              </Grid> 
              <Grid item xs={12} className="">
                <h4>{data.model.name}</h4>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
        <Divider sx={{ height: '1px', borderColor: "text.primary", backgroundColor: "text.primary", marginTop: "8px" }}></Divider>
        </Grid>
        <Grid container  mt={1} mb={1}>
          <Grid item xs={6} className="">
            <h4>{`Ordenados: ${formatNumber(data.quantity)}`}</h4>
          </Grid>
          <Grid item xs={5} className="">
            <h4>{`Pendientes: ${formatNumber(data.quantity_pending, 2)}`}</h4>
          </Grid>
        </Grid>

        {data.status !== 3 ? (
          <Paper sx={{backgroundColor:"background.default"}}>
            <Grid container  p={"8px"}>
          <Grid item xs={12} mb={"8px"}>
          <Typography >
            Presentación: {data.presentation.name}
          </Typography>
          <Typography >
            Barcode: {data.presentation.barcode}1234
          </Typography>
        </Grid>
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1} alignItems="center">
      
{/*               <Grid item xs={2.4}>

                <TextField
                  fullWidth
                  label="Presentación"
                  defaultValue={data.presentation.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  focused={false}
                  size="small"
                  margin="dense"
                  variant="outlined" 
                />
                </Grid> */}

            
                <Grid item xs={4}>
                <TextField
               fullWidth
               name="cost"
               value={ formik.values.cost } /* formatNumber */
               /* defaultValue={data.quantity_pending} */
               onChange={formik.handleChange}
               placeholder={formatNumber(data.cost)}
                type="number"
               /* onDoubleClick={()=>{formik.values.cost = data.cost}} */
               error={
                 formik.touched.cost &&
                 Boolean(formik.errors.cost)
               }
               

               helperText={
                 formik.touched.cost
                   ? formik.errors.cost
                   : ""
               }
               autoComplete ='none'
               label="Costo"
               margin="dense"
               size="small"
               variant="outlined" 
               
               

             />
          
            
                </Grid>
                <Grid item xs={4}>
             


                <TextField
                type="number"
                    fullWidth
                    name="quantity"
                    value={formik.values.quantity}
                    /* defaultValue={data.quantity_pending} */
                    onChange={formik.handleChange}
                    error={
                      formik.touched.quantity &&
                      Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity
                        ? formik.errors.quantity
                        : ""
                    }
                    autoComplete ='none'
                    label="Cantidad"
                    margin="dense"
                    size="small"
                    variant="outlined" 
                  />
             {/* <p>{new Intl.NumberFormat('en', { maximumSignificantDigits: 10 }).format(formik.values.cost)}</p> */}
       
           </Grid>
        
                <Grid item xs>

                
      
                </Grid>
          
              
              <Grid item xs={3}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="text"
                  sx={{ color: "primary" }}
                >
                  Recibir
                </LoadingButton>
                </Grid>

                </Grid>
              </form>
              </Grid>
              </Grid>
              </Paper>
        ) : (
          <Grid container justifyContent="center">
            {" "}
            <Typography variant="h5">Recibido</Typography>
          </Grid>
        )}
      </Grid>
    </BasicModal>
  );
};

export default PurchaseRecieved;
