import React, { useState, useEffect } from "react";
import BasicModal from "../../../../components/GenericModal";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initTransfers, valTransfers } from "../../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import { TransferService } from "../../../../api/inventory/transfersService";
import WarehouseService from "../../../../api/warehouseService";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";


const AddTransfer = (props) => {
  const { openModal, setOpenModal, UpdateTransfer } = props;
  const [loading, setLoading] = useState(false);
  const { logout, auth } = useAuth();
  const [sendNotification] = useNotification();
  const [warehouseList, setWarehouseList] = useState();
  const navigate = useNavigate();
  const today = new Date();
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    (async () => {
      let warehouService = new WarehouseService();
      let response = await warehouService.getAllAuth(logout);
      console.log("lista de warehouse", response.data);
      setWarehouseList(response.data);
    })();
    return () => {
      formik.resetForm();
    }
  }, [openModal]);

  const formik = useFormik({
    initialValues: initTransfers(auth.user.pk),
    validationSchema: Yup.object(valTransfers()),
    onSubmit: async (formData) => {
      setLoading(true);
      let transferService = new TransferService();
      let response = await transferService.postDataAuth(formData, logout);
      console.log("Esta es la respuesta", response);
      if (response.status == 201) {
        window.localStorage.setItem("menu", 24);
        navigate(`transfer/${response.data.id}`);
        setOpenModal(false);
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(UseerrorOrsuccess(response));
      }
      setLoading(false);
    },
  });

  return (
    <>
      <BasicModal
        open={openModal}
        setOpen={setOpenModal}
        title="Crear orden de transferencia"
      >
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  {`Fecha: ${today.getDate()} ${Month[today.getMonth()]}, ${today.getFullYear()}`}
                </Typography>
              </Grid>
              <Grid item xs={12} mt="16px">
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="demo-simple-select-filled-label">
                    Origen
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    name="warehouse_source"
                    label="Almacén Origen"
                    value={formik.values.warehouse_source}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.warehouse_source &&
                      Boolean(formik.errors.warehouse_source)
                    }
                  >
                    {warehouseList &&
                      warehouseList.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText sx={{ color: "error.main" }}>
                    {formik.touched.warehouse_source
                      ? formik.errors.warehouse_source
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} mt="16px">
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="demo-simple-select-filled-label">
                    Destino
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    name="warehouse_destiny"
                    label="Almacén Destino "
                    value={formik.values.warehouse_destiny}
                    onChange={formik.handleChange}

                    error={
                      formik.touched.warehouse_destiny &&
                      Boolean(formik.errors.warehouse_destiny)
                    }
                  >
                    {warehouseList &&
                      warehouseList.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText sx={{ color: "error.main" }}>
                    {formik.touched.warehouse_destiny
                      ? formik.errors.warehouse_destiny
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} mt="16px">
                <ToggleButtonGroup
                  fullWidth
                  name="type"
                  id="type"
                  color="primary"
                  role="group"
                  value={formik.values.type}
                  exclusive
                  onChange={formik.handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton name="type" key={"1"} value={"1"}>
                    Inmediata
                  </ToggleButton>
                  <ToggleButton name="type" key={"2"} value={"2"}>
                    Con Confirmar
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} mt="16px">
                <Typography variant="subtitle1" gutterBottom>
                  {
                    formik.values.type === "1" ?
                      "La transferencia es inmediata, los artículos se mueven de un almacén a otro sin necesidad de confirmación." :
                      "Almacén que entrega los artículos y almacén que recibe los artículos deben confirmar la transacción."
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} mt="16px">
                <Grid container justifyContent="flex-end">
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={6}
                    lg={3}
                    sx={{ marginTop: "15px" }}
                  >
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ color: "#fff" }}
                    >
                      Comenzar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </BasicModal>
    </>
  );
};

export default AddTransfer;
