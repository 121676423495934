import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import GenericTable from "../../../components/GenericTable";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import "../../../scss/adminBusiness.scss";
import AddBranch from "./addBranch";
import { useAuth } from "user-context-fran-dev";
import BranchManages from "../../../api/branchManages";
import { PaginationGeneric } from "react-generic-pagination";
import CircularProgress from '@mui/material/CircularProgress';
import GenericEmptyTable from "../../../components/GenericEmptyTable";

const Branch = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [table, setTable] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    id: "",
    name: "",
    phone: ""
  })


  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");


  useEffect(() => {
    navigate(`page=${page}`);
    UpdateBranchList();
  }, [page]);

  const UpdateBranchList = () => {
    (async () => {
      setLoading(true)
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getBranchs = new BranchManages();
      let response = await getBranchs.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      console.log(response.data)
      setLoading(false)
    })();
  };

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"
          >
            <strong>Sucursales</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Button
            variant="contained"
            sx={{ float: "right", color: "#fff" }}
            onClick={() => { setOpenModal(true); setEdit(false) }}
          >
            Agregar sucursal
          </Button>
          <AddBranch openModal={openModal} setOpenModal={setOpenModal}
            UpdateBranchList={UpdateBranchList} edit={edit} data={data} />
        </Grid>
      </Grid>
      <br />
      {table &&
        <>
          <GenericTable titles={["Nombre", /* "Teléfono", */ "Acciones"]}>
            {table.map((data) => (
              <TableRow
                key={data.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onDoubleClick={() => {
                  window.localStorage.setItem("menu", 9);
                  navigate(`detail/${data.id}`);
                }}
                hover
              >
                <TableCell
                  align="left">
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div">
                    {data.name}
                  </Typography>
                </TableCell>
                {/* <TableCell align="left">
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div">
                {data.phone}
              </Typography>
            </TableCell> */}
                <TableCell align="left">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.localStorage.setItem("menu", 9);
                      navigate(`detail/${data.id}`);
                    }}
                  >
                    Editar
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </GenericTable>
          <Grid item xs={12} mt={2}>
            <PaginationGeneric
              className="paginationStyle"
              page={page}
              total={totalpages}
              pageSetter={pageSetter}
            ></PaginationGeneric>
          </Grid>
        </>
      }
      {!table && loading &&
        <Grid container justifyContent="center" p={"24px"}>
          <CircularProgress />
        </Grid>
      }
      {!table && !loading &&
        <GenericEmptyTable />
      }
    </>
  );
};

export default Branch;
