import React, { useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { initValArticleInfo } from "../../../utils/formHelper";
import CircleIcon from "@mui/icons-material/Circle";
import Collapse from "@mui/material/Collapse";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const TableInfo = ({ data }) => {
  const formik = useFormik({
    initialValues: initValArticleInfo(data, data.date_created),
    enableReinitialize: true,
  });
  /* console.log("table info", data) */

  return (
    <>
    {data && 
      <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid spacing={2} container>
          <Grid item xs={3}>
            <img
              src={data.main_model?.image? data.main_model.image.url: ""}
              style={{ width: "50%", height: "auto" }}
            ></img>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="type"
                  label="Tipo"
                  margin="dense"
                  size="small"
                  value={formik.values.type}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="date_created"
                  label="Fecha de registro"
                  margin="dense"
                  size="small"
                  value={formik.values.date_created}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="preparation_time"
                  label="Tiempo de preparación"
                  margin="dense"
                  size="small"
                  value={formik.values.preparation_time}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography ml={2}>Descripción</Typography>
                <Paper
                  sx={{ height: "70px", margin: "10px", overflow: "auto" }}
                >
                  <div
                    style={{ margin: "10px" }}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data.model &&
              data.model.map((data, index) => (
                <TablePresentation key={index} data={data} />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>}
    </>
  );
};

const TablePresentation = ({ data }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {" "}
      <br />
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              elevation={2}
              sx={{ backgroundColor: "background.default", padding: "10px" }}
            >
              <Grid container>
                <Grid item xs={0.5}>
                  {data.is_active ? (
                    <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                  ) : (
                    <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                  )}
                </Grid>
                <Grid item xs={5.5}>
                  {data.name}
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon
                          fontSize="small"
                          sx={{ color: "text.primary" }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          sx={{ color: "text.primary" }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {data.presentation &&
                data.presentation.map((data, index) => (
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    p={2}
                    key={index}
                  >
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        name="type"
                        variant="standard"
                        label="Presentación"
                        margin="dense"
                        size="small"
                        value={data.name}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        name="name"
                        variant="standard"
                        label="Precio"
                        margin="dense"
                        size="small"
                        value={data.price}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} sm={2}>
                      <Grid container justifyContent="flex-end">
                        {data.is_active ? (
                          <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                        ) : (
                          <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default TableInfo;
