import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import LoadingButton from '@mui/lab/LoadingButton';
import Container from "../../layout/gridContainer";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import imageCompression from "browser-image-compression";
import { useAuth } from "user-context-fran-dev";
import { useParams } from 'react-router-dom'
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import "../../scss/addImage.scss"
import { Box } from "@mui/material";
import { useMediaQuery } from '@mui/material';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImgEdit = ({ open, handleClose, file, size, update }) => {
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:700px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

  const handleSave = async () => {
    if (file.size > 32 * 1024 * 1024) {

      alert('El tamaño de la imagen supera los 32 MB. Por favor, elige una imagen más pequeña.');
      return;
    }

    setLoading(true);
    // Aquí puedes realizar cualquier acción necesaria para guardar la imagen
    update(file); // Simplemente actualizamos con la misma imagen, ya que no se está modificando
    handleClose(false);
    setLoading(false);
  };

  return (
    <Container>
      <Dialog
        fullScreen
        open={open}
        onClose={() => handleClose(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Editar
            </Typography>
            <LoadingButton autoFocus loading={loading} color="inherit" onClick={handleSave}>
              Guardar
            </LoadingButton>
          </Toolbar>
        </AppBar>
        <Typography variant="h6" sx={{ marginTop: '25px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Resultado</Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '400px', // Altura deseada del contenedor
            overflow: 'auto', // Para agregar barras de desplazamiento si el contenido se desborda
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
        >
          <img
            src={URL.createObjectURL(file)}
            alt=""
            style={{
              paddingTop: '15px',
              maxWidth: '100%',
              maxHeight: '100%', // Ajusta la altura al 100% del contenedor
              objectFit: 'contain', // Ajusta la imagen dentro del contenedor manteniendo la relación de aspecto
            }}
          />
        </div>
      </Dialog>
    </Container >
  );
};

export default ImgEdit;


