import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useDebounced from "./useDebounce";
import { useAuth } from "user-context-fran-dev";

export const useFilters = () => {
  const [params, setParams] = useSearchParams();
  

  const handleSearchEmpty = (event) => {
    if (event.target.value.length !== 0) {
      params.set(event.target.name, event.target.value);
      setParams(params);
    } else if (event.target.value.length === 0 || event.target.value === "") {
      params.delete(event.target.name);
      setParams(params);
    }
    /*     params.forEach((e,key)=>{
          console.log(e, key) 
        }) */
  };

  const handleSearch = (key, value) => {
    params.set(key, value);
    setParams(params);
  };

  return [params, setParams, handleSearch, handleSearchEmpty];
};

/* --------------------------- Para Manejar tablas con el page de manera automatica  ----------------------------------- */


export const useTableFilters = (service, setTable, setLoading, filter=null, avoid="", debounce=0) => {
  
  const [params, setParams] = useSearchParams();
  const { logout, auth } = useAuth();
  /* const AVOID = "tab" */
  const totalPerPage = 10;
  
  const useDebouseSearch =  useDebounced(params, debounce);
  //console.log(useDebouseSearch)
  
 
  
  const handleSearchEmpty = (event) => {
    console.log('handle search empty', event)
    if (event.target.value.length !== 0) { 
      params.set(event.target.name, event.target.value);
      setParams(params);
    } else if (event.target.value.length === 0 || event.target.value === "") {
      params.delete(event.target.name);
      setParams(params);
    }
  }; 

  const handleSearch = (key, value) => {
    params.set(key, value);
    setParams(params);
  };

  const UdateTable = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];

      params.forEach((value, key) => {
       if(avoid != key) irequestFilter.push({ key: key, value: `${value}` });
      
      });

      irequestFilter.push({ key: "items", value: `${totalPerPage}` });

      if(filter){
        filter.forEach((data) => {
          irequestFilter.push({ key: data.key, value: `${data.value}` });
        })
      }


      let getArticle = new service();
      let response = await getArticle.filter(irequestFilter, logout);

      setTable(response.data);
      setLoading(false);
    })();
  };


  useEffect(() => {
    if (!params.has("page")) {
      params.set("page", 1);
      setParams(params);
     
    }else{
      UdateTable();
    }
    
  }, [useDebouseSearch]);

  return [params, setParams, handleSearch, handleSearchEmpty, UdateTable];
};
