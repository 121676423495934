import React from "react";
import nofound from "../assets/no_found.png" 

const WeightList = [0, 32, 64, 128, 256, 512];

const ImageSize = ({ img, weight, custom, click, classn }) => {
  return (
    <>
      {!custom && (
        <img
          src={img || nofound} 
          alt="sample"
          style={{ width: WeightList[weight], height: WeightList[weight] }}
          className={classn || ""}
          onClick={()=>{ if(click)click() }}
        ></img>
      )}
      { custom && !weight  &&(
        <img
        alt="sample"
          src={img || nofound}
          style={custom}
          onClick={()=>{ if(click)click() }}
          className={classn || ""}
        ></img>
      )}
    </>
  );
}; 

export default ImageSize;
