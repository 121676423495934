import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IconButton, Paper, TextField, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import '../scss/loginRecover.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from 'user-context-fran-dev';
import { useLocation } from 'react-router-dom';
import { RecoverPass } from "../api/userLogin";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { useSnackbar } from 'notistack';
import Footer from "../components/Footer";


const RecoverPassword = ({ theme }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { auth, login } = useAuth();
  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*     let location = useLocation();
   */
  /*     console.log(location)
      console.log(new URLSearchParams(location.search).get('uid')) */


  const formik = useFormik({
    initialValues: initialValues(),
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);

      let setEmail = new RecoverPass();
      let response = await setEmail.postData(formData);

      if (response) {
        if (response.name !== "Error" || response.name !== "Network Error") {
          enqueueSnackbar("Se ha enviado el correo electrónico de restablecimiento de contraseña.", {
            variant: "success",
            //autoHideDuration: 1000,
            //action
          });
          /* console.log( "response ", response?.data?.detail) */
        } else {
          sendNotification(UseerrorOrsuccess(response))
        }
      }
      /*           console.log(formData)
                navigate(`/reset-password`); */

      resetForm();
      setLoading(false);
    },
  });
  return (
    <>
      <Box
        sx={{
          px: 3,
          bgcolor: "background.default",
        }}
        className={`box-container`}  /* img-${theme} */
      >

        <Paper

          sx={{
            boxShadow: 'none',
            ...(theme === 'light' ?
              { bgcolor: 'rgba(0,0,0,0)' } :
              { bgcolor: 'rgba(0, 0, 0, 0)' })
          }}
          className="paperForm formSizeRecoverP">
          <Grid item className="grid-container">
            <Grid item md={12}>
              <AccountCircleIcon sx={{ fontSize: 60, color: 'white' }} />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5" className="title" sx={{ color: "white" }}>
                <strong>Recuperar contraseña</strong>
              </Typography>
            </Grid>
            <form onSubmit={formik.handleSubmit} >
              <Grid item md={12}>
                <TextField
                  fullWidth
                  name="email"
                  values={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                  label="Correo electrónico"
                  sx={{ backgroundColor: "white", borderRadius: '5px' }}
                  margin="dense"
                  size="small"
                  variant="outlined"
                  autoComplete='none'

                  helperText={
                    formik.touched.email ? formik.errors.email : ""
                  }
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: '15px' }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  variant="contained"
                  sx={{ color: '#fff' }}
                >
                  Enviar
                </LoadingButton>
              </Grid>

              <Grid item md={12} sx={{ marginTop: '15px' }}>

                <IconButton onClick={() => navigate('/')} sx={{ borderRadius: '5px' }}>
                  <Typography variant="subtitle2" sx={{ color: "white" }}>Iniciar sesión</Typography>
                </IconButton>
              </Grid>

            </form>
          </Grid>
        </Paper>
      </Box>
      <Footer />
    </>
  );
}

function initialValues() {
  return {
    email: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email('Invalid email')
      .required("Required")
  };
}

export default RecoverPassword;