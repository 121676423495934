import React, { useState } from "react";
import Container from "../layout/gridContainer";
import { Grid, Paper, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValSettingsGeneric,
  valSchSettingsGeneric,
} from "../utils/formHelper";
import { GenericHeaderTitle } from "./GenericHeader";

const GenericSettings = ({data, title, update }) => {
  const [loading, setLoading] = useState(false);


  const formik = useFormik({
    initialValues: initValSettingsGeneric(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchSettingsGeneric()),
    onSubmit: async (formData,) => {
      setLoading(true);
      console.log(formData)
      update(data.id, formData);

      setLoading(false);
    },
  });

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
        <GenericHeaderTitle title={title} />

{/*           <Paper sx={{ p: 1.5, backgroundColor: "background.header" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" component="div">
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Paper> */}
        </Grid>
        <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
          <Grid item xs={12} sm={6} mt={"16px"}>
            <FormControlLabel
              control={
              <Checkbox 
                name="is_iva"
                checked={formik.values.is_iva}
                onChange={formik.handleChange}

              />}
              label="Agregar IVA a las órdenes"
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={"16px"}>
            <Grid container justifyContent="end">
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="iva"
                  value={formik.values.iva}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.iva && Boolean(formik.errors.iva)
                  }
                  label="Cantidad"                          
                  margin="dense"
                  variant="outlined"
                  autoComplete ='none'
                  size="small"
                  helperText={
                    formik.touched.iva ? formik.errors.iva : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={"16px"}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={loading}
                  loadingIndicator="Loading…"
                >
                  Actualizar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
        </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GenericSettings;
