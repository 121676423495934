import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Container from "../../layout/gridContainer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Menu from '@mui/material/Menu';
import { Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InventoryTab from "./inventory/inventoryTab";
import Adjustment from "./operations/adjustment/adjustment"
import Scrap from "./operations/scrap/scrap"
import Transfers from "./operations/transfers/transfers"
import { useSearchParams } from "react-router-dom";




const InventoryInventory = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [params, setParams] = useSearchParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  /*     const handleChange = (event, newValue) => {
        console.log(newValue)
      setValue(newValue);
      }; */
  /*    const  handleClickTab= (tab) => {
       setValue(tab);
     } */

  useEffect(() => {
    if (!params.has("tab")) {
      params.set("tab", "inventory");
      setParams(params);
      setValue(0);
    } else {
      if (params.get("tab") == "inventory") {
        setValue(0);
      } else {
        setValue(1)
      }
    }



  }, [params])

  const handleChangesTab = (key, value) => {
    params.set(key, value);
    setParams(params);
  };

  const handleClickTabParams = (key, value) => {
    if (value === "inventory") {
      setValue(0);
    } else {
      setValue(1)
    }

    handleChangesTab(key, value)

  }



  return (
    <Container>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"
            sx={{ paddingBottom: '2%' }}
          >
            <strong>Inventario</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} variant="fullWidth" scrollButtons={false} allowScrollButtonsMobile={false}>
              <Tab variant="fullWidth" onClick={() => { handleClickTabParams("tab", "inventory")/* handleClickTab(0) */ }} label="  Inventario  " {...a11yProps(0)} />
              {/* <Tab label="  Min / Max  " onClick={()=>handleClickTab(1)}  {...a11yProps(1)}/> */}
              <Tab
                icon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                label="Operaciones"
                iconPosition="end"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick} {...a11yProps(2)} />
            </Tabs>

          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => {/* setValue(2); setMenuOp("ajuste"); */ handleClickTabParams("tab", "adjustment"); handleClose() }}>Ajustes del Inventario</MenuItem>
            <MenuItem onClick={() => {/* setValue(2); setMenuOp("desecho"); */ handleClickTabParams("tab", "scrap"); handleClose() }}>Desecho</MenuItem>
            <MenuItem onClick={() => {/* setValue(2); setMenuOp("transferencias"); */ handleClickTabParams("tab", "transfers"); handleClose() }}>Transferencias</MenuItem>
          </Menu>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <InventoryTab />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {params.get("tab") && params.get("tab") === "adjustment" ? (
                <Adjustment></Adjustment>
              ) : (params.get("tab") === "scrap" ?
                <Scrap></Scrap>
                : (params.get("tab") === "transfers") ?
                  <Transfers></Transfers>
                  : <h2> Selecciona algo </h2>
              )
              }
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              {params && params.get("tab") === "adjustment" ? (
                <Adjustment></Adjustment>
              ) : (params.get("tab") === "scrap" ?
                <Scrap></Scrap>
                : (params.get("tab") === "transfers") ?
                  <Transfers></Transfers>
                  : <h2> Selecciona algo </h2>
              )
              }
              {/*       {menuOp && menuOp == "ajuste"?(
                            <Adjustment></Adjustment>
                           ): (menuOp == "desecho" ? 
                             <Scrap></Scrap>
                           :(menuOp == "transferencias")?
                              <Transfers></Transfers>
                            : <h2> Selecciona algo </h2>
                           )
                          } */}
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Container>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



export default InventoryInventory;

