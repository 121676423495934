import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";

const RecipesTable = ({ data, GoToDetailRecipe }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  console.log(data);
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
        onDoubleClick={() => {
          GoToDetailRecipe(data.id);
        }}
        style={{height: 10}}
      >
        <TableCell align="left" sx={{minWidth: "20px"}}>
          <img
            src={data.product.main_model.image?.url}
            style={{ width: "30px", height: "auto" }}
          ></img>

        </TableCell>
        <TableCell sx={{maxWidth: '150px'}} align="left">
        <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle1" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data.product.name}
                  </Typography>}
                subheader={data.product.sku}
            />
        </TableCell>
        <TableCell align="left"  sx={{maxWidth: '150px'}}>
        <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle1" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data.product.category.name}
                  </Typography>}
            />
        </TableCell>
        <TableCell align="left"  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {data.product.is_active ? (
            <CircleIcon sx={{ color: "green", fontSize: 15 }} />
          ) : (
            <CircleIcon sx={{ color: "red", fontSize: 15, m: 0, p: 0  }} />
          )}
        </TableCell>

        <TableCell align="left" sx={{ m: 0, p: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ m: 0, p: 0 }}
            onClick={() => {
              /*if(!open) GetProduct();  */ setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon  sx={{ m: 0, p: 0 }} /> : <KeyboardArrowDownIcon   sx={{ m: 0, p: 0 }}/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/*          <Typography variant="h6" gutterBottom component="div">
                            Articulo Info 
                        </Typography> */}
              <br />
              {/* <TableInfo data={product}/> */}
              <br />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RecipesTable;
