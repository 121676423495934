import { createTheme } from "@mui/material/styles";
/* import { COLOR_PRIMARY,COLOR_SECONDARY } from "../../utils/constants"; */
// import FuturaPt from '../../assets/fonts/FuturaPTMedium.otf';
// import SourceSansPro from '../../assets/fonts/SourceSansPro-Regular.ttf';

const COLOR_PRIMARY = "#b53f41";
const COLOR_SECONDARY = "#0200f5";
export const Colors = {
  // primary: "#4CAF50",
  // secondary: "#FF5722",
  contrastText: '#fff',
  grayText: "#8a8a8a",
  success: "#00A650",
  info: "#3483FA",
  danger: "#B12704",
  warning: "#FFC107",
  dark: "#0e1b20",
  ligth: "#aaa",
  muted: "#abafb3",
  border: "#DDDFE1",
  inverse: "#2F3D4A",
  shaft: "#333",

  ///////////////
  // Greens 
  ///////////////
  ligth_green: "#9ad28a",
  ligth_green_default: "#9ad29c",
  green_dark: "#6fbf73",  

  // dim_gray:"#487e4c",
  // body_bg: "#81c784",

  /////////////////
  // solid color
  /////////////////
  white: "#fff",
  black: "#000",

  //custom colors
  prim: "#292929",
  sec: "#545454",
  sec_alt: "#767676",
  hiden: "#C8C8C8",
  green: "#6fbf73",

  //footer colors
  footer_backgound:"#f4f4f4"
}

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main:  COLOR_SECONDARY,
    },
    // typography: {
    //   fontFamily: 'SourceSans, Arial',
    // }
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    MuiOutlinedInput:{
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '90px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '90px',
          textTransform: 'initial',
          fontSize: '16px'
        },
      },
    },
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'SourceSans';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 400;
    //       src: local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2');
    //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //     }
    //   `,
    // },
  },
});

export default theme;