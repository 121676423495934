import React, { useState, useEffect } from "react";
import BasicModal from "../../../components/GenericModal";
import Button from "@mui/material/Button";
import { TextField, Grid, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { valSchAddUser, initValAddUser } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import AddUser from "../../../api/addUser";
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from "../../../hooks/snackBarResponse";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const AddUsers = ({ UpdateUserList }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {

    return () => {
      formik.resetForm();
    };
  }, [openModal]);

  const formik = useFormik({
    initialValues: initValAddUser(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddUser()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      /* console.log(formData); */
      let setUser = new AddUser();
      let response = await setUser.postDataAuth(formData, logout);
      if (response.data.pk) {
        sendNotification(UseerrorOrsuccess(response))
        setLoading(false);
        window.localStorage.setItem("menu", 8);
        UpdateUserList()
        resetForm()
        setOpenModal(false);
        navigate(`detail/${response.data.pk}`);
      }else {
        sendNotification(UseerrorOrsuccess(response))
        setLoading(false);
      }
      console.log("paso 2");
    },
  });

  //Function like event to manage the event onKeyPress and permise only letters and spaces in blanck
  const handleLettersOnly = (event) => {
    const regex = /^[A-Za-z\s]+$/; // Expresión regular to only permisse only letters
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevents the entry of characters other than letters
    }
  };


  return (
    <>
      <Button
        variant="contained"
        sx={{ float: "right", color: "#fff", mb: 2 }}
        onClick={() => setOpenModal(true)}
      >
        Agregar Usuario
      </Button>
      <BasicModal
        open={openModal}
        setOpen={setOpenModal}
        title="Agregar nuevo usuario"
      >
        <Grid item xs={12} >
          <form onSubmit={formik.handleSubmit}>
            <Grid container >
              <Grid item xs={12}>
                <CountWords length={formik.values.first_name.length} />
                <TextField
                  fullWidth
                  name="first_name"
                  values={formik.values.first_name}
                  onChange={formik.handleChange}
                  onKeyPress={handleLettersOnly} //Event that manage to permisse only letters and spaces
                  error={
                    formik.touched.first_name && Boolean(formik.errors.first_name)
                  }
                  label="Nombre"
                  margin="dense"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  inputProps={{ maxLength: 50 }} // Limit characters to 50
                  helperText={
                    formik.touched.first_name ? formik.errors.first_name : ""
                  }
                />
                <CountWords length={formik.values.last_name.length} />
                <TextField
                  fullWidth
                  name="last_name"
                  values={formik.values.last_name}
                  onChange={formik.handleChange}
                  onKeyPress={handleLettersOnly} //Event that manage to permisse only letters and spaces
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  label="Apellido"
                  autoComplete='none'
                  margin="dense"
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 50 }} // Limit characters to 50
                  helperText={
                    formik.touched.last_name ? formik.errors.last_name : ""
                  }
                />
                <TextField
                  fullWidth
                  name="email"
                  values={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  label="Correo electrónico"
                  margin="dense"
                  autoComplete='none'
                  size="small"
                  variant="outlined"
                  helperText={formik.touched.email ? formik.errors.email : ""}
                />

                {/* <TextField
                fullWidth
                name="password1"
                values={formik.values.password1}
                onChange={formik.handleChange}
                error={
                  formik.touched.password1 && Boolean(formik.errors.password1)
                }
                label="Contraseña"
                margin="dense"
                variant="outlined"
                size="small"
                autoComplete ='none'
                helperText={
                  formik.touched.password1 ? formik.errors.password1 : ""
                }
              /> */}
                <FormControl fullWidth size="small" sx={{ margin: "8px 0" }} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-password"
                    error={
                      formik.touched.password1 && Boolean(formik.errors.password1)
                    }>Contraseña</InputLabel>
                  <OutlinedInput
                    fullWidth
                    autoComplete='none'

                    size="small"
                    id="password1"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password1}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password1 && Boolean(formik.errors.password1)
                    }
                    label="Contraseña"

                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff sx={{ color: 'text.secondary' }} /> : <Visibility sx={{ color: 'text.secondary' }} />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="outlined-weight-helper-text" sx={{ color: '#d7281c' }}>
                    {formik.touched.password1 ? formik.errors.password1 : ""}
                  </FormHelperText>
                </FormControl>


                {/*        <TextField
                fullWidth
                name="password2"
                values={formik.values.password2}
                onChange={formik.handleChange}
                error={
                  formik.touched.password2 && Boolean(formik.errors.password2)
                }
                label="Verificar contraseña"
                margin="dense"
                variant="outlined"
                autoComplete ='none'
                size="small"
                helperText={
                  formik.touched.password2 ? formik.errors.password2 : ""
                }
              /> */}
                <FormControl fullWidth size="small" sx={{ margin: "8px 0" }} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-password"
                    error={
                      formik.touched.password2 && Boolean(formik.errors.password2)
                    }>Verificar contraseña</InputLabel>
                  <OutlinedInput
                    fullWidth
                    autoComplete='none'

                    size="small"
                    id="password2"
                    type={showPassword2 ? 'text' : 'password'}
                    value={formik.values.password2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password2 && Boolean(formik.errors.password2)
                    }
                    label="Verificar contraseña"

                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword2(!showPassword2)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff sx={{ color: 'text.secondary' }} /> : <Visibility sx={{ color: 'text.secondary' }} />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="outlined-weight-helper-text" sx={{ color: '#d7281c' }}>
                    {formik.touched.password2 ? formik.errors.password2 : ""}
                  </FormHelperText>
                </FormControl>

              </Grid>


              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ marginTop: "15px" }}
                >
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    Agregar Usuario
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </BasicModal>
    </>
  );
};


export default AddUsers;
