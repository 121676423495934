
import React from 'react'
import { CircularProgress, Box, Grid, Button, TableCell, TableRow, Switch, Typography } from '@mui/material';
import Container from '../../layout/gridContainer';
import { useTableFilters } from '../../hooks/searchParams';
import { useState } from 'react';
import PageParam from '../../components/PageParam';
import GenericEmptyTable from '../../components/GenericEmptyTable';
import CardHeader from "@mui/material/CardHeader";
import CircleIcon from '@mui/icons-material/Circle';
import { CustomTable } from '../../components/GenericTable';
import { ModalRight } from '../../components/GenericsModals';
import { useAuth } from "user-context-fran-dev";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect } from 'react';
import Products from '../../api/productsManages';
import ImageSize from '../../components/ImageSize';


const StoreProducts = ({ view }) => {
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [data, setData] = useState("")
  /*     let filter = [];
      filter.push({ key: "status_exclude", value: `1-2` }); */
  const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] = useTableFilters(Products, setTable, setLoading)



  return (
    <Container>
      {data && <ModalEdit setOpen={setOpen} open={open} data={data} update={UdateTable} view={view} />}

      <Grid container >
        <Grid container justifyContent='space-between' alignItems='center' mb="16px">
          <Grid item xs={12} sm={8} >
            <Grid container fontWeight="bold">
              {view === "store" ? <Grid item xs={12} sm={8} md={8} lg={8}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  className="title-card"
                  sx={{ paddingBottom: '2%' }}
                >
                  <strong>Productos de venta en línea</strong>
                </Typography>
              </Grid> :
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    className="title-card"
                    sx={{ paddingBottom: '2%' }}
                  >
                    <strong>Productos</strong>
                  </Typography>
                </Grid>}
            </Grid>
          </Grid>
          <Grid item  >

          </Grid>
        </Grid>

      </Grid>


      {table && <> <CustomTable titles={[
        { title: "#" },
        { title: "Nombre / SKU" },
        { title: "Estatus", align: "center" },
        { title: (view === "store") ? "Mostrar en la tienda" : "Mostrar en POS", align: "center" },
        { title: "Categoría", align: "left" },
        { title: "Más", align: "left" },
      ]}  >

        {table.data.map((data, index) => (

          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            hover
          >
            <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Typography variant="subtitle2" component="div" sx={{ minWidth: "20px", maxWidth: "18vw", fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {index + 1}
                </Typography>



              </Typography>
            </TableCell>

            <TableCell sx={{ maxWidth: '250px', m: 0, p: 0 }} align="left">
              <Typography variant="subtitle2" component="div" sx={{ minWidth: "20px", maxWidth: "100%", fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CardHeader
                  sx={{ m: 0, p: 0, marginLeft: "16px" }}
                  title={
                    <Typography variant="subtitle1" sx={{ m: 0, p: 0 }}>
                      {data.name}
                    </Typography>}
                  subheader={
                    data.sku

                  }
                />
              </Typography>

            </TableCell>
            <TableCell align="center" >
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {(data.is_active) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
                  <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}
              </Typography>
            </TableCell>
            <TableCell align="center" >
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {view === "store" ? (data.display_on_store) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
                  <CircleIcon sx={{ color: 'red', fontSize: 15 }} />
                  :
                  (data.display_on_pos) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
                    <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}



              </Typography>
            </TableCell>
            <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.category.name}



              </Typography>
            </TableCell>
            <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "10vw" }}>
              <Button onClick={() => { setData(data); setOpen(true); }}>
                Editar
              </Button>
            </TableCell>

          </TableRow>


        ))}


      </CustomTable>
        <PageParam totalpages={table.pagination} params={params} handleChange={handleSearch} />
      </>
      }


      {loading && !table && (
        <Grid container justifyContent="center" mt={5}>
          <CircularProgress />
        </Grid>
      )}
      {!table && !loading &&
        <GenericEmptyTable />
      }

    </Container>
  );
}

const ModalEdit = ({ setOpen, open, data, update, view }) => {
  const { logout, auth } = useAuth();
  const [loading, setLoading] = useState(false)
  const [check, setCheck] = useState(

  )

  const handleChange = async (event) => {
    setLoading(true)

    console.log(event.target.name + ":" + event.target.checked)
    setCheck({ ...check, [event.target.name]: event.target.checked })

    const setProduct = new Products()
    const response = await setProduct.editDataAuth(data.id, { [event.target.name]: event.target.checked }, logout);


    update()
    setLoading(false)


  }
  /*   useEffect(()=>{
      setCheckOnline(data.sell_products_online)
    },[data])
   */

  useEffect(() => {
    if (view === "store") {
      setCheck({
        display_on_store: data.display_on_store,
        outstanding: data.outstanding,
        show_in_gallery: data.show_in_gallery,
        discount_store: data.discount_store

      })
    } else {
      setCheck({
        display_on_pos: data.display_on_pos,
        outstanding: data.outstanding,
        show_in_gallery: data.show_in_gallery,
        discount_store: data.discount_store

      })
    }

  }, [data])


  return (
    <ModalRight setOpen={setOpen} open={open} title="Editar Artículo" >
      <Box sx={{ display: "flex" }}>
        <ImageSize img={data?.main_model?.image?.url ? data?.main_model?.image.url : ""} weight={2} />
        <Typography variant="h6" sx={{ margin: "8px 16px" }}>
          {data.name}
          <Typography variant="body1" >
            {data.sku}
          </Typography>
        </Typography>

      </Box>
      {check && <>
        <Grid container mt="32px" /* sx={{height: "100%", alignContent: "center"}} */ >
          {view === "store" ? <FormControlLabel control={
            <Switch
              name='display_on_store'
              disabled={loading}
              checked={check.display_on_store}
              onChange={(event) => handleChange(event)}
              inputProps={{ "aria-label": "controlled" }}
            />} label={<>Permitir <strong>Venta en línea</strong></>} />
            :
            <FormControlLabel control={
              <Switch
                name='display_on_pos'
                disabled={loading}
                checked={check.display_on_pos}
                onChange={(event) => handleChange(event)}
                inputProps={{ "aria-label": "controlled" }}
              />} label={<>Permitir <strong>Venta en POS</strong></>} />
          }
          <FormControlLabel sx={{ marginTop: "16px" }} control={
            <Switch
              name='outstanding'
              disabled={loading}
              checked={check.outstanding}
              onChange={(event) => handleChange(event)}
              inputProps={{ "aria-label": "controlled" }}
            />} label={<>Mostrar en la sección de <strong>Nuevos lanzamientos</strong></>} />
          <FormControlLabel sx={{ marginTop: "16px" }} control={
            <Switch
              name='show_in_gallery'
              disabled={loading}
              checked={check.show_in_gallery}
              onChange={(event) => handleChange(event)}
              inputProps={{ "aria-label": "controlled" }}
            />} label={<>Mostrar en la sección de <strong>Galería</strong></>} />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Typography variant="h7">Descuento {data.discount}%</Typography>

        </Grid>
        <Grid sx={{ mt: 3 }}>
          <FormControlLabel control={
            <Switch
              name='discount_store'
              disabled={loading}
              checked={check.discount_store}
              onChange={(event) => handleChange(event)}
              inputProps={{ "aria-label": "controlled" }}
            />
          } label={'Aplicar descuento'} />
        </Grid>
      </>}
    </ModalRight>
  );
}



export default StoreProducts;