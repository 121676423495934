import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';


const CountWords = (props) => {
    const {length, max} = props;
    return ( 
        <Grid container justifyContent="flex-end">
        <Typography variant="caption" display="block" color="text.secondary">
            {`${length}/${max}`}
            </Typography>
      </Grid>
     );
}
 
export default CountWords;

CountWords.propTypes ={
    length: PropTypes.number.isRequired, 
    max: PropTypes.string.isRequired
}

CountWords.defaultProps = {
    max: "50",
}