import React, { useEffect, useState } from 'react'
import { Grid, Typography, TextField, Link, Paper  } from "@mui/material";
import GenericTable from '../../GenericTable'
import {  IconButton, CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const TransferReceiveList = (props) => {

  const { data, setOpenModal, setModalInfo } = props

  const [open, setOpen] = useState(false);

  console.log(data)

  return (
    
   <div className="articles-table">
      <GenericTable titles={["Nombre / #parte ", "Modelo / Presentación",  "Cantidad", "Pendiente entregar", "Pendiente recibir", "Acción"]} >
      {data.articles.map((row, index) => (

        /* row.quantity_pending != 0 && */
          
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          hover

        >
        <TableCell align="left" sx={{maxWidth: '100'}}> 
          <CardHeader
          sx={{m:0, p:0}}
            title={
              <Typography variant="subtitle2" component="div">
                {row.available.model.product.name}
              </Typography>}
            subheader={row.available.model.product.sku}
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '100px'}}> 
          <CardHeader
          sx={{m:0, p:0}}
            title={
              <Typography variant="subtitle2" component="div">
                {row.available.model.product.name}
              </Typography>}
            subheader={row.available.presentation.name}
          />
        </TableCell>

        <TableCell align="left" sx={{maxWidth: '100px'}}> 


          <Typography variant="subtitle1" component="div">
            {row.quantity}
          </Typography>
      
         
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '100px'}}> 
          <CardHeader
            title={
              <Typography variant="subtitle1" component="div">
                {row.pending_deliver}
              </Typography>}
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '100px'}}> 
          <CardHeader
            title={
              <Typography variant="subtitle1" component="div">
                {row.pending_receive}
              </Typography>}
          />
        </TableCell>
        <TableCell align="left" sx={{maxWidth: '150px'}}> 
        { row.pending_deliver > 0 && 
          <CardHeader
          
            title={
                <Link
                sx={{ cursor:'pointer' }}
                underline="none"
                onClick={() => {
                    setOpenModal(true)
                    setModalInfo(row)
                }}>
              <Typography variant="subtitle2" component="div">
                Entregar
              </Typography>
              </Link>
              }
          />
            }
           { row.pending_receive > 0 && row.pending_deliver == 0 && 
          <CardHeader
          
          title={
            <Link
            sx={{ cursor:'pointer' }}
            underline="none"
            onClick={() => {
                setOpenModal(true)
                setModalInfo(row)
            }}>
          <Typography variant="subtitle2" component="div">
            Recibir
          </Typography>
          </Link>
          }
          
          />
            }
            { row.pending_receive == 0 && row.pending_deliver == 0 && 
          <CardHeader
          
          title={
           
          <Typography variant="subtitle2" component="div">
            Transferido
          </Typography>
         
          }
          
          />
        }
        </TableCell>
        </TableRow>
        
      
      ))}
      </GenericTable>

    </div>
  )
}

export default TransferReceiveList;