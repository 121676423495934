import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export class CatalogService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'catalog/product';
      super(baseUrl, endPoint)
    }
}

export class CatalogPosService extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/available';
    super(baseUrl, endPoint)
  }
}

export class PresentationService extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/available/presentation';
    super(baseUrl, endPoint)
  }
}

export class PresentationPos extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/pos_item';
    super(baseUrl, endPoint)
  }
}