import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export default class inventorySettings extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/settings';
      super(baseUrl, endPoint)
    }
}