import React from "react";
import { Button, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";


const HeaderGeneric = (props) => {
  const {
    //primer header
    leftTitle, //titulo del header izquierdo
    cancelButton, //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
    titleButton, // el boton a lado derecho de cancelar este solo es para el titulo
    handleButton, //la funcion del boton
    //segundo header
    rightTitle,
    name,
    noOrder,
    date,
    general,
    supplier,
    print,
    ...extraProp
  } = props;

  console.log('extra prop', extraProp.transfer)

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {leftTitle &&
              <Chip label={leftTitle.status} color={leftTitle.color} className={leftTitle?.with ? leftTitle.with : "chip-with"} variant="outlined" />
            }
            {print &&
              <Link
                style={{ textDecoration: "none", marginLeft: "32px", display: print.disable }}
                to={print.to}
                target={"_blank"}
              >
                <Button variant="outlined" size="small" disableElevation>
                  <PictureAsPdfIcon sx={{ mr: "10px" }} />
                  ver PDF
                </Button>
              </Link>}
          </Grid>

          <Grid item xs={12} sm={5}>
            {cancelButton && (
              <CancelButton handleClick={cancelButton} data={noOrder} />
            )}
          </Grid>

          <Grid item xs={12} sm={5}>
            {handleButton && titleButton && (
              <MainButton
                text={titleButton.tittle}
                disable={titleButton}
                handleClick={handleButton}
                data={noOrder}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={12} >
            <Grid container justifyContent="flex-end">
              {rightTitle && <RightTitle text={rightTitle} />}
            </Grid>
            <Grid container justifyContent="flex-end">
              {name && <Name text={name} />}
            </Grid>
            <Grid container>
              {noOrder && (
                <>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        {noOrder?.no_order && 'Núm. de orden:'}
                        {noOrder?.reference && 'Referencia:'}
                        {noOrder?.justification && 'Justificación:'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container justifyContent="flex-end">
                      <PlainText
                        text={noOrder?.no_order ? noOrder.no_order : (noOrder.justification ? noOrder.justification : noOrder.reference)} />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>


            <Grid container>
              {supplier && (
                <>
                  <Grid item xs={3} sm={4} md={6}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        {supplier?.rfc && 'RFC:'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} sm={8} md={6}>
                    <Grid container justifyContent="flex-end">
                      <PlainText text={supplier?.rfc} />

                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={4} md={6}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        {supplier?.phone && 'Teléfono:'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} sm={8} md={6}>
                    <Grid container justifyContent="flex-end">
                      <PlainText text={supplier?.phone} />

                    </Grid>
                  </Grid>
                  <Grid item xs={3} sm={4} md={6}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        {supplier?.email && 'Email:'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} sm={8} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid container justifyContent="flex-end" sx={{ maxWidth: "95%" }}>

                      <Typography variant="h7" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {supplier?.email}
                      </Typography>

                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container>
              {date && (
                <>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        Fecha:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      <Dia text={date} />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container >
              {general && (
                <>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        Almacén:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      <PlainText text={general} />
                    </Grid>
                  </Grid>
                </>
              )}
              {extraProp.transfer && (
                <>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      {/* <WestIcon disabled={true} className="arrows"/> */}
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        Tipo de transferencia:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      <PlainText text={extraProp.transfer.type === 1 ? 'Inmediata' : 'Con confirmación'} />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <WestIcon disabled={true} className="arrows" />
                      <Typography variant="h7" component="div" sx={{ textAlign: "right", ml: "6.37px" }}>
                        {/* Almacén */}   Origen:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      <PlainText text={extraProp.transfer.warehouse_source.name} />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <EastIcon disabled={true} className="arrows" />
                      <Typography variant="h7" component="div" sx={{ textAlign: "right" }}>
                        {/* Almacén */} Destino:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      <PlainText text={extraProp.transfer.warehouse_destiny.name} />
                    </Grid>
                  </Grid>
                </>
              )}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LeftHeader = ({ text }) => {
  return (
    <Typography
      variant="h6"
      sx={{ color: "text.secondary" }}
      gutterBottom
      component="div"
    >
      {text}
    </Typography>
  );
};

const CancelButton = ({ handleClick, data }) => {

  return (
    <LoadingButton
      loading={handleClick.loading}
      onClick={async () => {

        handleClick.handleClick()

        /*     setTimeout(()=>{
              setLoading(false)
          }, 1000); */


      }}
      disabled={data.status === 4 || data.status === 3 || data.status === 5 || handleClick.enable}
      sx={{
        bgcolor: "text.secondary" /* '&: hover':{ bgcolor: 'text.dark' } */,
      }}
      fullWidth
      variant="contained"
    >

      Cancelar
    </LoadingButton>
  );
};

const MainButton = ({ text, handleClick, data, disable }) => {
  console.log('data en boton', disable.enable)
  return (
    <LoadingButton
      loading={handleClick.loading}
      onClick={() => {

        handleClick.handleClick()

        /*   setTimeout(()=>{
            setLoading(false)
        }, 1000); */


      }}
      disabled={data.status !== 1 || !disable.enable}
      fullWidth
      variant="contained"
    >
      {" "}
      {text}
    </LoadingButton>
  );
};

const RightTitle = ({ text }) => {
  return (
    <Typography variant="h5" component="div">
      <strong>{text}</strong>
    </Typography>
  );
};

const Name = ({ text }) => {
  return (
    <Typography
      variant="h7"
      sx={{ color: "text.green" }}
      gutterBottom
      component="div"
    >
      <strong>{text}</strong>
    </Typography>
  );
};

const Dia = ({ text }) => {
  console.log('fecha en warehouse', text)
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  return (
    <Typography variant="h7" component="div">
      {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
    </Typography>
  );
};

const PlainText = ({ text }) => {
  return (
    <Typography variant="h7" component="div">
      {text}
    </Typography>
  );
};

export default HeaderGeneric;
