import React from "react";
import { Grid } from "@mui/material";


const Container = ({children}) => {
    return ( 
        <Grid container justifyContent="center" >
            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}  sx={{padding: "16px 16px 0 16px", minHeight: "calc(100vh - 128px)"}}>
                {children}
            </Grid>
        </Grid> 
     );
} 

 
export default Container;