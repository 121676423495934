import { Chip, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import BasicModal from "../../../components/GenericModal";
import { CashOrder } from "../../../api/posAdmin/CashRegisterManages";
import { useAuth } from "user-context-fran-dev";
import BasicTable from "../../../components/GenericTable";
import { TableCell, TableRow } from "@mui/material";
import { Dia } from "./cashRegisterDetail";
import LoadingButton from "@mui/lab/LoadingButton";
import { STATUSPOSORDER } from "../../../utils/constants";
import { formatMoney } from "../../../utils/functions";
import CustomizedDialogs from "../../../components/GenericsModals";

const OrderModal = ({ open, setOpen, data }) => {
  const [order, setOrder] = useState("");
  const { logout, auth } = useAuth();
  const [cashMoney, setCashMoney] = useState("");
  const [carMoney, setCarMoney] = useState("");
  /* const cashMoney =  data && data.pay_split.find((e)=> e.type == 1); */


  console.log("row", data);

  const GetRegisterCash = () => {
    (async () => {
      let getRegister = new CashOrder();
      let response = await getRegister.getAllByIdAuth(data.id, logout);
      setOrder(response.data);
      setCashMoney(response.data?.pay_split.find((e)=> e.type == 1))
      setCarMoney(response.data?.pay_split.find((e)=> e.type == 2))
      /* console.log(response.data.pay_split.find((e)=> e.type == 2)) */
      /* console.log("modal", response.data);  */
    })();
  };
 
  useEffect(() => {
    GetRegisterCash();

    /*   return ()=>{
            
            } */
  }, [data]);

  return (
    <>
      <BasicModal open={open} setOpen={setOpen} title={""}>
        <Grid container mb="16px">
        <Chip label={STATUSPOSORDER[data.status]} variant="outlined" sx={{width: "100px"}} />
        </Grid>
        <Grid container>
          <Grid item xs={6} >
            <Grid container justifyContent="flex-start" flexDirection="column">
            <Typography
                variant="h7"
                gutterBottom
                component="div"
              >
                Ticket: {data.id}
              </Typography>
               
              <Typography
                variant="h7"
                gutterBottom
                component="div"
              >
                Fecha: <Dia text={data.date_created} />
              </Typography>
     
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{}}>
   
       
                <Grid container justifyContent="flex-start" flexDirection="column">
                <Typography
                variant="h7"
                gutterBottom
                component="div"
              >
                  Cajero: {order && (<> {order.user.first_name} </>)}
              </Typography>
              <Typography
                variant="h7"
                gutterBottom
                component="div"
              >
                Hora: <TimeFormat text={data.date_created} />
              </Typography>
                
      
         
     
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt="16px" sx={{maxHeight:"50vh", overflow: "auto"}}>
          {order && (
            <BasicTable titles={["Cant.", "Artículo", "Precio", "Total"]}>
              {order.items.map((row, index) => (
                <Row row={row} key={index} />
              ))}
            </BasicTable>
          )}
        </Grid>
       { order && <Grid item xs={12} className="total-view" mt="16px">
        
          <Grid item xs={12}>
            <Grid item xs={12} className="display-total-items">
              <div>
                <h4>Subtotal:</h4>
              </div>
              <div className="quantity-total-view">
                <h4>{formatMoney(order.subtotal)}</h4>
              </div>
            </Grid>
            <Grid item xs className="display-total-items">
              <div className="rfc-data">
                <h4>IVA :</h4>
              </div>
              <div className="quantity-total-view">
                <h4>{formatMoney( order.iva)}</h4>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} className="display-total-items">
              <div className="rfc-data">
                <h4>Total :</h4>
              </div>
              <div className="quantity-total-view">
                <h4>{formatMoney(order.total)}</h4>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} className="display-total-items">
              <div className="rfc-data">
                <h4>(Efectivo) :</h4>
              </div>
              <div className="quantity-total-view">
                {cashMoney && <h4>{formatMoney(cashMoney.amount)}</h4>}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} className="display-total-items">
              <div className="rfc-data">
                <h4>(Tarjeta) :</h4>
              </div>
              <div className="quantity-total-view">
               {carMoney && <h4>{formatMoney(carMoney.amount) }</h4>}
              </div>
            </Grid>
          </Grid>
        </Grid>}
       
      {/*   <Grid container justifyContent={"flex-end"} mt={1}>
            <Grid item         xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={3}>
            <LoadingButton
                loading={false}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ color: "#fff" }}
                    >
                        Imprimir ticket 
                    </LoadingButton>
            </Grid>
        </Grid> */}
      </BasicModal>
    </>
  );
};

const Row = ({ row }) => {
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
        onDoubleClick={() => {}}
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
            {row.qty}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.model.product.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formatMoney(row.presentation.price)}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formatMoney(row.total)}
          </Typography>
        </TableCell>

        {/* <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          {row.subtotal}
        </TableCell> */}
      </TableRow>
    </>
  );
};

const TimeFormat = ({ text }) => {
  const dia = new Date(text);
/* console.log(dia.toISOString().slice(14,16))
console.log(dia.getMinutes()<10? "0"+ dia.getMinutes() :dia.getMinutes()) */

  return (
  <>
  {` ${dia.getHours()}:${dia.toISOString().slice(14,16)}`}
  </>);
};

export default OrderModal;
