import React from "react";
import { Typography } from "@mui/material";

export const DateFormat = ({beginDate}) => { 
    const Month = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril", 
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const dia = new Date(beginDate);
    return (
      <>
        {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
      </>
    );
  };

  export const DateFormat2 = ({beginDate}) => {

    const dia = new Date(beginDate);
    let month = (dia.getMonth()+1) <10 ? "0"+(dia.getMonth()+1): (dia.getMonth()+1);

    return (
      <>
        {` ${dia.getDate()}-${month}-${dia.getFullYear()}`}
      </>
    );
  };
  