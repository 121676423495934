import React, {useState} from "react";
import { CircularProgress, Grid } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import {Button} from "@mui/material";
import ScrapService from "../../../../api/inventory/scrapService"
import AddScrap from './addScrap'
import ScrapTable from './scrapTable'
import GenericEmptyTable from '../../../../components/GenericEmptyTable'
import BasicTable from "../../../../components/GenericTable";
import { useTableFilters } from "../../../../hooks/searchParams";
import PageParam from "../../../../components/PageParam";

const  Transfers = () => {

const [openModal, setOpenModal] = useState();
const [totalpages, setTotalPages] = useState();
const [table, setTable] = useState();
const [loading, setLoading] = useState(false)
const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] = useTableFilters( ScrapService, setTable, setLoading, "", "tab" )

return (
<Container>
    <Grid item xs={12} spacing={2}>
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
             <h2>Desecho</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container  justifyContent="flex-end">
                    <Grid item xs={12} sm={6}>
                        <Button 
                        onClick={()=> setOpenModal(true)}
                        fullWidth 
                        variant="contained"
                        >Crear</Button>
                       <AddScrap openModal={openModal} UpdateScrap={UdateTable} setOpenModal={setOpenModal}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    <Grid item xs={12} className="margin-scrap-table">
        {table?.data ? (
          <>
            <BasicTable className="marginTable" titles={["Justificación", "Fecha", "Almacén", "Estado", "Productos"]} >
              {table.data.map((data, index) => (
                <ScrapTable  key={index} data={data}/> 
              ))}
            </BasicTable>
            <div className='margin'>
          <PageParam  totalpages={table.pagination} params={params} handleChange={handleSearch} />
            </div>
           </>
        ) :
        (
          <div className="model-null">
          {loading && <CircularProgress />}
          {!table && !loading &&
                <GenericEmptyTable msg={"No hay datos en la tabla"}/>
          }
        </div>
      
        )}
       
    </Grid>
</Container>
    )
}

export default Transfers

