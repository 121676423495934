import React from 'react';
/* import ReactDOM from 'react-dom'; */
import './scss/index.css';
import App from './app';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

root.render(<App/>);

/* ReactDOM.render(
    <App />,
  document.getElementById('root')
);
 */
