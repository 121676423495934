/* Botones de alineado del contenido en general donde queremos que este*/
import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Divider,
    TextField
} from "@mui/material";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


function ToggleButtons({ handleAlignment, alignment, handleTitle, title, data }) {
    console.log(data)
    console.log((data.type))

    const alignText = {
        left: "Izquierda",
        center: "Centro",
        right: "Derecha"
    };

    return (
        <>
            <Divider
                sx={{ backgroundColor: "text.secondary" }}
            />
            <Box sx={{ margin: "12px 0" }}>
                <Typography variant="body1" component="div">
                    <strong>Alineación </strong>
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "12px 0" }}>

                <Typography variant="body1" component="div">
                    {alignText[alignment]}
                </Typography>

                <ToggleButtonGroup
                    name="justify_content"
                    value={alignment}
                    size="small"
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                >
                    <ToggleButton name="justify_content" value="left" aria-label="left aligned">
                        <FormatAlignLeftIcon />
                    </ToggleButton>
                    <ToggleButton name="justify_content" value="center" aria-label="centered">
                        <FormatAlignCenterIcon />
                    </ToggleButton>
                    <ToggleButton name="justify_content" value="right" aria-label="right aligned">
                        <FormatAlignRightIcon />
                    </ToggleButton>

                </ToggleButtonGroup>
            </Box>
        </>
    );
}

export default ToggleButtons;