import React from "react";
import Branch from './business/Branch';
import Container from '../../layout/gridContainer';

const AdminBranch = () => {
    return ( 
    <Container>
        <Branch />
    </Container> 
    );
}
 
export default AdminBranch;