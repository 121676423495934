
import { 
   red, 
   } from '@mui/material/colors';
   import {  createTheme } from '@mui/material/styles';


  export const  lightTheme  = createTheme({
    palette:{
      primary: {
        main: '#642BA0',  /* #1f96f2  new  #642BA0 */
        light: '#c36565',
      },
      secondary: {
        main:   "#00ABC7",
      },
      text:{
        primary:'#000',
        secondary: 'rgba(0, 0, 0, 0.54)',
        green: '#66bb6a',
        /* dark: 'rgba(50, 50, 50, 0.54)' */
        
      },
      background:{
        header: '#E8e8e7',
        tabs: "rgba(0, 0, 0, 0.14)",
        footer: "#E8e8e7"
      },
      
    }
  })
  export const  darkTheme = createTheme({
    palette: {
      // primary: {
      //   main: '#642BA0',  /* #1f96f2 */
      //   light: '#c36565',
      // },
      // secondary: {
      //   main: '#00ABC7',
      // },
      // background:{
      //   default: '#303030', 
      //   paper: '#424242',
      //   header: '#424242', 
      //   tabs: "#424242",
      //   footer: "#303030"
      // },
      // text:{
      //   primary: '#fff',
      //   secondary: 'rgba(255, 255, 255, 0.5)',
      //   green: '#388e3c'
      //   /* dark: 'rgba(220, 220, 220, 0.5)' */
      // },
     
      // info: {
      //   main: '#2196f3', 
      // }
    }
  })
  
/*   export const  darkTheme = createTheme({
    palette:{
      primary: {
        main: '#1f96f2',
        light: 'rgb(75, 171, 244)' ,
        dark: 'rgb(21, 105, 169)' ,
        contrastText: '#fff',
      },
      secondary: orange,
      background:{
        default: '#303030',
        paper: '#424242'
      },
      text:{
        primary: '#fff',
        secondary: 'rgba(255, 255, 255, 0.5)',
      }
  }
  }) */


/*   primary: {
    main: '#1f96f2',
    light: 'rgb(75, 171, 244)' ,
    dark: 'rgb(21, 105, 169)' ,
    contrastText: '#fff',
  },
  secondary: orange,
  background:{
    default: '#303030',
    paper: '#424242'
  },
  text:{
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.5)',
    

  }, */


  /* 
  
  #642BA0
  #00ABC7
  
  */




