import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TableInfo from './tableInfo';
import { useAuth } from 'user-context-fran-dev';
import PurchaseService from '../../../api/purchaseService';
import { STATUS, STATUSCOLOR } from '../../../utils/constants'
import GenericEmptyTable from '../../../components/GenericEmptyTable'
import CurrencyFormat from 'react-currency-format';
import { DateFormat } from '../../../components/DateFormat'
import Chip from '@mui/material/Chip';


const PurchasesTable = (props) => {
  const { data } = props
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const [dataItems, setDataItems] = useState();
  const beginDate = data.date_created
  const [status, setStatus] = useState('')




  const UpdatePurchaseItems = () => {
    (async () => {
      let getPurchases = new PurchaseService();
      let response = await getPurchases.getAllByIdAuth(data.id, login);
      console.log('purchase items', response.data)
      setOpen(!open)
      setDataItems(response.data)
    })();
  };

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
        onDoubleClick={() => {
          window.localStorage.setItem("menu", 11);
          navigate(`detail/${data.id}`);
        }}
        style={{ height: 10 }}
      >
        <TableCell align="left" sx={{ minWidth: "20px" }}>
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.no_order}
              </Typography>}
            subheader={DateFormat({ beginDate })}
          />
        </TableCell>
        <TableCell sx={{ maxWidth: '150px' }} align="left">
          <CardHeader
            onMouseOver={() => console.log("Entre")}
            onClick={() => console.log("click")}

            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle1" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.supplier ? data.supplier.business_name : ''}
              </Typography>}
            subheader={data.warehouse.name}
          />
        </TableCell>
        {/*ESTE ES PARA EL TIPO DE ORDEN */}
        <TableCell sx={{ maxWidth: '150px' }} align="left">
          <CardHeader
            onMouseOver={() => console.log("Entre")}
            onClick={() => console.log("click")}

            sx={{ m: 0, p: 0 }}
            //title={ }
            subheader={data.type == 1 ? 'Compra' : 'Cotización'}
          />
        </TableCell>

        <TableCell align="left">
          <Chip label={STATUS[data.status]} color={STATUSCOLOR[data.status]} className="chip-with" variant="outlined" />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography variant="subtitle1" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CurrencyFormat value={data.total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              </Typography>}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            disable=""
            onClick={() => { UpdatePurchaseItems() }}

          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {dataItems ?
                (<TableInfo data={dataItems} />) : (<GenericEmptyTable msg={"No hay Artículos en la orden"} />)
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default PurchasesTable;