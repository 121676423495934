import { Alert, Button, CardHeader, Grid, InputAdornment, Menu, MenuItem, Paper, TableCell, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicTable from '../../../components/GenericTable'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BasicModal from '../../../components/GenericModal';
import ImageSize from '../../../components/ImageSize';
import GenericTable from "../../../components/GenericTable"
import { BottonModalDiag, ModalRight } from '../../../components/GenericsModals';
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValStoreQuote, valSchStoreQuote } from '../../../utils/formHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import { StoreGuide, StoreQuotation } from '../../../api/store/storeOrders';
import { UseerrorOrsuccess, useNotification } from "../../../hooks/snackBarResponse"
import { useAuth } from "user-context-fran-dev";
import nueveMinutos from "../../../assets/shippingCompanyIcons/99MINUTOS.png"
import ampm from "../../../assets/shippingCompanyIcons/AMPM.png"
import defaultImg from "../../../assets/shippingCompanyIcons/DEFAULT.png"
import JTEXPRESS from "../../../assets/shippingCompanyIcons/JTEXPRESS.png"
import DHL from "../../../assets/shippingCompanyIcons/DHL.png"
import ESTAFETA from "../../../assets/shippingCompanyIcons/ESTAFETA.png"
import FEDEX from "../../../assets/shippingCompanyIcons/FEDEX.png"
import FLECHAAMARILLA from "../../../assets/shippingCompanyIcons/FLECHA_AMARILLA.png"
import IVOY from "../../../assets/shippingCompanyIcons/IVOY.png"
import PAQUETEEXPRESS from "../../../assets/shippingCompanyIcons/PAQUETEEXPRESS.png"
import PILOT from "../../../assets/shippingCompanyIcons/PILOT.png"
import REDPACK from "../../../assets/shippingCompanyIcons/REDPACK.png"
import TRESGUERRAS from "../../../assets/shippingCompanyIcons/TRESGUERRAS.png"
import UPS from "../../../assets/shippingCompanyIcons/UPS.png"
import AddressManages from '../../../api/addressManages';
import { FormHelperText } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from 'notistack';
import { formatMoney } from '../../../utils/functions';
import { Link } from 'react-router-dom';



const serviceImages = (service) => {

  switch (service) {
    case "99MINUTOS":
      return nueveMinutos;
    /* break; */
    case "AMPM":
      return ampm;

    case "JTEXPRESS":
      return JTEXPRESS;


    case "DHL":
      return DHL;

    case "ESTAFETA":
      return ESTAFETA;

    case "FEDEX":
      return FEDEX;

    case "FLECHAAMARILLA":
      return FLECHAAMARILLA;

    case "IVOY":
      return IVOY;

    case "PAQUETEEXPRESS":
      return PAQUETEEXPRESS;

    case "PILOT":
      return PILOT;

    case "REDPACK":
      return REDPACK;

    case "TRESGUERRAS":
      return TRESGUERRAS;

    case "UPS":
      return UPS;

    default:
      return defaultImg;

  }

}


export const ButtonStatus = ({ userDetails, orderDetails, statusOrder, cancelOrder, NextStep, update }) => {
  //Estados para los modales
  const [availableB, setAvailableB] = useState(false);
  const [dataAv, setDataAv] = useState("")

  const [quote, setQuote] = useState(false)


  const [service, setService] = useState(false);
  const [dataService, setDataService] = useState("");

  //estados y funciones para otras cosas
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const [checked, setChecked] = useState();
  const [listGuia, setListGuia] = useState();

  const [loading, setLoading] = useState(false);

  const [missing, setMissing] = useState()

  /* const [originAddress, setOriginAddress] = useState(false); */
  const [openModal, setOpenModal] = useState(false)
  /*falta el handleOpenModal para abrir la modalright con los datos del pedido */
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeCheck = (event, data) => {
    /*  setChecked({...checked, [event.target.name]: {...data, check: event.target.checked}});
     console.log(checked) */
    setChecked({ ...checked, [event.target.name]: { ...data, check: event.target.checked } });

  };

  useEffect(() => {
    if (userDetails.status === 4) {
      let items = []
      userDetails?.cart?.cart_items.forEach((element) => {
        items.push({ ...element, check: true })
      })
      let list = userDetails?.cart?.cart_items.filter(x => x.status === 3);

      /* console.log("list lenght", list.length)
      console.log("all list lenght", userDetails?.cart?.cart_items.length) */

      if (list.length === userDetails?.cart?.cart_items.length) {  /* TODO: checar si funciona */
        setMissing(false)
      } else {
        setMissing(true)
      }

      /* console.log("list items:",items) */
      setChecked(items)
    }
  }, [userDetails])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  //Funciones para los modales
  const OpenModalAvaBranch = (data) => {
    setAvailableB(true)
    setDataAv(data)
  }
  const OpenModalQuote = (data) => {
    console.log(checked)
    let list = Object.values(checked).filter(x => x.check == true && x.status == 3)

    console.log(list)
    if (list != "") {
      setListGuia(list)
      setQuote(true)
      handleClose()
    } else {
      enqueueSnackbar("Faltan seleccionar artículos", {
        variant: "error",
        //autoHideDuration: 1000,
        //action
      });
    }


    /*   setDataQuote(data) */
  }
  const OpenService = (data, modalInfo, originAddress) => {
    setService(true)
    setDataService({ ...data, "modal_info": { ...modalInfo }, "originAddress": { ...originAddress } })
  }



  return (
    <>
      {userDetails.status == 3 &&

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={() => cancelOrder(setLoading)}>Cancelar</LoadingButton>
          <LoadingButton
            loading={loading} variant="contained" size="medium" onClick={() => NextStep({ status: 4 }, setLoading)}  >Confirmar</LoadingButton>
        </Grid>
      }


      {/*ESTATUS DE ENVIAR EL PEDIDO*/}

      {userDetails.status == 4 &&
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={() => cancelOrder(setLoading)}>
            Cancelar
          </LoadingButton>
          <div style={{ display: "inline-block" }}>
            <Button
              loading={loading}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              size="medium"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant="contained"
            >
              Enviar
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={OpenModalQuote}>Con guía en Envioclick</MenuItem>
              {/*<MenuItem onClick={handleClose}>Con mis guías</MenuItem>*/}

            </Menu>
          </div>
        </Grid>
      }

      {/*FALTA DECLARAR LA MODALDRAWER PARA MOSTRAR DENTRO ESTO */}
      {/*ESTATUS 7 PARA REEMBOLSAR*/}
      {userDetails.status === 7 && userDetails.cancel.receipt_no !== null &&
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} >
          <Button
            variant="contained"
            //onClick={handleClick}
            loading={loading}
            disableElevation
            sx={{ mr: '20px' }}
            onClick={() => cancelOrder(setLoading)}
            size="medium"
          >
            Reembolsar
          </Button>
        </Grid>
      }
    </>
  );
};


export const Order = ({ userDetails, orderDetails, statusOrder, cancelOrder, NextStep, update }) => {
  //Estados para los modales
  const [availableB, setAvailableB] = useState(false);
  const [dataAv, setDataAv] = useState("")

  const [quote, setQuote] = useState(false)


  const [service, setService] = useState(false);
  const [dataService, setDataService] = useState("");

  //estados y funciones para otras cosas
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const [checked, setChecked] = useState();
  const [listGuia, setListGuia] = useState();

  const [loading, setLoading] = useState(false);

  const [missing, setMissing] = useState()

  /* const [originAddress, setOriginAddress] = useState(false); */
  const [openModal, setOpenModal] = useState(false)
  /*falta el handleOpenModal para abrir la modalright con los datos del pedido */
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeCheck = (event, data) => {
    /*  setChecked({...checked, [event.target.name]: {...data, check: event.target.checked}});
     console.log(checked) */
    setChecked({ ...checked, [event.target.name]: { ...data, check: event.target.checked } });

  };

  useEffect(() => {
    if (userDetails.status === 4) {
      let items = []
      userDetails?.cart?.cart_items.forEach((element) => {
        items.push({ ...element, check: true })
      })
      let list = userDetails?.cart?.cart_items.filter(x => x.status === 3);

      /* console.log("list lenght", list.length)
      console.log("all list lenght", userDetails?.cart?.cart_items.length) */

      if (list.length === userDetails?.cart?.cart_items.length) {  /* TODO: checar si funciona */
        setMissing(false)
      } else {
        setMissing(true)
      }

      /* console.log("list items:",items) */
      setChecked(items)
    }
  }, [userDetails])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  //Funciones para los modales
  const OpenModalAvaBranch = (data) => {
    setAvailableB(true)
    setDataAv(data)
  }
  const OpenModalQuote = (data) => {
    console.log(checked)
    let list = Object.values(checked).filter(x => x.check == true && x.status == 3)

    console.log(list)
    if (list != "") {
      setListGuia(list)
      setQuote(true)
      handleClose()
    } else {
      enqueueSnackbar("Faltan seleccionar artículos", {
        variant: "error",
        //autoHideDuration: 1000,
        //action
      });
    }


    /*   setDataQuote(data) */
  }
  const OpenService = (data, modalInfo, originAddress) => {
    setService(true)
    setDataService({ ...data, "modal_info": { ...modalInfo }, "originAddress": { ...originAddress } })
  }



  return (



    <>
      <ModalAvailableBranch openModal={availableB} setOpenModal={setAvailableB} data={dataAv} />
      <ModalQuote openModal={quote} setOpenModal={setQuote} data={userDetails} OpenService={OpenService} />
      <ModalService openModal={service} setOpenModal={setService} data={dataService} orderData={userDetails} listGuia={listGuia} update={update} />


      <Grid container marginTop='30px'>

        <Grid item xs={12} sx={{ display: "flex", gap: "8px", justifyContent: "flex-start", flexWrap: "wrap" }}>
          {userDetails.status == 5 && <Link
            style={{
              textDecoration: "none"
            }}
            to={`/order?id=${userDetails.id}&order_no=${userDetails.cart.id}&type=order_store`
            }
            target={"_blank"}
          >
            <Button variant="outlined" size="medium" disableElevation><PictureAsPdfIcon sx={{ mr: '10px' }} />ver orden</Button>
          </Link>}
          {userDetails.status == 5 && <a href={userDetails.shipping_guide[0].vendor_guide_url} target="_blank">
            <Button variant="outlined" size="medium" disableElevation><PictureAsPdfIcon sx={{ mr: '10px' }} />ver guía</Button>
          </a>}
        </Grid>


        {/* ESTOS ESTADOS ES LO MISMO QUE UTILIZO EN LA FUNCION CONST BUTTONSTATUS 

        {userDetails.status == 3 &&

          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={() => cancelOrder(setLoading)}>Cancelar</LoadingButton>
            <LoadingButton
              loading={loading} variant="contained" size="medium" onClick={() => NextStep({ status: 4 }, setLoading)}  >Confirmar</LoadingButton>
          </Grid>
        }


        {/*ESTATUS DE ENVIAR EL PEDIDO* /}

        {userDetails.status == 4 &&
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={loading} variant="outlined" size="medium" disableElevation sx={{ mr: '20px' }} onClick={() => cancelOrder(setLoading)}>
              Cancelar
            </LoadingButton>
            <div style={{ display: "inline-block" }}>
              <Button
                loading={loading}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                size="medium"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
              >
                Enviar
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={OpenModalQuote}>Con guía en Envioclick</MenuItem>
                {/*<MenuItem onClick={handleClose}>Con mis guías</MenuItem>* /}

              </Menu>
            </div>
          </Grid>
        }

        {/*FALTA DECLARAR LA MODALDRAWER PARA MOSTRAR DENTRO ESTO * /}
        {/*ESTATUS 7 PARA REEMBOLSAR* /}
        {userDetails.status === 7 && userDetails.cancel.receipt_no !== null &&
          <Grid item xs={3.8} sm={2} md={1.7} lg={1} xl={1} sx={{ display: "flex", justifyContent: "flex-end" }} >
            <Button
              variant="contained"
              //onClick={handleClick}
              loading={loading}
              disableElevation
              sx={{ mr: '20px' }}
              onClick={() => cancelOrder(setLoading)}
              size="medium"
            >
              Reembolsar
            </Button>
          </Grid>
        }

      */}




      </Grid>
      <Grid item xs={12} sx={{ margin: "16px 0" }}>
        {missing && <Alert sx={{ bgcolor: "error.alert", color: "text.primary" }} severity="error">{`No se cuenta con el inventario disponible en el  – revisa la materia prima faltante por receta!`}</Alert>}
      </Grid>

      <Grid container marginTop='33px' marginBottom='70px'>
        <Grid item xs={12} sm={6} sx={{ paddingBottom: '5%' }}>
          <Paper sx={{ padding: "8px 16px", backgroundColor: 'background.header', maxWidth: '95%' }}>
            <Typography variant='h6' fontWeight='bold'>Datos del Cliente</Typography>
          </Paper>
          <Typography variant='body2' marginTop='15px'>Nombre: <b>{userDetails.user?.first_name} {userDetails.user?.last_name} </b></Typography>
          <Typography variant='body2' marginTop='10px'>Email:<b> {userDetails.user?.email} </b></Typography>
          <Typography variant='body2' marginTop='10px'>Teléfono:<b> {userDetails.user?.phone} </b></Typography>

        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: "8px 16px", backgroundColor: 'background.header', maxWidth: '95%' }}>
            <Typography variant='h6' fontWeight='bold'> Dirección de envíos</Typography>
          </Paper>
          <Typography variant='body2' marginTop='15px'>{userDetails.delivery_address?.street} #{userDetails.delivery_address?.ext_number}</Typography>
          <Typography variant='body2' marginTop='10px'>{userDetails.delivery_address?.suburb}, {userDetails.delivery_address?.postal_code}</Typography>
          <Typography variant='body2' marginTop='10px'>{userDetails.delivery_address?.city}, {userDetails.delivery_address?.state}</Typography>
        </Grid>
        <Grid item xs={3} >
          {/*  <Typography variant='h6' fontWeight='bold'> Facturación </Typography> */}

        </Grid>
      </Grid>

      <Box sx={{ paddingBottom: '2%' }}>
        <Paper sx={{ padding: "8px 16px ", backgroundColor: 'background.header', maxWidth: '100%' }}>
          <Typography variant='h6' fontWeight='bold'> Artículos</Typography>
        </Paper>
      </Box>

      {userDetails?.cart?.cart_items && <BasicTable titles={
        userDetails.status == 3 ? [
          "Imagen",
          "Artículo / Modelo y presentación",
          "Cantidad",
          /*   "Inventario Disponible", */
          "Precio",
          "Importe",
          "Sucursal de envió"
        ] : userDetails.status == 4 ? [
          "Estado de envió",
          "Cantidad",
          "Articulo / Modelo y presentación",
          /*  "Inventario Disponible", */
          "Precio",
          "Importe",

        ] :
          [
            "Imagen",
            "Artículo / Modelo y presentación",
            "Cantidad",
            /*  "Inventario Disponible", */
            "Precio",
            "Importe",
            "Sucursal de envió"


          ]
      } >

        {orderDetails && userDetails?.cart?.cart_items.map((data, index) => {


          return (

            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              hover
            >
              {userDetails.status == 4 && checked && <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw", paddingLeft: "30px" }}>
                {checked[index].status == 4 && <Typography variant="subtitle2" component="div"> Enviado </Typography>}
                {checked[index].status == 3 && <Checkbox checked={checked[index].check} name={index} onChange={(e) => handleChangeCheck(e, checked[index])} />}

              </TableCell>}

              <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw", paddingLeft: "30px" }}>
                <img src={data?.model.image.url} alt={data?.model.name} style={{ maxWidth: '45px' }} />
              </TableCell>

              {/*ARTICULO / MODELO Y PRESENTACION */}
              <TableCell sx={{ maxWidth: '250px' }} align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <CardHeader
                    sx={{ maring: 0, padding: 0 }}
                    title={
                      <Typography variant="subtitle2" component="div">
                        {data.available?.model?.product?.name}
                        {/* {data.available?.model?.product?.name} */}
                      </Typography>}
                    subheader={<Typography variant="subtitle2" component="div">
                      {data?.model?.name}, {data.presentation.name}
                      {/* {data.available?.model?.name}, {data.presentation.name}    */}
                    </Typography>}
                  />
                  {/* {data.available?.model.product?.name}  / {data.available?.model?.name}, {data.presentation} */}
                </Typography>
              </TableCell>

              <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw", paddingLeft: "30px" }}>
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data?.quantity}
                </Typography>
              </TableCell>


              {/*            <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data?.available?.quantity} 
                </Typography>    
                </TableCell> */}
              <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  ${data.presentation.price}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {formatMoney(data.price)}
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {data.available.warehouse.name}
                </Typography>
              </TableCell>

              {userDetails.status == 3 && <TableCell align="center">
                <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Button onClick={() => OpenModalAvaBranch("dadd")}>Editar</Button>
                </Typography>
              </TableCell>}

            </TableRow>
          );

        })}


      </BasicTable>}

      <Grid container justifyContent='right' textAlign='right' marginTop='30px'>

        <Grid item xs={2} color='gray' >
          <Typography>Subtotal:</Typography>
          <Typography marginTop='10px'> Descuento:</Typography>
          <Typography marginTop='10px'>Envio:</Typography>
          <Typography marginTop='10px' fontWeight='bold'>Total:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{formatMoney(userDetails.sub_total)}</Typography>
          <Typography marginTop='10px'>{userDetails.discount}%</Typography>
          <Typography marginTop='10px'>{formatMoney(userDetails.shipping_cost)}</Typography>
          <Typography marginTop='10px'>{formatMoney(userDetails.total)}</Typography>
        </Grid>
      </Grid>

    </>



  )
}

const ModalAvailableBranch = ({ openModal, setOpenModal, data }) => {
  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title="Disponibilidad en almacén de otras sucursales" >
      <Grid container justifyContent="space-around"
        alignItems="center">
        <Grid item xs={9}>
          <Grid container>
            <ImageSize img={""} weight={2} />
            <Grid item ml={{ xs: "8px", md: "32px" }}>
              <h3>Name</h3>
              <h4>Model  </h4>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={3} >
          <Grid container justifyContent={"start"} alignItems={"end"} flexDirection="column" sx={{ height: "64px" }}>

            <h4> qty  </h4>
          </Grid>

        </Grid>
        <Grid item xs={12} mt={"32px"} /*  sx={{border: "2px solid red"}} */>
          <GenericTable titles={["Sucursal", "Disponible", "Opción 1", "Opción 2"]} >
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              hover
            >

              <TableCell align="left" /* sx={{maxWidth: '200px'}} */>
                <CardHeader
                  sx={{ padding: "0", margin: "0" }}
                  title={
                    <Typography variant="subtitle2" component="div">
                      Nombre
                    </Typography>}
                  subheader={"lfjlsfj"}
                />
              </TableCell>
              <TableCell align="left" /* sx={{maxWidth: '200px'}} */>
                100
              </TableCell>
              <TableCell align="left" /* sx={{maxWidth: '200px'}} */>
                <Button>Cambiar</Button>
              </TableCell>
              <TableCell align="left" /* sx={{maxWidth: '200px'}} */>
                <Button>Cambio manual</Button>
              </TableCell>

            </TableRow>
          </GenericTable>
        </Grid>

      </Grid>
    </BasicModal>
  );
}

const ModalQuote = ({ openModal, setOpenModal, data, OpenService }) => {
  const [loading, setLoading] = useState(false)
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();
  const [address, setAddress] = useState("")
  const [origin, setOrigin] = useState("")
  const [error, setError] = useState("")

  const formik = useFormik({
    initialValues: initValStoreQuote(data),
    /* enableReinitialize: true, */
    validationSchema: Yup.object(valSchStoreQuote()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      setError("")
      if (!origin) {
        setError("Este campo es requerido")
        setLoading(false);
        return;
      }
      if (formData.contentValue > 100000) {
        formData.contentValue = "99999";
      }
      let originAddress = address.find((e) => e.id == origin)
      console.log(originAddress)

      let formSent = {
        origin_address: originAddress?.street || "",
        origin_number: originAddress?.ext_number || "",
        origin_zip_code: originAddress?.postal_code || "",

        destination_address: data?.delivery_address?.street || "",
        destination_number: data?.delivery_address?.ext_number || "",
        destination_zip_code: data?.delivery_address?.postal_code || "",
        "package": {
          ...formData
        }
      }
      console.log(formSent)
      let setQuotation = new StoreQuotation();
      const response = await setQuotation.postDataAuth(formSent, logout);

      if (response?.data) {
        sendNotification(UseerrorOrsuccess(response))
        OpenService(response.data, formData, originAddress)
        setOpenModal(false);
        setLoading(false);
      } else {
        sendNotification(UseerrorOrsuccess(response))
      }

      /* OpenService()
      setOpenModal(false);  */
      setLoading(false);

    },
  });
  const getAddress = async () => {
    let irequestFilter = [];
    irequestFilter.push({ key: "is_shipping_address", value: `true` });
    let getDirec = new AddressManages();
    let response = await getDirec.filter(irequestFilter, logout);
    console.log(response.data)
    setAddress(response.data)
  }
  const handleChangeAddress = (e) => {
    setOrigin(e.target.value)
    console.log(e.target.value)
  }

  useEffect(() => {
    if (openModal) {
      getAddress()
    }
  }, [openModal])

  return (
    <BottonModalDiag
      open={openModal}
      setOpen={setOpenModal}
      title={{ "text": "Cotizar envío con Envioclick", "align": "left" }}
    >
      <Grid container  >
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing="12px" mt="2px">
              <Grid item xs={12}>

                {address && <FormControl fullWidth variant="outlined" size="small" >
                  <InputLabel i>
                    Origen
                  </InputLabel>
                  <Select
                    name="origen"
                    label="Origen"
                    value={origin}
                    onChange={handleChangeAddress}
                    error={
                      (error != "")
                    } >
                    {address && address.map((data) => (
                      <MenuItem key={data.id} value={data.id} >
                        {`${data?.street} ${data?.ext_number}, ${data?.suburb}, ${data?.postal_code}, ${data?.city}, ${data?.state}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ display: error ? "" : "none", color: "red" }}>
                    {error ? error : ""}
                  </FormHelperText>
                </FormControl>}
                {/*   <TextField
                fullWidth
                name="origin_address"
                value={ `${data?.business?.address?.street} ${data?.business?.address?.ext_number}, ${data?.business?.address?.suburb}, ${data?.business?.address?.postal_code}, ${data?.business?.address?.city}, ${data?.business?.address?.state}` || ""}
               

                label="Origen"
            
                size="small"
                variant="outlined"
                autoComplete ='none'
                InputProps={{
                  readOnly: true,
                }}
           
  
              /> */}

              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="destination_address"
                  value={`${data?.delivery_address?.street} ${data?.delivery_address?.ext_number}, ${data?.delivery_address?.suburb}, ${data?.delivery_address?.postal_code}, ${data?.delivery_address?.city}, ${data?.delivery_address?.state}` || ""}


                  label="Destino"
                  size="small"
                  variant="outlined"
                  autoComplete='none'

                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  Características de tu paquete
                </Typography>

              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="weight"
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.weight && Boolean(formik.errors.weight)
                  }
                  label="Peso"
                  type="number"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                  }}

                  helperText={
                    formik.touched.weight ? formik.errors.weight : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="height"
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.height && Boolean(formik.errors.height)
                  }
                  label="Alto"
                  type="number"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  helperText={
                    formik.touched.height ? formik.errors.height : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="width"
                  value={formik.values.width}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.width && Boolean(formik.errors.width)
                  }
                  label="Ancho"
                  size="small"
                  type="number"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  helperText={
                    formik.touched.width ? formik.errors.width : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="length"
                  value={formik.values.length}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.length && Boolean(formik.errors.length)
                  }
                  label="Largo"
                  type="number"
                  size="small"
                  variant="outlined"
                  autoComplete='none'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  helperText={
                    formik.touched.length ? formik.errors.length : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  ¿Que envías?
                </Typography>

              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description && Boolean(formik.errors.description)
                  }
                  label="¿Que envías?"
                  size="small"
                  variant="outlined"
                  autoComplete='none'

                  helperText={
                    formik.touched.description ? formik.errors.description : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" columnSpacing="12px">

                  <Grid item xs={3}>
                    <LoadingButton
                      fullWidth
                      onClick={() => formik.resetForm()}

                      variant="outlined"

                    >
                      Limpiar
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={4}>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      type="submit"
                      variant="contained"

                    >
                      Cotizar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </BottonModalDiag>
  );
}


const styleImage = {
  width: "100%",
  height: "auto"
}


const ModalService = ({ openModal, setOpenModal, data, orderData, listGuia, update }) => {
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = useState(false)
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();

  /* console.log(data) */
  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(event.target.value)

  };
  /* console.log(data) */
  const handleSubmit = async () => {
    setLoading(true)
    /* console.log(data.data.rates.find((x=>x.idRate == value))) */
    let service = data.data.rates.find((x => x.idRate == value))
    let items = []
    let cart_items = []
    listGuia.forEach(element => {
      items.push({
        c_ClaveUnidadPeso: "XPK",
        c_ClaveProdServCP: "31181701",
        c_MaterialPeligroso: null,
        c_TipoEmbalaje: null,
        contentValue: parseFloat(element.price),
        units: element.quantity
      });
      cart_items.push(element.id)
    });
    console.log(items)

    let formSent = {
      id_rate: value,
      order: orderData.id,
      carrier: service.carrier,
      total: orderData.total,
      origin_address: data.originAddress.id,
      cart_items: cart_items,
      /* destination_address: orderData.delivery_address.id, */
      "package": {
        ...data.modal_info,
        items
      }
    }
    console.log(formSent)

    /*     "items": [
          {
        c_ClaveUnidadPeso: "XPK",
      c_ClaveProdServCP: "31181701",
      c_MaterialPeligroso: null,
      c_TipoEmbalaje: null,
      contentValue: 100.5,
      units: 1
          }
      ]
    }, */

    let setQuotation = new StoreGuide();
    const response = await setQuotation.postDataAuth(formSent, logout);

    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response))

      setLoading(false);
    } else {
      sendNotification(UseerrorOrsuccess(response))
    }

    update()
    setLoading(false);
    setOpenModal(false)
  };

  useEffect(() => {
    if (!openModal) {
      setValue("")
    }

    return () => {
      setValue("")
    }
  }, [openModal])



  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title="Seleccionar servicio" >

      {data.data?.rates &&
        <Grid container justifyContent="space-around"
          alignItems="center" sx={{ maxHeight: "80vh", overflowY: "auto" }} className="invisible-scrollbar" >
          <FormControl sx={{ width: "100%" }}>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {data.data.rates.map((row, index) => (
                <Box
                  key={index}
                  sx={{ width: "100%", display: "flex", marginBottom: "16px" }}>

                  <Box align="left" sx={{ width: "30%", display: "flex", alignItems: "center" }}>
                    <ImageSize img={serviceImages(row.carrier)} custom={styleImage} />
                  </Box>

                  <Box align="left" sx={{ width: "30%" }}>
                    <CardHeader
                      sx={{ padding: "0", margin: "0" }}
                      title={
                        <Typography variant="subtitle2" component="div">
                          {row.carrier}
                        </Typography>}
                      subheader={<> <Typography variant="subtitle2" component="div">
                        {row.product}

                      </Typography>
                        <Typography variant="subtitle2" component="div">

                          {`Entrega aprox. ${row.deliveryDays}  ${(row.deliveryDays > 1 ? "días" : "día")}`}
                        </Typography>
                      </>}
                    />
                  </Box>

                  <Box align="left" sx={{ width: "30%" }}>
                    <CardHeader
                      sx={{ padding: "0", margin: "0" }}
                      title={
                        <Typography variant="subtitle2" component="div">
                          Nombre
                        </Typography>}
                      subheader={<Typography variant="subtitle2" component="div">

                        {formatMoney(row.total)}
                      </Typography>}
                    />
                  </Box>

                  <Box align="left" sx={{ width: "10%" }}>
                    <FormControlLabel value={row.idRate} control={<Radio />} labelPlacement="start" label="" />
                  </Box>

                </Box>
              ))}

              {/*      <Box
              sx={{ width: "100%", display: "flex", marginBottom: "16px" }}>

                <Box align="left" sx={{width: "30%"}}> 
                <ImageSize img={"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Logo_de_Estafeta.svg/6834px-Logo_de_Estafeta.svg.png"} custom={styleImage}/>
              </Box>
              
              <Box align="left" sx={{width: "30%"}}> 
                  <CardHeader
                  sx={{padding: "0", margin: "0"}}
                  title={
                  <Typography variant="subtitle2" component="div">
                  Nombre
                  </Typography>}
                  subheader={"lfjlsfj"}
                />
              </Box>
              
              <Box align="left"  sx={{width: "30%"}}> 
                  <CardHeader
                  sx={{padding: "0", margin: "0"}}
                  title={
                  <Typography variant="subtitle2" component="div">
                  Nombre
                  </Typography>}
                  subheader={"lfjlsfj"}
                />
              </Box>

              <Box align="left"  sx={{width: "10%"}}> 
              <FormControlLabel value="male" control={<Radio />} labelPlacement="start"   label=""/> 
              </Box>
      
            </Box> */}

              {/* <FormControlLabel value="male" control={<Radio />} label="Male" /> */}
            </RadioGroup>
          </FormControl>

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" columnSpacing="12px">
              <Grid item xs={3}>
                {listGuia && <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Seleccionar
                </LoadingButton>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
    </BasicModal>
  );
}