import React, { useState, useEffect } from "react";
import {
  Box,
  CardHeader,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import { LoadingButton } from "@mui/lab";

export const Rules = ({data, sentSettings}) => {
    const [loading, setLoading]= useState(false)
    const [checked, setChecked] = useState(data.proximity);  
    const [radioCheck, setRadioCheck] = useState(data.selling_rule);  

    const [hidden, setHidden] = useState(data.proximity);  
  
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
    const sentUpdate = () => {
      setLoading(true)
      
      let formData= {
        proximity: checked,
        selling_rule: radioCheck,
      }
      
      sentSettings(setLoading, formData)
    };

    useEffect(()=>{

      if(checked){
        setHidden(true)
      }else{
        setHidden(false)
      }

    },[checked])

    const handleChangeRadio = (event) => {
      setRadioCheck(event.target.value);
    };
  
    return (
      <Paper sx={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h6" gutterBottom component="div">
          <strong>Reglas</strong>
        </Typography>
        <Typography variant="h7" gutterBottom component="div">
          Habilitar geolocalización
        </Typography>
        <FormGroup>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <CardHeader
              sx={{ padding: "0", margin: "0" }}
              title={
                <Typography variant="body1" component="div">
                  Descontar del almacén con menor distancia al cliente.
                </Typography>
              }
              subheader={
                "Se calcula la distancia entre el cliente y los almacenes y se descuenta de aquel que este más cercano al cliente."
              }
            />
          </Box>
        </FormGroup>
  
        <Typography variant="h7" gutterBottom component="div" mt="16px">
          Ordenar almacén por
        </Typography>
          <RadioGroup
            
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioCheck}
            onChange={handleChangeRadio}>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
            disabled={hidden }
              value={1}
              inputProps={{ "aria-label": "controlled" }}
            />
            <CardHeader
            
              sx={{ padding: "0", margin: "0", color: (hidden? "text.disabled": "") }}
              title={
                <Typography variant="body1" component="div" > 
                  Descontar del almacén con mayor cantidad de inventario.
                </Typography>
              }
              subheader={
                <Typography variant="body2" component="div" sx={{color: (hidden? "text.disabled": "")}}>
                  Se calcula el total de inventario por almacén y se descuenta en automático de aquel que tenga mayor cantidad.
                </Typography>
                
              }
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              disabled={hidden}
              value={2}
              inputProps={{ "aria-label": "controlled" }}
            />
            <CardHeader
              sx={{ padding: "0", margin: "0", color: (hidden? "text.disabled": "") }}
              title={
                <Typography variant="body1" component="div" sx={{color: (hidden? "text.disabled": "")}}>
                  Descontar del almacén ordenado del mayor al menor.
                </Typography>
              }
              subheader={
                <Typography variant="body2" component="div" sx={{color: (hidden? "text.disabled": "")}}>
                Se le asigna un número a cada almacén y se van descontando a partir del 1 hasta n.
                </Typography>
              }
            />
          </Box>
        </RadioGroup>
        <Grid container justifyContent="end" mt="16px">
        <LoadingButton
                loading={loading}
                /* fullWidth */
                onClick={()=>sentUpdate()}
               /*  type="submit" */
                variant="contained"
                
              >
                Actualizar
              </LoadingButton>
        </Grid>
    
      </Paper>
    );
  };