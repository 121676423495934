import React, { useState, useEffect } from "react";
import BasicModal from "../../../components/GenericModal";
import { TextField, Grid, Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValAddPurchases,
  valSchAddPurchases,
} from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PurchaseService from "../../../api/purchaseService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import WarehouseService from "../../../api/warehouseService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import CountWords from "../../../components/CountWords";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import { formatISO } from 'date-fns'

const AddPurchase = (props) => {
  const { openModal, setOpenModal, UpdatePurchaseList, idOrder } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const { logout } = useAuth();
  const [warehouse, setWarehouse] = useState("");
  const [warehouseList, setWarehouseList] = useState("");
  const [msg, sendNotification] = useNotification();
  const [alignment, setAlignment] = React.useState("1");
  const navigate = useNavigate();
  /* const [noOrder, setNoOrder] = useState(""); */
  /* console.log(idOrder.toString().length) */

  useEffect(() => {
    warehouseListRequest();
    /*     if (idOrder?.id) {
          setNoOrder(idOrder.id + 1);
        } else {
          setNoOrder(1);
        } */
  }, []);

  const warehouseListRequest = () => {
    (async () => {
      let irequestFilter = []
      irequestFilter.push({ 'key': 'get_main', 'value': `true` })
      const warehouseService = new WarehouseService();
      const response = await warehouseService.filter(irequestFilter, logout);
      setWarehouseList(response.data);
      console.log("warehouse", response.data);
    })();
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (event, newAlignment) => {
    console.log("aligment", newAlignment);
    setAlignment(newAlignment);
  };

  const handleChangeWarehouse = (event) => {
    setWarehouse(event.target.value);
  };

  const formik = useFormik({
    initialValues: initValAddPurchases(),
    validationSchema: Yup.object(valSchAddPurchases()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);

      /* console.log(formatISO(formData.date_created, { format: 'extended', representation: 'complete' })); */


      formData.no_order = formData.no_order + idOrder.toString();
      formData.date_created = formatISO(formData.date_created);
      console.log(formData);

      if (alignment == "1") {
        formik.values.type = 1;
      } else if (alignment == "2") {
        formik.values.type = 2;
      }

      console.log(formik.values);
      let purchaseSer = new PurchaseService();
      let response = await purchaseSer.postDataAuth(formData, logout);
      /*    resetForm(); */
      formik.resetForm();
      sendNotification(UseerrorOrsuccess(response));


      if (Number(response.status) < 300) {
        setOpenModal(false);
        window.localStorage.setItem("menu", 20);
        navigate(`detail/${response.data.id}`);


      } else {
        setOpenModal(false);
      }

      UpdatePurchaseList();

      setLoading(false);
    },
  });
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [openModal]);

  return (
    <>
      <BasicModal
        open={openModal}
        setOpen={setOpenModal}
        title="Agregar compra"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12} mt="8px">
            <form onSubmit={formik.handleSubmit}>
              <Grid container rowSpacing={"8px"} columnSpacing={"8px"}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    name="no_order"
                    inputProps={{ maxLength: 20 - idOrder.toString().length }}
                    value={formik.values.no_order}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.no_order && Boolean(formik.errors.no_order)
                    }
                    label="Numero de orden"
                    /* margin="normal" */
                    size="small"
                    variant="outlined"
                    autoComplete="none"
                    helpertext={
                      formik.touched.no_order ? formik.errors.no_order : ""
                    }
                  />
                  {idOrder && <CountWords
                    length={formik.values.no_order.length}
                    max={20 - idOrder.toString().length}
                  />}
                </Grid>
                <Grid item xs={4} sx={{ height: "100%" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="none"
                    size="small"

                    id="no_order"
                    label="Número Consecutivo"
                    value={idOrder}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => (
                      <TextField fullWidth size="small" {...props} />
                    )}
                    name={"date_created"}
                    label="Fecha"
                    value={formik.values.date_created}
                    onChange={(newValue) => {
                      formik.setFieldValue("date_created", Date.parse(newValue));
                    }}
                    helpertext={formik.errors.date_created}
                    format="dd/MM/yyyy"
                  />

                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" size="small" >
                    <InputLabel id="demo-simple-select-filled-label">
                      Almacén
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      name="warehouse"
                      label="Almacén"
                      value={formik.values.warehouse}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.warehouse)
                      } >
                      {warehouseList && warehouseList.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.warehouse ? formik.errors.warehouse : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item mt={4} mb={2} xs={12}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment} //quotation
                    exclusive
                    onChange={handleChange1}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1">
                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Compra
                      </Typography>
                    </ToggleButton>
                    <ToggleButton value="2">
                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Cotización
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                {/*  <Grid item xs={12}>
                    Los artículos agregados a la orden se irán descontando del inventario y cambiaran al estado de ordenados. 
                    </Grid> */}

                <Grid container justifyContent="flex-end">
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ marginTop: "15px" }}
                  >
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ color: "#fff" }}
                    >
                      Agregar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </LocalizationProvider>
      </BasicModal>
    </>
  );
};

export default AddPurchase;
