import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IconButton, Paper, TextField, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "../scss/changePassword.css";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ChangesPass } from "../api/userLogin";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { useSnackbar } from 'notistack';
import { useAuth } from "user-context-fran-dev";
import Footer from "../components/Footer";


const COMEBACK = -1;


export default function ChangePasswordTest({ theme }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);
      let setPass = new ChangesPass();
      let response = await setPass.postDataAuth(formData, logout);
      console.log(response)
      if (response) {
        if (response.name !== "Error" || response.name !== "Network Error") {
          enqueueSnackbar("La contraseña se ha restablecido con la nueva contraseña.", {
            variant: "success",

          });


        } else {
          sendNotification(UseerrorOrsuccess(response))
        }
      }



      setLoading(false);
    },
  });

  return (
    <>
      <Box
        sx={{
          px: 3,
          bgcolor: "background.default",
        }}
        className={`box-container `}  /* img-${theme} */
      >
        <Grid
          elevation={12}
          // sx={theme === 'light'?
          // {bgcolor: 'rgba(255, 255, 255, 0.627)'}:
          // {bgcolor: 'rgba(16, 16, 16, .9)'}}  
          sx={{ bgcolor: { xs: 'transparent', md: 'rgba(0, 0, 0, 0)' } }}
          className="paperForm formSizeResetP">
          <Grid item className="grid-container">
            <Grid item md={12}>
              <AccountCircleIcon sx={{ fontSize: 60, color: 'white' }} />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5" className="title" sx={{ color: "white" }}>
                <strong>Cambiar contraseña</strong>
              </Typography>
            </Grid>
            <form onSubmit={formik.handleSubmit} >
              <Grid item md={12}>
                <TextField
                  sx={{ backgroundColor: "white", borderRadius: '5px' }}
                  fullWidth
                  name="email"
                  values={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                  label="Correo electrónico"
                  margin="dense"
                  variant="filled"
                  size="small"
                  autoComplete='none'
                  helperText={
                    formik.touched.email ? formik.errors.email : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  sx={{ backgroundColor: "white", borderRadius: '5px' }}
                  fullWidth
                  name="new_password1"
                  values={formik.values.new_password1}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.new_password1 && Boolean(formik.errors.new_password1)
                  }
                  label="Contraseña"
                  margin="dense"
                  variant="filled"
                  size="small"
                  autoComplete='none'
                  helperText={
                    formik.touched.new_password1 ? formik.errors.new_password1 : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  sx={{ backgroundColor: "white", borderRadius: '5px' }}
                  fullWidth
                  name="new_password2"
                  values={formik.values.new_password2}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.new_password2 && Boolean(formik.errors.new_password2)
                  }
                  label="Verificar contraseña"
                  margin="dense"
                  variant="filled"
                  autoComplete='none'
                  size="small"
                  helperText={
                    formik.touched.new_password2 ? formik.errors.new_password2 : ""
                  }
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: '15px' }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  variant="contained"
                  sx={{ color: '#fff' }}
                >
                  Enviar
                </LoadingButton>
              </Grid>

              <Grid item md={12} sx={{ marginTop: '15px' }}>

                <IconButton onClick={() => { window.localStorage.setItem("menu", 8); navigate(COMEBACK); }} >
                  <Typography sx={{ color: 'white' }}
                    variant="subtitle2" color='text.primary'>Volver</Typography>
                </IconButton>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

function initialValues() {
  return {
    new_password1: "",
    new_password2: "",
    email: ""
  };
}

function validationSchema() {
  return {
    new_password1: Yup.string()
      .max(128, 'máximo de caracteres alcanzado')
      .oneOf([Yup.ref("new_password2")], "Las contraseñas no coinciden")
      .required("Ingresa tu contraseña"),
    new_password2: Yup.string()
      .max(128, 'máximo de caracteres alcanzado')
      .oneOf([Yup.ref("new_password1")], "Las contraseñas no coinciden")
      .required("Confirmar la contraseña"),
    email: Yup.string().email('correo inválido')
      .required("El campo es requerido"),
  };
}
