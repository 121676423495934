import { useAuth } from "user-context-fran-dev";
import React, { useState } from 'react';
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Link from '@mui/material/Link';
import Edit from "@mui/icons-material/Edit";

{/*function HTMLDialogContent({ content }) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
}*/}


export function ConfirmAction({ open, handleClose, handleOpen, title, content, disagreeText, agreeText, service, id, getCurrent, action = 'delete' }) {
    const { logout } = useAuth();
    const [msg, sendNotification] = useNotification();




    const Delete = (id) => {
        (async () => {
            console.log("Si se elimino")
            let response = null;
            if (action === 'edit') {
                response = await service.editDataAuth(id, logout);
            } else {
                response = await service.deleteAuth(id, logout);
            }
            sendNotification(UseerrorOrsuccess(response));
            getCurrent();
            handleClose();
        })();
    }

    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{disagreeText}</Button>
                    <Button onClick={() => Delete(id)} autoFocus>
                        {agreeText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
