import { Typography } from "@mui/material";
import React from "react";
import Container from "../../layout/gridContainer"
const PsAdmin = () => {
  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        <strong>Reportes y estadísticas</strong>
      </Typography>
    </Container>
  );
}

export default PsAdmin;