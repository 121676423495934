import { BASE_PATH, BASE_PATH_DASH } from "../utils/constants";
import { BaseService } from 'base-service-fran-dev';


export default class AddressManages extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = 'common/address_business';
    super(baseUrl, endPoint)
  }
}
export class ZipCode extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH_DASH;
    const endPoint = 'addresses/zip_code';
    super(baseUrl, endPoint)
  }
}  