//Funciones de botones eliminar y editar de la modal que refleja nuestro componente creado y se refleja en "Elementos"
import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NoImageFound from "../../assets/no_found.png"
import { red } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";



const Element = ({ setOpenEdit, row, setEdit, deleteElement }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmationData, setConfirmationData] = useState(null);
    const { id } = useParams()
    const { enqueueSnackbar } = useSnackbar();


    const handleOpenDialog = () => {
        setConfirmationData(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = () => {
        deleteElement(row.id);
        handleCloseDialog();
        enqueueSnackbar("El elemento se ha eliminado correctamente!", {
            autoHideDuration: 5000,
            variant: "success",
        });
    };

    // Función para extraer el contenido de la etiqueta <p>
    const extractParagraphContent = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html'); // Utiliza el DOMParser para analizar la cadena de texto HTML como un documento HTML completo
        const paragraph = doc.querySelector('p'); // Busca la primera etiqueta <p> dentro del documento HTML analizado
        return paragraph ? paragraph.textContent : ''; //Si encuentra la etiqueta <p>, devuelve el cotenido dentro de ella, sino no envia nada
    };

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start" sx={{ padding: 0 }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2} >
                            <ListItemAvatar>
                                <Avatar
                                    alt={row?.title}
                                    src={row?.image?.url ? row.image?.url : NoImageFound}
                                    sx={{ width: 40, height: 40, borderRadius: 1 }}
                                />
                            </ListItemAvatar>
                        </Grid>
                        <Grid item xs={8}>
                            <div>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="text.primary"
                                    dangerouslySetInnerHTML={{ __html: extractParagraphContent(row?.title) }}
                                />

                            </div>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton size="small" sx={{ marginRight: 0.5 }} onClick={() => { setOpenEdit(true); setEdit(row) }}>
                                <CreateIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleOpenDialog}>
                                <DeleteOutlineIcon sx={{ color: 'red' }} />
                            </IconButton>
                        </Grid>
                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                            <DialogTitle>Eliminar elemento</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    ¿Estás seguro que deseas eliminar este elemento?
                                </Typography>
                            </DialogContent>

                            {/*} <DialogContent>
                                <Typography
                                    dangerouslySetInnerHTML={{ __html: `¿Estás seguro que deseas eliminar el elemento "${row.title}"?` }}
                                />
                                </DialogContent>*/}

                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleDelete} color="primary">
                                    Eliminar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/*---------------------SOLUCION TEMPORAL DE MODAL DE DIALOGO-----------------*/}
                    </Grid>
                </ListItem>
                <Divider component="li" sx={{ mt: 1, borderWidth: 1, }} />
            </List >
        </>
    );
};

export default Element;