import React, {useState} from "react";
import { Box, Button, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../../../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBusinessSocialMedia, valSchBusinessSocialMedia } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { BusinessMetaData } from '../../../api/businessManages';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'

const BUSINESSMETADATA = 1;

const SocialMedia = ({data, UpdateInfo})=>{
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();

  const UpdateSocialMedia = async (id, formData) => {
    let updateSocialMedia = new BusinessMetaData();
    const response = await updateSocialMedia.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response))
    UpdateInfo();
  }



  const formik = useFormik({
    initialValues: initValBusinessSocialMedia(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessSocialMedia()),
    onSubmit: async (formData) => {
        console.log(formData)
       
      setLoading(true);
  
      UpdateSocialMedia(BUSINESSMETADATA, formData);
     
      setLoading(false);
    },
  });

    return(
        <> {/* Paper className="paper" */}
          <Typography variant="h6" gutterBottom component="div">
            <strong>Redes sociales</strong>
          </Typography>
          <form  onSubmit={formik.handleSubmit} >
            <Grid container>
              <Grid item xs={12}>
              <Box sx={{display: "flex", gap: "8px", marginTop: "8px", alignItems: "center"}}> 
                <TextField
                      sx={{width: formik.values.facebook ?"80%": "100%"}}
                  name="facebook"
                  label="Facebook"
                  placeholder="https://www.facebook.com/Ejemplo"
                  size="small"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.facebook && Boolean(formik.errors.facebook)
                  }
               
                  helperText={
                      formik.touched.facebook ? formik.errors.facebook : ""
                  }
                />
                                          {formik.values.facebook !== "" &&  
                <a href={formik.values.facebook} target="_blank"> 
                 <Button variant="contained"  >Probar link</Button>
                 </a>}
                </Box>
                 <Box sx={{display: "flex", gap: "8px", marginTop: "8px", alignItems: "center"}}> 
                <TextField
                   sx={{width: formik.values.instagram ?"80%": "100%"}}
                  name="instagram"
                  label="Instagram"
                  size="small"
                  placeholder="https://www.instagram.com/Ejemplo"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.instagram && Boolean(formik.errors.instagram)
                  }
              
                  helperText={
                      formik.touched.instagram ? formik.errors.instagram : ""
                  }
                />
                                {formik.values.instagram !== "" &&  
                <a href={formik.values.instagram} target="_blank" rel="noreferrer"> 
                 <Button variant="contained"  >Probar link</Button>
                 </a>}
                </Box>
                
                 <Box sx={{display: "flex", gap: "8px", marginTop: "8px", alignItems: "center"}}> 
                <TextField
                    sx={{width: formik.values.youtube ?"80%": "100%"}}
                  name="youtube"
                  label="Youtube"
                  size="small"
                  placeholder="https://www.youtube.com/Ejemplo"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.youtube && Boolean(formik.errors.youtube)
                  }
              
                  helperText={
                      formik.touched.youtube ? formik.errors.youtube : ""
                  }
                />
                         {formik.values.youtube !== "" &&  
                <a href={formik.values.youtube} target="_blank" rel="noreferrer"> 
                 <Button variant="contained"  >Probar link</Button>
                 </a>}
                </Box>

                <Box sx={{display: "flex", gap: "8px", marginTop: "8px", alignItems: "center"}}> 
                <TextField
                  sx={{width: formik.values.whatsapp ?"80%": "100%"}}
                  name="whatsapp"
                  label="Whatsapp"
                  
                  size="small"
                  placeholder="https://www.whatsapp.com/Ejemplo"
                  value={formik.values.whatsapp}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                  }
                  helperText={
                      formik.touched.whatsapp ? formik.errors.whatsapp : ""
                  }
                />
                {formik.values.whatsapp !== "" &&  
                <a href={formik.values.whatsapp} target="_blank" rel="noreferrer"> 
                 <Button variant="contained"  >Probar link</Button>
                 </a>}
                </Box>
                </Grid>
                <Grid  item xs={0} sm={8} md={9} lg={10}/>
                <Grid  item xs={12} sm={4} md={3} lg={2} >
                    <br/>
                    <LoadingButton
                        fullWidth
                        className='button'
                        type="submit"
                        disabled={!formik.dirty}
                        loading={loading} 
                        variant="contained"
                    >
                        Actualizar
                    </LoadingButton>
                </Grid>
              
              </Grid>
          </form>
        </>
    
    );
  }
 
export default SocialMedia;