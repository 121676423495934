import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import TableCell from "@mui/material/TableCell";
import Collapse from '@mui/material/Collapse';
import { Box } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import CircleIcon from '@mui/icons-material/Circle';
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { initSearchParameters, valSearchparameters } from '../../utils/formHelper'
import { useAuth } from "user-context-fran-dev";
import { ProductService, ProductModelService } from "../../api/productService"
import GenericTable from "../../components/GenericTable"
import ModalArticleModelView from "../../components/inventario/ModalArticleModelView"
import CircularProgress from '@mui/material/CircularProgress';
import GenericEmptyTable from "../GenericEmptyTable";
import { GenericHeaderExpandable } from "../GenericHeader";
import SearchFilters from "./searchFilters";
import ImageSize from "../ImageSize";
import { InventoryServicePresentations } from "../../api/inventoryService";

export default function ArticleExpandableView(props) {

  const { type, reloadDataOforder, scrollBottom, wh } = props
  const [expanded, setExpanded] = React.useState(false);
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [dataTable, setData] = useState(false);
  /* const [categories, setCategories] = useState(''); */
  /*   const [category, setCategory] = useState('')
    const [alm, setAlm] = useState('') */
  const [openModal, setOpenModal] = useState(false);
  const [dataPresentation, setDataPresentation] = useState(false)
  const [dataProduct, setDataProduct] = useState(false)

  const [filters, setFilters] = useState("")


  /*   const handleChange = (panel) => (event, isExpanded) => {
      
      setExpanded(isExpanded ? panel : false);
      if(isExpanded){
      getCategories();
    }
  
    }; */

  /*   const handleChangeCat = (event) => {
      console.log('evento',event)
      setCategory(event.target.value)
    };
  
    const handleChangeAlm = (event) => {
      console.log('evento',event)
      setAlm(event.target.value)
    }; */


  /*   const getCategories = () => {
      (async () => {
        let categories = new CategoryManages();
        let response = await categories.getAllAuth(logout);
        setCategories(response.data)
        console.log('categories',response.data)
      })();
    }; */


  const formik = useFormik({
    initialValues: initSearchParameters(),
    validationSchema: Yup.object(valSearchparameters()),
    onSubmit: async (formData) => {
      setLoading(true);
      setData('')
      let irequestFilter = []
      if (filters) {
        for (let element in filters) {
          if (filters[element]) {
            irequestFilter.push({ 'key': `${element}`, 'value': `${filters[element]}` })
          }
        }
      }
      irequestFilter.push({ 'key': 'is_active', 'value': `true` }) // Cambiar en todo
      irequestFilter.push({ 'key': 'pattern', 'value': `${formik.values.name}` })
      irequestFilter.push({ 'key': 'page', 'value': `1` })
      irequestFilter.push({ 'key': 'items', 'value': `10` })

      let products = new ProductService();
      let dataRes = await products.filter(irequestFilter, logout);
      console.log('producto', dataRes)
      setData(dataRes.data.data)
      setDataPresentation(dataRes.data.data)
      if (dataRes) {
        //setOpenModal(false);
      } else {
        alert("Error al buscar producto");
      }
      //UpdatePurchaseList();
      setLoading(false);
    },
  });

  useEffect(() => {
    if (openModal === false) {
      setOpenModal("")
    }

  }, [openModal])

  return (
    <div>
      {dataProduct && openModal && <ModalArticleModelView open={openModal}
        setOpen={setOpenModal}
        dataPresentation={dataPresentation}
        reloadDataOforder={reloadDataOforder}
        dataProduct={dataProduct} title="Seleccionar Presentación" className="modal-products"
        type={type}
        wh={wh}
      >
      </ModalArticleModelView>}
      <GenericHeaderExpandable title="Buscar artículos" arrow={expanded} setArrow={setExpanded}>
        <>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              alignItems="center"
            >


              <Grid item xs={9} sm={4} mr="16px" >
                <TextField
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name && Boolean(formik.errors.name)
                  }
                  label="Buscar por nombre"
                  /* margin="normal" */
                  size="small"
                  variant="outlined"
                  /* className="space-text-field" */
                  helperText={
                    formik.touched.name ? formik.errors.name : ""
                  }
                />
              </Grid>
              <Grid item xs={3} sm={2} >
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  Buscar
                </LoadingButton>
              </Grid>

              <SearchFilters filters={filters} setFilters={setFilters} />



            </Grid>
          </form>
          {dataTable &&
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              mt="16px"
            >
              <GenericTable titles={["Imagen", "SKU", "Nombre", "Estatus", "Categoría", "Más"]} >
                {dataTable.map((row, index) => (
                  <Row key={index} row={row}
                    setOpenModal={setOpenModal}
                    setDataProduct={setDataProduct}
                    dataProduct={dataProduct}
                    type={type}
                    wh={wh}
                  >
                  </Row>
                ))}
              </GenericTable>
            </Grid>
          }
          {!dataTable && <GenericEmptyTable msg={"Sin resultados"} />

          }
        </>
      </GenericHeaderExpandable>


    </div>

  );
}

function Row(props) {

  const { row, index, setOpenModal, setDataProduct, wh, dataProduct } = props
  const [open, setOpen] = useState(false);
  const [dataPresentation, setDataResponse] = useState(false)
  const [loading, setLoading] = useState(false)
  const { logout } = useAuth();

  /* console.log('dataPresentation', dataPresentation) */



  async function getinfo(id) {
    setLoading(true)
    let irequestFilter = []
    irequestFilter.push({ 'key': 'product', 'value': `${id}` }) //Modifique el endpoint que se utiliza 
    irequestFilter.push({ 'key': 'is_active', 'value': `true` })

    let products = new ProductModelService();
    let dataRes = await products.filter(irequestFilter, logout);
    console.log(row)
    setDataResponse(dataRes.data)
    /* setDataProduct(dataRes) */
    /* setDataProduct(row) */
    setLoading(false)
  }


  async function getAvailableDataModal(idmodel, product, model) {
    let irequestFilter = []
    irequestFilter.push({ 'key': 'product', 'value': `${product}` })
    irequestFilter.push({ 'key': 'mdl', 'value': `${idmodel}` })
    irequestFilter.push({ 'key': 'wh', 'value': `${wh.warehouse.id}` })

    /*     let productInventory = new ProductModelAvailableService();
        let response = await productInventory.filter(irequestFilter, logout); */

    let serviceInstance = new InventoryServicePresentations()
    let response2 = await serviceInstance.filter(irequestFilter, logout);

    //console.log('model',response.data)

    setDataProduct({ product: row, presentations: response2.data, model: model })
    console.log({ product: row, presentations: response2.data, model: model })
    setOpenModal(true)
  }



  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover

      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }} >
          <ImageSize img={row?.main_model?.image?.url ? row.main_model?.image?.url : ""} weight={1} />
          {/*  {dataPresentation.data?.main_model?.image?.url &&
             <img  src={dataPresentation.data.main_model.image.url}
                style={{width: '20%', height: 'auto'}}
            ></img>
            } */}
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }} >
          <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.sku}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }} >
          <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }} >
          <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {(row.is_active) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
              <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }} >
          <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.category.name}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
              if (!open) {
                getinfo(row.id)
              }
            }
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            {dataPresentation ?
              //console.log('datapresentation', dataPresentation)
              <Box sx={{ margin: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {dataPresentation.map((model, index) => (
                      <Grid item xs={2} sm={4} md={4} key={index}>
                        <Button size="large" onClick={() => {
                          console.log('row', model.id)
                          getAvailableDataModal(model.id, row.id, model)
                        }} fullWidth color="primary" variant="outlined"> {model.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Box> :
              <div className="model-null">
                {loading && <CircularProgress />}
                {!dataPresentation && !loading &&
                  <GenericEmptyTable msg={"No hay modelos de este producto"} />
                }
              </div>
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}