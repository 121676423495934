import React, { useEffect, useState} from 'react'
import posAdminSettings from '../../api/posAdmin/settings';
import GenericSettings from '../../components/GenericSettings';
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import { useAuth } from "user-context-fran-dev";
import { useSnackbar } from "notistack";
import CustomTicket from './settings/customTicket';

const PsAdminSettings = () => { 
    const [settings, setSettings] = useState();
    const { logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [msg, sendNotification] = useNotification();

    const updateSettings = async (id, formData) => {
        let settings = new posAdminSettings();
        let response = await settings.editDataAuth(id, formData, logout);
        sendNotification(UseerrorOrsuccess(response))
        getSettings()
      }
    const getSettings = () => {
    (async () => {
        let getSetting = new posAdminSettings();
        let response = await getSetting.getAllAuth(logout);
        setSettings(response.data);
    })();
    };
    useEffect(()=>{
        getSettings()
    },[])


    return ( 
        <>
           {settings && <GenericSettings data={settings} title={"Tickets de venta"} update={updateSettings} />}
           {settings && <CustomTicket data={settings} update={getSettings} postData={updateSettings}/> }
           

        </>
     );
}
 
export default PsAdminSettings;