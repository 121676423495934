import * as React from 'react';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DropMenu from '../dropMenu';
import { Grid } from '@mui/material';
import { ItemsOrderService } from '../../../api/pos/ordersService'
import GenericSizeModal from '../GenericSizeModal';
import EditItem from '../EditItem';
import { useAuth } from "user-context-fran-dev";
import CurrencyFormat from 'react-currency-format';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import { BottonModalDiag } from '../../GenericsModals';


export default function PurchaseList(props) {

  const { productList, updateOrder, setIdToEdit, orderData } = props
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [openEdit, setOpenEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState()

  const Delete = (id) => {
    (async () => {
      let serviceApi = new ItemsOrderService()
      console.log('id of item in order', id)
      let response = await serviceApi.deleteAuth(id, logout)
      sendNotification(UseerrorOrsuccess(response))
      updateOrder()

    })();
  }

  const editItem = (data) => {
    setEdit(data)
    setOpenEdit(!openEdit)
  }

  return (
    <>

      <List sx={{ bgcolor: 'background.paper' }}>
        <AddProduct open={openEdit} setOpen={setOpenEdit} >
          <EditItem data={edit} open={openEdit} setOpen={setOpenEdit} orderData={orderData} updateOrder={updateOrder}></EditItem>
        </AddProduct>
        {productList.map((data, index) =>
          <>

            <ListItem key={index} alignItems="flex-start" onClick={() => { editItem(data) }}>

              {/* <ListItemAvatar>
          <Avatar alt={data.qty} />
        </ListItemAvatar> */}
              <ListItemText

                secondary={
                  <React.Fragment>
                    <Grid container direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid xs container direction="column" justifyContent="start" alignItems="start">
                        <Grid>
                          <Typography
                            variant="h7"
                            color="text.primary"
                          >
                            {data.model.name}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography

                            variant="body2"
                            color="text.primary"
                          >
                            {data.presentation.name}
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography


                            variant="subtitle2"
                            color="text.primary"
                          >
                            1 unidad a  <CurrencyFormat value={data.price} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid xs container  direction="column" justifyContent="center" alignItems="end">
                        <Grid xs>
                        <Typography variant="h7" color="text.primary" mr={1}> 
                        x{data.qty} 
                          </Typography>
                          <Typography variant="h7" color="text.primary"> 
                            <CurrencyFormat value={data.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                          </Typography>
                        </Grid>
                        
                        <Grid xs>
                          <IconButton size="small" aria-label="delete" onClick={(e) => { e.stopPropagation(); Delete(data.id) }}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                    </Grid>
                  </React.Fragment>
                }
              />
              {/* <DropMenu service={ItemsOrderService} id={data.id} update={updateOrder} openEdit={openEdit} setOpenEdit={setOpenEdit} setIdToEdit={setIdToEdit}></DropMenu> */}

            </ListItem>
            <Divider />
          </>
        )}

      </List>
    </>
  );

}

const AddProduct = ({ open, setOpen, children }) => {
  let title = "Editar artículo"
  const [cashReload, setReload] = useState(false)
  return (
    <BottonModalDiag open={open} setOpen={setOpen} title={{ "text": title, "align": "start" }} size='800px'>


      {children}


    </BottonModalDiag>
  )
}

