import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import TableRow from "@mui/material/TableRow";
import { Box, Grid } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, CardHeader } from "@mui/material";
import BasicTable from "../../../components/GenericTable";
import Typography from "@mui/material/Typography";
import TableInfo from "./tableInfo";
import PurchaseService from "../../../api/purchaseService";
import SalesOrder from "../../../api/salesOrder"; 
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import GenericEmptyTable from '../../../components/GenericEmptyTable'
import CurrencyFormat from 'react-currency-format';
import { DateFormat } from '../../../components/DateFormat'
import Chip from '@mui/material/Chip';
import { STATUSPRODUCTIONCOLOR, STATUSPRODUCTION } from "../../../utils/constants";
import { STATUSTRANSFER, STATUSTRANSFERCOLOR  } from "../../../utils/constants" 
import { useTableFilters } from "../../../hooks/searchParams";
import PageParam from "../../../components/PageParam";
import { ProductionOrder } from "../../../api/productionOrder";
import { Transfers } from "../../../api/transferService";
 

export const TablePurchase = () => {
  const purchase = [
    "# Orden / Fecha",
    "Proveedor / Almacén",
    /* "Estatus", */ 
    "Artículos",
    "Total",
    "Más",
  ];  
  const [loading, setLoading] = useState(false)
  const [tablePurchase, setTablePurchase] = useState();
  let filter = [];
  filter.push({ key: "sts", value: `2` });
  const [paramsPurchase, handleSearchPurchase] = 
  useTableFilters( PurchaseService, setTablePurchase, setLoading, filter, "tab" );
  const navigate = useNavigate();

/*   


  /* consollog(tablePurchase) 
  useEffect(() => {
    navigate(`page=${pagePurchase}`);
    getPurchaseOrder(setLoading);
  }, [pagePurchase]); */

  const GoToDetailPurchase = (id) => {
    window.localStorage.setItem("menu", 12);
    navigate(`purchase/detail/${id}`);
  };

    return (<> 
      {tablePurchase?.data && (
        <BasicTable titles={purchase}>
          {tablePurchase.data.map((data, index) => ( 
            <Row
              key={index}
              data={data}
              is_purchase={true}
              HandleGo={GoToDetailPurchase}
            />
          ))}
  
        </BasicTable>
      )}
      { !tablePurchase && loading &&
          <Grid container justifyContent="center" p={"24px"}>
              <CircularProgress />
       </Grid> 
       }
          {!tablePurchase?.data && !loading  &&
      <GenericEmptyTable msg={"No hay datos en la tabla"}/>
    }
            {tablePurchase?.data && <Grid container justifyContent="center" p={"24px"}>
       <PageParam  totalpages={tablePurchase.pagination} params={paramsPurchase} handleChange={handleSearchPurchase} />
       </Grid>}
    


      </>
     );

}

export const TableSales = () => {

  const sales = [
    "# orden / Fecha",
    "Cliente / Almacén",
    /* "Estatus", */
    "Artículos",
    "Total",
    "Más",
  ];
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [tableSales, setTableSales] = useState();
  let filter = [];
  filter.push({ key: "sts", value: `2` });
  const [paramsSales, handleSearchSales] = 
  useTableFilters( SalesOrder, setTableSales, setLoading, filter, "tab" )

/*   useEffect(() => {
    navigate(`page=${pageSales}`);
    getSaleOrden(setLoading);
  }, [pageSales]);  */

  const GoToDetailSale = (id) => {
    window.localStorage.setItem("menu", 13); 
    navigate(`sale/detail/${id}`);
  };


    return(
      <>
                {tableSales?.data && (
                  <BasicTable titles={sales}>
                    {tableSales.data.map((data, index) => (
                      <Row
                        key={index}
                        data={data}
                        is_purchase={false}
                        HandleGo={GoToDetailSale}
                      />
                    ))}
                  </BasicTable>
                )}
                      { !tableSales && loading &&
          <Grid container justifyContent="center" p={"32px"}>
              <CircularProgress />
       </Grid> 
       }
          {!tableSales?.data && !loading  &&
          <GenericEmptyTable />
          }
                      {tableSales && <Grid container justifyContent="center" p={"24px"}>
       <PageParam  totalpages={tableSales.pagination} params={paramsSales} handleChange={handleSearchSales} />
       </Grid>}
   
      </>
    );
  

}

export const TableProduction = () => {
  const production = [
    "Orden / Fecha",
    "Almacén",
    "Recetas",
    "Estatus",
    "Más",  
  ];
  /* console.log(tableProduction) */
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [tableProduction, setTableProduction] = useState();
  let filter = [];
  filter.push({ key: "sts", value: `2,3` });
  const [paramsProduction, handleSearchProduction] = 
  useTableFilters( ProductionOrder, setTableProduction, setLoading, filter, "tab" )

/*   useEffect(() => {
    navigate(`page=${pageProduction}`);
    getProduction(setLoading);
  }, [pageProduction]); 
 */
  const GoToDetailProduction = (id) => {
    window.localStorage.setItem("menu", 13);
    navigate(`production/detail/${id}`);
  };


    return(
      <>
       { loading &&
          <Grid container justifyContent="center" p={"24px"}>
              <CircularProgress />
       </Grid> 
       }
        {tableProduction?.data && !loading && (
          <BasicTable titles={production}>
            {tableProduction.data.map((data, index) => (
              <RowProduction
                key={index}
                row={data}
                HandleGo={GoToDetailProduction}
              />
            ))}
          </BasicTable>
        )}
          {!tableProduction?.data && !loading  &&
          <GenericEmptyTable msg={"No hay datos en la tabla"}/>
          }
                            {tableProduction?.data && <Grid container justifyContent="center" p={"24px"}>
       <PageParam  totalpages={tableProduction.pagination} params={paramsProduction} handleChange={handleSearchProduction} />
       </Grid>}
   
      </>
    );
}

export const TableTransfer = () => {
  const production = [
    "Almacén origen",
    "Almacén destinó",
    "Tipo",
    "Fecha",
    "Estado",
    "Más", 
  ];
  /* console.log(tableProduction) */
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [tableTransfer, setTableTransfer] = useState();
  let filter = [];
  filter.push({ key: "status", value: `2,3`  });
  const [paramsTransfer, handleSearchTransfer ] = 
  useTableFilters( Transfers, setTableTransfer, setLoading, filter, "tab" );
/* 
  useEffect(() => {
    navigate(`page=${pageTransfer}`);
    getTransferOrder(setLoading);
  }, [pageTransfer]);  */

  const GoToDetailTransfer = (id) => {
    window.localStorage.setItem("menu", 13);
    navigate(`transfer/detail/${id}`);
  };


    return(
      <>
       { loading &&
          <Grid container justifyContent="center" p={"24px"}>
              <CircularProgress />
       </Grid> 
       }
        {tableTransfer?.data && !loading && (
          <BasicTable titles={production}>
            {tableTransfer.data.map((data, index) => (
              <RowTransfer
                key={index}
                row={data}
                HandleGo={GoToDetailTransfer}
              />
            ))}
          </BasicTable>
        )}
          {!tableTransfer?.data && !loading  &&
          <GenericEmptyTable msg={"No hay datos en la tabla"}/>
          }
                           {tableTransfer?.data && <Grid container justifyContent="center" p={"24px"}>
       <PageParam  totalpages={tableTransfer.pagination} params={paramsTransfer} handleChange={handleSearchTransfer} />
       </Grid>}
   
      </>
    );
}

const RowTransfer = ({row, HandleGo}) => {
  const [open, setOpen] = useState(false);
  const beginDate = row.date_created

  return ( 
    <>
      <TableRow
    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor:'pointer' }}
    hover 
    onDoubleClick={()=> {HandleGo(row.id);}}
    >
    <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >
      <Typography variant="subtitle2"  sx={{ m:0, p:0 }}>
        {row.warehouse_source.name}
      </Typography>
    </TableCell>
    <TableCell align="left"  >
      <Typography variant="body2" gutterBottom  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row.warehouse_destiny.name}
      </Typography>
    </TableCell>
    <TableCell align="left" sx={{ minWidth: "100px", maxWidth: "5vw" }} >
      <Typography variant="body2" gutterBottom  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {row.type === 1 ? 'Inmediato' : 'Con confirmación'}
      </Typography>
    </TableCell>
    <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "8vw" }} >
      {DateFormat({beginDate})}
    </TableCell>
    <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >
      <Chip label={STATUSTRANSFER[row.status]} color={STATUSTRANSFERCOLOR[row.status]} className="chip-with"  variant="outlined"/>
    </TableCell>
    <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >
    <IconButton
        aria-label="expand row"
        size="small"
        sx={{m:0, p:0}}
        onClick={() => {
         if (!open) {
          /*    if (is_purchase) {
              
             
            } else {
         
            }
            setId(data.id) */
          }
          setOpen(!open);
        }}
      >
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>



    </TableRow>
    
    </> 
);
}
 
const RowProduction = ({row, HandleGo}) => {
  const [open, setOpen] = useState(false);
  const beginDate = row.date_created
  /* console.log(row) */


    return ( 
        <>
          <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor:'pointer' }}
        hover 
        onDoubleClick={()=> {HandleGo(row.id);}}
        >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >

              <CardHeader
            sx={{m:0, p:0}}
              title={
                <Typography variant="subtitle1"  sx={{ m:0, p:0 }}>
                    {row.no_order}
                </Typography>}
                subheader={
                  DateFormat({beginDate})
                }
            /> 

        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >
        <Typography variant="subtitle1"   sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {row.warehouse.name}
              </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "60px" }} >
          <Typography variant="body2" gutterBottom  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.recipe_total}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >
  
        <Chip label={STATUSPRODUCTION[row.status]} color={STATUSPRODUCTIONCOLOR[row.status]} className="chip-with"  variant="outlined"/>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >
        <IconButton
            aria-label="expand row"
            size="small"
            sx={{m:0, p:0}}
            onClick={() => {
             if (!open) {
              /*    if (is_purchase) {
                  
                 
                } else {
             
                }
                setId(data.id) */
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>



        </TableRow>
        
        </> 
    );
}


const Row = ({ data, is_purchase, HandleGo }) => {
  const [open, setOpen] = useState(false);

  const beginDate = data.date_created

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor:'pointer' }}
        hover 
        onDoubleClick={()=> {HandleGo(data.id)}}
        >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }} >


                <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle2"  sx={{ m:0, p:0 }}>
                  {data.no_order }
                </Typography>}
                subheader= {DateFormat({beginDate})}
            />
        </TableCell>

        <TableCell sx={{minWidth: "20px", maxWidth: "15vw" }}  align="left">


            <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle1" component="div"  sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <Typography variant="subtitle2" component="div" 
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {is_purchase
                ? data.supplier
                  ? data.supplier.business_name
                  : ""
                : data.client
                ? data.client.business_name
                : ""}
            </Typography>
                </Typography> }
                subheader={ data.warehouse.name }
            />
              
      </TableCell> 

        <TableCell align="left">
        <CardHeader
              sx={{m:0, p:0}}
                title={
                  <Typography variant="subtitle1" component="div"  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data.articles_total}
                  </Typography>}
              />
          
          </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" component="div"  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <CurrencyFormat value={data.total} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{m:0, p:0}}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ?  <KeyboardArrowUpIcon /> :  <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box /* sx={{ margin: 1 }} */>
            <TableInfo data={data}  HandleGo={HandleGo} is_purchase={is_purchase}  open={open}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      
    </>
  );
};
