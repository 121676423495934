import React, {useState} from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import DropMenu from '../../components/dropMenu'
import CommentService  from "../../api/commentService";
import EditGenericComments from "./editModals/editComments";
import GenericComments from '../NewComment'

const Comments = ({data, UpdateInfo, target}) => {
  const [open, setOpen]= useState(false);
  const [openEdit, setOpenEdit] = useState(false)
  const [dense, /* setDense */] = React.useState(false);
  const [idToEdit, setIdToEdit] = useState('');


    return ( 

      <Paper className="paper details-view">
         {openEdit &&
          <EditGenericComments data={idToEdit} openEdit={openEdit} setOpenEdit={setOpenEdit} UpdateInfo={UpdateInfo} business={true} target={target}/>
        }
          <Typography variant="h6" gutterBottom component="div" className="title-card">
                <strong>Comentarios</strong>
          </Typography>
              
          <Link component="button" variant="subtitle2" 
          onClick={()=>{ setOpen(true) }} underline="none" className="link">
            Agregar
          </Link>
          <GenericComments data={data} open={open} setOpen={setOpen} UpdateInfo={UpdateInfo} business={true} target={target}/>
          <Grid item xs>
            <List dense={dense}>
            {data.comments && data.comments.map((comment, index)=>(

              <ListItem key={index}
                  secondaryAction={
                    <DropMenu service={CommentService} row={comment} id={comment.id} update={UpdateInfo} openEdit={openEdit} setOpenEdit={setOpenEdit} setIdToEdit={setIdToEdit}></DropMenu>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <CommentIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                  sx={{overflow: "hidden", textOverflow: "ellipsis"}}
                    primary={
                      <Typography sx={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: {xs: "200px", sm: "500px", md: "700px"}}}> 
                      {comment.comments}
                      </Typography>
                    }
                  />
                </ListItem>

              ))}
              
            </List>
        </Grid>
      </Paper>

     );
}
 
export default Comments;