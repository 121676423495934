import React, {useState} from "react";
import {  IconButton, CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';
import { STATUSTRANSFER, STATUSTRANSFERCOLOR } from '../../../../utils/constants'


const TransferTable = (props) => {
    const { data } = props
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    return ( 
        <>
            <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onDoubleClick={() => {
                    console.log(data)
                    window.localStorage.setItem("menu", 24);
                    navigate(`transfer/${data.id}`); 
                }}
            >
              <TableCell sx={{minWidth: "20px", maxWidth: "15vw" }}  align="left" >
              <CardHeader
                  sx={{m:0, p:0}}
                    title={
                      <Typography variant="subtitle1" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {data.warehouse_source.name}
                    </Typography>}
                  />
              </TableCell>
              <TableCell sx={{minWidth: "20px", maxWidth: "15vw" }}  align="left" >
              <CardHeader
                  sx={{m:0, p:0}}
                    title={
                      <Typography variant="subtitle1" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {data.warehouse_destiny.name}
                    </Typography>}
                  />
              </TableCell>
              <TableCell  sx={{minWidth: "20px", maxWidth: "15vw" }}  align="left"> 
              <CardHeader
                  sx={{m:0, p:0}}
                    title={
                      <Typography variant="subtitle1" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> 
                        <Dia text={data.date_created} />
                      </Typography>}
                  />
              </TableCell>
              <TableCell  sx={{minWidth: "20px", maxWidth: "15vw" }}  align="left"> 
              <CardHeader
                  sx={{m:0, p:0}}
                    title={
                      <Typography variant="subtitle1" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> 
                      <Chip label={STATUSTRANSFER[data.status]} color={STATUSTRANSFERCOLOR[data.status]} className="chip-with"  variant="outlined"/>
                    </Typography>}
                  />
              </TableCell>
            </TableRow>
    
        </>   
     );
}

const Dia = ({ text }) => {
    const Month = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const dia = new Date(text);
    return (
      <Typography variant="h7" component="div">
        {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
      </Typography>
    );
  };
 
export default TransferTable;