import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export default class CategoryManages extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'catalog/category';
      super(baseUrl, endPoint)
    }
}