import React, { useState } from 'react'
import { Grid, Typography, TextField } from "@mui/material";
import GenericTable from '../GenericTable'
import TableCell from "@mui/material/TableCell";
import { CardHeader, TableRow } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from 'react-router-dom'
import { useAuth } from "user-context-fran-dev";
import { InventoryService, InventoryServiceSalesOrder } from '../../api/inventoryService'
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import { initAddArticleToPurchaseOrder, valSchAddToPurchaseOrder } from "../../utils/formHelper";
import GenericEmptyTable from '../GenericEmptyTable'
import { SnackbarProvider, useSnackbar } from 'notistack';
import { TableModal } from '../GenericsModals';
import ImageSize from '../ImageSize';
import { formatNumber } from '../../utils/functions';

export default function ModalArticleModelView(props) {

  const { open, setOpen, title, dataProduct, type, reloadDataOforder, wh } = props
  console.log('dataPresentation', dataProduct)


  return (
    <>
      {dataProduct &&
        <TableModal open={open} setOpen={setOpen} title={"Seleccionar presentación"} className="modal-articles" >


          <Grid container justifyContent="space-around"
            alignItems="center">

            <Grid item xs={9}>
              <Grid container>
                <ImageSize img={dataProduct?.model?.image ? dataProduct?.model?.image?.url : ""} weight={2} />
                <Grid item ml={{ xs: "8px", md: "32px" }}>
                  <h3>{dataProduct?.product?.name}</h3>
                  <h4>{dataProduct?.model?.name}  </h4>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={3} >
              <Grid container justifyContent={"start"} alignItems={"end"} flexDirection="column" sx={{ height: "64px" }}>
                <h4> {dataProduct?.product?.sku} </h4>
                <h4>       {(dataProduct?.product?.is_active) ? <Typography sx={{ color: 'green', fontSize: 15 }}>Activo</Typography> :
                  <Typography sx={{ color: 'red', fontSize: 15 }}>Desactivado</Typography>}  </h4>
              </Grid>

            </Grid>



            <Grid item xs={12} mt={"32px"}  >
              {dataProduct?.presentations && open ? (
                <GenericTable titles={type == "purchase" ?
                  ["Presentación", "Estatus", "Ord.", "Disp.", "Res.", "Costo", "Cantidad", "Acción"]
                  : ["Presentación", "Estatus", "Ord.", "Disp.", "Res.", "Precio", "Cantidad", "Acción"]}  >

                  {dataProduct?.presentations.map((row, index) => (
                    <Row setOpen={setOpen} key={index} row={row} type={type} dataProduct={dataProduct.model.id} reloadDataOforder={reloadDataOforder}>
                    </Row>
                  ))}
                </GenericTable>
              ) : (
                <GenericEmptyTable msg={"No hay datos en la tabla"} />
              )}

            </Grid>
          </Grid>


        </TableModal>
      }
    </>
  )
}

function Row(props) {

  const { row, type, reloadDataOforder, dataProduct, setOpen } = props
  /* const [open, setOpen] = useState(false); */
  const [loading, setLoading] = useState(false);
  const { id } = useParams()
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log(row)


  const formik = useFormik({
    initialValues: initAddArticleToPurchaseOrder(row),
    validationSchema: Yup.object(valSchAddToPurchaseOrder()),
    onSubmit: async (formData, { resetForm }) => {
      formik.values.presentation = row.id
      formik.values.model = dataProduct
      resetForm();
      setIsSubmitting(true); // Hasta que pase los datos del form, desabhilitara el boton para evitar volver a poder agregar hasta que sea un success o error
      setLoading(true);
      console.log(formData)
      if (type == 'purchase') {
        console.log('purchase', row)
        /*  formik.values.cost = row.price */
        formik.values.purchase = Number(id)
        let addToPurchases = new InventoryService();
        let response = await addToPurchases.postDataAuth(formData, logout)
        if (Number(response.status) < 300) {
          sendNotification(UseerrorOrsuccess(response))
        } else {
          sendNotification(UseerrorOrsuccess(response))
        }
        reloadDataOforder()
      } else {
        console.log(row)
        formik.values.sale = Number(id)
        /* formik.values.price = row.price */
        let addToPurchases = new InventoryServiceSalesOrder();
        let response = await addToPurchases.postDataAuth(formData, logout)
        reloadDataOforder()
      }
      //udateProducts();
      //setOpen(false);
      setLoading(false);
      setIsSubmitting(false); // Una ves ya pasado todo el formulario y si fue success o error, volvera activar el boton de agregar
    },
  });

  async function getinfo() {

  }

  return (

    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      hover
    >

      <TableCell align="left" /* sx={{maxWidth: '200px'}} */>

        <Typography variant="subtitle2" component="div">
          {row.name}
        </Typography>

      </TableCell>
      {/*         <TableCell align="left" > 

          <Typography variant="subtitle2" component="div">
          {row.price}
          </Typography>

          </TableCell> */}
      {/* <TableCell align="left" sx={{maxWidth: '120px'}}> 
          <CardHeader
            title={
              <Typography variant="subtitle2" component="div">
              {row.presentation.price ? row.presentation.price : ''}
              </Typography>}
            
          />
        </TableCell> */}
      <TableCell align="center" /* sx={{maxWidth: '120px'}} */>

        <Typography variant="subtitle2" component="div">
          {(row.is_active) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
            <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}
        </Typography>
      </TableCell>
      <TableCell align="left" /* sx={{maxWidth: '50px'}} */>
        <CardHeader
          title={
            <Typography variant="subtitle2" component="div">
              <a>{row.ordered}</a>
            </Typography>}
        />
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: '50px' }}>

        <Typography variant="subtitle2" component="div">
          <a>{row.available}</a>
        </Typography>
      </TableCell>
      <TableCell align="left" /* sx={{maxWidth: '50px'}} */>

        <Typography variant="subtitle2" component="div">
          <a>{row.reserved}</a>
        </Typography>
      </TableCell>
      <TableCell align="left" /* sx={{maxWidth: '150px'}} */>
        <form onSubmit={formik.handleSubmit}>
          {type === 'sales' &&



            <TextField
              fullWidth
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              /* error={formik.touched.price} */
              label="Precio"
              /*  margin="normal" */
              placeholder={row?.price ? formatNumber(row?.price) : 0}
              type='number'
              size="small"
              variant="outlined"
              //disabled={formik.values.quantity > row.available}
              error={
                formik.touched.price && Boolean(formik.errors.price)
              }

              helperText={
                formik.touched.price ? formik.errors.price : ""
              }
              className="space-text-field"
            />}
          {type === 'purchase' &&
            <TextField
              fullWidth
              name="cost"
              value={formik.values.cost}
              onChange={formik.handleChange}
              error={formik.touched.cost}
              label="Costo"
              /*  margin="normal" */
              placeholder={row?.cost ? formatNumber(row?.cost) : 0}
              type='number'
              size="small"
              variant="outlined"
              //disabled={formik.values.quantity > row.available}
              InputProps={{
                readOnly: true,
              }}
              className="space-text-field"
            />
          }



        </form>
      </TableCell>

      <TableCell align="left" /* sx={{maxWidth: '150px'}} */>
        <form onSubmit={formik.handleSubmit}>

          <TextField
            fullWidth
            name="quantity"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            /*  error={formik.touched.quantity} */
            label="Cantidad"
            /*  margin="normal" */
            type='number'
            size="small"
            variant="outlined"
            error={
              formik.touched.quantity && Boolean(formik.errors.quantity)
            }

            helperText={
              formik.touched.quantity ? formik.errors.quantity : ""
            }
            //disabled={formik.values.quantity > row.available}
            className="space-text-field"
          />

        </form>
      </TableCell>
      <TableCell align="left" /* sx={{maxWidth: '150px'}} */>
        <form onSubmit={formik.handleSubmit}>
          {type === 'sales' && <LoadingButton
            loading={false}
            fullWidth
            type="submit"
            variant="contained"
            disabled={isSubmitting || formik.values.quantity > row.available || row.available === 0}
          >
            Agregar
          </LoadingButton>
          }
          {type === 'purchase' && <LoadingButton
            loading={false}
            fullWidth
            type="submit"
            variant="contained"
            disabled={isSubmitting || !row.is_active}
          >
            Agregar
          </LoadingButton>
          }
        </form>
      </TableCell>
    </TableRow>

  )
}