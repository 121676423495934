/*Aqui configuramos los fondos de background ya sea imagenes, color o degradado*/
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
    FormGroup,
    Switch,
    Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ImgEdit from "../landing/ImgEdit";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { ImageCreator, ComponentCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import NoImageFound from "../../assets/no_found.png"
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBackground, initValCreatorSection, valSchBackground } from "../../utils/formHelper";
import { LoadingButton } from "@mui/lab";
import { useNotification, UseerrorOrsuccess } from "../../hooks/snackBarResponse";
import Gallery from "./Gallery";
import Slider from '@mui/material/Slider';
import ToggleButtons from "./toggleButtons";

const TitleList = ({ bgStyle, data, UpdateSection, setOpenPreview }) => {
    const [alignment, setAlignment] = useState('left');
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const { logout } = useAuth();
    const [msg, sendNotification] = useNotification();
    const [color1, setColor1] = useState("#ffffff")
    const [color2, setColor2] = useState("#ffffff")
    const [gradiant, setGradiant] = useState("top")

    const putData = async (formData) => {
        let put = new ComponentCreator();
        const response = await put.editDataAuth(data.component.id, formData, logout);
        sendNotification(UseerrorOrsuccess(response));
        UpdateSection(setLoading);
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };

    const formik = useFormik({
        initialValues: initValBackground(data),
        validationSchema: Yup.object(valSchBackground()),
        onSubmit: async (formData, { resetForm }) => {
            setLoading(true); // Activar indicador de carga aquí
            formData.justify_content = alignment;
            formData.title = title;
            putData(formData);
        },
    });

    const sendColor = () => {
        setLoading(true)
        let formdata = {
            bg_color1: color1,
            bg_type: 1,
            justify_content: alignment,
            title: title
        }
        putData(formdata)
    }

    const sendGradiant = () => {
        setLoading(true)
        let formdata = {
            bg_color1: color1,
            bg_color2: color2,
            bg_gradiant_orientation: gradiant,
            bg_type: 2,
            justify_content: alignment,
            title: title
        }
        putData(formdata)
    }


    useEffect(() => {
        setAlignment(data.component.justify_content || "left");
        setTitle(data.component.title || "");

        // Actualizar los valores iniciales de formik si los datos cambian
        formik.setValues(initValBackground(data));
    }, [data]);

    if (bgStyle === 3 || bgStyle === 2 || bgStyle === 1) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <Grid container mt="16px">
                    {data && (data.type === 3 || data.type === 5) && (
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div">
                                <strong>Titulo</strong>
                            </Typography>
                            <TextField
                                name="title"
                                value={title}
                                onChange={(e) => handleTitle(e)}
                                size="small"
                                autoComplete='none'
                                sx={{ mt: 2 }}
                                fullWidth
                            />
                        </Grid>

                    )}
                    {data && (data.type === 3 || data.type === 5) ? (
                        <>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                <Button onClick={() => setOpenPreview(true)}>Vista previa</Button>
                                <LoadingButton
                                    loading={loading}
                                    onClick={() => { sendGradiant() }}
                                    variant="contained"
                                    sx={{ color: "#fff", ml: 1 }}
                                >
                                    Actualizar
                                </LoadingButton>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
                {data && (data.type === 3 || data.type === 5) ? (
                    <Divider sx={{ mt: 2, height: 1, backgroundColor: "text.secondary" }} />
                ) : null}
            </form>
        );
    } else {
        return <></>;
    }

};




function valueLabelFormat(value) {


    return `${value} %`;
}

export default TitleList;