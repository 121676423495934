import React, {useState, useEffect}  from 'react'
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Grid } from "@mui/material";
import { initValPhonebook, valPhonebook } from "../../utils/formHelper"
import BasicModal from "../../components/GenericModal";
import ContactService from "../../api/contactService";
import { UseerrorOrsuccess, useNotification } from '../../hooks/snackBarResponse'
import { useParams } from 'react-router-dom'


const AddContact = ({setOpen, open, UpdateInfo, target}) => {

  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const { id } = useParams()
  const supplierService = new ContactService()
  

  const formik = useFormik({
    initialValues: initValPhonebook(id),
    enableReinitialize: true,
    validationSchema: Yup.object(valPhonebook()),
    onSubmit: async (formData,  {resetForm}) => {
      if(target === 'supplier'){
      formik.values.supplier = id
      }else {
        formik.values.client = id
      }
      setLoading(true);
      let response = await supplierService.postDataAuth(formData, logout); 
      if(Number(response.status) < 300){
      sendNotification(UseerrorOrsuccess(response))
      UpdateInfo()
      resetForm()
      setLoading(false);
      setOpen(false)}
      else{
        sendNotification(UseerrorOrsuccess(response))
        setLoading(false);
      }
        },
  });
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return(
    <BasicModal open={open} setOpen={setOpen} title="Agregar contacto">
    <form onSubmit={formik.handleSubmit} >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Nombre"
            margin="dense"
            size="small"
            value={formik.values.business_name}
            onChange={formik.handleChange}
            error={
                formik.touched.business_name && Boolean(formik.errors.business_name)
            }
           
            helperText={
                formik.touched.business_name ? formik.errors.business_name : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="email"
            label="Email"
            margin="dense"
            size="small"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
                formik.touched.email && Boolean(formik.errors.email)
            }
            
            helperText={
                formik.touched.email ? formik.errors.email : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="phone"
            label="Telefono"
            margin="dense"
            size="small"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={
                formik.touched.phone && Boolean(formik.errors.phone)
            }
           
            helperText={
                formik.touched.phone ? formik.errors.phone : ""
            }
          />
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid
          item
          xs={12}
          sm={7}
          md={6}
          lg={6}
          xl={6}
          sx={{ marginTop: "15px" }}
          >
          <LoadingButton
              loading={loading}
              
              fullWidth
              type="submit"
              variant="contained"
              sx={{ color: "#fff" }}
          >
              Agregar 
          </LoadingButton>
          </Grid>
      </Grid>
      </Grid>
      
    </form>
    </BasicModal>
  )
}

export default AddContact