import React, {useState}  from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {useAuth} from 'user-context-fran-dev';
import PurchaseList from "../../components/pos/purchaseList/purchaseList";
import GenericEmptyTable from "../../components/pos/GenericEmptyTable";
import TotalCard from '../../components/pos/totalCard'
import { OrderService } from "../../api/pos/ordersService";


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  //justifyContent: "flex-end",
}));


export default function PosDrawer(props) {
    const { listUpdate, openPurchaseList} = props
    const navigate = useNavigate();
    const [productList, setProductList] = useState(null)
    const [loading, setLoading] = useState('')
    const [orderData, setorderData] = useState('')
    const {logout, auth}= useAuth();
    const [idToEdit, setIdToEdit] = useState('')
    const [openList, setOpenList] = useState(true);
    const [cashRegister, setCashRegister] = useState('')
    let { id, cr } = useParams();

    var w = window.innerWidth;
    var h = window.innerHeight;
    
    const UpdateOrder = () => {
        (async () => {
        let orderService = new OrderService()
        let irequestFilter = []
        irequestFilter.push({'key':'cash_register', 'value': cr})
        irequestFilter.push({'key':'sts', 'value': '1'}) 
        let response = await orderService.filter(irequestFilter, logout)
       
        if(Number(response.status) < 300){
          console.log('order', response)
          let response1 = await orderService.getAllByIdAuth(response.data['0'].id, logout)
          setorderData(response.data)
          setProductList('')
          setProductList(response1.data.items)
        }
    })();
    }



    useEffect(() => {
        console.log('heigth, width', w, h)
        setCashRegister(auth.cashregister?.cr?.id)
        UpdateOrder()
      }, [listUpdate]);


    const toggleDrawer = (openList) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
    };
  
  
return (
    <>
    <Box sx={{ display: "flex"}}>
      <CssBaseline />
     
      <Drawer
        open={openPurchaseList}
        onClose={toggleDrawer(openPurchaseList, false)}
        sx={{
          display :{ xs: 'flex' , sm: 'flex', md: 'flex' },
          //width: 100,
          flexShrink: 3,
          "& .MuiDrawer-paper": {
            width: { xs: '50%' , sm: '30%', md: '22%' },
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"

      >
        <DrawerHeader>
        </DrawerHeader>
        <Divider />
        <List sx={{height: '85vh', overflow:'auto'}}>
              {productList &&
                <Grid item xs  className='shopping-list ' justifyContent="center" alignItems="center"  sx={{minheight: '100vh'}}>
                    <PurchaseList productList={productList} orderData={orderData} updateOrder={UpdateOrder}  setIdToEdit={setIdToEdit}></PurchaseList> 
                </Grid>
              }
              {!productList && !loading &&
                <Grid item className='empty-list ' justifyContent="center" alignItems="center">
                    <GenericEmptyTable sx={{minheight: 700}} msg={"Esta orden esta vacia"} />
                </Grid>
              }
                
        </List>
        <Grid item justifyContent="end"  alignItems="stretch" >
                  {orderData && 
                    <TotalCard orderData={orderData}></TotalCard>
                  }
                </Grid>
      </Drawer>
      <Outlet />
     
    </Box>
    </>
  );
}