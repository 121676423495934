import React from "react";
import Modal from "@mui/material/Modal";
import { Grid, Typography } from "@mui/material";
import "../scss/bottonModal.scss";

const BottonModal = (props) => {
  const { open, setOpen, children } = props;

  return (
    <div>
      <Modal
        open={open || false}
        onBackdropClick={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Grid container  justifyContent="center" className="modalBottom">
            <Grid
              className="box"
              item
              xs={12}
              sm={10}
              md={7}
              lg={6}
              xl={5}
              sx={{ bgcolor: "background.paper",  }}
            >
              {children}
            </Grid>
          </Grid>
        </>
      </Modal>
    </div>
  );
};

export default BottonModal;
