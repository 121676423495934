import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import img from '../../../assets/proboemas-02.png'



export default function ImgMediaCard(props) { 

  const {data, open, setOpen} = props

  return (
    <>
    
    <Card sx={{ maxWidth: 250 , height: 210}}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="150"
        width="200"
        image={data.model?.image ? data.model.image.url : img}
      />
      
      <CardContent sx={{padding:'8px'}}>
        <Typography variant="h8" component="div" className='bold-text'  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {data.model?.name ? data.model.name : 'Nombre De Producto'}
        </Typography>
        <Typography variant="h8" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {data.model?.product.name ? data.model.product.name : 'Nombre De Producto'}
        </Typography>
      </CardContent>
    </Card>
    </>
  );
}
