import { Grid, Pagination } from '@mui/material';
import React from 'react'


const PageParam = ({params, handleChange, totalpages}) => {

    if(parseInt(params.get("page"))> totalpages){
       handleChange("page", 1)     
    }
    return ( 
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", margin: "32px 0px"}}>
            {params && <Pagination name="page" id="page"
             showFirstButton showLastButton 
             count={totalpages} page={parseInt(params.get("page"))} 
             onChange={(e, value)=>handleChange("page", value)} color="primary" variant="outlined"  />}
        </Grid>
     ); 
}


 
export default PageParam;