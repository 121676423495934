import React, {useState, useEffect} from "react";
import { TextField, Grid, Typography } from "@mui/material";

const Step3 = () => {
    return (
        <Grid container item xs={12} className="final-step"  direction="column">
            <Grid sx={{textAlign: "center"}} >

            <Typography 
                    variant="h4" 
                    sx={{color: "text.primary"}}
                    component="div" >
                    <strong>¡Enhorabuena!</strong> 
                </Typography>
                <Typography 
                    variant="h6" 
                    sx={{color: "text.primary", }}
                    component="div" 
                    >
                    Tu negocio ha quedado configurado, ahora puedes comenzar a utilizar las aplicaciones que seleccionaste.
                </Typography>

            </Grid>
        </Grid>
    )
}

export default Step3;