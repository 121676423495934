import React, { useEffect, useState} from 'react';
import Rutes from "./context/routes";
import {  ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { lightTheme } from "./scss/theme";
import { SnackbarProvider} from 'notistack'

export default function App(){
    const [mode, setMode]= useState('light');
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');
    
    const bottomRef = React.useRef();

    const changeMode = ()=>{  
       if(mode ==='light'){
        setMode('dark')
        localStorage.setItem('theme', 'dark');
       }else{
        setMode('light')
        localStorage.setItem('theme', 'light');
       }
    }
    useEffect(
     ()=>{
      
      const modethem = prefersDarkMode ? 'dark' : 'light'
      const themUse = localStorage.getItem('theme');
      setMode(themUse== null? modethem: themUse)
    }, [prefersDarkMode] /* isOnline */
    );

    return(
      
      <ThemeProvider theme={mode === 'light' ? lightTheme: lightTheme }>
        <SnackbarProvider maxSnack={5}>
          <Rutes mode={mode} changeMode={changeMode}/>
          <div className="bottomContainerElement" ref={bottomRef} />
          </SnackbarProvider>
      </ThemeProvider>
      
  );
        
}