import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export  class RegisterCash extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'pos/cash_register';
      super(baseUrl, endPoint)
    }
}

export  class CashOrder extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/pos_order';
    super(baseUrl, endPoint)
  }
}



export  class RegisterCashPreview extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/cash_register/preview';
    super(baseUrl, endPoint)
  }
}