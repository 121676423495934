import React, { useState } from "react";
import Container from "../../layout/gridContainer";
import { GenericHeaderTitle } from "../GenericHeader";
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { BottonModalDiag, ModalRight } from "../GenericsModals";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import { Sections } from 'keski_lib_catalog'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { SectionsCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import { UseerrorOrsuccess, useNotification } from "../../hooks/snackBarResponse"
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { BusinessSettings } from "../../api/businessManages";

import AddIcon from '@mui/icons-material/Add';
import { ButtonsStyles, TabStyles } from "../TabMenu/TabStyles";
import DetailCreator, { details } from '../keskiCreator/details'
import DetailCreatorElement from "./detailsContent";
import { BgStyle } from '../keskiCreator/details'
import { ConfirmAction } from "../ConfirmAction/ConfirmAction";


const Creator = ({ view }) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState("");
  const [sections, setSections] = useState()
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState("");
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [viewId, setViewId] = useState("")
  const [selectedSection, setSelectedSection] = useState(""); // Estado para almacenar el ID de la sección seleccionada
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);

  const SECA = new Sections()
  const SectionCreator = new SectionsCreator()


  useEffect(() => {

    setList(SECA.getSectionList())
    console.log('length', list)
    UpdateSectionsList()
    UpdateStyles()

  }, [view]);

  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      setPages(response.data);
    })();
  };

  const UpdateSectionsList = async () => {
    setLoading(true)
    let get = new SectionsCreator();
    /* let response = await get.getAllAuth(logout); */
    let irequestFilter = []
    irequestFilter.push({ 'key': 'view', 'value': view })
    let response = await get.filter(irequestFilter, logout);

    setSections(response.data);
    setLoading(false)
  }
  const handleDeleteSection = async (id) => {
    let deleteUser = new SectionsCreator();
    let response = await deleteUser.deleteAuth(id, logout);
    sendNotification(UseerrorOrsuccess(response))
    UpdateSectionsList();
  }

  const UpDownSection = async (id, formData) => {

    setLoading(true)
    let updateComment = new SectionsCreator();
    const response = await updateComment.editDataAuth(id, formData, logout);
    UpdateSectionsList()

  }

  //==================For the ModalRight about "Banner"
  const [openModal, setOpenModal] = useState(false);


  const handleOpenModal = (id, type, index) => {
    console.log("ID de la sección:", id);
    setViewId(id);
    setSelectedSection(type);
    setSelectedSectionIndex(index);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //====================

  //====================For the background and content buttons
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  //=================================

  /*----------Estados para manejar el DialogModal ------------*/
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  //const section = sections.find(section => section.id === confirmationData.id);

  const handleOpenDialog = (id) => {
    setConfirmationData(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };



  //=================================CALIZ DE CREATOR============//

  return (
    <Container>
      {list && <AddSeccion open={open} setOpen={setOpen} seccionList={list} SECA={SECA} view={view} update={UpdateSectionsList} />}
      <GenericHeaderTitle
        title={
          <>
            <Typography variant="h6"><strong>Keski creator</strong></Typography>{" "}
            <Typography>
              Mantén al día tu página de inicio con nuestro sistema de creación de páginas
            </Typography>{" "}
          </>
        }
      >
        {pages?.domain && <a href={"https://" + pages.domain + "/"} target="_blank"><Button>Ver mi pagina</Button></a>}
      </GenericHeaderTitle>
      <Grid Container>
        <Grid item xs={12} mt={"16px"}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Secciones</Typography>
            <Button onClick={() => setOpen(true)}>Agregar</Button>
          </Box>
          <Divider sx={{ height: '1px', borderColor: "text.primary", backgroundColor: "text.primary", }} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" >
            <ModalRight title={`${selectedSectionIndex + 1} - ${list[selectedSection]}`}
              open={openModal}
              setOpen={setOpenModal}
            >
              <TabStyles
                backgroundContent="Background"
                contentContent="Contenido"
                activeTab={activeTab}
                handleTabChange={handleTabChange}
              >
                {activeTab === 0 && (
                  <Box>
                    <DetailCreator view={viewId} />
                  </Box>
                )}
                {activeTab === 1 && (
                  <Box>
                    <DetailCreatorElement view={viewId} />
                  </Box>
                )}
              </TabStyles>
            </ModalRight>
            {sections && (
              <>
                {confirmationData && (
                  <ConfirmAction
                    open={openDialog}
                    handleOpen={handleOpenDialog}
                    handleClose={handleCloseDialog}
                    title={`Eliminar sección`}
                    content={`¿Estás seguro que deseas eliminar esta seccion?`}
                    disagreeText="Cancelar"
                    agreeText="Eliminar"
                    service={SectionCreator}
                    id={confirmationData.id}
                    getCurrent={UpdateSectionsList}
                  />
                )}
                {sections.map((row, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "8px",
                        margin: "8px 0px"
                      }}
                      key={index}
                    >
                      <div>{`${index + 1}- ${list[row.type]}`}</div>
                      <div>
                        <ArrowUpwardIcon onClick={() => UpDownSection(row.id, { "move": "down" })} sx={{ cursor: "pointer", margin: "0 6px " }} />
                        <ArrowDownwardIcon onClick={() => UpDownSection(row.id, { "move": "up" })} sx={{ cursor: "pointer", margin: "0 6px " }} />
                        <EditIcon onClick={() => handleOpenModal(row.id, row.type, index)} sx={{ cursor: "pointer", margin: "0 12px 0 32px" }} />
                        <CloseIcon onClick={() => handleOpenDialog({ id: row.id, title: row.title })} sx={{ cursor: "pointer" }} />
                      </div>
                    </Box>
                    <Divider sx={{ height: '1px', borderColor: "text.primary", width: "90%", margin: "auto", backgroundColor: "text.primary", }} />
                  </div>
                ))}
              </>
            )}




          </Stack>
        </Grid>
        {!sections && loading && (
          <Grid container justifyContent="center" p={"24px"}>
            <CircularProgress />
          </Grid>
        )}


      </Grid>

    </Container >
  );
};




const AddSeccion = ({ open, setOpen, seccionList, SECA, update, view }) => {
  const [layout, setLayout] = useState("")
  /*   console.log(Object.values(seccionList)) */
  const [msg, sendNotification] = useNotification();

  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  const { logout } = useAuth();

  const setChanges = (e) => {
    console.log(e.target.value)
    setLayout(e.target.value)
  }
  useEffect(() => {


  }, [])

  const PostNewSection = async (id, formData) => {

    let form = {
      name: seccionList[layout],
      view: view,
      type: layout,
      component_layout: activeIndex,
      component_type: SECA.getLayoutsFromSection(layout).getFootPrint(activeIndex).req.type || 0
    }
    console.log(form)
    console.log(form)

    let updateComment = new SectionsCreator();
    const response = await updateComment.postDataAuth(form, logout);


    sendNotification(UseerrorOrsuccess(response))
    setOpen(false); // Close the modal when you add the section
    update();
    //navigate(`detail/${response.data.id}`)

  }




  return (
    <BottonModalDiag setOpen={setOpen} open={open} title={{ text: "Seleccionar sección" }} size="90%">
      <Grid container>
        <Grid item xs={12} mt="5px">
          <FormControl /* fullWidth */ sx={{ width: "200px" }} variant="outlined" size="small" >
            <InputLabel id="demo-simple-select-filled-label">
              Sección
            </InputLabel>
            <Select
              name={"seccion"}
              labelId="demo-simple-select-standard-label"
              label={"Sección"}
              value={layout}
              onChange={(e) => setChanges(e)} >


              {seccionList && Object.values(seccionList).map((data, index) => (
                <MenuItem key={index} value={index}>
                  {data}
                </MenuItem>
              ))}
            </Select>


          </FormControl>
        </Grid>
        <Grid item xs={12} mt={"16px"} /* sx={{height: "300px"}} */>

          {(layout !== "") && <CarruselLayouts layout={layout} SECA={SECA} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", width: "100%", justifyContent: "flex-end", margin: "32px 0 16px 0" }}>
          {layout !== "" && <Button onClick={() => PostNewSection()} variant="contained">
            Seleccionar
          </Button>}

        </Grid>
      </Grid>

    </BottonModalDiag>
  );
}

const json = {
  title: 'Hola JSON',
  layout: 0,
  backgroundImage: 'https://img.freepik.com/free-vector/gradient-grainy-texture_23-2148981502.jpg?w=1380&t=st=1683581727~exp=1683582327~hmac=d5affc8319cef6a52eef12e756657ac1ff51c2e23c661b296fce256271d0b8aa',
  content: [
    {
      title: 'Hola child'
    }
  ]
}

const json2 = {
  title: 'Hola JSON',
  layout: 0,
  backgroundImage: 'https://img.freepik.com/free-vector/gradient-grainy-texture_23-2148981502.jpg?w=1380&t=st=1683581727~exp=1683582327~hmac=d5affc8319cef6a52eef12e756657ac1ff51c2e23c661b296fce256271d0b8aa',
  content: [
    {
      title: 'Hola lgjasdklfgjasdkljasflñkasjklñadfjasdfkl'
    }
  ]
}

const CarruselLayouts = ({ layout, SECA, activeIndex, setActiveIndex }) => {
  const [ComponentRender, setComponentRender] = useState("")
  const [LayoutListLength, setLayoutListLength] = useState("")
  const [item, setItem] = useState("")
  /*   var ComponentRender; */
  /* var LayoutListLength; */

  useEffect(() => {
    console.log(layout)
    setComponentRender(SECA.getLayoutsFromSection(layout))
    let temRender = SECA.getLayoutsFromSection(layout)
    setLayoutListLength(temRender.getFootPrint()) /* getFootPrint  layoutList*/
    console.log('list length', LayoutListLength)
    setItems(temRender.layoutLength(), SECA.getLayoutsFromSection(layout))
  }, [layout])

  const setItems = (LayoutListLength, ComponentRender) => {
    const list = [];
    console.log("layout list lenght", LayoutListLength)
    for (let i = 0; i <= LayoutListLength - 1; i++) {  /* ComponentRender.getFootPrint(i).req.type */
      /* console.log(i) */
      list.push(<div className="item" data-value={i}> <img src={ComponentRender.getFootPrint(i).img} style={{ width: "100%" }} /> </div>) /* {ComponentRender.component(i==0?json:json2,  i)}  </div> */
    }

    setItem(list)
  }

  return (
    <>
      {/*   { LayoutListLength && ComponentRender &&  <Box sx={{height: "200px"}}>
        { Object.keys([...Array(LayoutListLength)]).map((index)=>(
           <Box sx={{height: "200px", width:"50%"}}> {ComponentRender.component(json,  index)} sss</Box>
        ))

        }
        </Box>} */}
      {/*     { Object.keys([...Array(LayoutListLength)]).map((index)=>(
         <>fasdfasf asdfasdf {index}</>
        ))

        } */}
      <>{item && <Carousel itemm={item} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />}</>

      {/* <>{ComponentRender.component({content:json.content, layout: 0} )}</> */}
    </>
  );
}
const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
  return items.map((item, i) => (
    <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
      {item}
    </div>
  ));
};

const Carousel = ({ itemm, activeIndex, setActiveIndex }) => {
  /* const [activeIndex, setActiveIndex] = useState(0); */
  const [items, setItems] = useState(itemm);

  useEffect(() => {
    setItems(itemm)
  }, [itemm])

  const slidePrev = () => {

    if (0 <= activeIndex - 1) { setActiveIndex(activeIndex - 1); }
  };
  const slideNext = () => {

    if (activeIndex + 1 <= itemm.length - 1) {
      setActiveIndex(activeIndex + 1);

    }
  };
  const syncActiveIndex = ({ item }) => setActiveIndex(item);
  useEffect(() => {

  })

  return (

    <>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {/*  <Button onClick={slidePrev}>Prev</Button> */}
        <IconButton onClick={slidePrev} >
          <ArrowBackIosIcon sx={{ color: 'text.secondary' }} />
        </IconButton>
        <Box sx={{ width: "90%" }}>
          <AliceCarousel
            mouseTracking
            disableDotsControls
            disableButtonsControls
            items={items}
            activeIndex={activeIndex}
            onSlideChanged={syncActiveIndex}
          />
        </Box>
        <IconButton onClick={slideNext} >
          <ArrowForwardIosIcon sx={{ color: 'text.secondary' }} />
        </IconButton>
        {/* <Button onClick={slideNext}>Next</Button> */}
      </Box>
      {/*      <div className="thumbs">


        
        
     </div>
      */}
    </>
  );
};

export default Creator;
