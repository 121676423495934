import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Box, Button, FormHelperText } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {  TableRow } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import { AdjustmentServiceItems } from "../../api/inventory/adjustmentService";
import GenericEmptyTable from "../GenericEmptyTable";
import {  AvailablePresentationInventory} from "../../api/availablePresentation";
import ImageSize from "../ImageSize";
import BasicTable from "../GenericTable";
import CustomizedDialogs, { TableModal } from "../GenericsModals";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { initValAdjustmentsAdd, valSchAdjustmentsAdd, initValInventoryAdjustment, valSchInventoryAdjustment } from "../../utils/formHelper";
import { AvailableAdjustmentAdd } from "../../api/availablePresentation";
import Presentation from "../../api/catalogPresentation";
import { formatNumber } from "../../utils/functions";


export default function AdjustmentModal(props) {
  const {
    open,
    setOpen,
    /* title, */
    reloadDataOforder,
    dataAdjustment,
    model, 
    Product,
  } = props;
  const [dataProduct, setDataProduct] = useState("");
  const { logout } = useAuth();
  const [add, setAdd] = useState(false)
  const [presentation, setPresentation] = useState("")

  console.log("dataProduct", dataProduct);
  console.log("model", model);

  async function getAvailableDataModal() {
    let irequestFilter = [];
    irequestFilter.push({ key: "wh", value: `${dataAdjustment.warehouse.id}` });
    irequestFilter.push({ key: "mdl", value: `${model.id}` });
    let productInventory = new AvailablePresentationInventory();
    let response = await productInventory.filter(irequestFilter, logout);
    setDataProduct(response.data);
  }
  useEffect(() => {
    getAvailableDataModal();
    getPresentations();
  }, []);

  async function getPresentations() {
    let irequestFilter = [];
    irequestFilter.push({ key: "mdl", value: `${model.id}` });
    let productInventory = new Presentation();
    let response = await productInventory.filter(irequestFilter, logout);
    setPresentation(response.data);
  }


  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={"Seleccionar Presentación"}
      className="table-adjustment"
      size={"md"}
    >
   
        <Grid container>
          <Grid item xs={12} mb="16px">
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Box sx={{ display: "flex" }}>
                  <ImageSize
                    img={
                      Product?.main_model?.image?.url
                        ? Product.main_model.image.url
                        : ""
                    }
                    weight={2}
                  />
                  <Box ml="16px">
                    <h3>{Product.name}</h3>
                    <h4>{Product.sku}</h4>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="end">
                  {/* <h4>{edit? article.category.name : article.category.name}</h4> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={"16px"}>
            <Alert severity="warning">
              <Typography
                variant="caption"
                sx={{ fontSize: "13px" }}
                display="block"
              >
                Disponible (real): Cantidad disponible total
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "13px" }}
                display="block"
              >
                Disponible (- reservado): Cantidad disponible total menos los
                productos reservados en órdenes de venta, producción,
                transferencia, etc. Cantidad contada: Cantidad contada
                físicamente en existencia.
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "13px" }}
                display="block"
              >
                Diferencia: disponible (real) – Cantidad contada
              </Typography>
            </Alert>
          </Grid>

          <Grid item xs={12} sx={{ maxHeight: "45vh", overflow: "auto" }}>
            {dataProduct && (
              <BasicTable
                titles={[
                  "Presentación",
                  "Barcode",
                  "Disponible (real)",
                  "Disponible (-reservado)",
                  "Cantidad contada",
                  "Diferencia",
                  "Acción",
                ]}
              >
              {dataProduct.map((rowList, indexList) => (
                rowList.items.map((row, index) => (
                  <RowInfo
                    row={row}
                    key={index}
                    setOpen={setOpen}
                    dataProduct={dataProduct}
                    //idArticle={article.id}
                    getAvailableDataModal={getAvailableDataModal}
                    dataAdjustment={dataAdjustment}
                    reloadDataOforder={reloadDataOforder}
                  />
                  ))
                  ))}
              </BasicTable>
            )}
                  
      {!dataProduct && (
        <GenericEmptyTable
          msg={`No se encontraron resultados en el ${dataAdjustment.warehouse.name}`}
        />
      )}
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"end"}>
              {/*      <form onSubmit={formik.handleSubmit}>
                
              </form> */}
            </Grid>
          </Grid>
          <Grid item xs={12} mt={"16px"}>
            <Grid container justifyContent={"end"}>
              <Button variant="text" onClick={()=> setAdd(!add)}>
                No aparece en la lista, agrega uno nuevo
              </Button>
            </Grid>
            { add &&
            <Grid container  mt="16px">
              <AddNewProductAdjustment data={dataAdjustment} update={getAvailableDataModal} presentation={presentation} reloadDataOforder={reloadDataOforder}
              model={model} add={add}/>
            </Grid>}
          </Grid>
        </Grid>
{/*       
      {!dataProduct && (
        <GenericEmptyTable
          msg={`No se encontraron resultados en el ${dataAdjustment.warehouse.name}`}
        />
      )} */}
    </CustomizedDialogs>
  );
}

const AddNewProductAdjustment = ({data, update, model,add, presentation, reloadDataOforder}) => {
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification(); 
  const { logout } = useAuth();
/*   console.log(data) */

  const setNewProductAdjustment = async (formData) => {
    let addProduction = new AvailableAdjustmentAdd();
    let response = await addProduction.postDataAuth(formData, logout); 
    reloadDataOforder()
 

    sendNotification(UseerrorOrsuccess(response))
    
  }
 
  useEffect(()=>{
    return()=>{
      formik.resetForm()
    }

  },[add])


  const formik = useFormik({
    initialValues: initValAdjustmentsAdd(data, model),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAdjustmentsAdd()),
    onSubmit: async (formData, {resetForm}) => {
      setLoading(true);
      if(formik.values.barcode == ""){
        formik.values.barcode = null
      }
      console.log(formData)
      setNewProductAdjustment(formData)
      update() 
      resetForm()
      setLoading(false);
 
    },
  });


  return (
    <Grid item xs={12}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent={"end"}>
      <Grid item xs={3} mr={"8px"}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-filled-label" >
            Presentaciones
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="presentation"
            name="presentation"
           label={"Presentaciones"}
                value={formik.values.presentation}
            onChange={formik.handleChange}
            error={
              formik.touched.presentation &&
              Boolean(formik.errors.presentation)
            } 
          >
{/*             <MenuItem value={""}>Todo</MenuItem>
            <MenuItem value={"2"}>222</MenuItem> */}
                       {presentation.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                        {data.name}
                    </MenuItem>
                ))}
          </Select>
          <FormHelperText sx={{color: "red"}}>
                        {formik.touched.presentation ? formik.errors.presentation : ""}
                      </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={2} mr={"8px"}>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="none"
          size="small"
          type="number"
          id="quantity"
          label="Cantidad contada"
          value={formik.values.quantity}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.quantity && Boolean(formik.errors.quantity)
                      }
                      helperText={
                        formik.touched.quantity ? formik.errors.quantity : ""
                      }
        />
      </Grid>
      <Grid item xs={2} mr={"8px"}>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="none"
          size="small"
          id="barcode"
          label="Barcode"
          value={formik.values.barcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.barcode && Boolean(formik.errors.barcode)
                      }
                      helperText={
                        formik.touched.barcode ? formik.errors.barcode : ""
                      }
        />
      </Grid>
      <Grid item xs={2}>
      <LoadingButton
          loading={loading}
          fullWidth
          type="submit" 
          variant="contained"
          sx={{ color: "#fff" }}
        >
          Agregar
        </LoadingButton>
      </Grid>
      </Grid>
    </form>

    </Grid>
  );
};

const RowInfo = ({
  row,
  setOpen,
  dataProduct,
  idArticle,
  dataAdjustment,
  reloadDataOforder,
  getAvailableDataModal,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [cantidad, setCantidad] = useState(0);
  const [error, setError] = useState("");
  const [quantity, setQuantity] = useState("");

  /*   console.log('row', row) */

  const handleCantidad = (e) => {
    setQuantity(e.target.value);
  };
  const formik = useFormik({ 
    initialValues: initValInventoryAdjustment(dataAdjustment.id, row.id), 
    enableReinitialize: true,
    validationSchema: Yup.object(valSchInventoryAdjustment()),
    onSubmit: async (formData) => {
      addArticleTransfer(formData)

    },
  });

/*   const SentDelivered = () => {
    console.log("entre");
    let number = quantity;
    console.log(number);
    if (quantity !== null && quantity !== 0 && isFinite(quantity)) {
      if (number > 0.0) {

            addArticleTransfer();
            setError("");

      } else {
        setError("La cantidad menor a 0");
      }
    } else {
      setError("Campo no valido");
    }
  }; */

  const addArticleTransfer = async (formData) => {
    setLoading(true);
    /*     const formData = {
      transfer_order : dataAdjustment.id,
      reserved: idArticle,
      available: row.id,
      presentation: dataProduct.presentation.id,
      model: row.model.id,
      quantity: quantity
    }; */
/*     const formData2 = {
      adjustment_order: dataAdjustment.id,
      available: row.id,
      quantity: quantity,
    };
    console.log(formData2);  */

    let products = new AdjustmentServiceItems();
    let dataRes = await products.postDataAuth(formData, logout);
    console.log(dataRes.data.data);
    reloadDataOforder();
    getAvailableDataModal(); 
    setLoading(false);
    /*     setOpen(false) */
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15px" }}>
        <Typography variant="subtitle2" component="div" sx={{ m: 0, p: 0 }}>
          {row.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
        <Typography variant="subtitle2" component="div" sx={{ m: 0, p: 0 }}>
          {row.barcode}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
        <Typography variant="subtitle2" component="div" sx={{ m: 0, p: 0 }}>
          {row.available}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
        <Typography variant="subtitle2" component="div" sx={{ m: 0, p: 0 }}>
        {row.quantity}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ minWidth: "150px", maxWidth: "5vw" }}>
      <form onSubmit={formik.handleSubmit}>
      <TextField
      /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
                   type="number"
    
                    fullWidth
                    label="Cantidad"
                    size="small"  
                    name="quantity"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.quantity && Boolean(formik.errors.quantity)
                    }
                   
                    helperText={
                        formik.touched.quantity ? formik.errors.quantity : ""
                    }
                    />

      </form>
{/*         <Typography variant="subtitle2" component="div" sx={{ m: 0, p: 0 }}>
          <TextField
            sx={{ m: 0, p: 0 }}
            fullWidth
            type="number"
            name="quantity"
            value={quantity}
            onChange={handleCantidad}
            error={error ? true : false}
            helperText={error ? error : ""}
            label="Cantidad"
            margin="dense"
            size="small"
            variant="outlined"
          />
        </Typography> */}
      </TableCell>
      <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            m: 0,
            p: 0,
            color: formik.values.quantity && row.quantity - formik.values.quantity != 0 ? "#FF0000" : "#008000",
          }}
        >
          {formik.values.quantity ? formatNumber(row.available - formik.values.quantity, 2)  : 0}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: "200px" }}>
      <form onSubmit={formik.handleSubmit} >
        <LoadingButton
          loading={loading}
          fullWidth
          variant="text"
          type="submit"
          sx={{ color: "primary", padding: 0 }}
        >
          Ajustar
        </LoadingButton>
        </form>
      </TableCell>
    </TableRow>
  );
};
