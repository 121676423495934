import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export  class ProductionOrder extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'inventory/production_order';
      super(baseUrl, endPoint)
    }
}
export  class Production extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/production';
    super(baseUrl, endPoint)
  }
}

export  class ProductionOrderRecipe extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/production_order_recipe';
    super(baseUrl, endPoint)
  }
}

export  class ProductionOrderVerify extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/production_order/verify';
    super(baseUrl, endPoint)
  }
}
export  class ProductionReceive extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/production/received';
    super(baseUrl, endPoint)
  }
}
export  class ProductionAll extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/production_all'; 
    super(baseUrl, endPoint)
  }
}



