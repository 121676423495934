import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { TextField, FormHelperText } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { initValAddArticle, valSchAddArticle } from "../../../utils/formHelper";
import CategoryManages from "../../../api/categoryManages";
import Products from "../../../api/productsManages";
import { useAuth } from "user-context-fran-dev";
import CountWords from "../../../components/CountWords";

import { useNavigate } from "react-router-dom";
import GenericEmptyTable from "../../../components/GenericEmptyTable";
import {UseerrorOrsuccess,  useNotification } from "../../../hooks/snackBarResponse";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { 
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      
    },
  },
};

const AddArticle = ({ udateProducts }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      formik.resetForm(); 
    };
  }, [open]);

  const getCategory = () => {
    (async () => {
      let getCategory = new CategoryManages();
      let response = await getCategory.getAllAuth(logout);
      setCategory(response.data);
    })();
  };

  const formik = useFormik({
    initialValues: initValAddArticle(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddArticle()),
    onSubmit: async (formData,{resetForm}) => {
      setLoading(true);
      console.log(formData)
      let setProduct = new Products();
      let response = await setProduct.postDataAuth(formData, logout);
      sendNotification(UseerrorOrsuccess(response))
      
      if(response){
        
        window.localStorage.setItem("menu", 10);
        setLoading(false);
        setOpen(false);
        navigate(`detail/${response.data.id}`)
      }
      setLoading(false);
      resetForm()
      setOpen(false);
      
    },
  });

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          getCategory();
          setOpen(!open);
        }}
        sx={{}}
      >
        Agregar
      </Button>
      <BasicModal open={open} setOpen={setOpen} title="Agregar Artículo">
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="name"
                  values={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  label="Nombre del artículo"
                  margin="dense"
                  size="small"
                  variant="outlined" 
                  autoComplete ='none'
                  helperText={formik.touched.name ? formik.errors.name : ""}
                />
                <CountWords length={formik.values.name.length.toString()}/>
               
                
                {category && (
                  <FormControl fullWidth variant="outlined"  size="small" >
                    <InputLabel id="demo-simple-select-filled-label">
                      Asignar Categoría
                    </InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                      name="category"
                      label="Asignar Categoría"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                    >
                      <MenuItem value="">
                        <em>Categoría</em>
                      </MenuItem>
                      {category.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.category ? formik.errors.category : ""}
                    </FormHelperText>
                  </FormControl>
                )}
                {
                  !category && 
                  <GenericEmptyTable msg="Para continuar añade una categoría primero"/>
                }
              </Grid>

  {/*             <Grid item xs={12} sm={9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_preparation_time"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.is_preparation_time
                          ? formik.errors.is_preparation_time
                          : ""
                      }
                    />
                  }
                  label="Tiempo de preparación"
                />
                <FormHelperText>
                  {formik.touched.is_preparation_time
                    ? formik.errors.is_preparation_time
                    : ""}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl size="small" fullWidth sx={{ m: 1,  }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Días
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="preparation_time"
                    value={formik.values.preparation_time || ""}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.preparation_time &&
                      Boolean(formik.errors.preparation_time)
                    }
                    autoWidth
                    MenuProps={MenuProps}
                  
                    label="Días"
                  >
                    <MenuItem value="">
                      <em>Días</em>
                    </MenuItem>
                    <MenuItem value={1}>{`1 día`}</MenuItem>
                    {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (number) => (
                        <MenuItem
                          key={number}
                          value={number}
                          
                          
                        >{`${number} días`}</MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText>
                    {formik.touched.preparation_time
                      ? formik.errors.preparation_time
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6} md={6} lg={6} />
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <LoadingButton
                  loading={loading}
                  disabled={category === null}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Agregar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </BasicModal>
    </>
  );
};


export default AddArticle;
