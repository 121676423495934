/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    Typography,
    TextField
} from "@mui/material";
import CustomizedDialogs, { BottonModalDiag } from "../GenericsModals";
import { useAuth } from "user-context-fran-dev";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initSearchParameters, valSearchparameters } from "../../utils/formHelper";
import { LoadingButton } from "@mui/lab";
import SearchFilters from "../inventario/searchFilters";
import { ProductService } from "../../api/productService";
import BasicTable from "../GenericTable";
import GenericEmptyTable from "../GenericEmptyTable";
import { CircularProgress } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CircleIcon from '@mui/icons-material/Circle';
import ImageSize from "../ImageSize";


const ProductLink = ({ open, setOpen, formiko }) => {
    const [filters, setFilters] = useState("");
    const [data, setData] = useState("");
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: initSearchParameters(),
        validationSchema: Yup.object(valSearchparameters()),
        onSubmit: async (formData) => {
            setLoading(true);
            setData('')
            let irequestFilter = []
            if (filters) {
                for (let element in filters) {
                    if (filters[element]) {
                        irequestFilter.push({ 'key': `${element}`, 'value': `${filters[element]}` })
                    }
                }
            }
            irequestFilter.push({ 'key': 'is_active', 'value': `true` }) // Cambiar en todo
            irequestFilter.push({ 'key': 'pattern', 'value': `${formik.values.name}` })
            /*       irequestFilter.push({'key':'page', 'value': `1`})
                  irequestFilter.push({'key':'items', 'value': `10`})
                   */
            let products = new ProductService();
            let dataRes = await products.filter(irequestFilter, logout);
            setData(dataRes.data)

            setLoading(false);
        },
    });

    return (
        <CustomizedDialogs open={open} setOpen={setOpen} title={"Seleccionar Producto"} size={"md"}>
            <Grid container>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item xs={9} sm={4} mr="16px" >
                            <TextField
                                fullWidth
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name && Boolean(formik.errors.name)
                                }
                                label="Buscar por nombre"
                                /* margin="normal" */
                                size="small"
                                variant="outlined"
                                /* className="space-text-field" */
                                helperText={
                                    formik.touched.name ? formik.errors.name : ""
                                }
                            />
                        </Grid>
                        <Grid item xs={3} sm={2} >
                            <LoadingButton
                                loading={loading}
                                fullWidth
                                type="submit"
                                variant="contained"
                            >
                                Buscar
                            </LoadingButton>
                        </Grid>

                        <SearchFilters filters={filters} setFilters={setFilters} />
                    </Grid>
                </form>
                <Grid item xs={12}>
                    {data && <BasicTable
                        titles={["Imagen", "Nombre", "Estatus", "Categoría", "Más"]}
                    >
                        {data.map((data, index) => (

                            <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                hover
                            >

                                <TableCell align="left" sx={{ maxWidth: '150px' }}>
                                    <ImageSize img={data.main_model?.image ? data.main_model.image.url : ""} weight={1} />
                                    {/*  <img alt="sample"  src={data.main_model?.image? data.main_model.image.url: ""}
                               style={{width: '30px', height: 'auto'}}
                           ></img> */} </TableCell>
                                <TableCell sx={{ maxWidth: '250px' }} align="left">

                                    <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {data.name}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    {(data.is_active) ? <CircleIcon sx={{ color: 'green', fontSize: 15 }} /> :
                                        <CircleIcon sx={{ color: 'red', fontSize: 15 }} />}
                                </TableCell>
                                <TableCell align="left">

                                    <Typography component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {data.category.name}
                                    </Typography>

                                </TableCell>

                                <TableCell>
                                    <Button onClick={() => { formiko.values.button_action = "/product/" + data.id; setOpen(false) }}>Seleccoinar</Button>

                                </TableCell>

                            </TableRow>


                        ))}


                    </BasicTable>}

                    {!data && loading && (
                        <Grid container justifyContent="center" p={"24px"}>
                            <CircularProgress />
                        </Grid>
                    )}
                    {!data && !loading && <GenericEmptyTable />}
                    {/* {!data && !loading  && <GenericEmptyTable msg={`No se encontró resultado para "${params.get("pattern")}"`} />} */}
                </Grid>
            </Grid>

        </CustomizedDialogs>
    );
};

export default ProductLink;