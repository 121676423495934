import React, {useEffect, useState, useRef} from 'react'
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
  } from "@mui/material";
import { OrderService } from "../../../api/pos/ordersService";
import {useAuth} from 'user-context-fran-dev';
import { TicketPreview } from './ticketPreview';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from '@mui/material/ButtonGroup';
import { green } from '@mui/material/colors';
import MoneyIcon from '@mui/icons-material/Money';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import { PayProcessService } from '../../../api/pos/payProcessService';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'
import CurrencyFormat from 'react-currency-format';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useReactToPrint } from 'react-to-print';

export default function PayProcess(){

    const [data, setData] = useState(null)
    const {logout, auth}= useAuth();
    const [number, setNumber] = useState('')
    const [pending,setPending] = useState(0)
    const [change, setChange] = useState(0)
    const [total, setTotal] = useState(0)
    const [iva, setIva] = useState(0)

    const [payTypeFlag, setPayTypeFlag] = useState('')
    const [payTypeEdit, setEditType] = useState('')

    const [creditState, setCreditState] = useState(false)
    const [creditAmount, setCreditamount] = useState(0)

    const [cashState, setrCashState] = useState(false)
    const [cashAmount, setCashAmount] = useState(0)

    const [print, setPrint] = useState(false)
    const navigate = useNavigate();
    const { id } = useParams()
    const [msg, sendNotification] = useNotification();
    
    //console.log('entro a update', auth.cashregister.cr.id)
    const componentRef = useRef();
    

    const handlePrint = useReactToPrint({
        
      content: () => componentRef.current,
    });

    useEffect(()=>{
        UpdateOrder();
        calculatePending()
    }, [])

    function handleAnswerChange(e){
      console.log(e.target.value)
      
     
  }


    useEffect(()=>{
        calculatePending()
    }, [cashAmount, creditAmount])

    const UpdateOrder = () => {
        
        (async () => {
        let orderService = new OrderService()
        let response = await orderService.getAllByIdAuth(id, logout)
        console.log(response.data)
        setTotal(response.data.total)
        setPending(response.data.total)
        setIva(response.data.iva)
        setData(response.data)
    })();
    }

    const setPayType = (type) =>{
        setPayTypeFlag(type)
        if(type === 1){
            setrCashState(true)
            console.log('entro a setype')
            setCashAmount(pending)
        }else if(type === 2){
            setCreditState(true)
            console.log('entro a setype')
            setCreditamount(pending)
        }
    }

    const deleteAmount = (type) =>{
        type === 1 ? setrCashState(false) : setCreditState(false)
        type === 1 ? setCashAmount(0) : setCreditamount(0)
        setEditType('')
        setPrint(false)
    }

    const calculatePending = () =>{
        console.log('entro a calculate')
        if(cashState || creditState){
            let splitSummary = Number(cashAmount) + Number(creditAmount) 
            let rest = total - splitSummary
            console.log('split sumary', splitSummary)
            console.log('split sumary', total - splitSummary)
            if(rest < 0){
                console.log('neagativo', total - splitSummary)
                setChange(-1*rest)
                setPending(0)
                
            }else{
                setChange('0')
                setPending(total - splitSummary)
            }
        }
        
    }

    const goBack = () =>{
      navigate(-1)
    }

    const pay = () =>{
        (async () => {
            let split = []
            if (cashAmount !== 0){
                split.push({"type": 1, "amount": cashAmount <= Number(data.total) ?  cashAmount : data.total, "pos_order": id})
            }
            if(creditAmount !== 0){
                split.push({"type": 2, "amount": creditAmount, "pos_order": id})
            }
            setPrint(true)
            console.log('cash amount', data)
            const formData = {
                "opcode": "pay",
                "split": split
                }
            let pay = new PayProcessService()
            let response = await pay.editDataAuth(id, formData, logout)
            sendNotification(UseerrorOrsuccess(response))
            UpdateOrder()
        })();
    }

    const KeyBoard = (props) => {
     
        return(
          <Grid continer container
          direction="column"
          justifyContent="center"
          alignItems="stretch">
          <Grid sx={{margin:2, display: { xs: 'flex', md: 'flex', lg: 'none' }}} >
          <ButtonCal label={'Enter'}/>
          <Grid sx={{margin:2, display: { xs: 'flex', md: 'flex', lg: 'none' }}} >
            <TextField id="outlined-basic" sx={{margin: 1, marginBottom: 2,width: 140}} label="Cantidad"  
                type='number' required value={number} onChange={handleAnswerChange} variant="outlined" />
            </Grid>
            </Grid>
              <Grid continer  direction="row" sx={{ display: { xs: 'none', md: 'none', lg: 'flex' }}} >
                    <Grid item xs>
                        <Box sx={{
                            display: 'flex',
                            '& > *': {
                            m: 0,
                            },
                        }}
                        >
                            <ButtonGroup
                                orientation="horizontal"
                                aria-label="vertical contained button group"
                                variant="outlined"
                            >   
                                <ButtonCal label={'7'}/>
                                <ButtonCal label={'8'}/>
                                <ButtonCal label={'9'}/>
                            </ButtonGroup>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            '& > *': {
                            m: 0,
                            },
                        }}
                        >
                            <ButtonGroup
                                orientation="horizontal"
                                aria-label="vertical contained button group"
                                variant="outlined"
                            >
                                <ButtonCal label={'4'}/>
                                <ButtonCal label={'5'}/>
                                <ButtonCal label={'6'}/>

                                
                            </ButtonGroup>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            '& > *': {
                            m: 0,
                            },
                        }}
                        >
                        <ButtonGroup
                            orientation="horizontal"
                            aria-label="vertical contained button group"
                            variant="outlined"
                        >   
                            <ButtonCal label={'1'}/>
                            <ButtonCal label={'2'}/>
                            <ButtonCal label={'3'}/>
                            
                        </ButtonGroup>
                    
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            '& > *': {
                            m: 0,
                            },
                        }}
                        >
                        <ButtonGroup
                            orientation="horizontal"
                            aria-label="vertical contained button group"
                            variant="outlined"
                        >
                          <ButtonCal label={'.'}/>
                            <ButtonCal label={'0'}/>
                            <ButtonCal label={'Delete'}/>
                            {/* <ButtonCal label={'Enter'}/> */}

                        </ButtonGroup>
                        </Box>
                    </Grid>
                    
          </Grid>
          </Grid>
        )
    }
    
    const ButtonCal = (props) => {
        
        const event = () =>{
            console.log('entro a event')
            if(props.label === 'Delete'){
                setNumber(number.slice(0, -1))
                payTypeFlag === 1 || payTypeEdit === 1 ? setCashAmount(number.slice(0, -1)) : setCreditamount(number.slice(0, -1))
            }else if(props.label === 'Clear'){
                setNumber('')
                payTypeFlag === 1  || payTypeEdit === 1 ? setCashAmount('') : setCreditamount('')
                
            }else{
                //setNumber('')
                setNumber(number+props.label)
                payTypeFlag === 1 || payTypeEdit === 1 ? setCashAmount(number+props.label) : setCreditamount(number+props.label)
            }
            
           
            
        }
        
        return (
            <>
            { props.label === 'Enter' &&
                <Button variant="contained" endIcon={<ArrowForwardIcon />} 
                type='submit' disabled={pending !== 0 } color="success" onClick={()=>{pay()}}>Validar</Button>
            }
             { props.label === 'Clear' &&
                <Button variant="contained" sx={{height: 80, width: 100}} onClick={()=>{event()}}> <DeleteIcon/></Button>
            }
             { props.label === 'Delete' &&
                <Button variant="contained" sx={{height: 80, width: 174, marginBottom:'11px', borderBottomColor: "error" }} color="error" onClick={()=>{event()}}><BackspaceIcon/></Button>
            }
             { props.label === '.' &&
                <Button variant="outlined" sx={{height: 80, width: 174}} color="primary" onClick={()=>{event()}}>
                        <Typography variant="h4" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {props.label}
                        </Typography> 
                </Button>
            }
            { props.label === '0' &&
                <Button variant="outlined" sx={{height: 80, width: 174}} color="primary" onClick={()=>{event()}}>
                        <Typography variant="h4" component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {props.label}
                        </Typography> 
                </Button>
            }
             { props.label !== 'Enter' && props.label !== 'Delete' && props.label !== 'Clear'  && props.label !== '0' && props.label !== '.' &&
                <Button sx={{height: 80, width: 175}}  key="one" variant="outlined" color='primary' onClick={()=>{event()}}>{props.label}</Button>
            }
            </>
        );
    } 
    

    return(
        <Container >

        
            <Grid container>
                <Grid container sx={{height: '60px'}} mt={1.5}>
                  <Grid xs  className="align-text ">
                    <Button variant="outlined" onClick={()=> {goBack()}} startIcon={<ArrowBackIcon />}>
                      Regresar
                    </Button>
                  </Grid>
                  <Grid xs  className="align-text ">
                    <Typography sx={{ fontSize: 24 , textOverflow: 'ellipsis', fontWeight:600}}  gutterBottom>
                      PAGO
                    </Typography>
                  </Grid>
                  <Grid xs  className="align-text ">
                    <ButtonCal label={'Enter'}/>
                  </Grid>
                  
                </Grid>
                <Grid>
                <Divider  mb={1.5}></Divider>
                </Grid>
            
                <Grid container >
                    
                    <Grid container className="align-text ">
                        <Grid container xs direction="column" justifyContent="start" alignItems="stretch">
                        
                        <Grid container direction="column" alignItems="stretch" justifyContent="space-between">
                        {!print &&
                            <>
                             
                                    <Grid item xs >
                                        {cashState &&
                                        <>
                                        <Typography sx={{ fontSize: 24 , textOverflow: 'ellipsis', fontWeight:600}}  gutterBottom>
                                        Ingrese el monto en efectivo que se le entrega
                                        </Typography>
                                        <Chip sx={{ fontSize: 25,  width: '60%', height:'60px',marginBottom: 3, color: payTypeEdit === 1 ? 'blue' : ''}}
                                        label={`Efectivo $${cashAmount ? cashAmount : '0'}`}
                                        onDelete={()=>{deleteAmount(1)}}
                                        color="primary"
                                        onClick={() => {setEditType(1)}}
                                        icon={<MoneyIcon sx={{fontSize: 30}} />}
                                        className="paymant-chip"
                                        />
                                        </>
                                    }
                                    </Grid>
                                    <Grid item xs sx={{marginTop: 1}}>
                                        {creditState &&
                                        <Chip sx={{fontSize: 25, width: '90%', height:'60px', marginBottom: 3, color: payTypeEdit === 2 ? 'blue' : ''}}
                                        label={`Tarjeta bancaria $${creditAmount ? creditAmount : '0'}`}
                                        onDelete={()=>{deleteAmount(2)}}
                                        color="primary"
                                        onClick={() => {setEditType(2)}}
                                        icon={<CreditCardIcon  sx={{fontSize: 30}}/>}
                                        className="paymant-chip"
                                        />
                                    }
                                     </Grid>
                                     </>
                            }
                            </Grid>
                            
                        
                          
                            <Grid container direction="column" alignItems="stretch" justifyContent="space-between" >
                            {!print &&
                            <>
                                <Grid item xs>
                                    <Button variant="contained" onClick={() => {setPayType(1)}} disabled={cashState}
                                        sx={{ width: '50%', marginBottom: 1}} 
                                    startIcon={<MoneyIcon />}>Efectivo</Button>
                                </Grid>
                                <Grid item xs>
                                    <Button  variant="contained" onClick={() => {setPayType(2)}} disabled={creditState}
                                    sx={{ width: '50%', marginBottom: 1,}} 
                                    startIcon={<CreditCardIcon />}>Tarjeta</Button>
                                </Grid>
                            </>
                            }
                            </Grid>
                            <Grid>   
                            {data?.ticket &&
                           <PrinTicket data={data} cashAmount={cashAmount} creditAmount={creditAmount} change={change} number={number} ref={componentRef}></PrinTicket>
                            }
                            </Grid>  
                        </Grid>
                        <Grid item xs >
                            <Grid container  justifyContent="center" sx={{display:'flex', marginTop:2, height: '200px'}} alignItems="center" > 
                                {(!cashState && !creditState) && 
                                <>
                                <Grid item xs={12}>
                                    {data &&<a className='totalPay'><CurrencyFormat value={pending} displayType={'text'} thousandSeparator={true} prefix={'$'}/></a>}
                                </Grid>
                                <Grid item xs sx={{margin: 2}}>
                                <a className=''>Por favor seleccione su forma de pago</a>
                                </Grid>
                                </>
                                }
                                {(cashState || creditState) && !print && 
                                <>           
                                <Grid  item xs={6} sx={{ marginBottom: 2}} justifyContent="center"  alignItems="stretch" className=''>
                                    <Grid container direction="row" justifyContent="center"  alignItems="center" xs={12} className=''>
                                        <h1>Restante:&nbsp;</h1><a className='previewPay' sx={{color: green}}>
                                            <CurrencyFormat value={pending} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/></a>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center"  alignItems="center"  xs={12}   className="">
                                        <h1 >Cambio:&nbsp;</h1><a className='previewPay' sx={{color: green}}>
                                        <CurrencyFormat value={change} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                        </a>
                                    </Grid>
                                </Grid>
                                <Grid item xs sx={{marginRight:4, marginBottom: 2}} justifyContent="start"  alignItems="stretch" >
                                    <Grid container direction="row" justifyContent="end"  alignItems="center" xs={12} >
                                        <h2>Subtotal:</h2>{data &&<a className='previewPaySubtotal' >{total ?
                                         <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/> :
                                          '00'}</a>}
                                    </Grid>
                                    <Grid container direction="row" justifyContent="end"  alignItems="center" xs={12} >
                                        <h2>IVA:&nbsp;</h2><a className='previewPaySubtotal' sx={{color: green}}>{iva ?
                                         <CurrencyFormat value={iva} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/> :
                                          '00'}</a>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="end"  alignItems="center" xs={12}>
                                        <h2>Total:&nbsp;</h2><a className='previewPaySubtotal' sx={{color: green}}>
                                        <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                        </a>
                                    </Grid>
                                </Grid>
                                </>
                                }
                            </Grid>
                            <Divider></Divider>
                            <Grid container sx={{marginTop: 3}} justifyContent="center" direction="column" alignItems="center" className="">
                            {!print &&
                                <>
                                <Grid container xs justifyContent="center" direction="row" alignItems="center">
                                <>
                                    </>
                                </Grid>
                                <Grid item  xs sx={{marginTop: 3}}>
                                {(cashState || creditState) &&
                                    <KeyBoard></KeyBoard>
                                }
                                </Grid>
                                </>
                                }
                                {print &&
                                <>
                                <Grid item xs sx={{margin: 3}} >
                                 <h1>Como te gustaría recibir tu pedido?</h1>
                                </Grid>
                                <Grid item  xs>
                                <Button  variant="contained" onClick={() => {handlePrint()}}
                                sx={{ height: 100, width: 450}} 
                                startIcon={<CreditCardIcon />}>Imprimir recibo</Button>
                                </Grid>
                                <Grid item xs >
                                    <TextField sx={{ height: 100, width: 450, margin:2}} id="outlined-basic" label="Enviar por correo" variant="outlined" />
                                </Grid>
                                </>
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                   
                </Grid>
            </Grid>
        </Container>
    )
} 
const PrinTicket = React.forwardRef((props, ref) => {
 const {data, cashAmount, creditAmount, change, number} = props
    return (  
        <>
        <style type="text/css" media="print">{"\
        @page {\ size: 80mm 80mm\ }\
        "}</style>
      <div  ref={ref} >
            <TicketPreview  data={data} cashAmount={cashAmount} creditAmount={creditAmount} change={change} number={number} />
        </div>
        </>
   );
  
   
});





