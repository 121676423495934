import React, { useEffect, useState } from "react";
import Container from "../../layout/gridContainer";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
} from "@mui/material";
import { GenericHeaderTitle } from "../GenericHeader";
import { ImageCreator } from "../../api/landing/creatorManages";
import nofound from "../../assets/no_found.png";
import { motion, usePresence, AnimatePresence } from "framer-motion";
import { useAuth } from "user-context-fran-dev";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import GenericEmptyTable from "../GenericEmptyTable";
import "../../scss/landingPage.css";
import ImgEdit from "../landing/ImgEdit";
import { useNotification, UseerrorOrsuccess } from "../../hooks/snackBarResponse";
import { useSnackbar } from "notistack";

const SIZETYPE = [
  {},
  { position: "relative", width: "320px", height: "180px", }, //16/9  id 1
  { position: "relative", width: "180px", height: "320px", }, //9:16  id 2
  { position: "relative", width: "320px", height: "180px", }, //16/9  id 3
  { position: "relative", width: "150px", height: "150px", }, //1:1   id 4
];

const SIZEIMG = [
  {},
  { width: 1280, height: 720, id: 1 },
  { width: 360, height: 640, id: 2 },
  { width: 640, height: 360, id: 3 },
  { width: 512, height: 512, id: 4 }
]

const ImageDetails = () => {
  const [imgStyle, setImgStyle] = useState(1);
  const [imgList, setImgList] = useState("");
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();


  const [openImg, setOpenImg] = useState(false);
  const [file, setFile] = useState();
  const [imgSize, setImgSize] = useState(SIZEIMG[1])
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar } = useSnackbar();


  const changeInput = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpenImg(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const UpdateImage = async () => {
    let irequestFilter = [];

    irequestFilter.push({ key: "type", value: `${imgStyle}` });


    let getDirec = new ImageCreator();
    let response = await getDirec.filter(irequestFilter, logout);
    setImgList(response.data);
    setLoading(false);
  };

  useEffect(() => {
    UpdateImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgStyle]);

  const DeleteImgs = (id) => {
    setImgList([...imgList.filter((x) => x.id !== id)]);

    (async () => {
      let images = new ImageCreator();
      // eslint-disable-next-line no-unused-vars
      let response = await images.deleteAuth(id, logout);
      UpdateImage();
    })();
  };

  const setImgStyleSize = (e) => {
    setLoading(true)
    setImgStyle(e.target.value);
    setImgSize(SIZEIMG[e.target.value])
    setImgList("")
  };

  const PostImage = (file) => {
    (async () => {

      var formData = new FormData();
      formData.append('imgUpload', file);
      formData.append('type', imgSize.id);
      let get = new ImageCreator();

      let response = await get.uploadImg(formData, logout);
      sendNotification(UseerrorOrsuccess(response))

      UpdateImage()

    })();
  };

  return (
    <Container>
      <Grid container mb={"32px"}>

        {file && openImg && imgSize && (
          <ImgEdit
            open={openImg}
            handleClose={setOpenImg}
            file={file}
            size={imgSize}
            update={PostImage}
          />
        )}


        <Grid item xs={12}>
          <GenericHeaderTitle title={"Editar imagenes"}></GenericHeaderTitle>
        </Grid>

        <Grid item xs={12} mt={"16px"}>
          <FormControl
            fullWidth
            /* variant="outlined" */ variant="standard"
            size="small"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Tipo de imagen
            </InputLabel>
            <Select
              fullWidth
              name={"Tipo de imagen"}
              labelId="demo-simple-select-standard-label"
              label={"Sección"}
              value={imgStyle}
              onChange={(e) => {
                setImgStyleSize(e);
              }}
            >
              <MenuItem key={1} value={1}>
                Background
              </MenuItem>
              <MenuItem key={2} value={2}>
                Rectangular vertical
              </MenuItem>
              <MenuItem key={3} value={3}>
                Rectangular horizontal
              </MenuItem>
              <MenuItem key={4} value={4}>
                Cuadrada
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={"32px"}>
          <ImageList
            list={imgList}
            imgStyle={imgStyle}
            DeleteImgs={DeleteImgs}
            changeInput={changeInput}
          />
        </Grid>
        <Grid item xs={12}>
          {!imgList && loading && (
            <Grid container justifyContent="center" p={"24px"}>
              <CircularProgress />
            </Grid>
          )}
          {!imgList && !loading && (
            <GenericEmptyTable msg={"No hay imagenes para mostrar"} />
          )}
        </Grid>
      </Grid>

    </Container>
  );
};
const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 };

export default ImageDetails;

function ImageList({ list, imgStyle, DeleteImgs, changeInput }) {
  /* const [items, setItems] = useState(list); */

  /* const reset = () => setItems([...list]); */
  /*  const removeSelf = (n) => {
    
    setItems([...items.filter((name) => name.id !== n)]);
  }; */

  return (
    <div>
      <div className="buttons">

      </div>
      <AnimatePresence>


        <AddNewImg imgStyle={imgStyle} key={2000} changeInput={changeInput}></AddNewImg>

        {list &&
          [...list].map((name, index) => (
            <ListItem
              /*    imgStyle={imgStyle} */
              key={name.id}
            /* onClick={() => DeleteImgs(name.id)} */
            >
              <>
                <div style={SIZETYPE[imgStyle]}>
                  <img
                    src={name?.url || nofound}
                    alt="no-found"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  ></img>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    left: "1px",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <IconButton
                    aria-label="more"
                    sx={{ margin: "5px" }}
                    onClick={() => DeleteImgs(name.id)}
                  >
                    <CloseIcon
                      sx={{
                        color: "text.primary",
                        p: 0,
                        m: 0,
                        cursor: "pointer",
                        background: "#ff0000",
                        borderRadius: "8px",
                      }}
                    />
                  </IconButton>
                </div>
              </>
            </ListItem>
          ))}
      </AnimatePresence>
    </div>
  );
}

function ListItem({ children, onClick }) {
  const [isPresent, safeToRemove] = usePresence();

  const animations = {
    layout: true,
    initial: "out",
    style: {
      display: "inline-block",
      margin: "8px",
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileHover: "tapped",
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0 },
      tapped: { scale: 0.98, transition: { duration: 0.1 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  return <motion.h2 {...animations}>{children}</motion.h2>;
}

function AddNewImg({ imgStyle, changeInput }) {
  const [isPresent, safeToRemove] = usePresence();


  const animations = {
    layout: true,
    initial: "out",
    style: {
      display: "inline-block",
      margin: "2px 8px",
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileHover: "tapped",
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0 },
      tapped: { scale: 0.98, transition: { duration: 0.1 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };



  return (
    <motion.h2 {...animations}>

      <div style={SIZETYPE[imgStyle]}>
        <Paper
          sx={{
            backgroundColor: "background.header",
            margin: 0,
            padding: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              margin: "auto",
            }}
          >

            <label style={{}}>
              <input
                hidden
                sx={{ display: "none", }}
                type="file"
                multiple
                onChange={changeInput}
              ></input>
              <Button
                sx={{ height: "100%", width: "100%", padding: 0, margin: 0, textAlign: "center" }}
                component="span"

              >
                Agregar imagen Nueva
              </Button>
            </label>

            {/* <Button sx={{height: "100%", width: "100%", padding: 0, margin: 0}}> Agregar imagen Nueva</Button> */}
          </div>
        </Paper>
      </div>


    </motion.h2>
  );
}
