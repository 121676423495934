import React from "react";
import {
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Switch,
  Typography,
} from "@mui/material";
import Container from "../../layout/gridContainer";
import BranchManages from "../../api/branchManages";
import { useTableFilters } from "../../hooks/searchParams";
import BasicTable from "../../components/GenericTable";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PageParam from "../../components/PageParam";
import GenericEmptyTable from "../../components/GenericEmptyTable";
import CardHeader from "@mui/material/CardHeader";
import CircleIcon from "@mui/icons-material/Circle";
import { CustomTable } from "../../components/GenericTable";
import { ModalRight } from "../../components/GenericsModals";
import WarehouseService from "../../api/warehouseService";
import { useAuth } from "user-context-fran-dev";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect } from "react";
import Alert from '@mui/material/Alert';

const StorePos = () => {
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  /*     let filter = [];
    filter.push({ key: "status_exclude", value: `1-2` }); */
  const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] =
    useTableFilters(WarehouseService, setTable, setLoading);

  return (
    <Container>
      {data && (
        <ModalEdit
          setOpen={setOpen}
          open={open}
          data={data}
          update={UdateTable}
        />
      )}

      <Grid container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
        >
          <Grid item xs={12} sm={8}>
            <Grid container fontWeight="bold">
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  className="title-card"
                  sx={{ paddingBottom: '2%' }}
                >
                  <strong>Puntos de venta en línea</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>

      {table && (
        <>
          {" "}
          <CustomTable
            titles={[
              { title: "#" },
              { title: "Sucursal / Dirección" },
              { title: "Vende en línea?", align: "center" },
              { title: "Más", align: "left" },
            ]}
          >
            {table.data.map((data, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell
                  align="left"
                  sx={{ minWidth: "20px", maxWidth: "15vw" }}
                >
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        minWidth: "20px",
                        maxWidth: "18vw",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Typography>
                </TableCell>

                <TableCell sx={{ maxWidth: "250px", m: 0, p: 0 }} align="left">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      minWidth: "20px",
                      maxWidth: "100%",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <CardHeader
                      sx={{ m: 0, p: 0, marginLeft: "16px" }}
                      title={
                        <Typography variant="subtitle1" sx={{ m: 0, p: 0 }}>
                          {data.name}
                        </Typography>
                      }
                      subheader={`${data.address?.street ? data.address?.street : ""
                        } ${data.address?.postal_code
                          ? "#" + data.address?.postal_code + ","
                          : ""
                        } ${data.address?.suburb ? data.address?.suburb + "," : ""
                        } ${data.address?.city ? data.address?.city + "," : ""} ${data.address?.state ? data.address?.state : ""
                        }`}
                    />
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.sell_products_online ? (
                      <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                    ) : (
                      <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ minWidth: "20px", maxWidth: "10vw" }}
                >
                  <Button
                    onClick={() => {
                      setData(data);
                      setOpen(true);
                    }}
                  >
                    Editar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </CustomTable>
          <PageParam
            totalpages={table.pagination}
            params={params}
            handleChange={handleSearch}
          />
        </>
      )}

      {loading && !table && (
        <Grid container justifyContent="center" mt={5}>
          <CircularProgress />
        </Grid>
      )}
      {!table && !loading && <GenericEmptyTable />}
    </Container>
  );
};

const ModalEdit = ({ setOpen, open, data, update }) => {
  const { logout, auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(data.sell_products_online);

  const handleChange = async (event) => {
    setLoading(true);
    setCheck(event.target.checked);

    const wareHouse = new WarehouseService();
    const response = await wareHouse.editDataAuth(
      data.id,
      { sell_products_online: event.target.checked },
      logout
    );

    update();
    setLoading(false);
  };
  useEffect(() => {
    setCheck(data.sell_products_online);
  }, [data]);

  return (
    <ModalRight setOpen={setOpen} open={open} title="Editar almacén">
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {data.name}

      </Typography>
      <Typography >
        {data.address?.street ? data.address?.street : ""}{" "} {data.address?.ext_number ? " #" + data.address?.ext_number + ", " : ""}

      </Typography>
      <Typography>
        {data.address?.suburb ? data.address?.suburb + ", " : ""}      {data.address?.postal_code
          ? "CP. " + data.address?.postal_code + ","
          : ""}
      </Typography>
      <Typography>
        {data.address?.city ? data.address?.city + ", " : ""}   {data.address?.state ? data.address?.state + "." : ""}
      </Typography>

      <Grid container mt="16px">


        <FormControlLabel
          control={
            <Switch
              name="checked"
              disabled={loading || !data.address}
              checked={check}
              onChange={(event) => handleChange(event)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <>
              Mostrar en la <strong>tienda</strong>
            </>
          }
        />

        {!data.address &&
          <Grid item marginTop={'16px'}>
            <Alert severity="warning"> Para poder habilitar el almacén es necesario contar con una direccion.</Alert>
          </Grid>
        }
      </Grid>
    </ModalRight>
  );
};

export default StorePos;
