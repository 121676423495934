import { useEffect, useState } from 'react'


const useNexOrder = (table) => {
    const [idOrder, setIdOrder]= useState("");

    useEffect(()=>{
        if (table) {
            setIdOrder(table[0].id + 1);
          } else {
            setIdOrder(1);
          }
    },[table])

    return [idOrder];
  
}
 
export default useNexOrder;