import React, { useState } from "react";
import Container from "../../layout/gridContainer";
import { CircularProgress, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { useTableFilters } from "../../hooks/searchParams";
import { useNavigate } from "react-router-dom";
import { GetcustomersList } from "../../api/userList";
import GenericEmptyTable from "../../components/GenericEmptyTable";
import PageParam from "../../components/PageParam";
import BasicTable from "../../components/GenericTable";
import { formatMoney } from "../../utils/functions";
import { DateFormat } from "../../components/DateFormat";

const StoreCustomers = () => {
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] = useTableFilters(GetcustomersList, setTable, setLoading);

  return (
    <Container>
      <Grid container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
        >
          <Grid item xs={12} sm={6}>
            <Grid container fontWeight="bold">
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  className="title-card"
                  sx={{ paddingBottom: '2%' }}
                >
                  <strong>Clientes</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {table && <> <BasicTable titles={[
            "Fecha de registro",
            "Nombre del cliente",
            /*    "Apellido", */
            "Correo Electrónico",
            "Verificado",
          ]}  >

            {table.data.map((data, index) => (

              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell sx={{ maxWidth: '350px' }} align="left">
                  <Typography variant="subtitle2" component="div" sx={{ minWidth: "50px", maxWidth: "50vw", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <DateFormat beginDate={data?.date_joined} />
                  </Typography>
                </TableCell>

                <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "15vw" }}>
                  <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Typography variant="subtitle2" component="div" sx={{ minWidth: "20px", maxWidth: "18vw", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {data?.first_name} {data?.last_name}
                    </Typography>
                  </Typography>
                </TableCell>

                <TableCell sx={{ maxWidth: '350px' }} align="left">
                  <Typography variant="subtitle2" component="div" sx={{ minWidth: "50px", maxWidth: "50vw", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data?.email}
                  </Typography>
                </TableCell>

                <TableCell sx={{ maxWidth: '350px' }} align="left">
                  <Typography variant="subtitle2" component="div" sx={{ minWidth: "50px", maxWidth: "50vw", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {data?.email_confirmation === 0 ? 'No' : 'Si'}
                  </Typography>
                </TableCell>

              </TableRow>


            ))}


          </BasicTable>
            <PageParam totalpages={table.pagination} params={params} handleChange={handleSearch} />
          </>
          }


          {loading && !table && (
            <Grid container justifyContent="center" mt={5}>
              <CircularProgress />
            </Grid>
          )}
          {!table && !loading &&
            <GenericEmptyTable />
          }


        </Grid>

      </Grid>
    </Container>
  );
};

export default StoreCustomers;
