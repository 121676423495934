import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export  class PosDiscount extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'pos/discount';
      super(baseUrl, endPoint)
    }
}

export  class UserCode extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/auth_code';
    super(baseUrl, endPoint)
  }
}

export  class RefreshCode extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'pos/auth_code/refresh';
    super(baseUrl, endPoint)
  }
}


