import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom'
import { Suppliers } from '../../../../api/supplierService'
import { useAuth } from "user-context-fran-dev";
import GenericAddress from "../../../../components/GenericAddress"
import Container from "../../../../layout/gridContainer";
import GenericProfile from "../../../../components/GenericProfile"
import PaymentConditions from "../../../../components/paymentConditions"
import Phonebook from "../../../../components/inventario/phonebook"
import Comments from '../../../../components/inventario/comments'

const SuppliersDetails = () => {
 
  const { logout } = useAuth()
  const [loading, setloading] = useState()
  const { id } = useParams()
  const [dataSupplier, setDataSuppllier] = useState()

  useEffect(() => {
    window.localStorage.setItem("menu", 18);
    GetInfoSupplier()
    return () => {
      window.localStorage.setItem("menu", 5);
    };
}, []);

const GetInfoSupplier = () => {
  (async () => {
    let getSupplier = new Suppliers();
    let response = await getSupplier.getAllByIdAuth(id, logout)
    console.log(response.data.data)
    setDataSuppllier(response.data)
  })();
}


  return (
    <>
    {dataSupplier && 
    <Container >
      <GenericProfile data={dataSupplier} UpdateInfo={GetInfoSupplier} target={'supplier'}/>
      <GenericAddress data={dataSupplier} UpdateInfo={GetInfoSupplier} target={'supplier'}/>
      <PaymentConditions data={dataSupplier} UpdateInfo={GetInfoSupplier} target={'supplier'}/>
      <Phonebook data={dataSupplier} UpdateInfo={GetInfoSupplier} target={'supplier'}/>
      <Comments data={dataSupplier} UpdateInfo={GetInfoSupplier} target={'supplier'}/>
    </Container>
    }
   
    </>
  )
}
export default SuppliersDetails;